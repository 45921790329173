import React from 'react';
import ReactResizeDetector from 'react-resize-detector';
import ProductImage from './ImageTab/ProductImage';
import { Button } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import ImageMissing from './ImageMissing';
import ImageGuide from './ImageGuide';
import AdditionalImageStyles from './ImageTab/AdditionalImageStyles';
import ImageStyleChange from './ImageStyleChange';
import { imageCategories, mainCategoriesList } from '../util/Data';
import {
  getOtmmBaseUrl,
  getFormat,
  getValuesFromTwoFields,
  getValueBasedOnTheCondition,
  checkArrayAndLength,
  checkIsSyscoBrand
} from '../util/Util';
import { REJECTED_REASON_CODES, PREF_IMG_REJECT_EXP_DAYS } from '../util/Constants';
import { getPreferredImages, getAdditionalAndUnclassifiedImages, getDisabledOptions } from '../util/ImageUtils';

class MarketingInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      commentShow: []
    };
    this.toggleComment = this.toggleComment.bind(this);
    this.sendComments = this.sendComments.bind(this);
    this.collectComments = this.collectComments.bind(this);
  }

  toggleComment(key) {
    let commentShow = this.state.commentShow;
    commentShow[key] = !commentShow[key];
    this.setState({
      commentShow: commentShow
    });
  }

  collectComments(attrKey, attrValue, comment, type) {
    let { actions } = this.props;
    actions.collectComment({
      comment: {
        field: attrKey,
        originalValue: attrValue,
        type: type,
        comment: comment
      }
    });
  }

  sendComments() {
    let { actions, item } = this.props;
    actions.sendComments({ ...item });
  }

  getRejectReasonText = review => {
    let fullLabel = false;

    if (review) {
      let target = _.find(REJECTED_REASON_CODES, obj => review.code === obj.code);
      let reason = _.get(target, 'name', null);
      let rejectComment = _.get(review, 'comment', '');

      if (reason) {
        fullLabel = (
          <span className="code-reason">
            <span className="code">Rejected Reason:</span>
            <span className="reason" title={` ${reason}. ${rejectComment}`}>{` ${reason}. ${rejectComment}`}</span>
          </span>
        );
      }
    }

    return fullLabel;
  };

  getRenditionInfo = renditions => {
    let rendition = null;
    if (renditions.thumbnail) {
      rendition = renditions.thumbnail;
    } else if (renditions.preview) {
      rendition = renditions.preview;
    } else if (renditions.master) {
      rendition = renditions.master;
    }
    return rendition;
  };

  setAndGetImageUrl = (rendition, syscoImagePath) => {
    return rendition ? syscoImagePath + rendition.id : '';
  };

  checkImageObjectIsDefaultOrNot = imgObj => {
    return (
      imgObj.imageStyle &&
      (imgObj.imageStyle.toLowerCase() === 'raw or uncooked' || imgObj.imageStyle.toLowerCase() === 'out of packaging')
    );
  };

  getImageChangeTypeAndDeleteStatus = (cmt, deleteStatus, changeType) => {
    let imageChangeType = changeType;
    let imageDeleteStatus = deleteStatus;
    if (cmt.imgComment.changeType === 'STYLE_CHANGE') {
      imageChangeType = `STYLE CHANGE (${cmt.value} -> ${cmt.imgComment.style})`.toUpperCase();
    }
    if (cmt.imgComment.changeType === 'DELETE') {
      imageChangeType = `IMAGE DELETION (${cmt.imgComment.style})`.toUpperCase();
      imageDeleteStatus = true;
    }
    if (cmt.imgComment.changeType === 'CREATE') {
      imageChangeType = `NEW IMAGE (${cmt.imgComment.style})`.toUpperCase();
    }
    if (cmt.imgComment.changeType === 'DELETE_REPLACE') {
      imageChangeType = `IMAGE REPLACE (${cmt.imgComment.style})`.toUpperCase();
      imageDeleteStatus = true;
    }
    return { changeType: imageChangeType, deleteStatus: imageDeleteStatus };
  };

  getDeleteReplaceImageAndUrl = (cmt, image, prev, order, currentIteration) => {
    let prevImage = prev;
    let imageUrl = image;
    if (cmt.imgComment.changeType === 'DELETE_REPLACE') {
      prevImage = `${getOtmmBaseUrl()}/otmmstream/productimage/${cmt.imgComment[order[currentIteration]]}`;
    } else {
      imageUrl = `${getOtmmBaseUrl()}/otmmstream/productimage/${cmt.imgComment[order[currentIteration]]}`;
    }
    return { previousImage: prevImage, imgUrl: imageUrl };
  };

  getImageUrlsWhenTypeIsNotCreateOrDelete = (cmt, image, previousImage) => {
    let imageUrl = image;
    let prevImage = previousImage;
    if (cmt.imgComment.changeType !== 'CREATE') {
      let order = ['otmmMasterRenditionId', 'otmmPreviewRenditionId', 'otmmThumbnailRenditionId'];
      for (let i = 0; i < order.length; i++) {
        if (checkArrayAndLength(cmt.imgComment[order[i]])) {
          const { previousImage, imgUrl } = this.getDeleteReplaceImageAndUrl(cmt, imageUrl, prevImage, order, i);
          prevImage = previousImage;
          imageUrl = imgUrl;
          break;
        }
      }
    }
    return { imageUrl, prevImage };
  };

  getImageUrlFromComment = cmt => {
    let imageUrl = null;
    let prevImage = null;
    if (cmt.value && cmt.value.match(/^http(.*)/i)) {
      imageUrl = cmt.value;
    } else if (cmt.imgComment) {
      if (['CREATE', 'DELETE_REPLACE'].includes(cmt.imgComment.changeType)) {
        let { reservationId, meta } = cmt.imgComment;
        try {
          meta = JSON.parse(meta);
          imageUrl = `${getOtmmBaseUrl()}/otmmstream/s3image/${reservationId}.${getFormat(meta.mime)}`;
        } catch (error) {}
      }
      const { imageUrl: imgUrl, prevImage: previousImage } = this.getImageUrlsWhenTypeIsNotCreateOrDelete(
        cmt,
        imageUrl,
        prevImage
      );
      imageUrl = imgUrl;
      prevImage = previousImage;
    }
    return { imageUrl, prevImage };
  };

  setPendingImageComments = pendingComments => {
    let pendingImageComments = [];
    if (pendingComments.comments && pendingComments.comments.length > 0) {
      pendingComments.comments.forEach(cmt => {
        if (cmt.field === 'PREFERRED IMAGE' && cmt.status === 10) return;

        let pendingImageCommentObject = {};
        if (cmt.imgComment) {
          const {
            changeType: imageChangeType,
            deleteStatus: imageDeleteStatus
          } = this.getImageChangeTypeAndDeleteStatus(cmt, false, '');
          const { imageUrl, prevImage } = this.getImageUrlFromComment(cmt);
          pendingImageCommentObject = {
            ...pendingImageCommentObject,
            comment: cmt.comment,
            type: imageChangeType,
            imageUrl: imageUrl,
            prevImage: prevImage,
            status: cmt.status,
            changeType: _.get(cmt.imgComment, 'changeType', null),
            assetId: _.get(cmt.imgComment, 'otmmAssetId', null),
            otmmMasterRenditionId: _.get(cmt.imgComment, 'otmmMasterRenditionId', null),
            otmmPreviewRenditionId: _.get(cmt.imgComment, 'otmmPreviewRenditionId', null),
            otmmThumbnailRenditionId: _.get(cmt.imgComment, 'otmmThumbnailRenditionId', null),
            isImageTypeDeleted: imageDeleteStatus
          };
        } else if (cmt.field === 'Image Feedback') {
          pendingImageCommentObject = {
            ...pendingImageCommentObject,
            comment: cmt.comment,
            type: 'FEEDBACK',
            imageUrl: cmt.value
          };
        }

        if (Object.keys(pendingImageCommentObject).length > 0) {
          if (cmt.status === 10) {
            let rejectReasonLabel = this.getRejectReasonText(cmt.imgComment.review);

            pendingImageCommentObject = {
              ...pendingImageCommentObject,
              previousCommentId: cmt.id,
              rejectReasonLabel
            };
          }
          pendingImageComments.push(pendingImageCommentObject);
        }
      });
    }
    return pendingImageComments;
  };

  getCommentsPopUpData = (popUpData, existingStyles, image, changeType, getImageUrl) => {
    let commentPopupData = { ...popUpData };
    switch (changeType) {
      case 'CREATE':
        commentPopupData = {
          ...commentPopupData,
          field: 'Add New Image',
          currentValue: '',
          fieldType: 'IMAGE_NEW',
          fieldCaption: ' ',
          existingStyles: existingStyles
        };
        break;
      case 'STYLE_CHANGE':
        commentPopupData = {
          ...commentPopupData,
          field: 'Image Style',
          currentValue: image.imageStyle,
          fieldType: 'IMAGE_STYLE',
          fieldCaption: 'Image Style',
          existingStyles: existingStyles,
          image: { ...image, url: getImageUrl(image) }
        };
        break;
      case 'DELETE':
        commentPopupData = {
          ...commentPopupData,
          field: 'Delete Image',
          currentValue: getImageUrl(image),
          fieldType: 'IMAGE_DELETE',
          fieldCaption: ' ',
          image: image
        };
        break;
      default:
        break;
    }
    return commentPopupData;
  };

  getPendingCommentImageUrl = pendingImageComment => {
    return (
      pendingImageComment.imageUrl && (
        <div
          className="image-link"
          onClick={() => {
            window.open(pendingImageComment.imageUrl);
          }}
        >
          image link
        </div>
      )
    );
  };

  getPendingImageCommentReason = pendingImageComment => {
    return (
      pendingImageComment.rejectReasonLabel && (
        <div className="reject-season-wrapper">{pendingImageComment.rejectReasonLabel}</div>
      )
    );
  };

  renderImgPreferenceRejects = () => {
    const { pendingComments } = this.props;
    const rejects = _.filter(pendingComments.comments, cmt => {
      const isReject = cmt.field === 'PREFERRED IMAGE' && cmt.status === 10;
      const expiryDate = moment(cmt.updatedAt).add(PREF_IMG_REJECT_EXP_DAYS, 'days');
      const isExpired = moment().isAfter(expiryDate);

      if (isReject && !isExpired) {
        return cmt;
      }
    });

    return (
      <div className="pending-image-comment-section">
        {_.map(rejects, obj => {
          const meta = _.get(obj, 'imgComment.meta', '');
          const jsonMeta = JSON.parse(meta);
          const styleBucket = _.get(jsonMeta, 'styleBucket', null);
          const styleBucketText = imageCategories[styleBucket]?.caption;
          const reason = _.get(obj, 'imgComment.review.comment', '');
          const masterRendition = _.get(obj, 'imgComment.otmmMasterRenditionId', '');
          const imageUrl = `${getOtmmBaseUrl()}/otmmstream/productimage/${masterRendition}`;

          return (
            <div key={obj.id} className={`comment-row rejected-img-update`}>
              <div className={'comment-row-inner'}>
                <div className="type">IMAGE PREFERENCE REJECTED:</div>
                <div className="comment">{styleBucketText}</div>
                <div className="image-link" onClick={() => window.open(imageUrl)}>
                  image link
                </div>
              </div>
              <div className="reject-season-wrapper">
                <span className="code-reason">
                  <span className="code">Rejected Reason:</span>
                  <span className="reason" title={reason}>
                    {reason}
                  </span>
                </span>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  render() {
    let { item, actions, pendingComments } = this.props;
    let isSyscoBrand = checkIsSyscoBrand(item);

    const syscoImagePath = `${getOtmmBaseUrl()}/otmmstream/productimage/`;

    const getRendition = renditions => {
      return this.getRenditionInfo(renditions);
    };

    const getImageUrl = imgObj => {
      let rendition = getRendition(imgObj.renditions);
      return this.setAndGetImageUrl(rendition, syscoImagePath);
    };

    const getImageTitle = imgObj => {
      let rendition = getRendition(imgObj.renditions);
      return rendition.name;
    };

    const isDefault = imgObj => {
      return this.checkImageObjectIsDefaultOrNot(imgObj);
    };

    const imageFn = {
      getRendition: getRendition,
      getImageUrl: getImageUrl,
      getImageTitle: getImageTitle,
      isDefault: isDefault
    };

    const { preferredImages: priorityStylesImages } = getPreferredImages(item.images);

    const {
      additionalImages: otherStylesImages,
      unclassifiedImages: noStylesImages,
      existingStyles
    } = getAdditionalAndUnclassifiedImages(item.images);

    const commentsPopupNewImage = () => {
      actions.commentsPopup({
        item: item,
        field: 'Add New Image',
        currentValue: '',
        fieldType: 'IMAGE_NEW',
        fieldCaption: ' ',
        existingComment: null,
        existingStyles: existingStyles
      });
    };

    const onClickResubmit = (changeType, assetId, previousCommentId) => {
      let { actions, item } = this.props;
      let images = item.images;

      let image = _.find(images, obj => assetId === obj.assetId);
      let commentPopupData = {
        item,
        previousCommentId,
        existingComment: null
      };

      commentPopupData = {
        ...this.getCommentsPopUpData(commentPopupData, existingStyles, image, changeType, getImageUrl)
      };
      actions.commentsPopup(commentPopupData);
    };

    const onCancelButtonClicked = (commentData, previousCommentId) => {
      let { actions, item } = this.props;
      const { otmmMasterRenditionId, otmmPreviewRenditionId, otmmThumbnailRenditionId, imageUrl } = commentData;
      const getRejectComment = commentData.comment.match(/\[(.*?)\]/);
      const commentText = commentData.comment.substring(commentData.comment.lastIndexOf(' '));
      actions.sendDeleteImageComment(
        item.supc,
        item.suvc,
        {
          renditions: {
            thumbnail: { id: otmmThumbnailRenditionId },
            master: { id: otmmMasterRenditionId },
            preview: { id: otmmPreviewRenditionId }
          },
          assetId: commentData.assetId
        } || {},
        {
          ...{
            item,
            previousCommentId,
            comment: commentText,
            field: 'Delete Image',
            id: null,
            stepId: item.basicData.stepId,
            type: commentData.type,
            originalValue: imageUrl || ''
          }
        },
        getRejectComment ? getRejectComment[1] : null
      );
    };

    let pendingImageComments = this.setPendingImageComments(pendingComments);

    return (
      <ReactResizeDetector
        handleWidth
        handleHeight
        render={({ width, height }) => (
          <div className="attr-panel marketing" style={{ height: window.innerHeight - 150 + 'px' }}>
            <div className="attr-section">
              <div className="attr-section-title">Images</div>
              <div className="attr-section-subtitle">
                Please review, update, and add product images based on image guidelines.
              </div>
              <ImageGuide />
              {item.imagesLoaded ? (
                <div>
                  {pendingImageComments.length > 0 && (
                    <div style={{ marginTop: '25px' }}>
                      <div className="attr-section-title">Pending Approval</div>
                      <div className="attr-section-subtitle">
                        These changes are under review, and soon will be approved.
                      </div>
                    </div>
                  )}

                  {pendingImageComments.length > 0 && (
                    <div className="pending-image-comment-section">
                      {pendingImageComments.map((pendingImageComment, index) => {
                        return (
                          <div
                            key={index}
                            className={`comment-row ${getValueBasedOnTheCondition(
                              pendingImageComment.status === 10,
                              'rejected-img-update',
                              ''
                            )}`}
                          >
                            <div className={'comment-row-inner'}>
                              <div className="type">{pendingImageComment.type}</div>
                              <div className="comment">{pendingImageComment.comment}</div>
                              {pendingImageComment.prevImage && (
                                <div
                                  className="image-link"
                                  onClick={() => {
                                    window.open(pendingImageComment.prevImage);
                                  }}
                                  title="This image will be deleted & replaced by the new image."
                                >
                                  prev image
                                </div>
                              )}
                              {this.getPendingCommentImageUrl(pendingImageComment)}
                              {pendingImageComment.status === 10 && !item.readOnly && (
                                <div
                                  className="btn-resubmit"
                                  onClick={() => {
                                    const { changeType, assetId, previousCommentId } = pendingImageComment;
                                    pendingImageComment.isImageTypeDeleted
                                      ? onCancelButtonClicked(pendingImageComment, previousCommentId)
                                      : onClickResubmit(changeType, assetId, previousCommentId);
                                  }}
                                >
                                  {getValueBasedOnTheCondition(
                                    pendingImageComment.isImageTypeDeleted,
                                    'Cancel',
                                    'Re-submit'
                                  )}
                                </div>
                              )}
                            </div>
                            {this.getPendingImageCommentReason(pendingImageComment)}
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {this.renderImgPreferenceRejects()}
                  <div style={{ marginTop: '25px' }}>
                    <div className="attr-section-title">Required Image Styles</div>
                    <div className="attr-section-subtitle"></div>
                  </div>
                  <div className="attr-set images">
                    {mainCategoriesList.map((mainCategory, index) => {
                      let assetId = _.get(priorityStylesImages[mainCategory], 'image.assetId', null);
                      let { disableDelete, disableEdit } = getDisabledOptions(assetId, pendingImageComments);

                      return (
                        <div className="attr-main-img-style-wrapper" key={index}>
                          <div className="attr-main-img">
                            {priorityStylesImages[mainCategory].image && (
                              <ProductImage
                                item={item}
                                image={priorityStylesImages[mainCategory].image}
                                pendingComments={pendingComments}
                                actions={actions}
                                imageFn={imageFn}
                                disableDelete={disableDelete}
                                hideDelete={isSyscoBrand}
                                hideReplace={false}
                              />
                            )}
                            {priorityStylesImages[mainCategory].image === null && (
                              <ImageMissing
                                item={item}
                                imageCategoryName={mainCategory}
                                imageCategory={imageCategories[mainCategory]}
                                pendingComments={pendingComments}
                                actions={actions}
                                existingStyles={existingStyles}
                              />
                            )}
                          </div>
                          <div className="attr-main-img-style-title main">
                            {priorityStylesImages[mainCategory].caption}
                            <ImageStyleChange
                              item={item}
                              image={priorityStylesImages[mainCategory].image}
                              pendingComments={pendingComments}
                              actions={actions}
                              imageFn={imageFn}
                              existingStyles={existingStyles}
                              disableEdit={disableEdit}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <hr className="attr-divider" />
                  <Button
                    key="new_image"
                    className="ant-btn btn-add-image btn-confirm free-image-upload"
                    onClick={commentsPopupNewImage}
                    disabled={item.readOnly}
                  >
                    Upload Images
                  </Button>
                  <AdditionalImageStyles
                    imageFn={imageFn}
                    images={otherStylesImages}
                    existingStyles={existingStyles}
                    readOnly={item.readOnly}
                  />
                  <div style={{ marginTop: '25px' }}>
                    <div className="attr-section-title">Unclassified Images</div>
                    <div className="attr-section-subtitle">Please classify below images.</div>
                  </div>
                  <div className="attr-set images">
                    {noStylesImages.length ? (
                      noStylesImages.map((noStylesImage, index) => {
                        let assetId = _.get(noStylesImage, 'assetId', null);
                        let { disableDelete, disableEdit } = getDisabledOptions(assetId, pendingImageComments);

                        return (
                          <div className="attr-main-img-style-wrapper" key={index}>
                            <div className="attr-main-img">
                              <ProductImage
                                item={item}
                                image={noStylesImage}
                                pendingComments={pendingComments}
                                actions={actions}
                                imageFn={imageFn}
                                disableDelete={disableDelete}
                                hideDelete={false}
                                hideReplace={true}
                              />
                            </div>
                            <div className="attr-main-img-style-title unclassed">
                              Unclassified
                              <ImageStyleChange
                                item={item}
                                image={noStylesImage}
                                pendingComments={pendingComments}
                                actions={actions}
                                imageFn={imageFn}
                                existingStyles={existingStyles}
                                disableEdit={disableEdit}
                              />
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div style={{ marginBottom: '55px' }}>No images.</div>
                    )}
                  </div>
                </div>
              ) : (
                <div style={{ marginTop: '10px' }}>Loading Images...</div>
              )}
              <div style={{ paddingBottom: '25px' }}></div>
            </div>
          </div>
        )}
      />
    );
  }
}

export default MarketingInfo;
