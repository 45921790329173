import React, { forwardRef } from 'react';
import { Table, Input, Select } from 'antd';

import { NUTRITION_REJECT_OPTIONS, NUTRITION_FEEDBACK_KEYS } from '../enums';
import PendingNutritions from './Nutritions/PendingNutritions';

const { TextArea } = Input;
const { Option } = Select;

const CommentExpandedNutrition = ({ commentData, onRejectFieldChanged }, ref) => {
  const { comment, id, status } = commentData;
  const updatedComment = JSON.parse(comment);
  const { feedback: { status: rejectStatus } = { status: 'Select Status', comment: null } } = updatedComment;

  const nutritionData = NUTRITION_FEEDBACK_KEYS.map((key, index) => {
    return {
      updatedComment: { ...updatedComment, fieldKey: key, fieldIndex: index },
      rejectStatus: '',
      commentDescription: ''
    };
  });

  const onChangeComment = (comment, fieldKey) => {
    let updatedCommentFeedbacks = {};
    if (updatedComment.feedback && updatedComment.feedback.comments) {
      if (comment === '' || comment === null) {
        const { [fieldKey]: removingFieldKey, ...rest } = updatedComment.feedback.comments;
        updatedCommentFeedbacks = { ...rest };
      } else {
        updatedCommentFeedbacks = { ...updatedComment.feedback.comments, [fieldKey]: comment };
      }
    }
    onRejectFieldChanged({
      id,
      comment: JSON.stringify({
        ...updatedComment,
        feedback: { ...updatedComment.feedback, comments: { ...updatedCommentFeedbacks } }
      }),
      status: rejectStatus
    });
  };

  const getInvalidDescritionStatus = totalComments => {
    if (totalComments < 1 && rejectStatus === 'REJECT') return true;
    return false;
  };

  const renderCommentDescriptionColumn = fieldValue => {
    const {
      updatedComment: { fieldKey, feedback: { comments } = { comments: {} } }
    } = fieldValue || {};
    let totalComments = null;

    if (comments) totalComments = Object.keys(comments).length;

    if ([0, 10, 20, 30].includes(status)) {
      const value = comments && comments[fieldKey] ? comments[fieldKey] : null;
      return (
        <TextArea
          className={getInvalidDescritionStatus(totalComments) ? 'nt-feedback-invalid-txt-error' : undefined}
          defaultValue={value}
          rows={4}
          disabled={[30].includes(status)}
          onChange={e => onChangeComment(e.target.value, fieldKey)}
        />
      );
    }
  };

  const onChangeRejectStatus = status => {
    onRejectFieldChanged({
      id,
      comment: JSON.stringify({ ...updatedComment, feedback: { ...updatedComment.feedback, status } }),
      status
    });
  };

  const renderReasonColumn = fieldValue => {
    const {
      updatedComment: { fieldKey }
    } = fieldValue || {};
    if (fieldKey === 'nutritionAnalysisHeader') {
      return (
        <Select
          disabled={status === 30}
          defaultValue={rejectStatus}
          dropdownMatchSelectWidth={false}
          style={{ width: '100%' }}
          onChange={value => onChangeRejectStatus(value)}
          className="image-feedback-dropdown"
        >
          <Option key={0} value="Select Status">
            Select Status
          </Option>
          {NUTRITION_REJECT_OPTIONS.map(value => {
            return (
              <Option key={value} value={value} disabled={value === 'REJECT' && status === 10}>
                {value}
              </Option>
            );
          })}
        </Select>
      );
    }
  };

  const columns = [
    {
      title: 'New/Changed Data',
      dataIndex: 'updatedComment',
      key: 'updatedComment',
      render: fieldValue => <PendingNutritions updatedComment={fieldValue} />
    },
    {
      title: 'Reject Status',
      width: 250,
      render: fieldValue => renderReasonColumn(fieldValue)
    },
    {
      title: 'Description (At least 1 comment required)',
      dataIndex: 'feedback',
      key: 'feedback',
      width: 250,
      render: (fieldValue, data) => renderCommentDescriptionColumn(data)
    }
  ];

  return (
    <div className="row-expand" style={{ display: 'flex' }}>
      <div className="image-expand-info-table">
        <Table dataSource={nutritionData} columns={columns} pagination={false} />
      </div>
    </div>
  );
};

export default forwardRef(CommentExpandedNutrition);
