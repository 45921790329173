import React, { forwardRef, useEffect } from 'react';
import { Table, Button } from 'antd';

import PendingCoreData from '../CoreDataExpanded/PendingCoreData';
import CoreAttributeHistory from './../CoreAttributeHistory';

const CoreDataExpanded = (
  {
    commentData,
    onAttributeChange,
    onRejectAllModalOpen,
    onConfirmRejectAll,
    onCancelRejectAll,
    isAllRejectModalOpen,
    getItemDetails,
    selfAssignComment,
    userId
  },
  ref
) => {
  const { comment, id, status, supc, catManFlag, assignee, assigningComment } = commentData;
  const updatedComment = JSON.parse(comment);

  useEffect(() => {
    getItemDetails({ supc, id });
  }, [supc, id, getItemDetails]);

  const handleAttributeChange = data => {
    onAttributeChange({ ...data });
  };

  const onClickAssignToMe = () => {
    selfAssignComment({
      commentId: id,
      userId
    });
  };

  const columns = [
    {
      title: () => {
        return (
          <div className="flags-parts">
            <span>{`Catman Flag: ${catManFlag || '...'}`}</span>
            <Button className="assign-to-me-btn" disabled={assigningComment} onClick={onClickAssignToMe}>
              Assign to Me
            </Button>
            {assignee && <span className="assignee-label">{`Assigned to: ${assignee}`}</span>}
            <CoreAttributeHistory supc={supc} type={'ITEM_HISTORY'} />
          </div>
        );
      },
      dataIndex: 'updatedComment',
      key: 'updatedComment',
      render: (text, record) => {
        return (
          <PendingCoreData
            updatedComment={record.updatedComment}
            id={id}
            supc={supc}
            handleAttributeChange={handleAttributeChange}
            status={status}
            assignee={assignee}
            currentUser={userId}
          />
        );
      }
    }
  ];

  return (
    <div className="row-expand" style={{ display: 'flex' }}>
      <div id="core-expanded-table-wrapper" className="image-expand-info-table">
        <Table
          rowKey={'rowKey'}
          dataSource={[{ rowKey: id, updatedComment, rejectStatus: '', commentDescription: '' }]}
          columns={columns}
          pagination={false}
        />
      </div>
    </div>
  );
};

export default forwardRef(CoreDataExpanded);
