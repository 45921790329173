import React, { useState, useEffect, useCallback } from 'react';
import { Checkbox } from 'antd';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PFAS_SINGLE_SELECTIONS } from '../../util/Constants';
import {
  handlePfasCheckBoxSelection,
  checkIfPfasAttributeIsDisabled,
  checkEachPfasAttributeIsDisabled
} from '../../util/Util';
import * as actionCreators from '../../actions';

const PfasCheckboxGroup = ({ record: { supc, brandCode }, obj: { picklist, name, attrId }, changes }) => {
  const [singleSelections, setSingleSelections] = useState([]);
  const bcName = useSelector(state => _.get(state.massUpdate.pfas, 'filters.bc', null));
  const bc = useSelector(state => _.find(_.get(state.massUpdate.pfas, 'completeTaxonomy', []), { name: bcName }));
  const isLoading = useSelector(state => _.get(state.massUpdate.pfas, 'isLoading', false));
  const isSaving = useSelector(state => _.get(state.massUpdate.pfas, 'isSaving', false));

  const [isDisabled, setIsDisabled] = useState(false);

  const { disabledTooltip } = checkIfPfasAttributeIsDisabled({ bc: { id: bc?.id.toString() } }, { name });

  const disabledTooltipContent = disabledTooltip ? 'This attribute is not applicable for this item category.' : null;

  useEffect(() => {
    let disabledStatus = false;
    if (brandCode === 'PACKER' || isSaving || isLoading) {
      disabledStatus = true;
    }
    setIsDisabled(disabledStatus);
  }, [brandCode, isSaving, isLoading]);

  const dispatch = useDispatch();
  const { updatePfasMassItemAttributeSelection } = bindActionCreators(actionCreators, dispatch);

  useEffect(() => {
    // Update single selections when picklist changes
    const updatedSingleSelections = picklist
      .filter(eachPicklist => PFAS_SINGLE_SELECTIONS.includes(eachPicklist.value))
      .map(eachPicklist => eachPicklist.id);
    setSingleSelections(updatedSingleSelections);
  }, [picklist]);

  const updatedPicklistValues = picklist.map(eachPicklist => {
    return {
      ...eachPicklist,
      label: eachPicklist.value,
      value: eachPicklist.id,
      disabled: checkEachPfasAttributeIsDisabled(changes, attrId, singleSelections, eachPicklist)
    };
  });

  useEffect(() => {
    // Update single selections when picklist changes
    const updatedSingleSelections = picklist
      .filter(eachPicklist => PFAS_SINGLE_SELECTIONS.includes(eachPicklist.value))
      .map(eachPicklist => eachPicklist.id);
    setSingleSelections(updatedSingleSelections);
  }, [picklist]);

  const handleSelectionChange = useCallback(
    value => {
      dispatch(
        updatePfasMassItemAttributeSelection({
          attrId,
          value: [...handlePfasCheckBoxSelection(changes, attrId, value, singleSelections)],
          supc
        })
      );
    },
    [dispatch, attrId, singleSelections, changes, supc]
  );

  return (
    <Checkbox.Group
      style={{ display: 'contents' }}
      disabled={isDisabled || Boolean(disabledTooltip)}
      options={updatedPicklistValues}
      value={changes[attrId]}
      tooltip={disabledTooltipContent}
      selected={changes[attrId]}
      onChange={handleSelectionChange}
    />
  );
};

export default PfasCheckboxGroup;
