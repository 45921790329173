import React from 'react';
import { Tooltip } from 'antd';

const TooltipInfo = ({ tooltip, className, placement }) => {
  return (
    <Tooltip
      overlayClassName={className}
      style={{ width: '100px' }}
      placement={placement}
      title={
        <div style={{ paddingLeft: '15px', paddingRight: '15px' }} className="gdsn-info">
          {tooltip}
        </div>
      }
    >
      {'i'}
    </Tooltip>
  );
};

export default TooltipInfo;
