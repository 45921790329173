import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button } from 'antd';
import ImageGuideNonExpanded from './ImageGuideNonExpanded';
import MassUploadDataTable from './MassUploadDataTable';
import { retrieveAllMassImageUpdateData, submitMassImageUpload } from '../../actions';
import { MASS_IMAGE_FIELD_KEYS } from '../../util/Constants';
import ConfirmationModal from './ConfirmationModal';

const MassImageUploadOverlay = ({ onCloseModal, supcs }) => {
  const dispatch = useDispatch();
  const [isConfirmModalActivated, setConfirmModalStatus] = useState(false);

  const suvc = useSelector(state => _.get(state.vendor, 'suvc', 0));
  const loadingPercentage = useSelector(state => _.get(state.massUpdate, 'loadingPercentage', 0));
  const isLoadingContent = useSelector(state => _.get(state.massUpdate, 'isLoadingContent', false));
  const itemList = useSelector(state => _.get(state.vendor, 'list', []));
  const massImages = useSelector(state => _.get(state.massUpdate, 'image', {}));
  const isMassUploading = useSelector(state => _.get(state.massUpdate, 'isUploading', false));

  const handleInitialDataLoad = useCallback(() => {
    dispatch(retrieveAllMassImageUpdateData({ supcs, suvc, itemList }));
  }, [supcs, suvc, itemList, dispatch]);

  const handleMassUpload = useCallback(() => {
    dispatch(submitMassImageUpload({ massImages, suvc }));
  }, [massImages, suvc, dispatch]);

  window.addEventListener('beforeunload', function(e) {
    e.preventDefault();
    e.returnValue = '';
  });

  const getSubmitButtonDisableStatus = useCallback(() => {
    let disabledStatus = true;
    try {
      Object.keys(massImages).forEach(eachSupc => {
        if (eachSupc) {
          const { changes } = massImages[eachSupc];
          MASS_IMAGE_FIELD_KEYS.forEach(field => {
            const { selectedImagePreview, imageAngle, imageFacing, imageStyle, imageType, isValidated } = changes[
              field
            ];
            if (selectedImagePreview && imageAngle && imageFacing && imageStyle && imageType && isValidated) {
              disabledStatus = false;
            } else if (selectedImagePreview && !(imageAngle && imageFacing && imageStyle && imageType && isValidated)) {
              disabledStatus = true;
              throw new Error();
            }
          });
        }
      });
    } catch (error) {}
    return disabledStatus;
  }, [massImages]);

  useEffect(() => {
    handleInitialDataLoad();
  }, [handleInitialDataLoad]);
  return (
    <Modal
      visible
      onCancel={() => {
        if (!isMassUploading) onCloseModal(null);
      }}
      footer={[]}
      wrapClassName="image-mass-upload-modal"
      bodyStyle={{ overflowY: 'scroll' }}
    >
      <div className="mass-upload-body-container">
        <div className="firt-row-image-descriptions">
          <div className="image-instructions-container">
            <div className="instruction-sub-title">Images</div>
            <div className="attr-section-subtitle">
              Please review, update, and add product images based on image guidelines.
            </div>
            <ImageGuideNonExpanded />
          </div>
        </div>
        <div>
          <MassUploadDataTable percentage={loadingPercentage} isLoadingContent={isLoadingContent} />
        </div>
        {!isLoadingContent && (
          <>
            <div className="footer-mass-upload-btn-container">
              <Button
                className={`footer-btn btn-confirm ${
                  getSubmitButtonDisableStatus() || isMassUploading
                    ? `disabled-mass-upload-image-submit-button`
                    : undefined
                }`}
                title="Submit changes to review"
                type="primary"
                disabled={getSubmitButtonDisableStatus() || isMassUploading}
                onClick={() => setConfirmModalStatus(true)}
              >
                {`${isMassUploading ? `...SUBMITTING` : `SUBMIT FOR REVIEW`}`}
              </Button>
            </div>
          </>
        )}
        {isConfirmModalActivated && (
          <ConfirmationModal
            isVisible={isConfirmModalActivated}
            handleVisibility={setConfirmModalStatus}
            handleSubmit={handleMassUpload}
          />
        )}
      </div>
    </Modal>
  );
};

export default MassImageUploadOverlay;
