import React, { useEffect, useState } from 'react';
import { Button, Modal, Pagination, Spin, Tooltip } from 'antd';
import { LoadingOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { bindActionCreators } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

import AttributeMassEditTaxoFilter from './AttributeMassEditTaxoFilter';
import AttributeFilter from './AttributeFilter';
import AttributeValueSelector from './AttributeValueSelector';
import AttributeMassEditTable from './AttributeMassEditTable';
import openNotification from 'components/Notification';
import * as actionCreators from '../../actions';
import {
  getHierarchyFilters,
  isValidHierarchyName,
  isPartialHierarchy,
  getCompleteHierarchyIds,
  getAttributeType,
  handleNutritionSynchedAvailability,
  checkMassAttributionChildNutritionEligibility
} from './../../util/Util';
import { BUSINESS_CENTERS } from 'util/Constants';
import { sustainTooltips } from '../../util/Data';
import { ALLBUSINESSCENTERS } from '../../actions/actionTypes';

export default function AttributeMassEditModal({ suvc }) {
  const windowHeight = window.innerHeight;
  const dispatch = useDispatch();
  const {
    toggleAttributeMassEditModal,
    massUpdateAttributes,
    massUndoAttributes,
    getAttributeMassEditList,
    getBusinessCenters,
    getAttributesAndPicklistValues,
    updateMassAttributeAllItems
  } = bindActionCreators(actionCreators, dispatch);

  const showModal = useSelector(state => _.get(state.massUpdate.attribute, 'showModal', false));
  const fetchingTaxonomy = useSelector(state => _.get(state.massUpdate.attribute, 'fetchingTaxonomy', false));
  const fetchingList = useSelector(state => _.get(state.massUpdate.attribute, 'fetchingList', false));
  const taxonomy = useSelector(state => _.get(state.massUpdate.attribute, 'taxonomy', {}));
  const completeTaxonomy = useSelector(state => _.get(state.massUpdate.attribute, 'completeTaxonomy', []));
  const attributes = useSelector(state => _.get(state.massUpdate.attribute, 'attributes', []));
  const list = useSelector(state => _.get(state.massUpdate.attribute, 'list', []));
  const appliedValues = useSelector(state => _.get(state.massUpdate.attribute, 'appliedValues', []));
  const saving = useSelector(state => _.get(state.massUpdate.attribute, 'saving', false));
  const page = useSelector(state => _.get(state.massUpdate.attribute, 'pagination.page', 1));
  const recordsCount = useSelector(state => _.get(state.massUpdate.attribute, 'pagination.recordsCount', null));
  const orderBy = useSelector(state => _.get(state.massUpdate.attribute, 'filters.orderBy', null));
  const bc = useSelector(state => _.get(state.massUpdate.attribute, 'filters.bc', null));
  const ig = useSelector(state => _.get(state.massUpdate.attribute, 'filters.ig', null));
  const ag = useSelector(state => _.get(state.massUpdate.attribute, 'filters.ag', null));
  const tableSearchData = useSelector(state => _.get(state.massUpdate.attribute, 'filters.tableSearchData', []));
  const tableFilters = useSelector(state => _.get(state.massUpdate.attribute, 'filters.tableFilters', []));
  const selectedValues = useSelector(state => _.get(state.massUpdate.attribute, 'selectedValues', []));
  const selectedRows = useSelector(state => _.get(state.massUpdate.attribute, 'selectedRows', []));

  const [hightlight, setHighlight] = useState(false);

  const isLoading = fetchingTaxonomy || fetchingList || saving;
  const hideContent = _.isEmpty(attributes) || _.isEmpty(bc);

  useEffect(() => {
    const bcNames = _.map(taxonomy, (val, key) => key);
    const bcIds = getBusinessCenterIds(bcNames);
    if (!_.isEmpty(bcIds)) {
      getBusinessCenters({ bcIds, actionType: ALLBUSINESSCENTERS });
    }
  }, [taxonomy]);

  useEffect(() => {
    if (isValidHierarchyName(bc)) {
      const graphFilter = getHierarchyFilters({ bc, ig, ag });
      getAttributeMassEditList({ suvc, page: 1, graphFilter });
    } else {
      getAttributeMassEditList({ isEmptyHierarchy: true });
    }
  }, [bc, ig, ag, suvc]);

  useEffect(() => {
    if (isPartialHierarchy(bc, ag)) {
      const taxoFilters = getCompleteHierarchyIds({
        bcName: bc,
        igName: ig,
        agName: ag,
        completeTaxonomy,
        isPartial: true
      });

      fetchAttributesAndPicklistValues(taxoFilters, true);
    } else if (isValidHierarchyName(bc)) {
      const taxoFilters = getCompleteHierarchyIds({
        bcName: bc,
        igName: ig,
        agName: ag,
        completeTaxonomy,
        isPartial: false
      });

      fetchAttributesAndPicklistValues(taxoFilters);
    }
  }, [bc, ag, completeTaxonomy]);

  const fetchAttributesAndPicklistValues = (taxoFilters, isPartial) => {
    if (taxoFilters.bc) {
      getAttributesAndPicklistValues({ ...taxoFilters, isPartial });
    }
  };

  const closeModal = () => {
    toggleAttributeMassEditModal({ showModal: false });
  };

  const toggleHighlight = () => {
    setHighlight(true);

    setTimeout(() => setHighlight(false), 1500);
  };

  const handlePagination = newPage => {
    if (!hasUnsubmittedData()) {
      const graphFilter = getHierarchyFilters({ bc, ig, ag });
      getAttributeMassEditList({ suvc, page: newPage, orderBy, graphFilter, tableSearchData, tableFilters });
    }
  };

  const hasUnsubmittedData = () => {
    if (!_.isEmpty(appliedValues)) {
      openNotification({
        className: 'error',
        message: 'HAS UNSAVED DATA',
        description: 'Please commit changes before moving to a different set of items'
      });

      return true;
    }
  };

  const getBusinessCenterIds = bcNames => {
    const ids = [];

    _.forEach(bcNames, bcName => {
      const target = _.find(BUSINESS_CENTERS, obj => obj.name === bcName);
      if (target) ids.push(target.id);
    });

    return ids;
  };

  const getUpdatedItemList = () => {
    const updatedItemList = list.map(item => {
      if (!selectedRows.includes(item.supc)) return item;
      const {
        taxonomy: { attributes: itemAttributes },
        hasNutritionSynchedTaxonomyAttributeChanges: status,
        pendingNutrition,
        pendingNutritions,
        ...rest
      } = item;
      let hasNutritionSynchedTaxonomyAttributeChanges = status;
      if (!hasNutritionSynchedTaxonomyAttributeChanges) {
        selectedValues.forEach(({ id, value }) => {
          const { name, picklist, selected, type } = _.defaultTo(_.find(itemAttributes, { attrId: Number(id) }), {});
          if (name && type && picklist && picklist.length) {
            const attrType = getAttributeType(type);
            const { nutritionSourceOid } = pendingNutrition;
            if (
              !checkMassAttributionChildNutritionEligibility({ ...rest, pendingNutrition, pendingNutritions }, name) &&
              ((nutritionSourceOid === null && pendingNutritions.length) || Number(nutritionSourceOid) === 21)
            ) {
              // check if the field is not CN & not having pending nutritions & nutritional data
              hasNutritionSynchedTaxonomyAttributeChanges = handleNutritionSynchedAvailability(
                item,
                name,
                picklist,
                Number(value),
                attrType
              );
            }
          }
        });
      }
      return { ...item, hasNutritionSynchedTaxonomyAttributeChanges };
    });
    return updatedItemList;
  };

  const onClickSubmit = () => {
    const updatedItemList = getUpdatedItemList();
    updateMassAttributeAllItems({ updatedItemList });
    massUpdateAttributes({ suvc, updatedData: appliedValues, list: updatedItemList, attributes });
  };

  const onClickUndo = () => {
    massUndoAttributes();
  };

  const renderModalBody = () => {
    const antIcon = <LoadingOutlined className="attr-mass-main-loader" spin />;

    if (isLoading) {
      return <Spin indicator={antIcon} />;
    } else {
      return (
        <div className="attr-mass-modal-body">
          <AttributeMassEditTaxoFilter suvc={suvc} hasUnsubmittedData={hasUnsubmittedData} />
          {!hideContent && (
            <React.Fragment>
              <AttributeFilter onFilterChange={toggleHighlight} />
              <AttributeValueSelector hightlight={hightlight} />
              <AttributeMassEditTable suvc={suvc} hasUnsubmittedData={hasUnsubmittedData} />
            </React.Fragment>
          )}
        </div>
      );
    }
  };

  const renderFooterButtons = () => {
    if (isLoading || hideContent || _.isEmpty(list)) {
      return <div className="attr-mass-modal-footer" />;
    } else {
      return (
        <div className="attr-mass-modal-footer">
          <div>
            {/* <Button key="reslot" className="footer-btns">
              RESOLT
            </Button> */}
            <Button key="massundo" className="footer-btns" onClick={onClickUndo}>
              UNDO
            </Button>
            {/* <Button key="export" className="footer-btns">
              EXPORT
            </Button> */}
          </div>
          <Pagination
            size="small"
            onChange={handlePagination}
            total={parseInt(recordsCount)}
            showTotal={total => `${total} rows`}
            current={page}
            defaultPageSize={15}
            hideOnSinglePage
            showQuickJumper
          />
          <div>
            <Button key="submit" className="footer-btns submit-btn" onClick={onClickSubmit}>
              SUBMIT CHANGES
            </Button>
          </div>
        </div>
      );
    }
  };

  return (
    <Modal
      className="attr-mass-edit-modal"
      visible={showModal}
      maskClosable={false}
      closable={!isLoading}
      onCancel={closeModal}
      width={'99%'}
      bodyStyle={{
        height: `${windowHeight - 73}px`,
        padding: '16px'
      }}
      footer={renderFooterButtons()}
    >
      {renderModalBody()}
    </Modal>
  );
}

export const InfoToolTip = ({ type, name }) => {
  const toolTipObj = _.find(
    sustainTooltips,
    ({ attribute }) => `${attribute}`.toLowerCase() === `${name}`.toLowerCase()
  );
  const toolTipDoc = _.get(toolTipObj, 'doc', null);
  const tooltipText = _.get(toolTipObj, 'tooltip_text', null);

  if (!_.includes(type, 'sustainability')) return <React.Fragment />;

  const content = (
    <div>
      <b>{name}</b>
      {tooltipText && (
        <span>
          <br />
          <br />
          {tooltipText}
          <br />
          <br />
        </span>
      )}
      {toolTipDoc && (
        <a className="tooltip-readmore" href={`/publicresources/pdf/${encodeURI(toolTipDoc)}.pdf`} target="_blank">
          Read more...
        </a>
      )}
    </div>
  );

  return (
    <Tooltip overlayClassName="attr-mass-edit-tool-tip" title={content} placement="left">
      <InfoCircleOutlined className="mass-attr-info-icon" />
    </Tooltip>
  );
};
