import React from 'react';
import { Input, Select, message, Tooltip } from 'antd';
import { getItemIsGdsnOrNot, getValueBasedOnTheCondition, isCooFieldDisabled } from '../util/Util';
import _ from 'lodash';

import {
  ACTIVITY_TYPE_KEY,
  CORE_DATA_FIELDS,
  ACTIVITY_CODE_KEY,
  ACTIVITY_SUBCODE_KEY,
  REGION_DESC_KEY
} from '../util/CoreDataConstants';
import { COUNTRY_CODE, COUNTRY_SUBDIVISION } from 'util/CountryCode';

class TextCooAttribute extends React.Component {
  constructor(props) {
    super(props);
  }

  checkDuplicateActivity = (event, key, activityIndex) => {
    let isDuplicate = false;
    const { item } = this.props;
    item.cooDatas.forEach((row, index) => {
      if (row[key].value == event && activityIndex !== index) {
        isDuplicate = true;
        return;
      }
    });
    return isDuplicate;
  };

  checkDuplicateCountryCode = (event, key, activityIndex, countryIndex) => {
    let isDuplicate = false;
    const { item } = this.props;
    if (['124', '484', '581', '840'].indexOf(event) < 0) {
      item.cooDatas[activityIndex].country.forEach((row, index) => {
        if (row[key].value == event && countryIndex !== index) {
          isDuplicate = true;
          return;
        }
      });
    }
    return isDuplicate;
  };

  checkDuplicateSubCode = (event, key, activityIndex, countryIndex) => {
    let isDuplicate = false;
    const { item } = this.props;
    const countryCode = item.cooDatas[activityIndex].country[countryIndex][ACTIVITY_CODE_KEY].value;
    item.cooDatas[activityIndex].country.forEach((row, index) => {
      if (row[key].value == event && row[ACTIVITY_CODE_KEY].value == countryCode && countryIndex !== index) {
        isDuplicate = true;
        return;
      }
    });
    return isDuplicate;
  };

  handleFieldChange = (value, key, item, activityIndex, countryIndex, isDropdown = false) => {
    if (!isDropdown) {
      let data = { key, cooValue: value || '', activityIndex, countryIndex };
      this.props.actions.changeCooDataFieldValue({ ...data });
      if (key === REGION_DESC_KEY && !this.getValueForField(ACTIVITY_TYPE_KEY, item, activityIndex, countryIndex)) {
        this.props.actions.changeCooDataFieldValue({
          key: ACTIVITY_TYPE_KEY,
          activityIndex,
          countryIndex,
          errMsg: 'Please Select Activity Type'
        });
      }
      return;
    }

    if (key === ACTIVITY_TYPE_KEY) {
      this.props.actions.changeCooDataFieldValue({ key, cooValue: value, activityIndex, countryIndex });
    } else if (key === ACTIVITY_CODE_KEY) {
      this.props.actions.changeCooDataFieldValue({ key, cooValue: value, activityIndex, countryIndex });
      /*this.props.actions.changeCooDataFieldValue({
        key: ACTIVITY_SUBCODE_KEY,
        cooValue: null,
        activityIndex,
        countryIndex,
        errMsg: ['124', '484', '581', '840'].indexOf(value) >= 0 ? 'Please Select Country Subdivision Code' : ''
      });*/
      if (!this.getValueForField(ACTIVITY_TYPE_KEY, item, activityIndex, countryIndex)) {
        this.props.actions.changeCooDataFieldValue({
          key: ACTIVITY_TYPE_KEY,
          activityIndex,
          countryIndex,
          errMsg: 'Please Select Activity Type'
        });
      }
    } else if (key === ACTIVITY_SUBCODE_KEY) {
      this.props.actions.changeCooDataFieldValue({ key, cooValue: value, activityIndex, countryIndex });
    }
  };

  /*handleFieldChange = (value, key, item, activityIndex, countryIndex, isDropdown = false) => {
    if (!isDropdown) {
      let data = { key, cooValue: value || '', activityIndex, countryIndex };
      this.props.actions.changeCooDataFieldValue({ ...data });
      if (key === REGION_DESC_KEY && !this.getValueForField(ACTIVITY_TYPE_KEY, item, activityIndex, countryIndex)) {
        this.props.actions.changeCooDataFieldValue({
          key: ACTIVITY_TYPE_KEY,
          activityIndex,
          countryIndex,
          errMsg: 'Please Select Activity Type'
        });
      }
      return;
    }

    if (key === ACTIVITY_TYPE_KEY) {
      if (this.checkDuplicateActivity(value, key, activityIndex)) {
        this.props.actions.changeCooDataFieldValue({
          key,
          cooValue: null,
          activityIndex,
          countryIndex,
          errMsg: 'Please Select Activity Type'
        });
        message.error('Repeat values, please reselect');
      } else {
        this.props.actions.changeCooDataFieldValue({ key, cooValue: value, activityIndex, countryIndex });
      }
    } else if (key === ACTIVITY_CODE_KEY) {
      if (this.checkDuplicateCountryCode(value, key, activityIndex, countryIndex)) {
        this.props.actions.changeCooDataFieldValue({
          key,
          cooValue: null,
          activityIndex,
          countryIndex,
          errMsg: 'Please Select Country of Activity Code'
        });
        message.error('Repeat values, please reselect');
      } else {
        this.props.actions.changeCooDataFieldValue({ key, cooValue: value, activityIndex, countryIndex });
        this.props.actions.changeCooDataFieldValue({
          key: ACTIVITY_SUBCODE_KEY,
          cooValue: null,
          activityIndex,
          countryIndex,
          errMsg: ['124', '484', '581', '840'].indexOf(value) >= 0 ? 'Please Select Country Subdivision Code' : ''
        });
        if (!this.getValueForField(ACTIVITY_TYPE_KEY, item, activityIndex, countryIndex)) {
          this.props.actions.changeCooDataFieldValue({
            key: ACTIVITY_TYPE_KEY,
            activityIndex,
            countryIndex,
            errMsg: 'Please Select Activity Type'
          });
        }
      }
    } else if (key === ACTIVITY_SUBCODE_KEY) {
      if (this.checkDuplicateSubCode(value, key, activityIndex, countryIndex)) {
        this.props.actions.changeCooDataFieldValue({
          key,
          cooValue: null,
          activityIndex,
          countryIndex,
          errMsg: 'Please Select Country Subdivision Code'
        });
        message.error('Repeat values, please reselect');
      } else {
        this.props.actions.changeCooDataFieldValue({ key, cooValue: value, activityIndex, countryIndex });
      }
    }
  };*/

  getOptions = (key, item, activityIndex, countryIndex) => {
    let options = [];
    if (key === ACTIVITY_SUBCODE_KEY) {
      const countryCode = item.cooDatas[activityIndex].country[countryIndex][ACTIVITY_CODE_KEY].value;
      if (countryCode && COUNTRY_SUBDIVISION[countryCode]) {
        options = COUNTRY_SUBDIVISION[countryCode];
      }
    } else if (key === ACTIVITY_CODE_KEY) {
      options = COUNTRY_CODE;
    } else {
      options = CORE_DATA_FIELDS[key].list;
    }
    return options;
  };

  getErrorMessage = (key, item, activityIndex, countryIndex) => {
    let errorMessage = '';
    if (countryIndex != undefined) {
      errorMessage = item.cooDatas[activityIndex].country[countryIndex][key].errMsg;
    } else {
      errorMessage = item.cooDatas[activityIndex][key].errMsg;
    }
    return errorMessage;
  };

  getValueForField = (key, item, activityIndex, countryIndex, isOnlyMasterValue = false) => {
    let value = '';
    if (key === ACTIVITY_TYPE_KEY || key === REGION_DESC_KEY) {
      value = item.cooDatas[activityIndex][key].value;
    } else {
      value = item.cooDatas[activityIndex].country[countryIndex][key].value;
    }
    return value;
  };

  // getIfFieldIsRequired = field => field.isRequired && '*';

  render() {
    const { Option } = Select;
    let { item, field, activityIndex, countryIndex } = this.props;

    const getFieldValue = (key, activityIndex, countryIndex) => {
      return this.getValueForField(key, item, activityIndex, countryIndex);
    };

    const options = this.getOptions(field.key, item, activityIndex, countryIndex);

    const errorMessage = this.getErrorMessage(field.key, item, activityIndex, countryIndex);
    const inlineStyle = {
      flexBasis: '45%',
      marginRight: field.key === ACTIVITY_TYPE_KEY || field.key === ACTIVITY_CODE_KEY ? '4%' : 0
    };
    const isGdsn = getItemIsGdsnOrNot(item);
    const keySetting = CORE_DATA_FIELDS[field.key];

    return (
      <div className={`coo-attr-wrapper`} style={inlineStyle}>
        <div className="attr-label">
          {field.caption}{' '}
          {isGdsn && keySetting.tooltip && keySetting.tooltip.length > 0 && (
            <Tooltip
              title={
                <div
                  style={{ paddingLeft: '15px', paddingRight: '15px' }}
                  className="gdsn-info"
                >{`GDSN Field: ${keySetting.tooltip}. To make changes, please do so via GDSN.`}</div>
              }
            >
              i
            </Tooltip>
          )}
          {/* <div className="required-indicator">{this.getIfFieldIsRequired(CORE_DATA_FIELDS[field.key])}</div> */}
        </div>
        {keySetting.list ? (
          <Select
            defaultValue={getFieldValue(field.key, activityIndex, countryIndex)}
            className={`attr-taxonomy ${getValueBasedOnTheCondition(
              isCooFieldDisabled(item),
              'disabled-content',
              undefined
            )}`}
            disabled={isCooFieldDisabled(item)}
            value={getFieldValue(field.key, activityIndex, countryIndex)}
            onSelect={value => this.handleFieldChange(value, field.key, item, activityIndex, countryIndex, true)}
            getPopupContainer={triggerNode => triggerNode.parentNode}
            optionFilterProp="children"
            showSearch
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            // id={field.key}
          >
            {options.map(({ value, label }, index) => (
              <Option key={index} value={value}>
                {field.key === ACTIVITY_SUBCODE_KEY ? `${value} (${value})` : label}
              </Option>
            ))}
          </Select>
        ) : (
          <>
            <Input
              className={`attr-textbox ${getValueBasedOnTheCondition(
                isCooFieldDisabled(item),
                'disabled-content',
                undefined
              )}`}
              value={getFieldValue(field.key, activityIndex, countryIndex)}
              disabled={isCooFieldDisabled(item)}
              onChange={event =>
                this.handleFieldChange(event.target.value, field.key, item, activityIndex, countryIndex)
              }
              maxLength={200}
              // id={field.key}
            />
          </>
        )}
        {errorMessage && <span className="validation-error">{errorMessage}</span>}
      </div>
    );
  }
}

export default TextCooAttribute;
