import _ from 'lodash';
import { useSelector } from 'react-redux';
import { Select, Collapse, Button } from 'antd';
import React, { useState, useEffect } from 'react';

import ImageBlock from './ImageBlock';
import { mainCategoriesList, imageCategories } from '../../util/Data';
import { isNonFood, getFileNamePart, getStyleCode } from '../../util/Util';
import {
  imageNameTypes,
  imageNameFacings,
  imageNameAngles,
  imageFoodStyles,
  imageNonFoodStyles,
  imageSourceFilterOptions
} from '../../util/Data';

const Option = Select.Option;
const Panel = Collapse.Panel;

export default function AdditionalImageStyles({ images, existingStyles, readOnly, imageFn }) {
  const taxonomy = useSelector(state => _.get(state, 'selectedItem.taxonomy', {}));

  const [filters, setFilters] = useState([]);
  const [activeCollapseRows, setActiveCollapseRows] = useState([]);

  const nonFood = isNonFood({ taxonomy });
  const groupedImgIds = groupAdditionalImages();

  useEffect(() => {
    if (_.isEmpty(filters)) {
      updateActiveCollapseRows([]);
    } else {
      updateActiveCollapseRows(groupedImgIds);
    }
  }, [filters]);

  function getFilteredImages() {
    let filtered = [];

    if (_.isEmpty(filters)) return images;

    _.forEach(images, img => {
      const name = img.name;
      let checks = [];

      // Extract the part of name between the underscore and the dot before file extension
      const match = name.match(/^[^_]+_([A-Za-z0-9]{4})\..*$/);
      const charactersArray = match ? match[1].split('') : null;
      const mapper = { 'img-type': 0, 'img-facing': 1, 'img-angle': 2, 'img-style': 3 };

      if (charactersArray) {
        _.forEach(filters, filter => {
          if (filter.type === 'img-source') {
            const sourceCheck = img.imageSource === filter.value;
            checks.push(sourceCheck);
          } else {
            const check = charactersArray[mapper[filter.type]] === _.find(filters, { type: filter.type })?.key;
            checks.push(check);
          }
        });

        if (_.every(checks, Boolean)) {
          filtered.push(img);
        }
      }
    });

    return filtered;
  }

  function updateActiveCollapseRows(groups) {
    const actives = _.keys(_.pickBy(groups, arr => arr.length > 0));
    setActiveCollapseRows(actives);
  }

  function groupAdditionalImages() {
    let groups = {};
    const filtered = getFilteredImages();

    _.forEach(mainCategoriesList, mainCategory => {
      const { styles } = imageCategories[mainCategory];
      const ids = _.map(
        _.filter(filtered, obj => _.includes(styles, _.toLower(obj.imageStyle))),
        'assetId'
      );
      groups[mainCategory] = ids;
    });

    return groups;
  }

  const onToggleCollapse = actives => {
    setActiveCollapseRows(actives);
  };

  const updateFilters = newFilters => {
    setFilters(newFilters);
  };

  const getCollapsibleHeader = category => {
    const color = _.isEmpty(groupedImgIds[category]) ? 'red' : 'green';
    const tooltip = _.isEmpty(groupedImgIds[category]) ? 'No images' : 'Has images';
    const text = _.toUpper(imageCategories[category].caption);

    const indicator = <div className={`dot-indicator ${color}`} title={tooltip} />;
    return (
      <div className="collapsible-header">
        <div>{text}</div>
        {indicator}
      </div>
    );
  };

  const renderDropdownList = () => {
    return (
      <div className="additional-img-filter-row">
        <FilterDropDown type={'img-style'} filters={filters} updateFilters={updateFilters} isNonFood={nonFood} />
        <FilterDropDown type={'img-type'} filters={filters} updateFilters={updateFilters} isNonFood={nonFood} />
        <FilterDropDown type={'img-facing'} filters={filters} updateFilters={updateFilters} isNonFood={nonFood} />
        <FilterDropDown type={'img-angle'} filters={filters} updateFilters={updateFilters} isNonFood={nonFood} />
        <FilterDropDown type={'img-source'} filters={filters} updateFilters={updateFilters} isNonFood={nonFood} />
        <Button className="clear-all-filters" onClick={() => updateFilters([])}>
          Clear Filters
        </Button>
      </div>
    );
  };

  const renderImages = category => {
    return (
      <div className="attr-set images">
        {_.map(groupedImgIds[category], id => {
          const image = _.find(images, obj => obj.assetId === id);
          return (
            <ImageBlock
              key={id}
              image={image}
              category={category}
              existingStyles={existingStyles}
              imageFn={imageFn}
              readOnly={readOnly}
            />
          );
        })}
      </div>
    );
  };

  const renderCollapsible = () => {
    return (
      <Collapse bordered={false} activeKey={activeCollapseRows} onChange={onToggleCollapse}>
        <Panel header={getCollapsibleHeader('primary')} key="primary">
          {renderImages('primary')}
        </Panel>
        <Panel header={getCollapsibleHeader('inPacking')} key="inPacking">
          {renderImages('inPacking')}
        </Panel>
        <Panel header={getCollapsibleHeader('openCase')} key="openCase">
          {renderImages('openCase')}
        </Panel>
        <Panel header={getCollapsibleHeader('case')} key="case">
          {renderImages('case')}
        </Panel>
        <Panel header={getCollapsibleHeader('lifeStyle')} key="lifeStyle">
          {renderImages('lifeStyle')}
        </Panel>
      </Collapse>
    );
  };

  return (
    <div id="additional-img-styles-section">
      <div className="section-title">Additional Image Styles</div>
      {renderDropdownList()}
      {renderCollapsible()}
    </div>
  );
}

function FilterDropDown({ type, filters, updateFilters, isNonFood }) {
  let placeholder, options;

  switch (type) {
    case 'img-style':
      placeholder = 'Image Style';
      options = isNonFood ? imageNonFoodStyles : imageFoodStyles;
      break;
    case 'img-type':
      placeholder = 'Image Type';
      options = imageNameTypes;
      break;
    case 'img-facing':
      placeholder = 'Image Facing';
      options = imageNameFacings;
      break;
    case 'img-angle':
      placeholder = 'Image Angle';
      options = imageNameAngles;
      break;
    case 'img-source':
      placeholder = 'Image Source';
      options = imageSourceFilterOptions;
      break;
  }

  const onChangeFilters = value => {
    let clone = [...filters];
    const index = _.findIndex(clone, obj => obj.type === type);
    const key = !value ? null : type === 'img-style' ? getStyleCode(value) : getFileNamePart(value);

    if (!value) {
      clone = _.filter(clone, obj => obj.type !== type);
    } else if (index !== -1) {
      clone[index] = { ...clone[index], value, key };
    } else {
      clone.push({ type, value, key });
    }

    updateFilters(clone);
  };

  const target = _.find(filters, obj => obj.type === type);
  const selected = _.get(target, 'value', null);

  return (
    <Select value={selected} onChange={onChangeFilters} dropdownMatchSelectWidth={false}>
      <Option value={null}>{placeholder}</Option>
      {_.map(options, opt => {
        const value = _.get(opt, 'value', opt);
        const label = _.get(opt, 'label', opt);

        return (
          <Option key={value} value={value}>
            {_.toUpper(label)}
          </Option>
        );
      })}
    </Select>
  );
}
