import React from 'react';
import { Chart } from 'react-google-charts';
import _ from 'lodash';

class FnbSectionBarChart extends React.Component {
  calculate() {
    let { summaryItems } = this.props;

    let data = [];
    data.push(['F&B Section', 'Complete', 'In Complete', { role: 'annotation' }]);

    let fnbTypes = [
      'PRODUCT DESCRIPTOR',
      'PACKAGING INFORMATION',
      'SIZE AND SHAPE OF PRODUCT',
      'YIELD / SERVING SIZE',
      'QUALITY & FORMAT',
      'PREP & COOKING INSTRUCTIONS',
      'STORAGE & USAGE',
      'HANDLING INSTRUCTIONS',
      'ADDITIONAL PRODUCT INFORMATION'
    ];
    let fnbData = {};
    fnbTypes.forEach(fnbType => {
      fnbData[fnbType] = [];
    });

    summaryItems.forEach(item => {
      if (item.prm) {
        fnbData['PRODUCT DESCRIPTOR'].push(parseInt(item.prm.fpd));
        fnbData['PACKAGING INFORMATION'].push(parseInt(item.prm.fpi));
        fnbData['SIZE AND SHAPE OF PRODUCT'].push(parseInt(item.prm.fss));
        fnbData['YIELD / SERVING SIZE'].push(parseInt(item.prm.fys));
        fnbData['QUALITY & FORMAT'].push(parseInt(item.prm.fqf));
        fnbData['PREP & COOKING INSTRUCTIONS'].push(parseInt(item.prm.fpc));
        fnbData['STORAGE & USAGE'].push(parseInt(item.prm.fsu));
        fnbData['HANDLING INSTRUCTIONS'].push(parseInt(item.prm.fhi));
        fnbData['ADDITIONAL PRODUCT INFORMATION'].push(parseInt(item.prm.fai));
      }
    });

    fnbTypes.forEach(type => {
      let complete = 0;
      if (fnbData[type].length > 0) {
        complete = _.sum(fnbData[type]);
      }
      data.push([type, complete, fnbData[type].length - complete, '']);
    });

    return data;
  }

  render() {
    let { summaryItems, filterGraph, paramTypes } = this.props;

    let calculatedValues = this.calculate();

    if (!(summaryItems.length > 0)) {
      return <div></div>;
    }

    let height = 450,
      width = 350;

    return (
      <div className="attribute-type-chart-wrapper" style={{ width: `${width}px`, height: `${height}px` }}>
        <div className={'attribute-type-chart-title'}>{'FEATURES & BENEFITS BY SECTION'}</div>
        <div className={'attribute-type-chart'}>
          <Chart
            chartType="BarChart"
            data={calculatedValues}
            legendToggle
            options={{
              width: 350,
              height: height,
              legend: 'none',
              bar: { groupWidth: '50%' },
              isStacked: true,
              colors: ['#92bb88', '#e9705f'],
              backgroundColor: '#f9f6f8',
              tooltip: {
                textStyle: {
                  color: '#7b8c92'
                }
              },
              hAxis: {
                textStyle: {
                  color: '#7b8c92'
                },
                baselineColor: '#7b8c92'
              },
              vAxis: {
                textStyle: {
                  color: '#7b8c92',
                  fontSize: 9
                },
                baselineColor: '#7b8c92'
              },
              chartArea: {
                left: 135,
                width: width - 165,
                top: 45
              }
            }}
            chartEvents={[
              {
                eventName: 'select',
                callback({ chartWrapper }) {
                  const { row, column } = chartWrapper.getChart().getSelection()[0],
                    fnbType = paramTypes[row],
                    complete = column === 1 ? '1' : '0';
                  filterGraph([{ param: fnbType, val: complete }], [...paramTypes, ...['comp_fnb']]);
                }
              }
            ]}
          />
        </div>
      </div>
    );
  }
}
export default FnbSectionBarChart;
