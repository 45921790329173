import React, { forwardRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import CommentExpandedImage from './CommentExpandedImage';
import FnBDetailsTable from './FnBDetailsTable';
import CommentExpandedNutrition from './CommentExpandedNutrition';
import ThirdPartyFnBDetailsTable from './ThirdPartyFnBDetailsTable';
import CoreDataExpanded from './CoreDataExpanded/CoreDataExpanded';
import ImagePreferenceComment from './ImagePreferenceComment';
import * as userActions from 'actions';
import ThirdpartyUniversalExpanded from './ThirdpartyUniversalExpanded/ThirdpartyUniversalExpanded';
import CommentExpandedOverallFeedback from './CommentExpandedOverallFeedback';
import { CORE, COMMENT_TYPES, OVERALL } from 'util/Constants';

const [FnB, IMAGE, ATRBYTIMG, ATRBYTFNB, NUTRITION] = COMMENT_TYPES;

class CommentExpanded extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmModel: false
    };
    this.toggleConfirmModel = this.toggleConfirmModel.bind(this);
  }

  toggleConfirmModel() {
    this.setState({
      confirmModel: !this.state.confirmModel
    });
  }

  componentDidMount() {
    const type = _.get(this.props, 'comment.type', null);
    const isGdsn = _.get(this.props, 'comment.gdsn', false);
    const gtin = _.get(this.props, 'comment.gtin', null);
    const supc = _.get(this.props, 'comment.supc', null);

    if (type === 'FnB' && isGdsn && gtin) {
      this.props.actions.getGdsnFnbComments({ gtin, supc });
    }
  }

  getContentToReturnBasedOnComment = (comment, conditionSuccessContent, conditionFalseContent) => {
    if (comment) return conditionSuccessContent;
    return conditionFalseContent;
  };

  getRelavantComment = (allComment, comment) => {
    return _.find(allComment, item => {
      if (item.pending) {
        return comment.id === item.pending.comment_id;
      }
    });
  };

  render() {
    let {
      comment,
      fnbComments,
      actions,
      innerRef,
      addFnBCommentToRejectState,
      removeFnBCommentFromRejectState,
      addImageCommentToRejectState,
      removeImageCommentFromRejectState,
      isUpdating,
      handlePostStyleChange,
      isAllRejectingCoreDataAttributesInComment,
      userId
    } = this.props;

    switch (comment.type) {
      case ATRBYTIMG:
      case IMAGE: {
        if (comment.field === 'Image Feedback') {
          return <div>{comment.comment}</div>;
        }
        if (comment?.imageComment?.changeType === 'PREFERRED_IMAGE') {
          return <ImagePreferenceComment comment={comment} ref={innerRef} />;
        }
        if (comment.imageComment && comment.imageComment.changeType) {
          return (
            <CommentExpandedImage
              ref={innerRef}
              comment={comment}
              isUpdating={isUpdating}
              addImageCommentToRejectState={addImageCommentToRejectState}
              removeImageCommentFromRejectState={removeImageCommentFromRejectState}
              handlePostStyleChange={data => handlePostStyleChange(data)}
            />
          );
        }
        return <div className="empty-reviews">Loading...</div>;
      }
      case ATRBYTFNB: {
        const fnbComment = this.getRelavantComment(fnbComments, comment);

        return this.getContentToReturnBasedOnComment(
          fnbComment,
          <ThirdPartyFnBDetailsTable
            commentStatus={comment.status}
            fnbComment={fnbComment}
            ref={innerRef}
            isUpdating={isUpdating}
            addFnBCommentToRejectState={addFnBCommentToRejectState}
            removeFnBCommentFromRejectState={removeFnBCommentFromRejectState}
            modelToggle={this.toggleConfirmModel}
            modelVisible={this.state.confirmModel}
          />,
          <div className="empty-reviews">No Features & Benefits comments found</div>
        );
      }
      case FnB: {
        const fnbComment = this.getRelavantComment(fnbComments, comment);

        if (fnbComment) {
          return this.getContentToReturnBasedOnComment(
            fnbComment.isFetchingGdsnFnb,
            <div className="empty-reviews">Loading...</div>,
            <FnBDetailsTable
              parentComment={comment}
              fnbComment={fnbComment}
              gdsnFnb={fnbComment.gdsnFnb}
              editFnBAttribute={actions.editFnBAttribute}
              ref={innerRef}
              isUpdating={isUpdating}
              addFnBCommentToRejectState={addFnBCommentToRejectState}
              removeFnBCommentFromRejectState={removeFnBCommentFromRejectState}
              modelToggle={this.toggleConfirmModel}
              modelVisible={this.state.confirmModel}
            />
          );
        }
        return <div className="empty-reviews">No Features & Benefits comments found</div>;
      }
      case NUTRITION: {
        return this.getContentToReturnBasedOnComment(
          comment.comment,
          <CommentExpandedNutrition
            commentData={{ ...comment }}
            ref={innerRef}
            isUpdating={isUpdating}
            onRejectFieldChanged={actions.onNutritionRejectFieldsChanged}
          />,
          <div className="empty-reviews">Loading...</div>
        );
      }
      case CORE: {
        return this.getContentToReturnBasedOnComment(
          comment.comment,
          <CoreDataExpanded
            commentData={{ ...comment }}
            userId={userId}
            ref={innerRef}
            isUpdating={isUpdating}
            onAttributeChange={actions.coreDataCommentChanged}
            onRejectAllModalOpen={actions.rejectAllCoreAttributesModalOpen}
            onConfirmRejectAll={actions.confirmRejectAllCoreAttributes}
            onCancelRejectAll={actions.cancelRejectAllCoreAttributes}
            isAllRejectModalOpen={isAllRejectingCoreDataAttributesInComment}
            getItemDetails={actions.getItemDetails}
            selfAssignComment={actions.selfAssignComment}
          />,
          <div className="empty-reviews">Loading...</div>
        );
      }
    }

    if (comment.type === 'ATRBYTUNI') {
      if (comment.comment) {
        return (
          <ThirdpartyUniversalExpanded
            commentData={{ ...comment }}
            ref={innerRef}
            isUpdating={isUpdating}
            onAttributeChange={actions.coreDataCommentChanged}
            onRejectAllModalOpen={actions.rejectAllCoreAttributesModalOpen}
            onConfirmRejectAll={actions.confirmRejectAllCoreAttributes}
            onCancelRejectAll={actions.cancelRejectAllCoreAttributes}
            isAllRejectModalOpen={isAllRejectingCoreDataAttributesInComment}
            getItemDetails={actions.getItemDetails}
          />
        );
      } else {
        return <div className="empty-reviews">Loading...</div>;
      }
    }

    if (comment.type === OVERALL) {
      return <CommentExpandedOverallFeedback actions={actions} userId={userId} commentData={{ ...comment }} />;
    }

    return (
      <div className="row-expand">
        <div className="text-column">
          <div className="header">Existing Value : </div>
          <div className="content">{comment.originalValue}</div>
        </div>
        <div className="text-column">
          <div className="header">New Value : </div>
          <div className="content">{comment.comment}</div>
        </div>
      </div>
    );
  }
}

const CommentExpandedWrapper = forwardRef((props, ref) => {
  return <CommentExpanded {...props} innerRef={ref} />;
});

function mapStateToProps(state) {
  return {
    fnbComments: state.comments.fnbComments,
    isAllRejectingCoreDataAttributesInComment: state.comments.isAllCoreAttributesInCommentRejecting,
    userId: state.user.data.contactId
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(userActions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(CommentExpandedWrapper);
