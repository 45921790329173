import React from 'react';
import { Chart } from 'react-google-charts';

class AttributionAgBarChart extends React.Component {
  calculate() {
    let { summaryItems } = this.props;

    let data = [];
    data.push(['Attribute Group', 'Complete', 'In-Complete', { role: 'annotation' }]);

    let agData = [],
      ags = [];

    summaryItems.forEach(item => {
      let itemAg = item.prm && item.prm.ag ? item.prm.ag : '';
      if (itemAg !== '' && item.dim.attr) {
        if (!agData[itemAg]) {
          agData[itemAg] = {
            completed: 0,
            incomplete: 0
          };
          ags.push(itemAg);
        }
        if (item.dim.attr.cmpt) {
          agData[itemAg].completed++;
        } else {
          agData[itemAg].incomplete++;
        }
      }
    });

    const agSort = (a, b) => {
      let aAg = agData[a],
        bAg = agData[b],
        aAgTotal = aAg.completed + aAg.incomplete,
        bAgTotal = bAg.completed + bAg.incomplete;
      if (aAg.incomplete === 0 && bAg.incomplete !== 0) {
        return 1;
      }
      if (aAg.incomplete !== 0 && bAg.incomplete === 0) {
        return -1;
      }
      if (aAgTotal < bAgTotal) {
        return 1;
      } else {
        return -1;
      }
    };

    const makeSortingBrands = sortFunction => ags.sort(sortFunction);

    let sortedAgs = makeSortingBrands(agSort);

    let index = 0;
    let maxBrands = 10;
    sortedAgs.forEach(ag => {
      if (index >= maxBrands) {
        return;
      }
      index++;
      data.push([ag, agData[ag].completed, agData[ag].incomplete, '']);
    });

    return data;
  }

  render() {
    let { filterGraph } = this.props;

    let calculatedValues = this.calculate();

    if (calculatedValues.length <= 1) {
      return <div></div>;
    }

    let height = 300,
      groupWidth = 50,
      width = 350,
      alignObj = {};
    if (calculatedValues.length > 15) {
      height = 600;
      groupWidth = 60;
      alignObj = { position: 'absolute', marginTop: '-36px' };
      if (calculatedValues.length > 50) {
        groupWidth = 95;
        width = 600;
        alignObj = { position: 'absolute', marginTop: '-36px' };
      }
    }

    return (
      <div className="ag-chart-wrapper" style={{ height: `${height}px`, width: `${width}px` }}>
        <div className={'ag-chart-title'}>PRODUCT ATTRIBUTE GROUPS</div>
        <div className={'ag-chart'} style={alignObj}>
          <Chart
            chartType="BarChart"
            data={calculatedValues}
            legendToggle
            options={{
              width: width,
              height: height,
              legend: 'none',
              bar: { groupWidth: groupWidth + '%' },
              isStacked: true,
              colors: ['#92bb88', '#e9705f'],
              backgroundColor: '#f9f6f8',
              tooltip: {
                textStyle: {
                  color: '#7b8c92'
                }
              },
              hAxis: {
                textStyle: {
                  color: '#7b8c92'
                },
                baselineColor: '#7b8c92',
                format: '0'
              },
              vAxis: {
                textStyle: {
                  color: '#7b8c92',
                  fontSize: 7,
                  width: 10
                },
                baselineColor: '#7b8c92'
              },
              chartArea: {
                left: 135,
                width: width - 165
              }
            }}
            chartEvents={[
              {
                eventName: 'select',
                callback({ chartWrapper }) {
                  const { row, column } = chartWrapper.getChart().getSelection()[0],
                    dataRow = row + 1,
                    ag = calculatedValues[dataRow][0],
                    complete = column === 1 ? '1' : '0';
                  filterGraph(
                    [
                      { param: 'ag', val: ag },
                      { param: 'comp_attr', val: complete }
                    ],
                    ['comp_attr', 'ag']
                  );
                }
              }
            ]}
          />
        </div>
      </div>
    );
  }
}
export default AttributionAgBarChart;
