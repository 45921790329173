import React from 'react';

class CommentOriginalValue extends React.Component {
  render() {
    let { field, row } = this.props;

    return (
      <span>
        {row.type === 'IMAGE' && field && field.match(/^http/) ? (
          <img
            alt={`${field}`}
            src={field}
            className="inline-image"
            onClick={() => {
              window.open(field, '_blank');
            }}
          />
        ) : field.length > 100 ? (
          field.substr(0, 99) + ' ...'
        ) : (
          field
        )}
      </span>
    );
  }
}

export default CommentOriginalValue;
