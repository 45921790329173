export const imageDeleteReasons = [
  'Duplicate',
  'Incorrect Product Image',
  'Incorrect Size',
  'Quality - Blurry',
  'Quality - Other',
  'Other'
];

export const imageNameTypes = [
  'A: Product Images',
  'B: Product Image with Supporting Element',
  'C: Product Image (High Resolution)',
  'D: Product Image with Supporting Element (High Resolution)',
  'E: Product Image 360 & 3D',
  'F: Detail Image'
];

export const massImageNameTypes = [
  'A: Product Images',
  'B: Product Image with Supporting Element',
  'C: Product Image (High Resolution)',
  'D: Product Image with Supporting Element (High Resolution)'
];

export const imageNameFacings = [
  '0: Not Applicable',
  '1: Front',
  '2: Left',
  '3: Top',
  '7: Back',
  '8: Right',
  '9: Bottom'
];

export const nOneFacingOptions = [
  'L1: Full Flat',
  'L2: Nutritional',
  'L3: Barcode',
  'L4: Ingredients',
  'L5: Nutritional/ Ingredients Combined',
  'L6: Marketing Content'
];

export const imageNameAngles = ['C: Center', 'L: Left', 'R: Right', 'N: No Plunge Angle'];

export const imageSourceFilterOptions = [
  { value: 'GDSN', label: 'GDSN' },
  { value: 'IRG', label: 'Creative Drive (IRG)' },
  { value: 'SDP', label: 'Supplier Suite' },
  { value: 'ADOBE', label: 'Raw (Adobe)' }
];

export const styleCodes = {
  'out of packaging': '0',
  'in packaging': '1',
  case: 'a',
  innerpack: 'b',
  'raw or uncooked': 'c',
  prepared: 'd',
  plated: 'e',
  styled: 'f',
  staged: 'g',
  held: 'h',
  worn: 'j',
  used: 'k',
  family: 'l',
  'open case': 'm',
  'pallet/display': 'p'
};

export const imageStyles = [
  'raw or uncooked',
  'out of packaging',
  'in packaging',
  'case',
  'open case',
  'styled',
  'plated',
  'prepared',
  'worn',
  'staged',
  'family',
  'used',
  'held',
  'innerpack'
];

export const imageFoodStyles = [
  'raw or uncooked',
  'out of packaging',
  'in packaging',
  'case',
  'open case',
  'styled',
  'plated',
  'staged',
  'prepared',
  'family',
  'innerpack'
];

export const imageNonFoodStyles = [
  'raw or uncooked',
  'out of packaging',
  'in packaging',
  'case',
  'open case',
  'worn',
  'staged',
  'family',
  'used',
  'held',
  'innerpack'
];

export const imageCategories = {
  primary: {
    caption: 'Out of Packaging or Raw/Uncooked',
    styles: ['out of packaging', 'raw or uncooked', 'primary'],
    scoreField: 'ip'
  },
  inPacking: {
    caption: 'In Packaging / Innerpack',
    styles: ['in packaging', 'innerpack'],
    scoreField: 'ii'
  },
  openCase: {
    caption: 'Open Case',
    styles: ['open case'],
    scoreField: 'io'
  },
  case: {
    caption: 'Case',
    styles: ['case'],
    scoreField: 'ic'
  },
  lifeStyle: {
    caption: 'Lifestyle',
    styles: ['styled', 'prepared', 'plated', 'staged', 'used', 'worn', 'held', 'family'],
    scoreField: 'il'
  }
};

export const mainCategoriesList = ['primary', 'inPacking', 'openCase', 'case', 'lifeStyle'];

export const imageAttributes = ['ic', 'ii', 'il', 'io', 'ip'];

export const nonFoodItemBusinessCenterNames = ['DISPOSABLES', 'CHEMICAL', 'SUPPLIES & EQUIPMENT', 'ADMINISTRATIVE'];

export const foodItemAttributes = ['fai', 'fhi', 'fpc', 'fpd', 'fpi', 'fqf', 'fss', 'fsu', 'fys'];

export const nonFoodItemAttributes = ['fai', 'fhi', 'fpd', 'fpi', 'fqf', 'fss', 'fsu', 'fys'];

export const scoreCompletionColors = {
  green: 'green',
  red: 'red'
};

export const scoreStatus = {
  complete: 'complete',
  incomplete: 'incomplete'
};

export const fnbCommentAttributes = [
  'descriptor',
  'packaging',
  'size',
  'yield',
  'quality',
  'prep',
  'storage',
  'handling',
  'additional',
  'marketing',
  'culinary'
];

export const featuresAndBenifitsCommentKeys = {
  descriptor: { name: 'Product Descriptor' },
  packaging: { name: 'Packaging Information' },
  size: { name: 'Size and Shape of Product' },
  yield: { name: 'Yield or Serving Size' },
  quality: { name: 'Quality and Format' },
  prep: { name: 'Prep & Cooking Instructions' },
  storage: { name: 'Storage & Usage' },
  handling: { name: 'Handling Instruction' },
  additional: { name: 'Additional Product Information' },
  marketing: { name: 'Marketing Statements' },
  culinary: { name: 'Culinary Applications' }
};

export const gdsnFnbTooltips = {
  descriptor: `Trade Item Marketing Message`,
  packaging: `• Quantity of Inner Pack
  • Quantity of Next Level GTIN
  • Packaging Type
  • Packaging Description
  • Shipping Container Quantity Description
  • Shipping Container Quantity`,
  size: `Depth Additional Trade Item Dimensions
    Width Additional Trade Item Dimensions
    Height Additional Trade Item Dimensions`,
  yield: `• Serving Suggestion
  • Number of Servings Per Package
  • Measurement Precision Of Number Of Servings Per Package
  • Serving Size Description
  • Serving Size
  • Serving Size UOM
  • ServingSizeWeight`,
  quality: `• Additional Trade Item Description`,
  prep: `• Preparation Type
  • Preparation Instructions`,
  storage: `• Consumer Usage Instructions
  • Consumer Storage Instructions
  • Temperature Qualifier Code
  • Minimum Temperature
  • Minimum Temperature UOM
  • Maximum Temperature
  • Maximum Temperature UOM
  • Minimum Product Lifespan from Production
  • Minimum Product Lifespan from Arrival`,
  handling: `• Handling Instruction
  • Storage Temperature Min.
  • Storage Temperature Min. UOM
  • Storage Temperature Max.
  • Storage Handling Temp Max. UOM
  • Minimum Product Lifespan from Production
  • Minimum Product Lifespan from Arrival`,
  additional: `LWH: Height_GDS, Width_GDS and Depth_GDS`,
  marketing: `• Trade Item Feature Benefit`,
  culinary: `Trade Item Marketing Message`
};

export const requiredFnbCommonToFoodAndNonfood = [
  'descriptor',
  'packaging',
  'size',
  'yield',
  'quality',
  'storage',
  'handling',
  'additional'
];

export const levelOfContainments = {
  CONTAINS: 'CONTAINS',
  30: '30',
  FREE_FROM: 'FREE_FROM',
  MAY_CONTAIN: 'MAY_CONTAIN'
};

export const defaultOptions = {
  name: 'Please Select',
  value: null
};

export const nutritionTraitsClaimsDropDownData = {
  traits: {
    AC: {
      allergenCode: 'AC',
      dropDownData: [
        {
          ...defaultOptions
        },
        {
          name: 'Contains Crustacean Shellfish',
          value: levelOfContainments['CONTAINS']
        },
        {
          name: 'Crustacean Shellfish Not Intentionally Included In Product',
          value: levelOfContainments['30']
        },
        {
          name: 'Free From Crustacean Shellfish',
          value: levelOfContainments['FREE_FROM']
        },
        {
          name: 'May Contain Crustacean Shellfish',
          value: levelOfContainments['MAY_CONTAIN']
        }
      ]
    },
    AE: {
      allergenCode: 'AE',
      dropDownData: [
        {
          ...defaultOptions
        },
        {
          name: 'Contains Egg',
          value: levelOfContainments['CONTAINS']
        },
        {
          name: 'Egg Not Intentionally Included In Product',
          value: levelOfContainments['30']
        },
        {
          name: 'Free From Egg',
          value: levelOfContainments['FREE_FROM']
        },
        {
          name: 'May Contain Crustacean Shellfish',
          value: levelOfContainments['MAY_CONTAIN']
        }
      ]
    },
    AF: {
      allergenCode: 'AF',
      dropDownData: [
        {
          ...defaultOptions
        },
        {
          name: 'Contains Fish',
          value: levelOfContainments['CONTAINS']
        },
        {
          name: 'Fish Not Intentionally Included In Product',
          value: levelOfContainments['30']
        },
        {
          name: 'Free From Fish',
          value: levelOfContainments['FREE_FROM']
        },
        {
          name: 'May Contain Crustacean Shellfish',
          value: levelOfContainments['MAY_CONTAIN']
        }
      ]
    },
    AM: {
      allergenCode: 'AM',
      dropDownData: [
        {
          ...defaultOptions
        },
        {
          name: 'Contains Milk',
          value: levelOfContainments['CONTAINS']
        },
        {
          name: 'Milk Not Intentionally Included In Product',
          value: levelOfContainments['30']
        },
        {
          name: 'Free From Milk',
          value: levelOfContainments['FREE_FROM']
        },
        {
          name: 'May Contain Milk',
          value: levelOfContainments['MAY_CONTAIN']
        }
      ]
    },
    AP: {
      allergenCode: 'AP',
      dropDownData: [
        {
          ...defaultOptions
        },
        {
          name: 'Contains Peanuts',
          value: levelOfContainments['CONTAINS']
        },
        {
          name: 'Peanuts Not Intentionally Included In Product',
          value: levelOfContainments['30']
        },
        {
          name: 'Free From Peanuts',
          value: levelOfContainments['FREE_FROM']
        },
        {
          name: 'May Contain Peanuts',
          value: levelOfContainments['MAY_CONTAIN']
        }
      ]
    },
    AY: {
      allergenCode: 'AY',
      dropDownData: [
        {
          ...defaultOptions
        },
        {
          name: 'Contains Soybeans',
          value: levelOfContainments['CONTAINS']
        },
        {
          name: 'Soybeans Not Intentionally Included In Product',
          value: levelOfContainments['30']
        },
        {
          name: 'Free From Soybeans',
          value: levelOfContainments['FREE_FROM']
        },
        {
          name: 'May Contain Soybeans',
          value: levelOfContainments['MAY_CONTAIN']
        }
      ]
    },
    AN: {
      allergenCode: 'AN',
      dropDownData: [
        {
          ...defaultOptions
        },
        {
          name: 'Contains Tree Nuts',
          value: levelOfContainments['CONTAINS']
        },
        {
          name: 'Tree Nuts Not Intentionally Included In Product',
          value: levelOfContainments['30']
        },
        {
          name: 'Free From Tree Nuts',
          value: levelOfContainments['FREE_FROM']
        },
        {
          name: 'May Contain Tree Nuts',
          value: levelOfContainments['MAY_CONTAIN']
        }
      ]
    },
    UW: {
      allergenCode: 'UW',
      dropDownData: [
        {
          ...defaultOptions
        },
        {
          name: 'Contains Wheat',
          value: levelOfContainments['CONTAINS']
        },
        {
          name: 'Wheat Not Intentionally Included In Product',
          value: levelOfContainments['30']
        },
        {
          name: 'Free From Wheat',
          value: levelOfContainments['FREE_FROM']
        },
        {
          name: 'May Contain Wheat',
          value: levelOfContainments['MAY_CONTAIN']
        }
      ]
    },
    HALAL: {
      dietTypeCode: 'HALAL',
      dropDownData: [
        {
          ...defaultOptions
        },
        {
          name: 'Halal',
          value: 'HALAL'
        }
      ]
    },
    KOSHER: {
      dietTypeCode: 'KOSHER',
      dropDownData: [
        {
          ...defaultOptions
        },
        {
          name: 'Kosher',
          value: 'KOSHER'
        }
      ]
    },
    VEGAN: {
      dietTypeCode: 'VEGAN',
      dropDownData: [
        {
          ...defaultOptions
        },
        {
          name: 'Vegan',
          value: 'VEGAN'
        }
      ]
    },
    VEGETARIAN: {
      dietTypeCode: 'VEGETARIAN',
      dropDownData: [
        {
          ...defaultOptions
        },
        {
          name: 'Vegetarian',
          value: 'VEGETARIAN'
        }
      ]
    }
  }
};

export const syscoBrandValues = {
  true: '1',
  false: null
};

export const sustainTooltips = [
  {
    attribute: 'Post Consumer Recycled Content Claim',
    doc: 'Post Consumer Recycled Content Claim ',
    tooltip_text:
      'Post Consumer Recycled Content Claim identifies the amount of post consumer recycled content within the product by percentage.  Sysco is committed to the highest level of accuracy in reporting sustainability attributes.  Answering in the affirmative for any sustainability attribute for any item should only be done when accurate and supportable.'
  },
  {
    attribute: 'AGA - Certified Grass Fed',
    doc: 'AGA- Certified Grass Fed',
    tooltip_text:
      'AGA - Certified Grass Fed products are derived from animals raised by producers that have been certified to meet the AGA standard of four main areas within their production process.  Those four standards are 100% grass diet, raised on pasture, no antibiotics, or hormones, and having a family farm origin.  Sysco is committed to the highest level of accuracy in reporting sustainability attributes. Certification documentation should be readily available upon request, at any time, for any certificate, any item. Answering in the affirmative for any sustainability attribute for any item should only be done when such documentation is available.'
  },
  {
    attribute: 'American Humane Certified',
    doc: 'American Humane Certified',
    tooltip_text:
      'A product being American Humane Certified means that its producer entered a voluntary certification program that provides verification that the producers animal care is up to humane standards. Sysco is committed to the highest level of accuracy in reporting sustainability attributes. Certification documentation should be readily available upon request, at any time, for any certificate, any item. Answering in the affirmative for any sustainability attribute for any item should only be done when such documentation is available.'
  },
  {
    attribute: 'Antibiotic Free',
    doc: 'Antibiotic Free',
    tooltip_text:
      'The Antibiotic Free claim should be answered in the affirmative for Livestock/Red Meat or Poultry in which it is necessary to disclose that the animals were not administered antibiotics at any point in the animal production process.  Sysco is committed to the highest level of accuracy in reporting sustainability attributes. Certification documentation should be readily available upon request, at any time, for any certificate, any item. Answering in the affirmative for any sustainability attribute for any item should only be done when such documentation is available.'
  },
  {
    attribute: 'Aquaculture Stewardship Council (ASC)',
    doc: 'Aquaculture Stewardship Council (ASC)',
    tooltip_text:
      'The Aquaculture Stewardship Council standards include active prevention of negative impacts, alleviation of impact, the maintenance of favorable conditions, and the restoration of ecosystems that have seen damage. Sysco is committed to the highest level of accuracy in reporting sustainability attributes. Certification documentation should be readily available upon request, at any time, for any certificate, any item. Answering in the affirmative for any sustainability attribute for any item should only be done when such documentation is available.'
  },
  {
    attribute: 'Best Aquaculture Practice (BAP)',
    doc: 'Best Aquaculture Practice (BAP)',
    tooltip_text:
      'Best Aquaculture Practices is a third-party aquaculture specific certification program that addresses four areas of sustainability—environmental, social, food safety, and animal health & welfare. Sysco is committed to the highest level of accuracy in reporting sustainability attributes. Certification documentation should be readily available upon request, at any time, for any certificate, any item. Answering in the affirmative for any sustainability attribute for any item should only be done when such documentation is available.'
  },
  {
    attribute: 'Better Cotton Initiative (BCI)',
    doc: 'Better Cotton Initiative (BCI)',
    tooltip_text:
      'Better Cotton Initiative certification comes with membership to the Better Cotton Initiative and compliance with the 7 global Better Cotton principles identified.  Sysco is committed to the highest level of accuracy in reporting sustainability attributes. Certification documentation should be readily available upon request, at any time, for any certificate, any item. Answering in the affirmative for any sustainability attribute for any item should only be done when such documentation is available.'
  },
  {
    attribute: 'Biodegradable Products Institute Certified (BPI)',
    doc: 'Biodegradable Products Institute Certified (BPI)',
    tooltip_text:
      'BPI is an organization that promotes the production, use, and appropriate end of lives for materials and products that are designed to fully biodegrade in specific biologically active environments.  Sysco is committed to the highest level of accuracy in reporting sustainability attributes. Certification documentation should be readily available upon request, at any time, for any certificate, any item. Answering in the affirmative for any sustainability attribute for any item should only be done when such documentation is available.'
  },
  {
    attribute: 'Bioengineered (GMO)',
    doc: 'Bioengineered (GMO) ',
    tooltip_text:
      'Bioengineered products are products that have been modified through certain laboratory techniques in which the desired modification could not be obtained through conventional practices.  Sysco is committed to the highest level of accuracy in reporting sustainability attributes.  Answering in the affirmative for any sustainability attribute for any item should only be done when accurate and supportable.'
  },
  {
    attribute: 'Bird Friendly by Smithsonian',
    doc: 'Bird Friendly by Smithsonian',
    tooltip_text:
      'Bird friendly products are certified by the Smithsonian`s National Zoo and Conservation Biology Institute as derived by farms that provide habitats for birds that resemble forests. Sysco is committed to the highest level of accuracy in reporting sustainability attributes. Certification documentation should be readily available upon request, at any time, for any certificate, any item. Answering in the affirmative for any sustainability attribute for any item should only be done when such documentation is available.'
  },
  {
    attribute: 'BPA Free',
    doc: 'BPA Free',
    tooltip_text:
      'This claim attests that there is no Bisphenol A (BPA) used in the product or packaging of anything that may be directly ingested.  Sysco is committed to the highest level of accuracy in reporting sustainability attributes.  Answering in the affirmative for any sustainability attribute for any item should only be done when accurate and supportable.'
  },
  {
    attribute: 'Cage-Free Claim',
    doc: 'Cage-Free Claim',
    tooltip_text:
      'The term `Cage Free` refers to products that are sourced from animals that are allowed to roam in a room or otherwise open area rather than confined to a cage.  Sysco is committed to the highest level of accuracy in reporting sustainability attributes.  Answering in the affirmative for any sustainability attribute for any item should only be done when accurate and supportable.'
  },
  {
    attribute: 'Certified Humane Raised and Handled',
    doc: 'Certified Humane Raised and Handled',
    tooltip_text:
      'Products that are Certified Humane are verifiably produced in facilities that prioritize animal welfare. The goal of this program is to bring consumer awareness to the need for improvement in the lives of farm animals in food production from birth to slaughter. Sysco is committed to the highest level of accuracy in reporting sustainability attributes. Certification documentation should be readily available upon request, at any time, for any certificate, any item. Answering in the affirmative for any sustainability attribute for any item should only be done when such documentation is available.'
  },
  {
    attribute: 'Certified Palm Oil',
    doc: 'Certified Palm Oil ',
    tooltip_text:
      'Certified Palm Oil should be answered in the affirmative for any product that includes palm oil that has been certified as sustainable by any accredited entity.  Sysco is committed to the highest level of accuracy in reporting sustainability attributes. Certification documentation should be readily available upon request, at any time, for any certificate, any item. Answering in the affirmative for any sustainability attribute for any item should only be done when such documentation is available.'
  },
  {
    attribute: 'Certified Responsible Antibiotic Use (CRAU)',
    doc: 'Certified Responsible Antibiotic Use (CRAU)',
    tooltip_text:
      'CRAU is the very first responsible use standard to be verified by the U.S Department of Agriculture that designates the appropriate (minimal) use of medically important antibiotics in production. Sysco is committed to the highest level of accuracy in reporting sustainability attributes. Certification documentation should be readily available upon request, at any time, for any certificate, any item. Answering in the affirmative for any sustainability attribute for any item should only be done when such documentation is available.'
  },
  {
    attribute: 'Compost Manufacturing Alliance Certified (CMA)',
    doc: 'Compost Manufacturing Alliance Certified (CMA)',
    tooltip_text:
      'CMA Certified validates items that have met the acceptance standard for compostables by performing the testing necessary to confirm that the item adequately breaks down within the production cycle. Sysco is committed to the highest level of accuracy in reporting sustainability attributes. Certification documentation should be readily available upon request, at any time, for any certificate, any item. Answering in the affirmative for any sustainability attribute for any item should only be done when such documentation is available.'
  },
  {
    attribute: 'Compostable',
    doc: 'Compostable',
    tooltip_text:
      'This claim affirms that the product in question can be broken down by biological treatment at a commercial or industrial composting facility.  Sysco is committed to the highest level of accuracy in reporting sustainability attributes.  Answering in the affirmative for any sustainability attribute for any item should only be done when accurate and supportable.'
  },
  {
    attribute: 'Cultivation Claim',
    doc: 'Cultivation Claim',
    tooltip_text:
      'The `Cultivation` claim refers to the way that the fruit is acquired.  If the product was farmed or raised purposefully, it is considered `High Bush`.  If the product was acquired from a wild location, it is considered `Low Bush`.  Sysco is committed to the highest level of accuracy in reporting sustainability attributes.  Answering in the affirmative for any sustainability attribute for any item should only be done when accurate and supportable.'
  },
  {
    attribute: 'Demeter Certified Biodynamic',
    doc: 'Demeter Certified Biodynamic',
    tooltip_text:
      'A product that is Demeter Certified Biodynamic comes from a production facility that meets the Demeter farm minimum requirements of conforming to the biodynamic method.  The biodynamic method emphasizes the importance of forming living interactions and location specificity of needs.  Sysco is committed to the highest level of accuracy in reporting sustainability attributes. Certification documentation should be readily available upon request, at any time, for any certificate, any item. Answering in the affirmative for any sustainability attribute for any item should only be done when such documentation is available.'
  },
  {
    attribute: 'Energy Star',
    doc: 'Energy Star',
    tooltip_text:
      'Energy Star designates products that are high efficiency through third party certification. Sysco is committed to the highest level of accuracy in reporting sustainability attributes. Certification documentation should be readily available upon request, at any time, for any certificate, any item. Answering in the affirmative for any sustainability attribute for any item should only be done when such documentation is available.'
  },
  {
    attribute: 'Environment Safe',
    doc: 'Environment Safe',
    tooltip_text:
      'Environment safe products are products that do not contribute a negative impact on the environment in its production or in its use.  Sysco is committed to the highest level of accuracy in reporting sustainability attributes.  Answering in the affirmative for any sustainability attribute for any item should only be done when accurate and supportable.'
  },
  {
    attribute: 'EPA Registered',
    doc: 'EPA Registered',
    tooltip_text:
      'EPA Registered designation validates antimicrobial products that are effective against pests.  Sysco is committed to the highest level of accuracy in reporting sustainability attributes. Certification documentation should be readily available upon request, at any time, for any certificate, any item. Answering in the affirmative for any sustainability attribute for any item should only be done when such documentation is available.'
  },
  {
    attribute: 'EPA Safer Choice',
    doc: 'EPA Safer Choice',
    tooltip_text:
      'Products that are certified by the EPA as `Safer Choice` have passed the stringent criteria required to be labeled as safe for family use.  Sysco is committed to the highest level of accuracy in reporting sustainability attributes. Certification documentation should be readily available upon request, at any time, for any certificate, any item. Answering in the affirmative for any sustainability attribute for any item should only be done when such documentation is available.'
  },
  {
    attribute: 'Equitable Food Initiative (EFI)',
    doc: 'Equitable Food Initiative (EFI)',
    tooltip_text:
      'Equitable Foods Initiative Certification verifies that fruits and vegetables are grown in farms that hold up to the best practices in food safety, farmworkers are treated fairly and experience a healthy work environment, and farmworkers are engaged from a business and functionality perspective. Sysco is committed to the highest level of accuracy in reporting sustainability attributes. Certification documentation should be readily available upon request, at any time, for any certificate, any item. Answering in the affirmative for any sustainability attribute for any item should only be done when such documentation is available.'
  },
  {
    attribute: 'ETL Listed',
    doc: 'ETL Listed',
    tooltip_text:
      'ETL Listed mark is proof that the product in question has been tested and has been found to be compliant to the North American safety standards.  Sysco is committed to the highest level of accuracy in reporting sustainability attributes. Certification documentation should be readily available upon request, at any time, for any certificate, any item. Answering in the affirmative for any sustainability attribute for any item should only be done when such documentation is available.'
  },
  {
    attribute: 'Fair Food Program by Coalition of Immokalee Workers (CIW)',
    doc: 'Fair Food Program by Coalition of Immokalee Workers (CIW)',
    tooltip_text:
      'The Fair Food Program asks participating (certified) growers and buyers to abide by the `Fair Food Code of Conduct`; a collection of protective guidelines for farmworkers. Sysco is committed to the highest level of accuracy in reporting sustainability attributes. Certification documentation should be readily available upon request, at any time, for any certificate, any item. Answering in the affirmative for any sustainability attribute for any item should only be done when such documentation is available.'
  },
  {
    attribute: 'Fair Trade Certified',
    doc: '',
    tooltip_text:
      'Products that are Fair Trade are certified  and meet criteria in three different categories: economic, environmental, and social. Sysco is committed to the highest level of accuracy in reporting sustainability attributes. Certification documentation should be readily available upon request, at any time, for any certificate, any item. Answering in the affirmative for any sustainability attribute for any item should only be done when such documentation is available.'
  },
  {
    attribute: 'Fair Trade USA Certified',
    doc: 'Fair Trade USA Certified',
    tooltip_text:
      'Products that are Fair Trade certified meet standards that contribute to safe working conditions, environmental protection, sustainable livelihoods, and community development funds.  Sysco is committed to the highest level of accuracy in reporting sustainability attributes. Certification documentation should be readily available upon request, at any time, for any certificate, any item. Answering in the affirmative for any sustainability attribute for any item should only be done when such documentation is available.'
  },
  {
    attribute: 'Fairtrade America (International FLO)',
    doc: 'Fairtrade America (International FLO)',
    tooltip_text:
      'Products that are Fairtrade America International certified meet criteria in three different categories: economic, environmental, and social. Sysco is committed to the highest level of accuracy in reporting sustainability attributes. Certification documentation should be readily available upon request, at any time, for any certificate, any item. Answering in the affirmative for any sustainability attribute for any item should only be done when such documentation is available.'
  },
  {
    attribute: 'Forest Stewardship Council (FSC)',
    doc: 'Forest Stewardship Council (FSC)',
    tooltip_text:
      'FSC certifies that products come from responsibly managed forests that are environmentally sound, socially beneficial, and economically successful.  FSC utilizes 10 principles and 57 criteria to certify forests.  Sysco is committed to the highest level of accuracy in reporting sustainability attributes. Certification documentation should be readily available upon request, at any time, for any certificate, any item. Answering in the affirmative for any sustainability attribute for any item should only be done when such documentation is available.'
  },
  {
    attribute: 'Free Range Claim',
    doc: 'Free Range Claim',
    tooltip_text:
      'The Free Range Claim indicates that the meat or poultry product comes from an animal that is raised outdoors or with access to the outdoors. Sysco is committed to the highest level of accuracy in reporting sustainability attributes.  Answering in the affirmative for any sustainability attribute for any item should only be done when accurate and supportable.'
  },
  {
    attribute: 'Global Animal Partnership Certified (GAP)',
    doc: 'Global Animal Partnership Certified (GAP)',
    tooltip_text:
      'The Global Animal Partnership (GAP) certifies based on a comprehensive animal welfare program, defined by the 3 overlapping components of Health & Productivity, Emotional Well-Being, and Natural Living. Sysco is committed to the highest level of accuracy in reporting sustainability attributes. Certification documentation should be readily available upon request, at any time, for any certificate, any item. Answering in the affirmative for any sustainability attribute for any item should only be done when such documentation is available.'
  },
  {
    attribute: 'Global GAP Certified',
    doc: 'Global GAP Certified',
    tooltip_text:
      'Global G.A.P is an internationally recognized standard certified by the National Science Foundation for farm production set with the goal of safe and sustainable agricultural production that benefits farmers, retailers, and consumers.  Sysco is committed to the highest level of accuracy in reporting sustainability attributes. Certification documentation should be readily available upon request, at any time, for any certificate, any item. Answering in the affirmative for any sustainability attribute for any item should only be done when such documentation is available.'
  },
  {
    attribute: 'Global Organic Textile Certified (GOTS)',
    doc: 'Global Organic Textile Certified (GOTS)',
    tooltip_text:
      'Products certified by GOTS contain a credible and traceable organic origin of the product as well as processing that is environmentally and socially responsible in its management. Sysco is committed to the highest level of accuracy in reporting sustainability attributes. Certification documentation should be readily available upon request, at any time, for any certificate, any item. Answering in the affirmative for any sustainability attribute for any item should only be done when such documentation is available.'
  },
  {
    attribute: 'GMRI Responsibly Harvested',
    doc: 'GMRI Responsibly Harvested',
    tooltip_text:
      'A product labeled as GMRI Responsibly Harvested is from the Gulf of Maine region and managed in a way that preserves the long-term health of the body of water and its resources. Sysco is committed to the highest level of accuracy in reporting sustainability attributes. Certification documentation should be readily available upon request, at any time, for any certificate, any item. Answering in the affirmative for any sustainability attribute for any item should only be done when such documentation is available.”'
  },
  {
    attribute: 'Green Circle Certified',
    doc: 'Green Circle Certified',
    tooltip_text:
      'Green Circle Certification refers to a variety of certifications under GreenCircle Certified LLC.  These certifications include Recycled Content, Closed Loop Product, Certified Energy Savings, Biobased Content, Recyclable Material, and Life Cycle Assessment Optimized. Sysco is committed to the highest level of accuracy in reporting sustainability attributes. Certification documentation should be readily available upon request, at any time, for any certificate, any item. Answering in the affirmative for any sustainability attribute for any item should only be done when such documentation is available.'
  },
  {
    attribute: 'Green Seal Certified',
    doc: 'Green Seal Certified',
    tooltip_text:
      'Green Seal is a symbol that a product is independently verified to have met standards regarding four key impacts: protect human health, preserve the climate, minimize waste, and ensure clean water. Sysco is committed to the highest level of accuracy in reporting sustainability attributes. Certification documentation should be readily available upon request, at any time, for any certificate, any item. Answering in the affirmative for any sustainability attribute for any item should only be done when such documentation is available.'
  },
  {
    attribute: 'Made From Recycled',
    doc: 'Made From Recycled',
    tooltip_text:
      'The `Made From Recycled` claim distinguishes items that were made from remanufactured materials from those that were not.  Sysco is committed to the highest level of accuracy in reporting sustainability attributes. Certification documentation should be readily available upon request, at any time, for any certificate, any item. Answering in the affirmative for any sustainability attribute for any item should only be done when such documentation is available.'
  },
  {
    attribute: 'MADE IN GREEN by Oeko-TEX',
    doc: 'MADE IN GREEN by Oeko-TEX',
    tooltip_text:
      'MADE IN GREEN is a certification that verifies that the textiles used have been manufactured in environmentally friendly facilities under safe and socially responsible working conditions.  Sysco is committed to the highest level of accuracy in reporting sustainability attributes. Certification documentation should be readily available upon request, at any time, for any certificate, any item. Answering in the affirmative for any sustainability attribute for any item should only be done when such documentation is available.'
  },
  {
    attribute: 'Marine Stewardship Council (MSC)',
    doc: 'Marine Stewardship Council (MSC)',
    tooltip_text:
      'The blue MSC label indicates wild fish or seafood from fisheries that abide by MSC Fisheries standard for the purpose of sustainability and have been certified as such. Sysco is committed to the highest level of accuracy in reporting sustainability attributes. Certification documentation should be readily available upon request, at any time, for any certificate, any item. Answering in the affirmative for any sustainability attribute for any item should only be done when such documentation is available.'
  },
  {
    attribute: 'Monterey Bay Seafood Watch',
    doc: 'Monterey Bay Seafood Watch',
    tooltip_text:
      'Monterey Bay Aquarium Seafood Watch uses culminating information based on species of seafood, the harvesting method used for that seafood, and the body of water it is found in to classify seafood product into four categories. The four categories are as follows; `Best Choice` (Green), `Certified` (Blue), `Good Alternative` (Yellow), and `Avoid` (Red). Please utilize the linked search engine to select the category that best suits your product. Sysco is committed to the highest level of accuracy in reporting sustainability attributes.  Answering in the affirmative for any sustainability attribute for any item should only be done when accurate and supportable.'
  },
  {
    attribute: 'Natural Certification',
    doc: '',
    tooltip_text:
      'Natural Certification refers to being certified as `natural` by any certifying body.  Products that are certified as natural derive from animal resources that are fed a vegetarian diet, never given antibiotics, and never given hormones to accelerate growth.  Sysco is committed to the highest level of accuracy in reporting sustainability attributes. Certification documentation should be readily available upon request, at any time, for any certificate, any item. Answering in the affirmative for any sustainability attribute for any item should only be done when such documentation is available'
  },
  {
    attribute: 'NSF Listed',
    doc: 'NSF Listed',
    tooltip_text:
      'NSF Listed product are products that have been tested, audited, and certified to comply with a variety of specific standards for safety, quality, sustainability, and performance. Sysco is committed to the highest level of accuracy in reporting sustainability attributes. Certification documentation should be readily available upon request, at any time, for any certificate, any item. Answering in the affirmative for any sustainability attribute for any item should only be done when such documentation is available.'
  },
  {
    attribute: 'Organic Claim Status',
    doc: 'Organic Claim Status',
    tooltip_text:
      'An organic claim is any claim that describes a product as organic, or the ingredients used to make a product as organic. If your product claims to be organic in any capacity, please select the status that best represents that claim.   Sysco is committed to the highest level of accuracy in reporting sustainability attributes.  Answering in the affirmative for any sustainability attribute for any item should only be done when accurate and supportable.'
  },
  {
    attribute: 'OPT Certified Grass-Fed Organic',
    doc: 'OPT Certified Grass-Fed Organic',
    tooltip_text:
      'OPT Certified Grass-Fed Organic   is a certification that is available to those who are either certified organic or in the process of being certified organic under a USDA accredited certification agency.  This certification affirms that the product is an animal product derived from a source that is not only organic but also provided a diet in accordance with the standards set by PCO.   Sysco is committed to the highest level of accuracy in reporting sustainability attributes. Certification documentation should be readily available upon request, at any time, for any certificate, any item. Answering in the affirmative for any sustainability attribute for any item should only be done when such documentation is available.'
  },
  {
    attribute: 'PFAS Claim',
    doc: 'PFAS Claim',
    tooltip_text:
      'PFAS are a subset of manufactured chemicals that have been widely used because of their useful properties. If PFAS have been intentionally included in your product, please select `Per- and Polyfluoroalkyl Substances (PFAS Intentionally Added)`.  If PFAS have not been intentionally included in your product please select `Per- and Polyfluoroalkyl Substances (PFAS Not Intentionally Added)`. Sysco is committed to the highest level of accuracy in reporting sustainability attributes.  Answering in the affirmative for any sustainability attribute for any item should only be done when accurate and supportable.'
  },
  {
    attribute: 'Programme for the Endorsement of Forest Certification (PEFC)',
    doc: 'Programme for the Endorsement of Forest Certification (PEFC)',
    tooltip_text:
      'This certification is a voluntary certification that verifies that the forests involved in production are managed in accordance with the rigorous environmental, social, and economic requirements that the PEFC sets.  Sysco is committed to the highest level of accuracy in reporting sustainability attributes. Certification documentation should be readily available upon request, at any time, for any certificate, any item. Answering in the affirmative for any sustainability attribute for any item should only be done when such documentation is available.'
  },
  {
    attribute: 'Rainforest Alliance Certified',
    doc: 'Rainforest Alliance Certified',
    tooltip_text:
      'Rainforest Alliance Certification means that the product or ingredient was produced in a way that is verified by third-party auditors to promote the three pillars of sustainability; social, economic, and environmental.   Sysco is committed to the highest level of accuracy in reporting sustainability attributes. Certification documentation should be readily available upon request, at any time, for any certificate, any item. Answering in the affirmative for any sustainability attribute for any item should only be done when such documentation is available.'
  },
  {
    attribute: 'rBST-Free',
    doc: 'rBST-Free',
    tooltip_text:
      'This claim affirms that the resource animal was not treated with Bovine somatotropin, also known as bovine growth hormone.  This growth hormone is an animal drug approved by the FDA to facilitate accelerated milk production in dairy cows.  Sysco is committed to the highest level of accuracy in reporting sustainability attributes.  Answering in the affirmative for any sustainability attribute for any item should only be done when accurate and supportable.'
  },
  {
    attribute: 'Recyclable',
    doc: 'Recyclable ',
    tooltip_text:
      'The `Recyclable` claim allows Suppliers to distinguish items as made of recyclable material if that is the case.   Recyclable materials often include paper, cardboard, glass, plastic, and metal containers.  Sysco is committed to the highest level of accuracy in reporting sustainability attributes.  Answering in the affirmative for any sustainability attribute for any item should only be done when accurate and supportable.'
  },
  {
    attribute: 'Recycled Material',
    doc: 'Recycled Material',
    tooltip_text:
      'The `Recycled Material` claim distinguishes items that were made from remanufactured materials from those that were not.  Sysco is committed to the highest level of accuracy in reporting sustainability attributes.  Answering in the affirmative for any sustainability attribute for any item should only be done when accurate and supportable.'
  },
  {
    attribute: 'Regenerative Organic Certified',
    doc: 'Regenerative Organic Certified',
    tooltip_text:
      'Regenerative Organic Certified refers to certification delivered by the Regenerative Organic Alliance that focuses on the need for agriculture that regenerates soil health and the farm ecosystem. Sysco is committed to the highest level of accuracy in reporting sustainability attributes. Certification documentation should be readily available upon request, at any time, for any certificate, any item. Answering in the affirmative for any sustainability attribute for any item should only be done when such documentation is available.'
  },
  {
    attribute: 'Renewable',
    doc: 'Renewable',
    tooltip_text:
      'Products made with renewable material are products made with natural resources that can be repeatedly replenished. Sysco is committed to the highest level of accuracy in reporting sustainability attributes.  Answering in the affirmative for any sustainability attribute for any item should only be done when accurate and supportable.'
  },
  {
    attribute: 'Reusable',
    doc: 'Reusable',
    tooltip_text:
      'To `Reuse` is the use of a product or material again for the same purpose, in its original form or with little enhancement or change.  Products that are `Reusable` can withstand multiple uses.  Sysco is committed to the highest level of accuracy in reporting sustainability attributes.  Answering in the affirmative for any sustainability attribute for any item should only be done when accurate and supportable.'
  },
  {
    attribute: 'RSPO Certified Palm Oil',
    doc: 'RSPO Certified Palm Oil',
    tooltip_text:
      'RSPO certification verifies that the palm oil is produced in a sustainable manner. Sysco is committed to the highest level of accuracy in reporting sustainability attributes. Certification documentation should be readily available upon request, at any time, for any certificate, any item. Answering in the affirmative for any sustainability attribute for any item should only be done when such documentation is available.'
  },
  {
    attribute: 'Salmon Safe Certified',
    doc: 'Salmon Safe Certified',
    tooltip_text:
      'Products that are Salmon Safe certified, are produced by companies that have been certified to utilize environmental practices that protect water quality and habitat for salmon.  Sysco is committed to the highest level of accuracy in reporting sustainability attributes. Certification documentation should be readily available upon request, at any time, for any certificate, any item. Answering in the affirmative for any sustainability attribute for any item should only be done when such documentation is available.'
  },
  {
    attribute: 'Seafood Watch',
    doc: '',
    tooltip_text:
      'Monterey Bay Aquarium Seafood Watch uses culminating information based on species of seafood, the harvesting method used for that seafood, and the body of water it is found in to classify seafood product into four categories. The four categories are as follows; `Best Choice` (Green), `Certified` (Blue), `Good Alternative` (Yellow), and `Avoid` (Red). Please utilize the linked search engine to select the category that best suits your product. Sysco is committed to the highest level of accuracy in reporting sustainability attributes. Certification documentation should be readily available upon request, at any time, for any certificate, any item. Answering in the affirmative for any sustainability attribute for any item should only be done when such documentation is available.'
  },
  {
    attribute: 'Sustainability Claim',
    doc: 'Sustainability Claim',
    tooltip_text:
      'The `Sustainability` claim is meant to distinguish products that are produced in a way that contributes to the maintenance of conditions under which humans and nature can coexist and support generations to come from those that are not.  Sysco is committed to the highest level of accuracy in reporting sustainability attributes.  Answering in the affirmative for any sustainability attribute for any item should only be done when accurate and supportable.'
  },
  {
    attribute: 'Sustainable Claim',
    doc: 'Sustainable Claim',
    tooltip_text:
      'The `Sustainable` claim is meant to distinguish products that are produced in a way that contributes to the maintenance of conditions under which humans and nature can coexist and support generations to come from those that are not.  Sysco is committed to the highest level of accuracy in reporting sustainability attributes.  Answering in the affirmative for any sustainability attribute for any item should only be done when accurate and supportable.'
  },
  {
    attribute: 'Sustainable Forestry Initiative (SFI)',
    doc: 'Sustainable Forestry Initiative (SFI)',
    tooltip_text:
      'SFI provides a variety of different certifications for different uses, including SFI Forest Management, SFI Fiber Sourcing, and SFI Certified Sourcing among others.  The common thread among these separate certifications is the support of a growing need for forest products while driving the reduction of carbon pollution and waste. Sysco is committed to the highest level of accuracy in reporting sustainability attributes. Certification documentation should be readily available upon request, at any time, for any certificate, any item. Answering in the affirmative for any sustainability attribute for any item should only be done when such documentation is available.'
  },
  {
    attribute: 'UI/Ecologo',
    doc: 'UL Ecologo',
    tooltip_text:
      'Products that are Ul Ecologo certified have been verified by third party certifiers to have undergone rigorous testing and have been found to have identifiers that indicate reduced environmental impact.  Sysco is committed to the highest level of accuracy in reporting sustainability attributes. Certification documentation should be readily available upon request, at any time, for any certificate, any item. Answering in the affirmative for any sustainability attribute for any item should only be done when such documentation is available.'
  },
  {
    attribute: 'USDA Biopreferred Certified',
    doc: 'USDA Biopreferred Certified',
    tooltip_text:
      'The USDA BioPreferred certification was created with the intent to increase the purchase and use of biobased product.  Biobased products are products that either is wholly composed of biological products or has majority composition as such.  Sysco is committed to the highest level of accuracy in reporting sustainability attributes. Certification documentation should be readily available upon request, at any time, for any certificate, any item. Answering in the affirmative for any sustainability attribute for any item should only be done when such documentation is available.'
  },
  {
    attribute: 'USDA Certified Organic',
    doc: 'USDA Certified Organic ',
    tooltip_text:
      'Organic certification verifies that a farm or handling facility meets USDA Organic regulations. Sysco is committed to the highest level of accuracy in reporting sustainability attributes.  Answering in the affirmative for any sustainability attribute for any item should only be done when accurate and supportable.'
  }
];
