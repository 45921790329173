import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import { Button, Modal, Table, Select, Input } from 'antd';
import _ from 'lodash';

import { fnbCommentAttributes, featuresAndBenifitsCommentKeys } from '../util/Data';
import { DEFAULT_STATUS, REJECT, DENY } from '../util/Constants';
import { getValuesFromTwoFields, getValueBasedOnTheCondition } from '../util/Util';

const { TextArea } = Input;
const Option = Select.Option;

const STATUS = [REJECT];

const EditableCell = ({ editable, value, onChange, disabled }) => (
  <div>
    {editable ? (
      <React.Fragment>
        <TextArea rows={4} value={value} onChange={e => onChange(e.target.value)} disabled={disabled} />
      </React.Fragment>
    ) : (
      value
    )}
  </div>
);

function ThirdPartyFnBDetailsTable(
  {
    fnbComment,
    addFnBCommentToRejectState,
    removeFnBCommentFromRejectState,
    isUpdating,
    modelToggle,
    modelVisible,
    commentStatus
  },
  ref
) {
  const [sourceData, setSourceData] = useState([]);
  const [updatedFields, setUpdatedFields] = useState([]);

  useEffect(() => {
    let data = initializeData(fnbComment);

    setSourceData(data);
  }, [fnbComment]);

  useImperativeHandle(
    ref,
    () => ({
      getUpdatedFnBData: () => {
        let updatedData = [];

        _.forEach(sourceData, item => {
          if (_.includes(updatedFields, item.attribute)) {
            let obj = {
              attribute: item.attribute,
              status: item.status,
              comment: item.comment
            };

            if (item.status !== null) {
              updatedData.push(obj);
            }
          }
        });

        return updatedData;
      }
    }),
    [updatedFields, sourceData]
  );

  const initializeData = fnbComment => {
    let data = [];

    fnbCommentAttributes.forEach(item => {
      let review = _.find(fnbComment.review, review => review.attribute === item);

      let obj = {
        attribute: item,
        attributeValue: _.get(fnbComment.pending, `${item}`, null),
        status: _.get(review, 'status', null),
        comment: _.get(review, 'comment', null)
      };

      if (obj.attributeValue) {
        data.push(obj);
      }
    });

    return data;
  };

  const renderStatusColumn = record => {
    let status = _.get(record, 'status', null);

    let previousReview = _.find(fnbComment.review, item => {
      return record.attribute === item.attribute;
    });

    return (
      <Select
        disabled={getValuesFromTwoFields(isUpdating, commentStatus === 30)}
        value={getValueBasedOnTheCondition(status, status, DEFAULT_STATUS)}
        style={{ width: 150 }}
        onChange={value => onChangeStatus(value, record.attribute)}
      >
        <Option key={0} value={DEFAULT_STATUS} disabled={previousReview}>
          <span style={{ opacity: '0.5' }}>{DEFAULT_STATUS}</span>
        </Option>
        {STATUS.map(item => {
          return (
            <Option key={item} value={item}>
              {item}
            </Option>
          );
        })}
      </Select>
    );
  };

  const renderCommentsColumn = record => {
    if (getValuesFromTwoFields(record.status === DENY, record.status === REJECT)) {
      return (
        <TextArea
          defaultValue={record.comment}
          rows={4}
          disabled={isUpdating}
          onChange={e => onChangeComment(e.target.value, record.attribute)}
        />
      );
    }
    return <div>{_.get(record, 'comment', '')}</div>;
  };

  const renderAttributeColumn = attribute => {
    return <div style={{ fontWeight: 'bold' }}>{featuresAndBenifitsCommentKeys[attribute].name}</div>;
  };

  const renderAttributeValueColumn = (text, record) => {
    return (
      <EditableCell
        editable={record.editable}
        disabled={getValuesFromTwoFields(record.isSavingAttribute, isUpdating)}
        value={text}
      />
    );
  };

  const onChangeStatus = (value, attribute, rejectAll = false) => {
    const clone = [...sourceData];
    const target = clone.filter(item => attribute === item.attribute)[0];

    if (value === DEFAULT_STATUS) {
      target.status = null;
      target.comment = null;
    } else {
      target.status = value;
    }

    setSourceData(clone);

    let updatedFieldsClone = [...updatedFields];
    if (!updatedFieldsClone.includes(attribute)) updatedFieldsClone.push(attribute);
    if (rejectAll) {
      setUpdatedFields(prevState => [...prevState, ...updatedFieldsClone]);
    } else {
      setUpdatedFields(updatedFieldsClone);
    }

    setParentCommentReviewState(clone);
  };

  const onChangeComment = (value, attribute, rejectAll = false) => {
    const clone = [...sourceData];
    const target = clone.filter(item => attribute === item.attribute)[0];

    target.comment = value;
    setSourceData(clone);

    let updatedFieldsClone = [...updatedFields];
    if (!updatedFieldsClone.includes(attribute)) updatedFieldsClone.push(attribute);
    if (rejectAll) {
      setUpdatedFields(prevState => [...prevState, ...updatedFieldsClone]);
    } else {
      setUpdatedFields(updatedFieldsClone);
    }
  };

  const setParentCommentReviewState = data => {
    // Checking if there are any rejected or denied reviews
    const index = _.findIndex(data, item => {
      return getValuesFromTwoFields(item.status === REJECT, item.status === DENY);
    });

    if (index === -1) {
      removeFnBCommentFromRejectState(fnbComment.pending.comment_id);
      return;
    }
    addFnBCommentToRejectState(fnbComment.pending.comment_id);
  };

  const showModal = () => {
    modelToggle();
  };

  const handleCancel = () => {
    modelToggle();
  };

  const handleOk = () => {
    sourceData.forEach(item => {
      onChangeComment('Rejected', item.attribute, true);
      onChangeStatus(REJECT, item.attribute, true);
    });
    modelToggle();
  };

  const rejectAllClick = commentStatus => {
    if (commentStatus !== 30) showModal();
  };

  const columns = [
    {
      title: 'Sections',
      dataIndex: 'attribute',
      width: 220,
      render: text => renderAttributeColumn(text)
    },
    {
      title: 'Values',
      dataIndex: 'attributeValue',
      width: 'auto',
      render: (text, record) => renderAttributeValueColumn(text, record)
    },
    {
      title: (
        <span>
          Reject
          <span className="tiny-click" onClick={() => rejectAllClick(commentStatus)}>
            Reject All
          </span>
          <Modal
            visible={modelVisible}
            title="Please confirm..."
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
              <Button key="back" onClick={handleCancel}>
                Cancel
              </Button>,
              <Button key="submit" type="danger" onClick={handleOk}>
                Reject All
              </Button>
            ]}
          >
            Do you want to reject all the attributes of this item?
          </Modal>
        </span>
      ),
      dataIndex: 'status',
      width: 200,
      render: (text, record, index) => renderStatusColumn(record)
    },
    {
      title: 'Comments',
      dataIndex: 'comment',
      width: 'auto',
      render: (text, record, index) => renderCommentsColumn(record)
    }
  ];

  return (
    <div className="fnb-details-table-wrapper">
      <Table bordered pagination={false} dataSource={sourceData} columns={columns} />
    </div>
  );
}

export default forwardRef(ThirdPartyFnBDetailsTable);
