import React from 'react';
import { Select, Tooltip } from 'antd';
import _ from 'lodash';
import {
  CHILD_NUTRITIONS_OR_PFS,
  OSD_TAB_CN_VALIDATION_TEXT,
  ATTRIBUTE_TYPES_SYNCHED_WITH_NUTRITIONS,
  CHILD_NUTRITION_ELIGIBILITY,
  DISABLED_ATTRIBUTION_FIELDS_WITH_NUTRITIONS,
  ENUT_WINNING_SOURCE_VALUE,
  NUTRITION_SYNCHED_TAXONOMY_ATTRIBUTE_NAMES_LIST
} from 'util/Constants';
import PendingComment from './PendingComment';
import {
  getFeedbackSelectedValue,
  getClassNameFromConditions,
  getValueBasedOnTheCondition,
  getFieldDisabledStatus,
  getTaxonomyDisabledStatusOnChildNutritionValue,
  getChildNutritionData,
  isDataSourcedFromQaSpecOrGdsn,
  getPendingOrExistingCnCheckedValues,
  getGdsnOrSyscoBrandFlag,
  getDisabledOptionsDetailsForGdsnAttributes,
  isPicklistOptionDisabledForGdsnAttributes
} from '../util/Util';
import osdNutrition from '../util/osdNutrition';
import { sustainTooltips } from '../util/Data';

const hoverStyle = {
  color: '#66c2ff',
  '&:hover': { color: '#008ae6 !important' }
};

export const GDSN_DISABLED_GENERAL_ATTRIBUTES = {
  [CHILD_NUTRITION_ELIGIBILITY]: {
    title: (
      <>
        Updates may not be made in this portal to disabled fields. This product is a{' '}
        <b>GDSN-published, national brand item</b>, please update this attribute on the{' '}
        <b>isChildNutrition or HasProductFormulationStmt</b> GS1 attributes with your data pool partner and be sure to
        publish your changes to Sysco (GLN 0074865000000).
      </>
    )
  }
};

export const GDSN_DISABLED_AVAILABLE_TOOLTIPS = {
  'Organic Claim Status': {
    title: (
      <>
        Updates may not be made in this portal to disabled fields. This product is a{' '}
        <b>GDSN-published, national brand item</b>, please update this attribute on the
        <b>OrganicTradeItemCode</b> GS1 attributes with your data pool partner and be sure to publish your changes to
        Sysco (GLN 0074865000000).
      </>
    )
  }
};

class TaxonomyAttribute extends React.Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.state = {
      selectedState: false,
      selectedValue: null,
      isSelectChanged: false
    };
    this.toggleSelected = this.toggleSelected.bind(this);
  }

  isGdsnOrSyscoBrandedItemLevelIndicator = (gdsn, syscoBrand) => gdsn === 'Y' || syscoBrand === 'Y';

  toggleSelected(selected) {
    this.setState({
      selectedState: selected
    });
  }

  handleChildNutritionEligibility = (name, picklist, changedId, attrType) => {
    const {
      item: {
        pendingNutrition: {
          changedFields,
          isChildNutrition: existingIsChildNutrition,
          sleHasProductFormulationStmt: existingSleHasProductFormulationStmt
        } = { changedFields: [] },
        nutritions: { nutritionSourceOid, child_or_product_formulation: childNutritionData } = {},
        pendingNutritions,
        basicData: { gdsn, syscoBrand } = {}
      },
      actions
    } = this.props;
    const isNutritionsSourcedFromQaOrGdsn =
      isDataSourcedFromQaSpecOrGdsn(nutritionSourceOid) ||
      this.isGdsnOrSyscoBrandedItemLevelIndicator(gdsn, syscoBrand);
    if (name === CHILD_NUTRITION_ELIGIBILITY) {
      const { isChildNutrition, sleHasProductFormulationStmt, hasSelectionRemoved } = getChildNutritionData(
        picklist,
        changedId
      );
      let dataObject = {
        ...getPendingOrExistingCnCheckedValues({
          attrType,
          existingIsChildNutrition,
          existingSleHasProductFormulationStmt,
          isChildNutrition,
          sleHasProductFormulationStmt,
          pendingNutritions
        })
      };
      if (hasSelectionRemoved && attrType) {
        dataObject = {
          ...dataObject,
          ...childNutritionData
        };
      }
      if (!isNutritionsSourcedFromQaOrGdsn) {
        dataObject = {
          ...dataObject,
          changedFields
        };
        if (!(isChildNutrition || sleHasProductFormulationStmt)) {
          this.clearValidationErrorsForK12Information();
        }
        if (
          (isChildNutrition || sleHasProductFormulationStmt) &&
          (isChildNutrition !== existingIsChildNutrition ||
            sleHasProductFormulationStmt !== existingSleHasProductFormulationStmt)
        ) {
          dataObject = {
            ...dataObject,
            changedFields: [...new Set([...changedFields, 'isChildNutrition', 'sleHasProductFormulationStmt'])]
          };
          actions.updateNutritionTaxonomySynchedChanges({ hasNutritionSynchedTaxonomyAttributeChanges: true });
        } else if (
          !(isChildNutrition && sleHasProductFormulationStmt) &&
          nutritionSourceOid &&
          nutritionSourceOid == 21
        ) {
          const removedCnPfsFlags = changedFields.filter(
            field => !['isChildNutrition', 'sleHasProductFormulationStmt'].includes(field)
          );
          dataObject = {
            ...dataObject,
            changedFields: [...removedCnPfsFlags]
          };
          actions.updateNutritionTaxonomySynchedChanges({ hasNutritionSynchedTaxonomyAttributeChanges: false });
        }
      }
      actions.nutritionValuesChanged(dataObject);
    } else if (
      attrType &&
      ATTRIBUTE_TYPES_SYNCHED_WITH_NUTRITIONS.includes(attrType) &&
      NUTRITION_SYNCHED_TAXONOMY_ATTRIBUTE_NAMES_LIST.includes(name) &&
      // name !== 'Natural Claim' &&
      !isNutritionsSourcedFromQaOrGdsn
    ) {
      actions.updateNutritionTaxonomySynchedChanges({ hasNutritionSynchedTaxonomyAttributeChanges: true });
    }
  };

  componentDidMount() {
    const {
      attribute: { selected, picklist, name }
    } = this.props;
    this.handleChildNutritionEligibility(name, picklist, selected);
    this.toggleSelected(selected != null);
  }

  handleCancel = () => {
    this.toggleSelected(false);
    this.setState({ selectedValue: null, isSelectChanged: false });
  };

  getTooltipContent = (text, type = null) => {
    return (
      <Tooltip
        className={type == 'sustainability' ? 'sustain' : 'non-sustain'}
        title={
          <div style={{ paddingLeft: '15px', paddingRight: '15px' }} className="gdsn-info">
            {text}
          </div>
        }
      >
        {!type ? 'i' : ''}
      </Tooltip>
    );
  };

  getSustainabilityAttributeTooltips = ({ name, type }) => {
    if (`${type}`.includes('sustainability')) {
      let toolTipObj = _.find(
        sustainTooltips,
        ({ attribute }) => `${attribute}`.toLowerCase() == `${name}`.toLowerCase()
      );
      let toolTipDoc = _.get(toolTipObj, 'doc', false);

      const element = (
        <p>
          <b>{name}</b>
          <br />
          {toolTipObj && (
            <span>
              <br />
              {toolTipObj.tooltip_text}
              <br />
              <br />
            </span>
          )}
          {toolTipDoc && (
            <a href={`/publicresources/pdf/${encodeURI(toolTipDoc)}.pdf`} target="_blank" style={hoverStyle}>
              Read more...
            </a>
          )}
        </p>
      );
      return this.getTooltipContent(element, false);
    }
  };

  getPfasClaimTooltip = ({ name }) => {
    if (name === 'PFAS Claim') {
      return [
        true,
        this.getTooltipContent(
          'PFAS Claim Field: This attribute must be enriched in the Regulatory Tab under Product Level.',
          false
        )
      ];
    }
    return [false, false];
  };

  getChildNutritionError = ({ name }, item) => {
    if (
      GDSN_DISABLED_GENERAL_ATTRIBUTES[name] &&
      getTaxonomyDisabledStatusOnChildNutritionValue(item) &&
      !item.isNutritionDetailsNotApplicable
    ) {
      return <span className="validation-error">{OSD_TAB_CN_VALIDATION_TEXT}</span>;
    }
    return null;
  };

  clearValidationErrorsForK12Information = () => {
    const { actions, nutritionErrors } = this.props;
    const updatedNutritionErrors = { ...nutritionErrors };
    CHILD_NUTRITIONS_OR_PFS.map(({ code }) => {
      if (nutritionErrors[code]) {
        delete updatedNutritionErrors[code];
      }
      return null;
    });
    actions.updateNutritionFieldErrors({ updatedErrors: updatedNutritionErrors });
  };

  handleSelectChange = (pickList, changedId, attrType) => {
    const {
      attribute: { name }
    } = this.props;
    this.handleChildNutritionEligibility(name, pickList, changedId, attrType);
    this.setState({ selectedValue: changedId, isSelectChanged: true });
  };

  render() {
    const { item, attribute, actions, pendingComments, isDisabledForGdsnOrSysco, attrType } = this.props;
    const {
      taxonomyChanges = [],
      nutritions: { nutritionSourceOid },
      basicData
    } = item;
    const { Option } = Select;

    const attrFieldName = `${attribute.name} (${attribute.attrId})`;

    let commentText = '';
    let existingComment = null;
    pendingComments.comments.forEach(comment => {
      if (comment.field === attrFieldName) {
        commentText = comment.comment;
        existingComment = comment;
      }
    });

    const commentsPopup = e => {
      this.inputRef.current.blur();
      actions.commentsPopup({
        item,
        field: attrFieldName,
        currentValue: '',
        fieldType: 'TAXONOMY',
        fieldCaption: attribute.name,
        existingComment
      });
    };

    const onTaxonomyAttrChange = attrId => {
      return e => {
        const attrChange = {
          attrId: attrId.toString(),
          selected: getFeedbackSelectedValue(e, '[feedback]')
        };
        this.toggleSelected(attrChange.selected != null);
        actions.addAttrChange({ attrChange });
        this.setState({ isSelectChanged: true });
      };
    };

    const checkForPendingNutritionValues = itemAttribute => {
      let value = '';
      let pendingNutrition = false;
      const { item: { pendingNutritions } = { pendingNutritions: [] } } = this.props;
      if (pendingNutritions.length) {
        const { taxonomyChanges: changes } = JSON.parse(pendingNutritions[0].comment);
        if (changes && changes.length) {
          const a = changes.filter(({ attrId }) => Number(attrId) === Number(itemAttribute.attrId));
          if (a && a.length) {
            value = a[0].selected;
            pendingNutrition = true;
          }
        }
      }
      return { value, pendingNutrition };
    };

    const getUnapprovedOrPendingNutritionIcon = (attr, isOnlyPendingNutritionStatus = false) => {
      const { pendingNutrition } = checkForPendingNutritionValues(attr);
      if (isOnlyPendingNutritionStatus) return pendingNutrition;
      return pendingNutrition || attr.unapproved;
    };

    let taxonomyAttrToolTip = getValueBasedOnTheCondition(attribute.tooltip, attribute.tooltip, attribute.name);
    if (attribute.unapproved || getUnapprovedOrPendingNutritionIcon(attribute, true)) {
      taxonomyAttrToolTip = `${taxonomyAttrToolTip} - Pending change to be approved.`;
    }

    const pickList = [..._.get(attribute, 'picklist', [])];
    pickList.sort((a, b) => {
      const aValue = getValueBasedOnTheCondition(a.value, a.value, '');
      const bValue = getValueBasedOnTheCondition(b.value, b.value, '');

      if (aValue.includes('Not Applicable')) {
        return 1;
      }

      if (bValue.includes('Not Applicable')) {
        return -1;
      }

      return aValue.localeCompare(bValue, 'en', { numeric: true, sensitivity: 'base' });
    });

    const getSelectedValue = () => {
      let value = attribute.selected;
      const { value: pendingNutritionValue, pendingNutrition } = checkForPendingNutritionValues(attribute);
      if (pendingNutrition) value = Number(pendingNutritionValue) || null;
      const changedValueIndex = taxonomyChanges.findIndex(({ attrId }) => attrId === attribute.attrId.toString());
      if (this.state.isSelectChanged) value = this.state.selectedValue;
      if (changedValueIndex > -1) value = Number(taxonomyChanges[changedValueIndex].selected) || null;
      if (attribute.name === 'PFAS Claim') return attribute.pfasSelected || value;
      return value;
    };

    const disabledMainKey = getGdsnOrSyscoBrandFlag(nutritionSourceOid, basicData);

    const isFieldDisabledForSameValue = DISABLED_ATTRIBUTION_FIELDS_WITH_NUTRITIONS[disabledMainKey]?.[
      'disabled_for_same_values'
    ].includes(attribute?.name);

    const disabledField =
      DISABLED_ATTRIBUTION_FIELDS_WITH_NUTRITIONS[disabledMainKey]?.[attribute?.winningSource]?.[attribute?.name] ?? {};
    const isFieldDisabled = Object.keys(disabledField).length;

    const isFieldHavingSameValuesInEnutAndAttribution = osdNutrition.getNutritionRelatedOsdValue(
      attribute.name,
      item.nutritions,
      item?.taxonomy?.attributes
    );

    const areEnutAndAttributionValuesAreSameForGdsnItem =
      isFieldDisabledForSameValue && isFieldHavingSameValuesInEnutAndAttribution;
    const enabledSameFieldDetails = getDisabledOptionsDetailsForGdsnAttributes(
      isFieldDisabledForSameValue,
      disabledMainKey,
      attribute.name
    );
    const isPositiveOptionsDisabled = Object.keys(enabledSameFieldDetails).length;

    const getTooltipValueForDisabled = () => {
      if (isFieldDisabled) return this.getTooltipContent(disabledField.tooltip);
      if (isPositiveOptionsDisabled && !areEnutAndAttributionValuesAreSameForGdsnItem)
        return this.getTooltipContent(enabledSameFieldDetails.tooltip);
      if (areEnutAndAttributionValuesAreSameForGdsnItem && isFieldDisabledForSameValue)
        return this.getTooltipContent(
          DISABLED_ATTRIBUTION_FIELDS_WITH_NUTRITIONS[disabledMainKey]?.[ENUT_WINNING_SOURCE_VALUE]?.[attribute?.name]
            .tooltip
        );
      if (
        DISABLED_ATTRIBUTION_FIELDS_WITH_NUTRITIONS[disabledMainKey]?.['other_available_tooltips']?.[attribute?.name]
      ) {
        return this.getTooltipContent(
          DISABLED_ATTRIBUTION_FIELDS_WITH_NUTRITIONS[disabledMainKey]?.['other_available_tooltips']?.[attribute?.name]
            .tooltip
        );
      }
    };

    const [isPfasDisabled, pfasTooltip] = this.getPfasClaimTooltip(attribute);

    return (
      <div
        key={`f ${attribute.attrId}`}
        className={`attr-wrapper ${getClassNameFromConditions(
          getUnapprovedOrPendingNutritionIcon(attribute),
          ' un-approved'
        )}`}
      >
        <div title={taxonomyAttrToolTip} className="attr-label">
          {attribute.name}
          {getUnapprovedOrPendingNutritionIcon(attribute) && ' ⟳'}
          {getTooltipValueForDisabled()}
          {this.getSustainabilityAttributeTooltips(attribute)}
          {pfasTooltip}
        </div>
        <Select
          defaultValue={attribute.selected}
          id={`${attribute.attrId}`}
          name={`attr_${attribute.attrId}`}
          className={`attr-taxonomy${getClassNameFromConditions(
            commentText !== '',
            ' pending-approval'
          )}${getClassNameFromConditions(
            attribute.source && attribute.source === 'GDSN',
            ' is-gdsn-value syncedvalue'
          )}${getClassNameFromConditions(getSelectedValue() === null, ' with-placeholder')}
          ${!attribute.selected ? 'incomplete-attr' : ''}`}
          dropdownMatchSelectWidth={false}
          onChange={onTaxonomyAttrChange(attribute.attrId)}
          disabled={isFieldDisabled || areEnutAndAttributionValuesAreSameForGdsnItem || item.readOnly || isPfasDisabled}
          ref={this.inputRef}
          value={getSelectedValue()}
          onSelect={value => this.handleSelectChange(pickList, value, attrType)}
        >
          <Option
            key={0}
            value={null}
            disabled={isPicklistOptionDisabledForGdsnAttributes(
              isPositiveOptionsDisabled,
              enabledSameFieldDetails,
              null
            )}
          >
            Please select...
          </Option>
          {pickList.map((pick, index) => (
            <Option
              key={parseInt(pick.id)}
              value={pick.id}
              disabled={isPicklistOptionDisabledForGdsnAttributes(
                isPositiveOptionsDisabled,
                enabledSameFieldDetails,
                pick.value
              )}
              className={getClassNameFromConditions(
                attribute.picklist.length - 1 === index,
                'taxonomy-attr-with-margin-bottom'
              )}
            >
              {pick.value}
            </Option>
          ))}
          <Option key="last" className="taxonomy-feedback" onMouseDown={commentsPopup} disabled value="[feedback]">
            Send Feedback
            <span className="send-icon"></span>
          </Option>
        </Select>
        {this.getChildNutritionError(
          attribute,
          item
        ) /* comment this child nutrition error when disabling enut application */}
        {attribute.source && attribute.source === 'GDSN' && (
          <Tooltip
            className={`attr-source-tag ${attribute.source.toLowerCase()}`}
            title="This value was taken from GDSN."
          >
            G
          </Tooltip>
        )}
        {commentText !== '' && <PendingComment comment={commentText} clickOn={!item.readOnly && commentsPopup} />}
      </div>
    );
  }
}

export default TaxonomyAttribute;
