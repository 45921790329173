import React from 'react';
import _ from 'lodash';
import { Input, Select } from 'antd';
import {
  SERVING_UOM_OPTIONS,
  DEFAULT_SELECTED_SERVING_UOM_CODE,
  SERVING_DATA_KEYS,
  HEADER_INDEX,
  SERVING_DESCRIPTION_OPTIONS
} from '../../../util/Constants';
import {
  isValidNutritionHeaderField,
  checkForFieldPendingNutrition,
  seperateServingDescription,
  validateForThreeDecimalsNutritionsQuantity,
  validateForServingDescriptionQuantity,
  checkIfStartsWithNumberic,
  validateServingDescriptionQuantity,
  hasDecimal
} from '../../../util/Util';
import CustomTooltip from '../Tooltip';

const { Option } = Select;
const { DESCRIPTION, QUANTITY, UOFM } = SERVING_DATA_KEYS;

const ServingDetails = ({
  nutritionData,
  isReadOnly,
  isItemReadOnly,
  onFieldChange,
  nutritionErrors,
  updateNutritionErrors,
  pendingNutritions,
  isDisabledBySyscoOrGdsnItemFlag
}) => {
  const nutritionAnalysisHeader = _.get(nutritionData, 'nutritionAnalysisHeader', []);
  const changedFields = _.get(nutritionData, 'changedFields', []);
  const [
    { servingSizeDescription, servingSizeQuantity, servingSizeUofm } = {
      servingSizeDescription: null,
      servingSizeQuantity: null,
      servingSizeUofm: null
    }
  ] = nutritionAnalysisHeader;
  let uom = servingSizeUofm;
  const target = _.find(SERVING_UOM_OPTIONS, opt => opt.code === servingSizeUofm);

  const [servingSizeDescriptionQuantity, servingSizeDescriptionValue] = seperateServingDescription(
    servingSizeDescription
  );

  if (target) {
    uom = target.des;
  }

  const handleFieldChange = (field, value, isSubField, fieldValue) => {
    let updatedErrors = { ...nutritionErrors };
    const updatedHeader = nutritionAnalysisHeader;
    updatedHeader[HEADER_INDEX] = {
      ...nutritionAnalysisHeader[HEADER_INDEX],
      [field]: value
    };
    if (
      !isValidNutritionHeaderField(value) ||
      (isSubField && field === DESCRIPTION && !validateServingDescriptionQuantity(fieldValue))
    ) {
      updatedErrors = {
        ...updatedErrors,
        [field]: 'Invalid Value'
      };
    } else {
      delete updatedErrors[field];
    }
    onFieldChange({ nutritionAnalysisHeader: updatedHeader, changedFields: [...new Set([...changedFields, field])] });
    updateNutritionErrors({ updatedErrors });
  };

  const hasFieldErrors = (field, subField) => {
    if (
      Object.keys(nutritionErrors).includes(field) ||
      (subField === 'DESCRIPTIONQUANTITY' && !validateServingDescriptionQuantity(servingSizeDescriptionQuantity))
    )
      return `nt-invalid-field`;
    return ``;
  };

  const getDisableStatus = () => {
    return isReadOnly || isItemReadOnly || isDisabledBySyscoOrGdsnItemFlag;
  };

  const validateQuantityField = value => {
    if (value) {
      if (hasDecimal(value)) {
        return value.replace(/\.\d{4,}/g, match => {
          return match.slice(0, match.indexOf('.') + 4);
        });
      }
      return value;
    }
    return '';
  };

  const concatenateQuantityAndDescription = (quantity, description) => {
    let concatenatedString = '';
    if (quantity) concatenatedString = validateQuantityField(quantity);
    if (description) concatenatedString = `${concatenatedString} ${description.trim()}`;
    console.log({ quantity, description, concatenatedString });
    return concatenatedString;
  };

  const handleServingDescriptionRelatedChanges = (type, newValue) => {
    let updatedValue = '';
    if (type === QUANTITY) {
      updatedValue = concatenateQuantityAndDescription(newValue, servingSizeDescriptionValue);
    } else if (type === DESCRIPTION) {
      updatedValue = concatenateQuantityAndDescription(servingSizeDescriptionQuantity, newValue);
    }
    const [updatedQuantity] = seperateServingDescription(updatedValue);
    handleFieldChange(DESCRIPTION, updatedValue, type === QUANTITY, updatedQuantity);
  };

  const renderTag = value => {
    return (
      <span style={{ maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
        {value}
      </span>
    );
  };

  const avoidLetterE = event => {
    const allowedKeys = [8, 9, 32, 37, 38, 39, 40, 46, 109, 189, 190, 191];
    const key = event.keyCode || event.which;
    if (event.shiftKey || (!allowedKeys.includes(key) && !(key >= 48 && key <= 57))) {
      event.preventDefault();
      return false;
    }
  };

  const disabledStatus = getDisableStatus();

  return (
    <div className="nutri-header">
      <div className="nutri-header-title nt-title nutrition-facts-header-title with-enut-tooltip">
        Nutrition Facts
        <CustomTooltip tooltipKey="nutrient_values" isCustom />
      </div>
      <hr className="light-seperator" />
      <div className="nutrition-facts-container">
        <div className="nutrition-header-each-fact-panel" style={{ display: 'flex', flexDirection: 'column' }}>
          <div className="left-panel nutrition-fact-sub-title with-enut-tooltip">
            Serving Size:
            <span className="required-fields custom-fonts">* </span>
            <CustomTooltip tooltipKey="serving_size" isCustom />
          </div>
          {/* <div className="right-panel serving-fields-inputs" style={{ display: 'block', width: '100%' }}> */}
          <div
            className="right-panel serving-fields-inputs"
            style={{ display: 'flex', justifyContent: 'flex-start', width: '100%', padding: '10px 0px' }}
          >
            <div style={{ flex: '1', width: '50%' }}>
              <div className="nutri-row-title long left-panel serving-field-attached" style={{ overflow: 'hidden' }}>
                Serving Size Description /UOM
                <span className="required-fields">*</span>
                <div>
                  {disabledStatus ? (
                    <Input
                      className={`serving-size-input serving-des-input input-fields-serving description only-disabled ${hasFieldErrors(
                        DESCRIPTION
                      )} ${checkForFieldPendingNutrition(DESCRIPTION, pendingNutritions)}`}
                      placeholder="Description"
                      title={servingSizeDescription}
                      value={servingSizeDescription}
                      disabled={disabledStatus}
                      onChange={e => handleFieldChange(DESCRIPTION, e.target.value)}
                    />
                  ) : (
                    <>
                      <Input
                        className={`serving-size-input input-fields-serving serving-size ${hasFieldErrors(
                          DESCRIPTION,
                          'DESCRIPTIONQUANTITY'
                        )} ${checkForFieldPendingNutrition(DESCRIPTION, pendingNutritions)}`}
                        value={servingSizeDescriptionQuantity}
                        placeholder="Qty"
                        title={`Quantity ${servingSizeDescriptionQuantity || ''}`}
                        // type="number"
                        // min="0"
                        disabled={disabledStatus}
                        onKeyDown={e => avoidLetterE(e)}
                        // onChange={e =>
                        //   handleServingDescriptionRelatedChanges(
                        //     QUANTITY,
                        //     validateForThreeDecimalsNutritionsQuantity(e.target.value, servingSizeDescriptionQuantity)
                        //   )
                        // }
                        onChange={e =>
                          handleServingDescriptionRelatedChanges(
                            QUANTITY,
                            validateForServingDescriptionQuantity(e.target.value, servingSizeDescriptionQuantity)
                          )
                        }
                      />
                      <Select
                        className={`serving-select-uom input-fields-serving serving-size-uom  ${hasFieldErrors(
                          DESCRIPTION,
                          'DESCRIPTIONUOM'
                        )}  ${checkForFieldPendingNutrition(DESCRIPTION, pendingNutritions)}`}
                        mode="tags"
                        style={{ paddingLeft: '5px' }}
                        placeholder="Please select or enter text..."
                        value={
                          !servingSizeDescriptionValue || servingSizeDescriptionValue.trim() == ''
                            ? []
                            : [servingSizeDescriptionValue]
                        }
                        disabled={disabledStatus}
                        onSelect={value => handleServingDescriptionRelatedChanges(DESCRIPTION, value)}
                        onDeselect={() => handleServingDescriptionRelatedChanges(DESCRIPTION, null)}
                        tagRender={renderTag}
                        dropdownMatchSelectWidth={false}
                      >
                        {SERVING_DESCRIPTION_OPTIONS.map(obj => {
                          return (
                            <Option value={obj.code} key={obj.code}>
                              {obj.des}
                            </Option>
                          );
                        })}
                      </Select>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div style={{ flex: '1', width: '50%' }}>
              <div className="nutri-row-title long left-panel serving-field-attached">
                Serving Size Quantity /UOM
                <span className="required-fields">*</span>
                <div>
                  <Input
                    className={`serving-size-input input-fields-serving serving-size ${hasFieldErrors(
                      QUANTITY
                    )} ${checkForFieldPendingNutrition(QUANTITY, pendingNutritions)}`}
                    value={servingSizeQuantity}
                    placeholder="Qty"
                    title={`Quantity ${servingSizeQuantity || ''}`}
                    type="number"
                    min="0"
                    disabled={disabledStatus}
                    onKeyDown={e => avoidLetterE(e)}
                    onChange={e =>
                      handleFieldChange(
                        QUANTITY,
                        validateForThreeDecimalsNutritionsQuantity(e.target.value, servingSizeQuantity)
                      )
                    }
                  />
                  <Select
                    className={`serving-select-uom input-fields-serving serving-size-uom ${checkForFieldPendingNutrition(
                      UOFM,
                      pendingNutritions
                    )}`}
                    style={{ paddingLeft: '5px' }}
                    placeholder="Please select..."
                    value={uom || DEFAULT_SELECTED_SERVING_UOM_CODE}
                    disabled={disabledStatus}
                    onSelect={value => handleFieldChange(UOFM, value)}
                    dropdownMatchSelectWidth={false}
                  >
                    {SERVING_UOM_OPTIONS.map(obj => {
                      return (
                        <Option value={obj.code} key={obj.code}>
                          {obj.des}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="bold-seperator" />
      </div>
    </div>
  );
};

export default ServingDetails;
