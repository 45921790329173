const initialState = {
  user: {
    fetching: false,
    data: {
      email: null,
      contactId: null,
      name: null,
      validTill: null,
      roles: [],
      department: null,
      suvc: null,
      selectedVendor: null,
      permissions: []
    },
    error: null
  },
  comments: {
    fetching: false,
    data: [],
    error: null,
    search: '',
    page: 1,
    fType: 4,
    fStatus: 4,
    recordCount: 0,
    fetchingFnbComments: false,
    fnbComments: [],
    isAllCoreAttributesInCommentRejecting: false,
    history: {
      coreData: [],
      fetching: false
    },
    coreModal: {
      data: {},
      isVisible: false,
      isSaving: false
    },
    preferredImages: [],
    overallItemFeedbackHistory: {
      singleComments: {},
      allHistory: [],
      isOverallHistoryLoading: false
    }
  },
  scorecards: {
    scoresLoading: true,
    taxonomy: {},
    brands: [],
    scores: [
      {
        cardType: 'overall',
        title: 'OVERALL SCORE',
        score: 0
      },
      {
        cardType: 'attribution',
        title: 'PRODUCT ATTRIBUTION',
        score: 0
      },
      {
        cardType: 'image',
        title: 'PRODUCT IMAGES',
        score: 0
      },
      {
        cardType: 'fnb',
        title: 'FEATURES AND BENEFITS',
        score: 0
      },
      {
        cardType: 'contact',
        title: 'CONTACTS',
        score: 0
      }
    ],
    activeChart: '',
    summaryItems: [],
    overallSummary: {},
    fetchingOverallSummary: false,
    filterForGraphs: {
      brand: null,
      sysco: null,
      bc: null,
      ig: null,
      ag: null
    }
  },
  vendor: {
    fetching: false,
    suvc: null,
    list: null,
    details: {
      subSuvc: [],
      brands: []
    },
    all: [],
    selectedSuvc: null,
    rejectedFnbs: [],
    rejectedImages: [],
    rejectedNutritions: [],
    fetchingRejectedSupcs: false,
    fnbRejectFilterOn: false,
    autoSavedFnb: [],
    imageRejectFilterOn: false,
    nutritionRejectFilterOn: false,
    feeFilterOn: false,
    isAutoSavedFnbFilterOn: false,
    tableFilters: null,
    tableSearchData: null,
    brandGroupFilters: [],
    orderBy: null,
    filterType: [],
    dateRangeFilter: null,
    isFiltersComplex: false,
    feeExclusionsSupcs: [],
    freeSearch: false,
    fetchingFeeExclusionsCount: false,
    fnbMassEditItems: [],
    savingFnbMassUpdate: false,
    showFnbMassUpdateModal: false,
    fetchingFnbMassEditData: false,
    rejectedCoreData: [],
    rejectedOverall: [],
    coreDataRejectFilterOn: false
  },
  selectedItem: {
    fetching: false,
    basicData: null,
    supc: null,
    master: null,
    feedback: null,
    fnb: {
      fnb: null,
      pending: null,
      review: null,
      tooltip: null,
      tooltipFetching: false,
      fnbFetching: false,
      gdsnFnb: {},
      isGdsnFetchedOnce: false,
      isFetchingGdsnFnb: false
    },
    images: null,
    imagesLoaded: false,
    taxonomy: null,
    pending: false,
    taxonomyChanges: [],
    taxonomyComment: '',
    defaultTab: '1',
    masterComments: [],
    suvc: null,
    readOnly: false,
    nutritions: {},
    fetchingNutritions: false,
    updatingNutritions: false,
    sendingNutritionQuestions: false,
    isNutritionDetailsNotApplicable: true,
    updatingNutritionsApplicableStatus: false,
    fetchingNutritionsApplicableStatus: false,
    nutritionsApplicableStatusError: false,
    pendingNutritions: [],
    fetchingPentingNutritions: false,
    cancelingNutritionUpdate: false,
    updateTaxonomyNutritions: 0,
    syncedNutritions: { traits: [], claims: [], organicTradeItemCode: null },
    coreData: { comment: {} },
    originalCoreData: {},
    isCoreDataSubmitable: false,
    isCoreDataSubmitting: false,
    originalCoreDataValues: { comment: {} },
    isNutritionDataHasBeenFetched: false,
    pendingNutrition: {
      changedFields: []
    },
    nutritionErrors: {},
    hasNutritionSynchedTaxonomyAttributeChanges: false,
    previousHasNutritionSynchedTaxonomyAttributeChanges: false,
    itemMasterData: {
      fetching: false
    },
    imageTab: {
      unsavedPreferredImgs: {},
      pendingPreferredImgs: {},
      submittingPreferredImgs: false,
      incorrectNaming: []
    },
    feeExclusions: [],
    cooDatas: [],
    originalCooDatas: [],
    isCooDataSubmitable: false,
    isCooDataSubmitting: false,
    fetchingCooData: false,
    regulatorySubTab: '1',
    pfas: {
      supc: null,
      attributes: [],
      changes: {},
      changesBackup: {},
      isMassNotApplicable: false,
      isLoadingOrSaving: false
    },
    fsma: {
      attributes: [],
      isLoadingFsma: false,
      isSavingFsma: false,
      changes: []
    }
  },
  fieldComment: {
    show: false,
    field: '',
    currentValue: '',
    item: {},
    type: '',
    fieldCaption: '',
    hint: '',
    existingComment: null,
    existingStyles: [],
    execution: 1,
    imageCategoryName: null,
    imageCategory: null,
    image: null,
    loading: false,
    previousCommentId: null
  },
  pendingComments: {
    comments: [],
    pendingOverallFeedbackHistory: []
  },
  page: {
    subSuvc: null,
    search: '',
    page: 1,
    recordCount: 0,
    graphfilter: null
  },
  pgmItems: {
    list: [],
    visibilityChanges: [],
    forcedVisibleSupcs: [],
    fetchingList: false,
    updatingVisibility: false,
    search: '',
    page: 1,
    recordCount: 0,
    forcedVisibilityBannerOn: false
  },
  massUpdate: {
    updateType: null,
    image: {},
    attribute: {
      list: [],
      taxonomy: {},
      completeTaxonomy: [],
      attributes: [],
      selectedValues: [],
      appliedValues: [],
      selectedRows: [],
      filters: {
        bc: null,
        ig: null,
        ag: null,
        attributeFilters: [],
        tableFilters: [],
        tableSearchData: [],
        orderBy: null
      },
      pagination: {
        page: 1,
        recordsCount: 0
      },
      showModal: false,
      fetchingTaxonomy: false,
      fetchingAttributes: false,
      fetchingList: false,
      saving: false
    },
    pfas: {
      showModal: false,
      isLoading: false,
      isSaving: false,
      filters: {
        bc: null,
        ig: null,
        ag: null,
        tableFilters: [],
        tableSearchData: [],
        orderBy: null,
        attributes: {
          PFAS: []
        }
      },
      appliedValues: {},
      fetchingTaxonomy: false,
      pagination: {
        page: 1,
        recordsCount: 0
      },
      list: [],
      changes: {},
      selectedValues: []
    },
    isLoadingContent: false,
    loadingPercentage: 0,
    isUploading: false,
    savingPercentage: 0
  }
};

export default initialState;
