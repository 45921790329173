import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import { Button, Modal, Table, Select, Input, Popconfirm, Tooltip } from 'antd';
import _ from 'lodash';

import { fnbCommentAttributes, featuresAndBenifitsCommentKeys, gdsnFnbTooltips } from '../util/Data';
import {
  DEFAULT_STATUS,
  REJECT,
  DENY,
  FIXED,
  FNB_CHARACTER_LIMIT,
  PRODUCT_DESCRIPTOR_CHARACTER_LIMIT,
  OVERALL_FNB_CHARACTER_LIMIT,
  IGNORING_GDSN_FNB_ATTRIBUTES
} from './../util/Constants';
import {
  getValuesFromTwoFields,
  getValueBasedOnTheCondition,
  updateArrayOnItemStatus,
  checkStatusWithTwoAndConditions,
  checkValueAndReturnElement,
  updateArrayValuesIfNotExisting,
  pushObjectToColumnArrayByCheckingCondition
} from '../util/Util';

const { TextArea } = Input;
const Option = Select.Option;

const STATUS = [REJECT, FIXED];

const EditableCell = ({ editable, value, onChange, disabled, characterLimit }) => (
  <div>
    {editable ? (
      <React.Fragment>
        <div className="fnb-value-count-wrapper">
          <div>{`${value.length}/${characterLimit}`}</div>
        </div>
        <TextArea
          // autoSize={{ minRows: 3, maxRows: 8 }}
          rows={4}
          value={value}
          onChange={e => onChange(e.target.value)}
          disabled={disabled}
          maxLength={characterLimit}
        />
      </React.Fragment>
    ) : (
      value
    )}
  </div>
);

function FnBDetailsTable(
  {
    fnbComment,
    editFnBAttribute,
    addFnBCommentToRejectState,
    removeFnBCommentFromRejectState,
    parentComment,
    isUpdating,
    modelToggle,
    modelVisible,
    gdsnFnb
  },
  ref
) {
  const [sourceData, setSourceData] = useState([]);
  const [cachedAttributes, setCachedAttributes] = useState([]);
  const [updatedFields, setUpdatedFields] = useState([]);

  useEffect(() => {
    let data = initializeData(fnbComment);

    setSourceData(data);
    setCachedAttributes(data.map(item => ({ attribute: item.attribute, attributeValue: item.attributeValue })));
  }, [fnbComment]);

  useImperativeHandle(
    ref,
    () => ({
      getUpdatedFnBData: () => {
        let updatedData = [];

        _.forEach(sourceData, item => {
          if (_.includes(updatedFields, item.attribute)) {
            let obj = {
              attribute: item.attribute,
              status: item.status,
              comment: item.comment
            };
            updatedData = updateArrayOnItemStatus(item, updatedData, obj);
          }
        });

        return updatedData;
      }
    }),
    [updatedFields, sourceData]
  );

  const initializeData = fnbComment => {
    let data = [];

    fnbCommentAttributes.forEach(item => {
      let review = _.find(fnbComment.review, review => review.attribute === item);

      let obj = {
        attribute: item,
        attributeValue: _.get(fnbComment.pending, `${item}`, null),
        status: _.get(review, 'status', null),
        comment: _.get(review, 'comment', null)
      };

      data.push(obj);
    });

    return data;
  };

  const getOverallStatementLength = () => {
    let count = 0;

    _.forEach(sourceData, obj => {
      let value = _.get(obj, 'attributeValue', '');
      count = count + value.length;
    });

    return count;
  };

  const renderEditColumn = (text, record) => {
    const { editable } = record;
    return (
      <div className="editable-row-operations">
        {getValueBasedOnTheCondition(
          editable,
          <div>
            <span
              className={`btn-fnb ${getValueBasedOnTheCondition(
                getValuesFromTwoFields(record.isSavingAttribute, isUpdating),
                'btn-disabled',
                ''
              )}`}
              onClick={() => saveAttribute(record.attribute)}
            >
              Save
            </span>
            <Popconfirm title="Sure to cancel?" onConfirm={() => cancelAttribute(record.attribute)}>
              <span
                className={`btn-fnb ${getValueBasedOnTheCondition(
                  getValuesFromTwoFields(record.isSavingAttribute, isUpdating),
                  'btn-disabled',
                  ''
                )}`}
              >
                Cancel
              </span>
            </Popconfirm>
          </div>,
          <span
            className={`btn-fnb ${getValueBasedOnTheCondition(
              getValuesFromTwoFields(parentComment.status === 30, isUpdating),
              'btn-disabled',
              ''
            )}`}
            onClick={() => editAttribute(record.attribute)}
          >
            Edit
          </span>
        )}
      </div>
    );
  };

  const renderStatusColumn = record => {
    let status = _.get(record, 'status', null);

    let previousReview = _.find(fnbComment.review, item => {
      return record.attribute === item.attribute;
    });

    return (
      <Select
        disabled={getValuesFromTwoFields(parentComment.status === 30, isUpdating)}
        value={getValueBasedOnTheCondition(status, status, DEFAULT_STATUS)}
        style={{ width: 150 }}
        onChange={value => onChangeStatus(value, record.attribute)}
      >
        <Option key={0} value={DEFAULT_STATUS} disabled={previousReview}>
          <span style={{ opacity: '0.5' }}>{DEFAULT_STATUS}</span>
        </Option>
        {STATUS.map(item => {
          return (
            <Option key={item} value={item}>
              {item}
            </Option>
          );
        })}
      </Select>
    );
  };

  const renderCommentsColumn = record => {
    return getValueBasedOnTheCondition(
      getValuesFromTwoFields(record.status === DENY, record.status === REJECT),
      <TextArea
        // autoSize={{ minRows: 3, maxRows: 8 }}
        defaultValue={record.comment}
        rows={4}
        disabled={isUpdating}
        onChange={e => onChangeComment(e.target.value, record.attribute)}
      />,
      <div>{_.get(record, 'comment', '')}</div>
    );
  };

  const renderAttributeColumn = attribute => {
    return <div style={{ fontWeight: 'bold' }}>{featuresAndBenifitsCommentKeys[attribute].name}</div>;
  };

  const renderAttributeValueColumn = (text, record) => {
    return (
      <EditableCell
        editable={record.editable}
        disabled={getValuesFromTwoFields(record.isSavingAttribute, isUpdating)}
        value={text}
        onChange={value => onChangeAttributeValue(value, record.attribute)}
        characterLimit={getValueBasedOnTheCondition(
          record.attribute === 'descriptor',
          PRODUCT_DESCRIPTOR_CHARACTER_LIMIT,
          FNB_CHARACTER_LIMIT
        )}
      />
    );
  };

  const renderGDSNColumn = record => {
    const gdsnValue = _.get(gdsnFnb, `fnb[${record.attribute}]`, '');
    const tooltip = _.get(gdsnFnbTooltips, `${record.attribute}`, '');
    return checkValueAndReturnElement(
      !IGNORING_GDSN_FNB_ATTRIBUTES.includes(record.attribute),
      <div className="fnb-expanded-gdsn-column">
        {getValueBasedOnTheCondition(
          tooltip,
          <Tooltip
            overlayClassName="feedback-fnb-gdsn-tooltip"
            title={<div className="fnb-gdsn-tooltip-text">{tooltip}</div>}
          >
            i
          </Tooltip>
        )}
        <div className="fnb-expanded-gdsn-value">
          {getValueBasedOnTheCondition(!_.isEmpty(gdsnValue), gdsnValue, 'No GDSN values found')}
        </div>
      </div>
    );
  };

  const onChangeAttributeValue = (value, attribute) => {
    const clone = [...sourceData];
    const target = clone.filter(item => attribute === item.attribute)[0];
    if (target) {
      let totalCount = getOverallStatementLength();
      if (!checkStatusWithTwoAndConditions(totalCount >= OVERALL_FNB_CHARACTER_LIMIT, target.attributeValue < value)) {
        target.attributeValue = value;
        setSourceData(clone);
      } // To limit the overall statement use in the else statement
    }
  };

  const onChangeStatus = (value, attribute, rejectAll = false) => {
    const clone = [...sourceData];
    let target = clone.filter(item => attribute === item.attribute)[0];

    if (value === DEFAULT_STATUS) {
      target.status = null;
      target.comment = null;
    } else {
      target.status = value;
    }

    setSourceData(clone);

    let updatedFieldsClone = updateArrayValuesIfNotExisting([...updatedFields], attribute);
    if (rejectAll) {
      setUpdatedFields(prevState => [...prevState, ...updatedFieldsClone]);
    } else {
      setUpdatedFields(updatedFieldsClone);
    }

    setParentCommentReviewState(clone);
  };

  const onChangeComment = (value, attribute, rejectAll = false) => {
    const clone = [...sourceData];
    const target = clone.filter(item => attribute === item.attribute)[0];

    target.comment = value;
    setSourceData(clone);

    let updatedFieldsClone = updateArrayValuesIfNotExisting([...updatedFields], attribute);
    if (rejectAll) {
      setUpdatedFields(prevState => [...prevState, ...updatedFieldsClone]);
      return;
    }
    setUpdatedFields(updatedFieldsClone);
  };

  const editAttribute = attribute => {
    const clone = [...sourceData];
    const target = clone.filter(item => attribute === item.attribute)[0];
    if (target) {
      target.editable = true;
      setSourceData(clone);
    }
  };

  const saveAttribute = attribute => {
    const callBack = success => {
      const clone = [...sourceData];
      const target = clone.filter(item => attribute === item.attribute)[0];

      if (success) {
        delete target.editable;
        target.isSavingAttribute = false;
        setSourceData(clone);

        let cachedAttributes = clone.map(item => ({ attribute: item.attribute, attributeValue: item.attributeValue }));
        setCachedAttributes(cachedAttributes);
      } else {
        target.isSavingAttribute = false;
        setSourceData(clone);
      }
    };

    const clone = [...sourceData];
    const target = clone.filter(item => attribute === item.attribute)[0];
    let totalCount = getOverallStatementLength();

    if (checkStatusWithTwoAndConditions(target, totalCount <= OVERALL_FNB_CHARACTER_LIMIT)) {
      target.isSavingAttribute = true;
      setSourceData(clone);

      let updatedObj = {};
      updatedObj[attribute] = target.attributeValue;

      editFnBAttribute(parentComment.suvc, parentComment.supc, updatedObj, callBack);
    }
  };

  const cancelAttribute = attribute => {
    const clone = [...sourceData];
    const target = clone.filter(item => attribute === item.attribute)[0];
    if (target) {
      let cachedAttribute = _.find(cachedAttributes, item => item.attribute === attribute);
      target.attributeValue = cachedAttribute.attributeValue;
      delete target.editable;
      setSourceData(clone);
    }
  };

  const setParentCommentReviewState = data => {
    // Checking if there are any rejected or denied reviews
    const index = _.findIndex(data, item => {
      return item.status === REJECT || item.status === DENY;
    });

    if (index === -1) {
      removeFnBCommentFromRejectState(fnbComment.pending.comment_id);
      return;
    }
    addFnBCommentToRejectState(fnbComment.pending.comment_id);
  };

  const showModal = () => {
    modelToggle();
  };

  const handleCancel = () => {
    modelToggle();
  };

  const handleOk = () => {
    sourceData.forEach(item => {
      onChangeComment('Rejected', item.attribute, true);
      onChangeStatus(REJECT, item.attribute, true);
    });
    modelToggle();
  };

  let columns = [
    {
      title: 'Sections',
      dataIndex: 'attribute',
      width: 220,
      render: text => renderAttributeColumn(text)
    },
    {
      title: 'Values',
      dataIndex: 'attributeValue',
      width: 'auto',
      render: (text, record) => renderAttributeValueColumn(text, record)
    },
    {
      title: 'Edit',
      dataIndex: 'operation',
      width: 120,
      render: (text, record) => renderEditColumn(text, record)
    },
    {
      title: (
        <span>
          Reject
          <span className="tiny-click" onClick={showModal}>
            Reject All
          </span>
          <Modal
            visible={modelVisible}
            title="Please confirm..."
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
              <Button key="back" onClick={handleCancel}>
                Cancel
              </Button>,
              <Button key="submit" type="danger" onClick={handleOk}>
                Reject All
              </Button>
            ]}
          >
            Do you want to reject all the attributes of this item?
          </Modal>
        </span>
      ),
      dataIndex: 'status',
      width: 200,
      render: (text, record, index) => renderStatusColumn(record)
    },
    {
      title: 'Comments',
      dataIndex: 'comment',
      width: 'auto',
      render: (text, record, index) => renderCommentsColumn(record)
    }
  ];

  columns = [
    ...pushObjectToColumnArrayByCheckingCondition(columns, gdsnFnb, {
      title: 'GDSN Data',
      dataIndex: 'gdsn',
      width: 'auto',
      render: (text, record) => renderGDSNColumn(record)
    })
  ];

  return (
    <div className="fnb-details-table-wrapper">
      <div className="fnb-overall-count">
        <div className="fnb-overall-count-lbl">Overall statement: </div>
        <div
          className={getValueBasedOnTheCondition(
            getOverallStatementLength() > OVERALL_FNB_CHARACTER_LIMIT,
            'fnb-overall-count-error',
            ''
          )}
        >
          {getOverallStatementLength()}
        </div>
        <div>{`/${OVERALL_FNB_CHARACTER_LIMIT}`}</div>
      </div>
      <Table bordered pagination={false} dataSource={sourceData} columns={columns} />
    </div>
  );
}

export default forwardRef(FnBDetailsTable);
