const UserRole = {
  VENDOR: 'vendor',
  CORP_MERCH: 'corpmerch',
  OPCO_MERCH: 'opcomerch',
  ADMIN: 'admin'
};

const nutritionPropertyKeys = {
  nutritionAnalysisHeader: 'Nutrition Analysis Header',
  ingredientStatement: 'Ingredient Statement',
  organicTradeItemCodes: 'Organic Trade Item Codes',
  manufacturer: 'Manufacturer Details'
};

const nutritionHeaderKeys = {
  servingSizeDescription: 'Serving Size Description',
  servingSizeQuantity: 'Serving Size Quantity',
  servingSizeUofm: 'Serving Size Uofm',
  nutritionAnalysisDetail: 'Nutrition Analysis Detail'
};

const nutritionUpdateSubTitles = {
  nutritionAnalysisDetail: 'Nutrition Analysis Detail'
};

const nutritionAnalysisDetailKeys = {
  dailyValueIntakePercent: 'Daily Value Intake Percent',
  quantityContained: 'Quantity Contained',
  quantityContainedUofm: 'Quantity Contained Uofm',
  nutritionName: 'Nutrition Name'
};

const organicTradeItemCodes = {
  1: '100% Organic',
  2: 'Organic (At Least 95 % By Weight)',
  3: 'Made With Organic Ingredients (At Least 70 % By Weight)',
  4: 'Some Organic Ingredients (Less than 70 % By Weight)',
  5: 'Not Organic',
  6: 'In Conversion',
  7: 'Bio Dynamic',
  8: 'Disqualified'
};

const childNutritionProductFormulationKeys = {
  sleProductId: 'CN Identification Number',
  slePortionSize: 'Portion Size',
  sleNSLPmeatQuantity: 'Meat/Meat Alt (oz eq)',
  sleNSLPbreadQuantity: 'Grain/Bread (oz eq)',
  sleNotes: 'School Nutrition Note',
  sleNSLPfruitQuantity: 'Fruit (cup)',
  sleRedOrangeVegetableQuantity: 'Vegetable - Red/Orange (cup)',
  sleDarkGreenVegetableQuantity: 'Vegetable - Dark Green (cup)', // should be numeric below 99.99
  sleStarchyVegetableQuantity: 'Vegetable - Starchy (cup)',
  sleBeansPeasVegetableQuantity: 'Vegetable - Beans/Peas (cup)',
  sleOtherQuantity: 'Vegetable - Other (cup)',
  isChildNutrition: 'Is Child Nutrition?',
  sleHasProductFormulationStmt: 'Has Product Formulation Statement?'
};

const NUTRITION_REJECT_OPTIONS = ['REJECT', 'FIXED'];

const MANUFACTURER_KEYS = {
  name: 'Name',
  gln: 'Global Location Number',
  alternateIdentifier: 'Alternate Identifier'
};

const UOM_TYPES_MAPPING = {
  GRM: 'g',
  MGM: 'mg',
  MC: 'mcg',
  E14: 'kcal'
};

const NUTRITION_FEEDBACK_KEYS = [
  'nutritionAnalysisHeader',
  'organicTradeItemCodes',
  'ingredientStatement',
  // 'manufacturer',
  'allergen',
  'diet_type',
  'nutritionalClaimsDetails',
  'slePortionSize'
];

const NUTRITION_FEEDBACK_KEY_VALUES = {
  nutritionAnalysisHeader: 'Nutrition Facts',
  organicTradeItemCodes: 'Organic Trade Item Codes',
  ingredientStatement: 'Ingredient Statement',
  allergen: 'Allergens',
  diet_type: 'Diet Types',
  nutritionalClaimsDetails: 'Claims',
  slePortionSize: 'K12 Information'
};

const PRODUCT_ATTRIBUTE_REJECT_FIELDS = ['allergen', 'diet_type', 'nutritionalClaimsDetails', 'organicTradeItemCodes'];

export {
  UserRole,
  nutritionPropertyKeys,
  nutritionHeaderKeys,
  nutritionUpdateSubTitles,
  nutritionAnalysisDetailKeys,
  organicTradeItemCodes,
  childNutritionProductFormulationKeys,
  NUTRITION_REJECT_OPTIONS,
  MANUFACTURER_KEYS,
  UOM_TYPES_MAPPING,
  NUTRITION_FEEDBACK_KEYS,
  NUTRITION_FEEDBACK_KEY_VALUES,
  PRODUCT_ATTRIBUTE_REJECT_FIELDS
};
