import React from 'react';
import _ from 'lodash';

class ScoreCard extends React.Component {
  render() {
    let {
      cardType,
      score,
      title,
      loading,
      actions,
      activeChart,
      summaryItems,
      suvc,
      graphFilter,
      overallSummary,
      explain
    } = this.props;

    let cardColor = 'red';
    if (score >= 50) {
      cardColor = 'yellow';
    }
    if (score >= 80) {
      cardColor = 'green';
    }
    if (loading) {
      cardColor = 'gray';
    }

    let inactive = '';

    if (activeChart !== '' && activeChart !== cardType) {
      inactive = 'inactive';
    }

    const activateCharts = () => {
      actions.activateCharts({ chartType: activeChart === cardType ? '' : cardType });

      if (cardType === 'overall' && _.isEmpty(overallSummary)) {
        actions.getOverallSummary({ suvc, graphFilter });
      }

      if (summaryItems.length === 0) {
        actions.getSummary({ suvc, bc: null, ig: null, ag: null });
      }

      if (graphFilter) {
        actions.getOverallSummary({ suvc, graphFilter: null });
      }
      actions.changeFilterForGraph({
        filter: {
          brand: null,
          bc: null,
          ig: null,
          ag: null,
          sysco: null
        }
      });

      actions.loadItemList({
        suvc,
        page: 1,
        search: '',
        subSuvc: null,
        graphfilter: null,
        tableFilters: null,
        orderBy: null,
        brandGroupFilters: [],
        tableSearchData: null
      });
    };

    if (!explain) {
      explain = title;
    }

    // let displayStyle = {};
    // if (cardType === 'overall') {
    //   displayStyle = {
    //     visibility: 'hidden'
    //   };
    // }

    return (
      <div
        className={`score-card ${cardColor} ${inactive}`}
        id="attribution-card"
        title={explain}
        onClick={() => {
          activateCharts();
        }}
        // style={displayStyle}
      >
        <div className="card-details">
          <div className="card-title">{title}</div>
          <div className="card-score">{`${score}%`}</div>
        </div>
        <div className="background-graph"></div>
      </div>
    );
  }
}

export default ScoreCard;
