import React from 'react';
import { Table, Select, Icon, Tooltip } from 'antd';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { useSelector, useDispatch } from 'react-redux';

import ColumnSearchBox from './ColumnSearchBox';
import * as actionCreators from '../../actions';
import {
  getSpecificAttributes,
  getHierarchyFilters,
  isDataSourcedFromQaSpecOrGdsn,
  getChildNutritionData,
  getPendingOrExistingCnCheckedValues,
  getAttributeType,
  checkForPendingNutritionValues,
  getSelectedValueMassAttriutionField,
  checkMassAttributionChildNutritionEligibility,
  getGdsnOrSyscoBrandFlag,
  isPicklistOptionDisabledForGdsnAttributes
} from '../../util/Util';
import {
  OSD_VS_GS1_ATTRIBUTE_NAMES_MAPPING,
  CHILD_NUTRITION_ELIGIBILITY,
  ATTRIBUTE_TYPES_SYNCHED_WITH_NUTRITIONS,
  DISABLED_ATTRIBUTION_FIELDS_WITH_NUTRITIONS,
  ENUT_WINNING_SOURCE_VALUE,
  NUTRITION_SYNCHED_TAXONOMY_ATTRIBUTE_NAMES_LIST
} from '../../util/Constants';
import osdNutrition from '../../util/osdNutrition';
import { InfoToolTip } from './AttributeMassEditModal';

const { Option } = Select;

export default function AttributeMassEditTable({ suvc, hasUnsubmittedData }) {
  const dispatch = useDispatch();
  const {
    updateAttrMassEditCheckboxes,
    getAttributeMassEditList,
    updateIndividualAttribute,
    massAttributionNutritionSynchedFieldChangeHandle,
    massAttributeItemNutritionFieldChange
  } = bindActionCreators(actionCreators, dispatch);

  const list = useSelector(state => _.get(state.massUpdate.attribute, 'list', []));
  const attributes = useSelector(state => _.get(state.massUpdate.attribute, 'attributes', []));
  const attributeFilters = useSelector(state => _.get(state.massUpdate.attribute, 'filters.attributeFilters', []));
  const appliedValues = useSelector(state => _.get(state.massUpdate.attribute, 'appliedValues', []));
  const selectedRows = useSelector(state => _.get(state.massUpdate.attribute, 'selectedRows', []));
  const orderBy = useSelector(state => _.get(state.massUpdate.attribute, 'filters.orderBy', null));
  const bc = useSelector(state => _.get(state.massUpdate.attribute, 'filters.bc', null));
  const ig = useSelector(state => _.get(state.massUpdate.attribute, 'filters.ig', null));
  const ag = useSelector(state => _.get(state.massUpdate.attribute, 'filters.ag', null));
  const tableSearchData = useSelector(state => _.get(state.massUpdate.attribute, 'filters.tableSearchData', []));
  const brands = useSelector(state => _.get(state.vendor.details, 'brands', []));
  const tableFilters = useSelector(state => _.get(state.massUpdate.attribute, 'filters.tableFilters', []));

  const windowHeight = window.innerHeight;
  const yScroll = windowHeight - 390;
  const supcSearchTerm = _.find(tableSearchData, obj => obj.param === 'supc');
  const materialDescriptionSearchTerm = _.find(tableSearchData, obj => obj.param === 'materialDescription');
  const manufactProdCodeSearchTerm = _.find(tableSearchData, obj => obj.param === 'manufactProdCode');
  const gtinSearchTerm = _.find(tableSearchData, obj => obj.param === 'gtin');
  const targetBrandName = _.find(tableFilters, obj => obj.param === 'brandName');
  const filteredBrands = _.get(targetBrandName, 'val', []);

  const getFilteredAttributes = () => {
    return getSpecificAttributes(attributes, attributeFilters);
  };

  const getSortOrder = columnName => {
    const field = _.get(orderBy, 'param', null);
    let order = _.get(orderBy, 'val', false);

    if (order === 'asc') {
      order = 'ascend';
    } else if (order === 'desc') {
      order = 'descend';
    }

    if (field === columnName) {
      return order;
    }

    return false;
  };

  const getFormattedBrands = () => {
    brands.sort();
    const formatted = [];

    _.forEach(brands, elem => {
      formatted.push({ text: elem, value: elem });
    });
    return formatted;
  };

  const getGs1AttributeName = attributeId => {
    const target = _.find(OSD_VS_GS1_ATTRIBUTE_NAMES_MAPPING, obj => obj.id === attributeId);
    if (target) return target;
  };

  const handleFilterChanges = (pagination, filters, sorter) => {
    if (!hasUnsubmittedData()) {
      const field = _.get(sorter, 'field', null);
      let order = _.get(sorter, 'order', false);
      const tFilters = [];

      if (order === 'ascend') {
        order = 'asc';
      } else if (order === 'descend') {
        order = 'desc';
      }

      _.forIn(filters, (value, key) => {
        if (!_.isEmpty(value)) {
          tFilters.push({ param: key, val: value });
        }
      });

      const newOrder = field ? { param: field, val: order } : null;
      const graphFilter = getHierarchyFilters({ bc, ig, ag });

      getAttributeMassEditList({ suvc, orderBy: newOrder, graphFilter, tableSearchData, tableFilters: tFilters });
    }
  };

  const getSpecificItemDetailsFromList = supc => list.find(item => item.supc === supc);

  const isGdsnColumn = attributeId => {
    const gdsnCoulmns = _.map(OSD_VS_GS1_ATTRIBUTE_NAMES_MAPPING, obj => obj.id);
    return _.includes(gdsnCoulmns, attributeId);
  };

  const clearAllFilters = () => {
    const graphFilter = getHierarchyFilters({ bc, ig, ag });
    getAttributeMassEditList({
      suvc,
      orderBy: null,
      graphFilter,
      tableSearchData: [],
      tableFilters: []
    });
  };

  const isGdsnOrSyscoBrandedItemLevelIndicator = (gdsn, syscoBrand) => gdsn === 'Y' || syscoBrand === 'Y';

  // const clearValidationErrorsForK12Information = () => {
  //   const { actions, nutritionErrors } = this.props;
  //   const updatedNutritionErrors = { ...nutritionErrors };
  //   CHILD_NUTRITIONS_OR_PFS.map(({ code }) => {
  //     if (nutritionErrors[code]) {
  //       delete updatedNutritionErrors[code];
  //     }
  //     return null;
  //   });
  //   actions.updateNutritionFieldErrors({ updatedErrors: updatedNutritionErrors });
  // };

  const handleChildNutritionEligibility = (supc, name, picklist, changedId, attrType) => {
    const {
      pendingNutrition: {
        changedFields,
        isChildNutrition: existingIsChildNutrition,
        sleHasProductFormulationStmt: existingSleHasProductFormulationStmt,
        nutritionSourceOid: sourceOid
      } = { changedFields: [] },
      nutritions: { nutritionSourceOid, child_or_product_formulation: childNutritionData } = {},
      pendingNutritions,
      gdsn,
      syscoBrand
    } = getSpecificItemDetailsFromList(supc);
    const isNutritionsSourcedFromQaOrGdsn =
      isDataSourcedFromQaSpecOrGdsn(nutritionSourceOid) || isGdsnOrSyscoBrandedItemLevelIndicator(gdsn, syscoBrand);
    if (name === CHILD_NUTRITION_ELIGIBILITY) {
      const { isChildNutrition, sleHasProductFormulationStmt, hasSelectionRemoved } = getChildNutritionData(
        picklist,
        changedId
      );
      let dataObject = {
        ...getPendingOrExistingCnCheckedValues({
          attrType,
          existingIsChildNutrition,
          existingSleHasProductFormulationStmt,
          isChildNutrition,
          sleHasProductFormulationStmt,
          pendingNutritions,
          supc
        })
      };
      if (hasSelectionRemoved && attrType) {
        dataObject = {
          ...dataObject,
          ...childNutritionData
        };
      }
      if (!isNutritionsSourcedFromQaOrGdsn) {
        dataObject = {
          ...dataObject,
          changedFields
        };
        // if (!(isChildNutrition || sleHasProductFormulationStmt)) {
        //   clearValidationErrorsForK12Information();
        // }
        if (
          (isChildNutrition || sleHasProductFormulationStmt) &&
          (isChildNutrition !== existingIsChildNutrition ||
            sleHasProductFormulationStmt !== existingSleHasProductFormulationStmt)
        ) {
          dataObject = {
            ...dataObject,
            changedFields: [...new Set([...changedFields, 'isChildNutrition', 'sleHasProductFormulationStmt'])]
          };
          massAttributionNutritionSynchedFieldChangeHandle({ hasNutritionSynchedTaxonomyAttributeChanges: true, supc });
        }
      }
      massAttributeItemNutritionFieldChange(dataObject);
    } else if (
      attrType &&
      ATTRIBUTE_TYPES_SYNCHED_WITH_NUTRITIONS.includes(attrType) &&
      NUTRITION_SYNCHED_TAXONOMY_ATTRIBUTE_NAMES_LIST.includes(name) &&
      // name !== 'Natural Claim' &&
      !isNutritionsSourcedFromQaOrGdsn &&
      ((sourceOid === null && pendingNutritions.length) || Number(sourceOid) === 21)
    ) {
      massAttributionNutritionSynchedFieldChangeHandle({ hasNutritionSynchedTaxonomyAttributeChanges: true, supc });
    }
  };

  const onChangeIndividualAttribute = (supc, attributeId, value) => {
    updateIndividualAttribute({ supc, attributeId, value });
  };

  const getPendingNutritionClassOrTitle = (pendingNutrition, type, attributeName, isCnFieldDisabled, name) => {
    if (type === 'class' && pendingNutrition) return 'un-approved';
    if (type === 'title' && pendingNutrition) return `${attributeName} - Pending changed to be approved`;
    if (type === 'title' && isCnFieldDisabled) return `Use single item view to make changes for ${name}`;
    if (type === 'class') return attributeName;
    return '';
  };

  const renderDropdownColumn = (record, attribute) => {
    const { id: attributeId, picklist, name, type } = attribute;
    const { nutritions: { nutritionSourceOid } = {}, ...itemFields } = record;
    const target = _.find(record.attributes, obj => obj.attrId === attributeId);
    const valueId = _.get(target, 'value', null);

    const targetItem = _.find(appliedValues, item => item.supc === record.supc);
    const targetAttributes = _.get(targetItem, 'attributes', []);
    const targetAttribute = _.find(targetAttributes, obj => obj.id === attributeId);
    const newValueId = _.get(targetAttribute, 'value', null);
    const currentAttribute = _.find(itemFields.taxonomy.attributes, { attrId: Number(attributeId) }, {});

    const { gs1: gs1Name, osd: osdName } = getGs1AttributeName(attributeId) || {};
    const subText = gs1Name ? `in ${gs1Name} ` : '';
    const attrType = getAttributeType(type);
    const { pendingNutritions } = getSpecificItemDetailsFromList(record.supc);

    const { pendingNutrition } = checkForPendingNutritionValues(attributeId, pendingNutritions, targetAttributes);
    const selectedValue = getSelectedValueMassAttriutionField(attributeId, pendingNutritions, newValueId, valueId);
    const isCnFieldDisabled = checkMassAttributionChildNutritionEligibility(record, name);
    const disabledMainKey = getGdsnOrSyscoBrandFlag(nutritionSourceOid, itemFields);

    const isFieldDisabledForSameValue = DISABLED_ATTRIBUTION_FIELDS_WITH_NUTRITIONS[disabledMainKey]?.[
      'disabled_for_same_values'
    ].includes(attribute.name);

    const getDisabledOptionsDetailsForGdsnAttributes = () => {
      if (
        isFieldDisabledForSameValue &&
        DISABLED_ATTRIBUTION_FIELDS_WITH_NUTRITIONS[disabledMainKey]?.['enabled_same_fields']?.[attribute.name]
      ) {
        return DISABLED_ATTRIBUTION_FIELDS_WITH_NUTRITIONS[disabledMainKey]?.['enabled_same_fields']?.[attribute.name];
      }
      return {};
    };

    const disabledFields =
      DISABLED_ATTRIBUTION_FIELDS_WITH_NUTRITIONS[disabledMainKey]?.[currentAttribute?.winningSource]?.[name] ?? {};
    const isFieldDisabled = Object.keys(disabledFields).length > 0;

    const isFieldHavingSameValuesInEnutAndAttribution = osdNutrition.getNutritionRelatedOsdValue(
      attribute.name,
      record.nutritions,
      itemFields?.taxonomy?.attributes
    );
    const areEnutAndAttributionValuesAreSameForGdsnItem =
      isFieldDisabledForSameValue && isFieldHavingSameValuesInEnutAndAttribution;

    const enabledSameFieldDetails = getDisabledOptionsDetailsForGdsnAttributes();
    const isPositiveOptionsDisabled = Object.keys(enabledSameFieldDetails).length;

    const getTooltipValue = () => {
      if (isFieldDisabled) return disabledFields.tooltip;
      if (isPositiveOptionsDisabled && !areEnutAndAttributionValuesAreSameForGdsnItem)
        return enabledSameFieldDetails.tooltip;
      if (areEnutAndAttributionValuesAreSameForGdsnItem && isFieldDisabledForSameValue)
        return DISABLED_ATTRIBUTION_FIELDS_WITH_NUTRITIONS[disabledMainKey]?.[ENUT_WINNING_SOURCE_VALUE]?.[
          attribute?.name
        ].tooltip;
      if (
        DISABLED_ATTRIBUTION_FIELDS_WITH_NUTRITIONS[disabledMainKey]?.['other_available_tooltips']?.[attribute?.name]
      ) {
        return DISABLED_ATTRIBUTION_FIELDS_WITH_NUTRITIONS[disabledMainKey]?.['other_available_tooltips']?.[
          attribute?.name
        ].tooltip;
      }
    };

    const dropdown = (
      <Select
        className={`attr-col-select ${newValueId ? 'new-value' : ''} ${getPendingNutritionClassOrTitle(
          pendingNutrition,
          'class'
        )}`}
        value={selectedValue}
        disabled={isFieldDisabled || areEnutAndAttributionValuesAreSameForGdsnItem}
        dropdownMatchSelectWidth={false}
        onChange={value => {
          onChangeIndividualAttribute(record.supc, attributeId, value);
          if (!name.includes('Child Nutrition'))
            handleChildNutritionEligibility(record.supc, osdName, picklist, value, attrType);
        }}
      >
        <Option
          value={null}
          key="0"
          disabled={isPicklistOptionDisabledForGdsnAttributes(isPositiveOptionsDisabled, enabledSameFieldDetails, null)}
        >
          Please select
        </Option>
        {_.map(picklist, pick => {
          return (
            <Option
              value={pick.id}
              key={pick.id}
              disabled={isPicklistOptionDisabledForGdsnAttributes(
                isPositiveOptionsDisabled,
                enabledSameFieldDetails,
                pick.value
              )}
              title={getPendingNutritionClassOrTitle(pendingNutrition, 'title', pick.value, isCnFieldDisabled, name)}
            >
              {pick.value}
            </Option>
          );
        })}
      </Select>
    );

    if (
      isFieldDisabled ||
      (isPositiveOptionsDisabled && !areEnutAndAttributionValuesAreSameForGdsnItem) ||
      (areEnutAndAttributionValuesAreSameForGdsnItem && isFieldDisabledForSameValue) ||
      DISABLED_ATTRIBUTION_FIELDS_WITH_NUTRITIONS[disabledMainKey]?.['other_available_tooltips']?.[attribute?.name]
    ) {
      return (
        <Tooltip
          overlayClassName="mass-attr-dropdown-tooltip"
          trigger={'hover'}
          placement="left"
          title={<div>{getTooltipValue()}</div>}
        >
          {dropdown}
        </Tooltip>
      );
    }
    return dropdown;
  };

  const renderTitle = obj => {
    return (
      <div className="attr-mass-edit-table-header">
        <div className="attr-mass-edit-table-header-txt" title={obj.name}>
          {obj.name}
        </div>
        <InfoToolTip type={obj.type} name={obj.name} />
      </div>
    );
  };

  const rowSelection = {
    onChange: (keys, records) => {
      updateAttrMassEditCheckboxes(keys);
    },
    selectedRowKeys: selectedRows
  };

  const columns = [
    {
      title: 'SUPC',
      dataIndex: 'supc',
      key: 'supc',
      width: 100,
      fixed: true,
      sorter: true,
      sortOrder: getSortOrder('supc'),
      filterDropdown: () => <ColumnSearchBox suvc={suvc} columnName={'supc'} hasUnsubmittedData={hasUnsubmittedData} />,
      filterIcon: <Icon type="search" style={{ color: !_.isEmpty(supcSearchTerm) ? '#108ee9' : '#aaa' }} />
    },
    {
      title: 'GTIN',
      dataIndex: 'gtin',
      key: 'gtin',
      width: 140,
      fixed: true,
      sorter: true,
      sortOrder: getSortOrder('gtin'),
      filterDropdown: () => <ColumnSearchBox suvc={suvc} columnName={'gtin'} hasUnsubmittedData={hasUnsubmittedData} />,
      filterIcon: <Icon type="search" style={{ color: !_.isEmpty(gtinSearchTerm) ? '#108ee9' : '#aaa' }} />
    },
    {
      title: 'MPC',
      dataIndex: 'manufactProdCode',
      key: 'manufactProdCode',
      width: 140,
      fixed: true,
      sorter: true,
      sortOrder: getSortOrder('manufactProdCode'),
      filterDropdown: () => (
        <ColumnSearchBox suvc={suvc} columnName={'manufactProdCode'} hasUnsubmittedData={hasUnsubmittedData} />
      ),
      filterIcon: <Icon type="search" style={{ color: !_.isEmpty(manufactProdCodeSearchTerm) ? '#108ee9' : '#aaa' }} />
    },
    {
      title: 'Brand Name',
      dataIndex: 'brandName',
      key: 'brandName',
      width: 150,
      fixed: true,
      sorter: true,
      sortOrder: getSortOrder('brandName'),
      filters: getFormattedBrands(),
      filteredValue: filteredBrands
    },
    {
      title: 'Material Description',
      className: 'mat-desc-col',
      dataIndex: 'materialDescription',
      key: 'materialDescription',
      render: (text, record) => (
        <div title={text} className="mat-desc-col-text">
          {text}
        </div>
      ),
      width: 230,
      fixed: true,
      sorter: true,
      sortOrder: getSortOrder('materialDescription'),
      filterDropdown: () => (
        <ColumnSearchBox suvc={suvc} columnName={'materialDescription'} hasUnsubmittedData={hasUnsubmittedData} />
      ),
      filterIcon: (
        <Icon
          type="search"
          style={{
            color: !_.isEmpty(materialDescriptionSearchTerm) ? '#108ee9' : '#aaa'
          }}
        />
      )
    }
  ];

  const filteredAttributes = getFilteredAttributes();

  _.forEach(filteredAttributes, obj => {
    columns.push({
      title: renderTitle(obj),
      key: obj.id,
      width: 280,
      render: (text, record) => renderDropdownColumn(record, obj)
    });
  });

  return (
    <div className="attr-mass-edit-table-wrapper">
      <div className="clear-all-filters" onClick={clearAllFilters}>
        Clear Table Filters
      </div>
      <Table
        id="attr-mass-edit-table"
        rowKey={'supc'}
        // bordered
        columns={columns}
        dataSource={list}
        pagination={false}
        scroll={{ x: 400, y: yScroll }}
        rowSelection={rowSelection}
        onChange={handleFilterChanges}
      />
    </div>
  );
}
