const REQUEST = 'REQUEST';
const MERGE = 'MERGE';
const SUCCESS = 'SUCCESS';
const FAILURE = 'FAILURE';
const TOOLTIP_REQUEST = 'TOOLTIP_REQUEST';
const TOOLTIP_FAILURE = 'TOOLTIP_FAILURE';
const TOOLTIP_SUCCESS = 'TOOLTIP_SUCCESS';
const COMMENTS_REQUEST = 'COMMENTS_REQUEST';
const COMMENTS_FAILURE = 'COMMENTS_FAILURE';
const COMMENTS_SUCCESS = 'COMMENTS_SUCCESS';
const ADD_FEEDBACK_REQUEST = 'ADD_FEEDBACK_REQUEST';

export function createRequestTypes(base) {
  return [REQUEST, SUCCESS, FAILURE].reduce((acc, type) => {
    acc[type] = `ITEM_MANAGEMENT_${base}_${type}`;
    return acc;
  }, {});
}

export function createCommentsRequestTypes(base) {
  return [REQUEST, SUCCESS, FAILURE, MERGE].reduce((acc, type) => {
    acc[type] = `ITEM_MANAGEMENT_${base}_${type}`;
    return acc;
  }, {});
}
export function createImageRequestTypes(base) {
  return [REQUEST, SUCCESS, FAILURE, COMMENTS_REQUEST, COMMENTS_FAILURE, COMMENTS_SUCCESS].reduce((acc, type) => {
    acc[type] = `ITEM_MANAGEMENT_${base}_${type}`;
    return acc;
  }, {});
}

export function createFeedbackRequestTypes(base) {
  return [REQUEST, SUCCESS, FAILURE, ADD_FEEDBACK_REQUEST].reduce((acc, type) => {
    acc[type] = `ITEM_MANAGEMENT_${base}_${type}`;
    return acc;
  }, {});
}

export function createFnBRequestTypes(base) {
  return [
    REQUEST,
    SUCCESS,
    FAILURE,
    TOOLTIP_REQUEST,
    TOOLTIP_SUCCESS,
    TOOLTIP_FAILURE,
    COMMENTS_REQUEST,
    COMMENTS_FAILURE,
    COMMENTS_SUCCESS
  ].reduce((acc, type) => {
    acc[type] = `ITEM_MANAGEMENT_${base}_${type}`;
    return acc;
  }, {});
}

export function action(type, payload = {}) {
  return { type, ...payload };
}

export const createReducer = (TypeFunc, initialState) => (state = initialState, _action) => {
  switch (_action.type) {
    case TypeFunc.REQUEST:
      return { ...state, fetching: true, error: null };
    case TypeFunc.SUCCESS:
      return { ...state, fetching: false, data: _action.payload };
    case TypeFunc.FAILURE:
      return { ...state, fetching: false, data: [], error: _action.error };
    default:
      return state;
  }
};

export const createReducerShort = (initialState, handlers) =>
  function reducer(state = initialState, _action) {
    if (Object.prototype.hasOwnProperty.call(handlers, _action.type)) {
      return handlers[_action.type](state, _action);
    }
    return state;
  };
