/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import { useSelector } from 'react-redux';
import * as _ from 'lodash';
import { Table, Progress } from 'antd';
import ReactResizeDetector from 'react-resize-detector';
import { PlusCircleFilled } from '@ant-design/icons';
import { GDSN_VALUE } from 'util/Constants';
import ExpandedContent from './ExpandedContent/index';
import { getValueBasedOnTheCondition } from '../../util/Util';

const getDataSource = data => {
  const dataSource = [];
  if (data) {
    Object.keys(data).forEach(key => {
      if (!_.isEmpty(data[key]) && !_.isEmpty(data[key].basicData)) {
        const {
          basicData: { supc, brandName, manufactProdCode, trueVendorName, materialDescription, gdsn, gtin },
          images: { images },
          comments,
          taxonomy,
          isValidating,
          ...rest
        } = data[key];
        dataSource.push({
          supc,
          brandName,
          manufactProdCode,
          trueVendorName,
          materialDescription,
          gdsn,
          gtin,
          images,
          taxonomy,
          comments,
          isValidating,
          ...rest
        });
      }
    });
  }
  return dataSource;
};

const MassUploadDataTable = React.memo(function ChildComponent({ percentage, isLoadingContent, supcs }) {
  const massUploadImageData = useSelector(state => _.get(state.massUpdate, 'image', false));
  const dataSource = getDataSource(massUploadImageData);
  const columns = [
    {
      title: 'SUPC',
      width: 100,
      dataIndex: 'supc',
      key: 'supc'
    },
    {
      title: 'Description',
      width: 250,
      dataIndex: 'materialDescription',
      key: 'materialDescription'
    },
    {
      title: 'Brand',
      width: 200,
      dataIndex: 'brandName',
      key: 'brandName'
    },
    {
      title: 'Supplier Account',
      width: 250,
      dataIndex: 'trueVendorName',
      key: 'trueVendorName'
    },
    {
      title: 'MPC',
      width: 180,
      dataIndex: 'manufactProdCode',
      key: 'manufactProdCode'
    },
    {
      title: 'GDSN',
      width: 70,
      dataIndex: 'gdsn',
      key: 'gdsn',
      render: gdsn => <span>{getValueBasedOnTheCondition(gdsn === GDSN_VALUE, '✓', '')}</span>,
      align: 'center'
    },
    {
      title: 'GTIN',
      width: 120,
      dataIndex: 'gtin',
      key: 'gtin'
    },
    {
      title: 'Product Images',
      width: 150,
      key: 'productImages'
    }
  ];

  const tableLoading = {
    spinning: isLoadingContent,
    indicator: <Progress type="circle" percent={percentage} status="active" width={60} />
  };

  return (
    <>
      <ReactResizeDetector
        handleWidth
        handleHeight
        render={({ width, height }) => (
          <div className="mass-image-upload-table-container">
            <Table
              className="image-upload-mass-table"
              size="small"
              rowKey="supc"
              dataSource={dataSource}
              columns={columns}
              pagination={false}
              expandRowByClick
              expandedRowRender={record => <ExpandedContent record={record} />}
              loading={tableLoading}
            />
          </div>
        )}
      />
    </>
  );
});

export default MassUploadDataTable;
