import React from 'react';
import { Chart } from 'react-google-charts';

class FnbOverallDonutChart extends React.Component {
  calculate() {
    let { summaryItems } = this.props;

    let completed = 0;
    let incomplete = 0;
    summaryItems.forEach(item => {
      if (item.prm) {
        if (item.prm.f === '1') {
          completed++;
        } else {
          incomplete++;
        }
      }
    });

    return {
      complete: completed,
      incomplete: incomplete
    };
  }

  render() {
    let { summaryItems, filterGraph, paramTypes } = this.props;

    let calculatedValues = this.calculate();

    let completeItems = calculatedValues.complete;
    let incompleteItems = calculatedValues.incomplete;
    let percentageCompletion = 0;
    if (completeItems > 0) {
      percentageCompletion = parseInt((completeItems / (completeItems + incompleteItems)) * 100);
    }

    if (summaryItems.length === 0) {
      return <div></div>;
    }

    return (
      <div className="overall-donut-chart-wrapper" style={{ width: '165px' }}>
        <div className={'donut-title'}>LOGICALLY ACTIVE ITEMS</div>
        <div className="percentage-completion">{percentageCompletion + '%'}</div>
        <div className={'overall-donut-chart'}>
          <Chart
            chartType="PieChart"
            data={[
              ['Task', 'Number of Items'],
              ['Complete', completeItems],
              ['In-Complete', incompleteItems]
            ]}
            width="270px"
            height="270px"
            legendToggle
            options={{
              title: '',
              pieHole: 0.62,
              colors: ['#92bb88', '#e9705f'],
              legend: 'none',
              pieSliceText: 'none',
              backgroundColor: '#f9f6f8',
              tooltip: {
                trigger: 'none'
              }
            }}
            chartEvents={[
              {
                eventName: 'select',
                callback({ chartWrapper }) {
                  const { row } = chartWrapper.getChart().getSelection()[0],
                    complete = row === 0 ? '1' : '0';
                  filterGraph([{ param: 'comp_fnb', val: complete }], [...paramTypes, ...['comp_fnb']]);
                }
              }
            ]}
          />
        </div>
        <div className="overall-legend">
          <div className="overall-legend-values incomplete">
            <span>{incompleteItems}</span>
            <span>IN-COMPLETE</span>
          </div>
          <div className="overall-legend-values complete">
            <span>{completeItems}</span>
            <span>COMPLETE</span>
          </div>
        </div>
      </div>
    );
  }
}

export default FnbOverallDonutChart;
