import React from 'react';
import { Button, Modal, Table } from 'antd';
import { bindActionCreators } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

import * as actionCreators from '../../actions';
import { CORE_DATA_FIELDS } from '../../util/CoreDataConstants';

export default function CoreReviewConfirmationModal({ onConfirm }) {
  const dispatch = useDispatch();
  const { toggleCoreModal } = bindActionCreators(actionCreators, dispatch);

  const data = useSelector(state => _.get(state.comments, 'coreModal.data', {}));
  const isVisible = useSelector(state => _.get(state.comments, 'coreModal.isVisible', false));
  const isSaving = useSelector(state => _.get(state.comments, 'coreModal.isSaving', false));

  const closeModal = () => {
    toggleCoreModal({ isVisible: false, data: {} });
  };

  const renderBody = () => {
    const comment = JSON.parse(_.get(data, 'comment', '{}'));
    let tableData = [];

    _.forEach(comment, (record, key) => {
      const obj = {
        attribute: key,
        existingValue: record.existingValue,
        value: record.value
      };

      if (record.isApproved) tableData.push(obj);
    });

    const columns = [
      {
        title: 'Attribute',
        dataIndex: 'attribute',
        key: 'attribute',
        render: text => {
          const label = _.get(CORE_DATA_FIELDS[text], 'caption', '');
          return <div>{label}</div>;
        }
      },
      {
        title: 'Current value',
        dataIndex: 'existingValue',
        key: 'existingValue'
      },
      {
        title: 'New value',
        dataIndex: 'value',
        key: 'value'
      }
    ];

    if (!_.isEmpty(tableData)) {
      return (
        <React.Fragment>
          <div className="core-review-conf-msg">Following data will be sent to STEP.</div>
          <Table
            id="core-review-conf-table"
            rowKey={'attribute'}
            columns={columns}
            dataSource={tableData}
            pagination={false}
          />
        </React.Fragment>
      );
    } else {
      return <div>No approved data to be sent to STEP.</div>;
    }
  };

  return (
    <Modal
      className="core-review-conf-modal"
      title="Please confirm"
      visible={isVisible}
      maskClosable={false}
      closable={!isSaving}
      onCancel={closeModal}
      footer={[
        <Button key="cancel" onClick={closeModal} disabled={isSaving}>
          Cancel
        </Button>,
        <Button key="confirm" type="primary" loading={isSaving} onClick={() => onConfirm(data)}>
          Confirm
        </Button>
      ]}
    >
      {renderBody()}
    </Modal>
  );
}
