import _ from 'lodash';
import React from 'react';
import PendingComment from './PendingComment';
import { Select, Input } from 'antd';
import {
  getClassNameFromConditions,
  getFeedbackSelectedValue,
  getFieldValidationForFeedbackAttributes,
  getIfProprietarySelectionIsDisabledForSyscoBrandItems,
  fetchItemStatus,
  getOverallSelectionIsDisabledForSixtyDays
} from '../util/Util';

class FeedbackAttribute extends React.Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.state = {
      selectedState: false,
      selectedValue: null
    };
    this.toggleSelected = this.toggleSelected.bind(this);
  }

  toggleSelected(selected) {
    this.setState({
      selectedState: selected
    });
  }

  componentDidMount() {
    let { attribute } = this.props;
    this.toggleSelected(attribute.selected != null);
  }

  handleCancel = () => {
    this.toggleSelected(false);
    this.setState({ selectedValue: null });
  };

  getInputValue = (item, attribute) => {
    const { feedback } = item;
    if (feedback && feedback.changers && feedback.changers.length) {
      const selectedChange = _.find(feedback.changers, change => {
        return change.name === attribute.name;
      });
      if (selectedChange) return selectedChange.selected;
    }
    return null;
  };

  getOptionDisabledStatus = (
    { id, value },
    pendingOverallFeedbackHistory,
    pendingComments,
    feeExclusions,
    { basicData }
  ) => {
    return (
      getIfProprietarySelectionIsDisabledForSyscoBrandItems(basicData, id) ||
      getOverallSelectionIsDisabledForSixtyDays(pendingComments, pendingOverallFeedbackHistory, feeExclusions, value)
    );
  };

  render() {
    let { item, attribute, actions, pendingOverallFeedbackHistory, pendingComments, feeExclusions } = this.props;
    let { Option } = Select;

    let commentText = '';

    let onFeedbackAttrChange = (name, type, title, parent) => {
      return e => {
        let attrChange = {
          name: '',
          type: '',
          selected: ''
        };

        switch (type) {
          case 'Select': {
            attrChange = {
              name: name.toString(),
              type,
              title,
              selected: getFeedbackSelectedValue(e, '[feedback]')
            };
            break;
          }
          case 'Input': {
            attrChange = {
              name: name.toString(),
              type,
              selected: e.target.value,
              title,
              parent
            };
            break;
          }
          default:
            break;
        }
        this.toggleSelected(attrChange.selected != null);
        actions.addFeedbackChange({ attrChange });
      };
    };

    return (
      <div
        key={'f' + attribute.name}
        className={`attr-wrapper attr-wrapper-feedback ${
          getFieldValidationForFeedbackAttributes(attribute.name, item) ? '' : 'error'
        }`}
      >
        {attribute.picklist && (
          <div>
            <Select
              defaultValue={attribute.selected}
              id={'' + attribute.name}
              name={'attr_' + attribute.name}
              className={
                'attr-feedback' +
                getClassNameFromConditions(commentText !== '', ' pending-approval') +
                getClassNameFromConditions(this.state && !this.state.selectedState, ' with-placeholder')
              }
              dropdownMatchSelectWidth={false}
              onChange={onFeedbackAttrChange(attribute.name, 'Select', attribute.title)}
              ref={this.inputRef}
              value={this.getInputValue(item, attribute)}
              onSelect={value => this.setState({ selectedValue: value })}
            >
              <Option key={0} value={null}>
                Please select...
              </Option>
              {attribute.picklist.map((pick, index) => (
                <Option
                  key={parseInt(pick.id)}
                  value={pick.value}
                  disabled={this.getOptionDisabledStatus(
                    pick,
                    pendingOverallFeedbackHistory,
                    pendingComments,
                    feeExclusions,
                    item
                  )}
                >
                  {pick.value}
                </Option>
              ))}
            </Select>
            <div className="data-error "> {attribute.validationMsg} </div>
            {commentText !== '' && <PendingComment comment={commentText} />}
          </div>
        )}
        {!attribute.picklist && (
          <div>
            <div className="attr-label">{attribute.name}</div>
            <Input
              defaultValue={attribute.selected}
              id={'' + attribute.name}
              name={'attr_' + attribute.name}
              className={
                'attr-feedback' +
                getClassNameFromConditions(commentText !== '', 'pending-approval') +
                getClassNameFromConditions(this.state && !this.state.selectedState, ' with-placeholder')
              }
              onChange={onFeedbackAttrChange(attribute.name, 'Input', attribute.title, attribute.parent)}
              value={this.getInputValue(item, attribute)}
              ref={this.inputRef}
            />
            <div className="data-error "> {attribute.validationMsg} </div>
          </div>
        )}
      </div>
    );
  }
}

export default FeedbackAttribute;
