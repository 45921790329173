import { action } from 'reduxHelpers';
import {
  USER,
  ITEMS,
  ITEMMASTER,
  ITEMTAXO,
  ITEMUNLOAD,
  ITEMIMAGE,
  IMAGEDELETE,
  ITEMFNB,
  ATTRCHANGE,
  TAXOSAVE,
  MSTRCOMMENT,
  SENDCOMMENTS,
  COMMENTS,
  UPDATECOMMENT,
  FIELDCOMMENT,
  SENDCOMMENT,
  SCORES,
  CHARTS,
  SUMMARY,
  FILTERFORGRAPH,
  IMAGESTYLE,
  FEEDBACK,
  IMAGEFEED,
  SUPCBASIC,
  VENDORS,
  SUPPLIERS,
  EDITATTRIBUTE,
  UPDATEFNBCOMMENT,
  RESETTAB,
  TABCHANGED,
  REJECTEDSUPCS,
  OVERALLSUMMARY,
  NUTRITIONS,
  UPDATENUTRITIONS,
  AUTOSAVE,
  UPDATEIMAGECOMMENT,
  UPDATEIMAGESTYLE,
  COMPLEXGRAPHRENDERING,
  FEEEXCLUSIONSBYSUPC,
  FEEEXCLUSIONSCOUNT,
  UPDATENUTRITIONDATA,
  NUTRITIONQUESTIONS,
  NUTRITIONAPPLICABLESTATUS,
  UPDATENUTRITIONAPPLICABLESTATUS,
  PENDINGNUTRITIONS,
  CANCELNUTRITIONUPDATE,
  ONNUTRITIONREJECTFIELDSCHANGED,
  BLOCKTAXONMYNUTRITIONUPDATE,
  TAXOQUITESAVE,
  SETSYNCEDNUTRITIONS,
  FNBMASSEDITINITIALDATA,
  FNBMASSEDIT,
  TOGGLEFNBMASSEDITMODAL,
  VENDORSSILENT,
  GDSNFNBCOMMENTS,
  GDSNFNB,
  COREDATA,
  COREDATACHANGED,
  COREDATACOMMENT,
  COREDATACOMMENTCHANGED,
  SENDCOREDATACOMMENT,
  REJECTALLCOREDATAATTRIBUTES,
  GETITEMDETAILS,
  RESETCURRENTCOREDATACHANGES,
  PGMITEMS,
  UPDATEVISIBILITY,
  CHANGEVISIBILITY,
  FORCEDVISIBLESUPCS,
  NUTRITIONUPDATE,
  UPDATENUTRITIONFIELDERRORS,
  UPDATEATTRIBCOMMENT,
  UPDATETAXONOMYCHANGESWHOLE,
  UPDATENUTRITIONTAXONOMYSYNCHEDCHANGES,
  SELFASSIGNCOMMENT,
  COREATTRIBUTEHISTORY,
  TOGGLECOREMODAL,
  MASSUPDATETYPECHANGED,
  TOGGLEATTRIBUTEMASSEDITMODAL,
  ALLBUSINESSCENTERS,
  ATTRIBUTEMASSEDITFILTERS,
  ATTRIBUTEMASSEDITLIST,
  ATTRIBUTESLIST,
  SELECTATTRIBUTESELECTORVALUE,
  UPDATEATTRIBUTEFILTERS,
  CLEARATTRIBUTESELECTOR,
  APPLYATTRIBUTESELECTIONS,
  RETRIEVEMASSIMAGEUPLOADDATA,
  CLOSEMASSIMAGEUPLOADOVERLAY,
  CHANGEMASSIMAGELOADINGPERCENTAGE,
  UPDATEMASSIMAGEUPLOADFIELDS,
  REVERTMASSIMAGEUPLOADSUPCFIELD,
  MASSIMAGEUPLOADSUBMIT,
  CLEAREACHITEMCHANGESINMASSIMAGES,
  VALIDATEMASSUPLOADIMAGES,
  ATTRMASSEDITCHECKBOX,
  MASSUPDATEATTRIBUTES,
  MASSATTRIBUTIONUPDATEALLITEMLIST,
  ATTRIBUTEMASSUNDO,
  UPDATEINDIVIDUALATTRIBUTE,
  MASSATTRIBUTEITEMTAXONOMYSYNCHEDFIELDCHANGED,
  MASSATTRIBUTEITEMTNUTIRTIONFIELDCHANGED,
  MASSATTRIBUTIONTAXONOMYFETCHEDAFTEREACHTAXONOMYWITHNUTRITIONALCOMMENT,
  MDMITEMMASTER,
  UNSAVEDPREFERREDIMGS,
  SUBMITPREFERREDIMAGES,
  PREFERREDIMAGECOMMENT,
  ASSOCIATEPREFERREDIMAGE,
  PREFERREDIMAGEREVIEWSTATUS,
  APPROVEPREFERREDIMAGE,
  REJECTPREFERREDIMAGE,
  FETCHOVERALLITEMFEEDBACKHISTORY,
  UPDATEOVERALLFEEDBACK,
  SUBMITOVERALLFEDBACK,
  COODATACHANGED,
  COOACTIVITYADD,
  COOACTIVITYDEL,
  COOCOUNTRYADD,
  COOCOUNTRYDEL,
  COODATAPUSHED,
  ITEMCOO,
  PFAS,
  PFASSELECTIONCHANGED,
  PFASCLEARSELECTIONS,
  PFASMASSNOTAPPLICABLE,
  PFASUPDATE,
  TOGGLEPFASMASSEDIT,
  PFASMASSEDITFILTERS,
  ALLBUSINESSCENTERSPFAS,
  PFASMASSEDITLIST,
  PFASMASSEDITCHECKBOX,
  PFASMASSEDITITEMATTRIBUTESELECTION,
  PFASMASSEDITWHOLESINGLEATTRIBUTESELECTION,
  PFASMASSEDITWHOLESINGLEATTRIBUTESELECTIONCLEAR,
  REGULATORYCOMPLIANCESELECTIONCHANGED,
  REGULATORYCOMPLIANCEAPPLYVALUE,
  CLEARMASSPFASAPPLIEDVALUES,
  APPLYPFASMASSFILTEREDVALUES,
  UNDOMASSPFASCHANGES,
  SUBMITMASSPFASCHANGES,
  FSMACHANGES,
  REGULATORYSUBTABCHANGE,
  SAVEFSMACHANGES
} from './actionTypes';

const loadUserDetails = userId => action(USER.REQUEST, { userId });
const loadItemList = details => action(ITEMS.REQUEST, details);
const loadSupc = info => action(SUPCBASIC.REQUEST, info);
const itemDetails = itemdata => action(ITEMMASTER.REQUEST, itemdata);
const unLoadItemDetails = () => action(ITEMUNLOAD.REQUEST, {});
const loadTaxonomy = itemdata => action(ITEMTAXO.REQUEST, itemdata);
const loadImages = itemdata => action(ITEMIMAGE.REQUEST, itemdata);
const loadFnb = itemdata => action(ITEMFNB.REQUEST, itemdata);
const addAttrChange = change => action(ATTRCHANGE.REQUEST, change);
const addFeedbackChange = change => action(FEEDBACK.ADD_FEEDBACK_REQUEST, change);
const sendFeedbackComment = comment => action(FEEDBACK.REQUEST, comment);
const saveTaxonomy = itemdata => action(TAXOSAVE.REQUEST, itemdata);
const saveTaxonomyQuite = itemdata => action(TAXOQUITESAVE.REQUEST, itemdata);
const collectComment = comment => action(MSTRCOMMENT.REQUEST, comment);
const sendComments = itemdata => action(SENDCOMMENTS.REQUEST, itemdata);
const loadDashComments = searchData => action(COMMENTS.REQUEST, searchData);
const updateDashComments = commentId => action(UPDATECOMMENT.REQUEST, { commentId });
const updateAttribUniComments = (commentId, comment) => action(UPDATEATTRIBCOMMENT.REQUEST, { commentId, comment });
const commentsPopup = fieldData => action(FIELDCOMMENT.REQUEST, fieldData);
const closeCommentsPopup = () => action(FIELDCOMMENT.SUCCESS, {});
const sendFieldComment = comment => action(SENDCOMMENT.REQUEST, comment);
const setUnsavedPreferredImgs = data => action(UNSAVEDPREFERREDIMGS.REQUEST, { payload: data });
const submitPreferredImgs = data => action(SUBMITPREFERREDIMAGES.REQUEST, data);
const getPreferredImageCommentDetails = data => action(PREFERREDIMAGECOMMENT.REQUEST, { payload: data });
const updateAssociatePreferredImage = data => action(ASSOCIATEPREFERREDIMAGE.REQUEST, { payload: data });
const updatePreferredImageReviewStatus = data => action(PREFERREDIMAGEREVIEWSTATUS.REQUEST, { payload: data });
const approvePreferredImage = (payload, postData) => action(APPROVEPREFERREDIMAGE.REQUEST, { payload, postData });
const rejectPreferredImage = (payload, postData) => action(REJECTPREFERREDIMAGE.REQUEST, { payload, postData });

const sendImageComment = (supc, suvc, image, imageStyle, givenName, comment, deletionDetails, preferredImageData) =>
  action(ITEMIMAGE.COMMENTS_REQUEST, {
    supc,
    suvc,
    image,
    imageStyle,
    givenName,
    comment,
    deletionDetails,
    preferredImageData
  });

const sendDeleteImageComment = (supc, suvc, image, comment, reason) =>
  action(IMAGEDELETE.COMMENTS_REQUEST, { supc, suvc, image, comment, reason });

const sendFeedbackImageComment = (supc, suvc, image, comment) =>
  action(IMAGEFEED.REQUEST, { supc, suvc, image, comment });

const sendImageStyle = (supc, suvc, image, imageStyle, comment, isWithChangedName) =>
  action(IMAGESTYLE.REQUEST, { supc, suvc, image, imageStyle, comment, isWithChangedName });

const sendFnBComment = (supc, suvc, basicData, exsistingFnB, fnb) =>
  action(ITEMFNB.COMMENTS_REQUEST, { supc, suvc, basicData, exsistingFnB, fnb });
const loadScores = suvcObj => action(SCORES.REQUEST, suvcObj);
const activateCharts = chartType => action(CHARTS.REQUEST, chartType);
const getSummary = suvcObj => action(SUMMARY.REQUEST, suvcObj);
const changeFilterForGraph = filter => action(FILTERFORGRAPH.REQUEST, filter);
const loadAllVendors = () => action(VENDORS.REQUEST, {});
const selectedSupplierChanged = () => action(SUPPLIERS.REQUEST, {});

const editFnBAttribute = (suvc, supc, fieldData, callBack) =>
  action(EDITATTRIBUTE.REQUEST, { suvc, supc, fieldData, callBack });

const updateFnBComments = (commentId, comment, updatedData, status, invalid) =>
  action(UPDATEFNBCOMMENT.REQUEST, { commentId, comment, updatedData, status, invalid });
const resetTabs = tabType => action(RESETTAB.REQUEST, tabType);
const tabChanged = tab => action(TABCHANGED.REQUEST, tab);
const getOverallSummary = data => action(OVERALLSUMMARY.REQUEST, data);
const loadRejectedSupcs = suvcObj => action(REJECTEDSUPCS.REQUEST, suvcObj);
const getNutritions = supc => action(NUTRITIONS.REQUEST, { supc });
const sendNutritions = data => action(UPDATENUTRITIONS.REQUEST, data);
const loadAutoSavedFnbComments = data => action(AUTOSAVE.REQUEST, data);
const updateImageComments = data => action(UPDATEIMAGECOMMENT.REQUEST, data);
const updateImageStyle = data => action(UPDATEIMAGESTYLE.REQUEST, data);

const graphsUpdateComplex = data => action(COMPLEXGRAPHRENDERING.REQUEST, data);
const fetchFeeExclusionsBySupc = (suvc, supc) => action(FEEEXCLUSIONSBYSUPC.REQUEST, { suvc, supc });
const getFeeExclusionsCount = suvc => action(FEEEXCLUSIONSCOUNT.REQUEST, { suvc });

const updateEnutritionData = data => action(UPDATENUTRITIONDATA.REQUEST, data);
const sendNutritionQuestions = data => action(NUTRITIONQUESTIONS.REQUEST, data);
const getNutritionApplicableStatus = supc => action(NUTRITIONAPPLICABLESTATUS.REQUEST, { supc });
const updateNutritionApplicableStatus = data => action(UPDATENUTRITIONAPPLICABLESTATUS.REQUEST, data);
const getPendingNutritions = supc => action(PENDINGNUTRITIONS.REQUEST, { supc });
const cancelNutritionUpdate = commentId => action(CANCELNUTRITIONUPDATE.REQUEST, { commentId });
const onNutritionRejectFieldsChanged = data => action(ONNUTRITIONREJECTFIELDSCHANGED.REQUEST, { ...data });
const blockUpdateTaxonomyNutritions = () => action(BLOCKTAXONMYNUTRITIONUPDATE.REQUEST, {});
const setSyncedNutritionData = data => action(SETSYNCEDNUTRITIONS.REQUEST, { ...data });
const getFnbMassEditInitialData = data => action(FNBMASSEDITINITIALDATA.REQUEST, data);
const fnbMassUpdate = data => action(FNBMASSEDIT.REQUEST, data);
const toggleFnbMassEditModal = isVisible => action(TOGGLEFNBMASSEDITMODAL.REQUEST, isVisible);
const loadAllVendorsSilent = () => action(VENDORSSILENT.REQUEST, {});
const getGdsnFnbComments = data => action(GDSNFNBCOMMENTS.REQUEST, data);
const getGdsnFnb = data => action(GDSNFNB.REQUEST, data);
const coreDataFetched = data => action(COREDATA.REQUEST, data);
const changeCoreDataFieldValue = data => action(COREDATACHANGED.REQUEST, data);
const createUpdateCoreData = data => action(COREDATACOMMENT.REQUEST, data);
const coreDataCommentChanged = data => action(COREDATACOMMENTCHANGED.REQUEST, data);
const updateCoreData = data => action(SENDCOREDATACOMMENT.REQUEST, data);
const rejectAllCoreAttributesModalOpen = () => action(REJECTALLCOREDATAATTRIBUTES.REQUEST, {});
const confirmRejectAllCoreAttributes = data => action(REJECTALLCOREDATAATTRIBUTES.SUCCESS, data);
const cancelRejectAllCoreAttributes = () => action(REJECTALLCOREDATAATTRIBUTES.FAILURE, {});
const getItemDetails = data => action(GETITEMDETAILS.REQUEST, data);
const resetCurrentCoreDataValues = () => action(RESETCURRENTCOREDATACHANGES.REQUEST, {});
const getPgmItemsList = data => action(PGMITEMS.REQUEST, data);
const changeItemVisibility = data => action(CHANGEVISIBILITY.REQUEST, data);
const updateItemVisibility = data => action(UPDATEVISIBILITY.REQUEST, data);
const getForcedVisibleSupcs = () => action(FORCEDVISIBLESUPCS.REQUEST);
const nutritionValuesChanged = data => action(NUTRITIONUPDATE.REQUEST, data);
const updateNutritionFieldErrors = data => action(UPDATENUTRITIONFIELDERRORS.REQUEST, data);
const updateWholeTaxonomyAttributeChanges = data => action(UPDATETAXONOMYCHANGESWHOLE.REQUEST, data);
const updateNutritionTaxonomySynchedChanges = data => action(UPDATENUTRITIONTAXONOMYSYNCHEDCHANGES.REQUEST, data);
const selfAssignComment = data => action(SELFASSIGNCOMMENT.REQUEST, data);
const fetchCoreAttributesHistory = data => action(COREATTRIBUTEHISTORY.REQUEST, data);
const toggleCoreModal = data => action(TOGGLECOREMODAL.REQUEST, { payload: data });
const getItemMasterData = data => action(MDMITEMMASTER.REQUEST, data);

// overall item feedbacks
const fetchOverallHistoryForComment = data => action(FETCHOVERALLITEMFEEDBACKHISTORY.REQUEST, data);
const updateOverallHistoryComment = data => action(UPDATEOVERALLFEEDBACK.REQUEST, data);
const submitOverallFeedback = data => action(SUBMITOVERALLFEDBACK.REQUEST, data);

// Attribute Mass Update
const toggleAttributeMassEditModal = data => action(TOGGLEATTRIBUTEMASSEDITMODAL.REQUEST, { payload: data });
const getBusinessCenters = data => action(ALLBUSINESSCENTERS.REQUEST, data);
const handleAttributeMassEditFilters = filters => action(ATTRIBUTEMASSEDITFILTERS.REQUEST, { payload: filters });
const getAttributeMassEditList = data => action(ATTRIBUTEMASSEDITLIST.REQUEST, data);
const getAttributesAndPicklistValues = data => action(ATTRIBUTESLIST.REQUEST, data);
const pickValueForAttributeSelector = data => action(SELECTATTRIBUTESELECTORVALUE.REQUEST, { payload: data });
const updateAttributeFilters = data => action(UPDATEATTRIBUTEFILTERS.REQUEST, { payload: data });
const clearAttributeSelector = () => action(CLEARATTRIBUTESELECTOR.REQUEST, {});
const applyAttributeSelectorValues = () => action(APPLYATTRIBUTESELECTIONS.REQUEST, {});
const updateAttrMassEditCheckboxes = data => action(ATTRMASSEDITCHECKBOX.REQUEST, { payload: data });
const massUpdateAttributes = data => action(MASSUPDATEATTRIBUTES.REQUEST, data);
const massUndoAttributes = () => action(ATTRIBUTEMASSUNDO.REQUEST, {});
const updateIndividualAttribute = data => action(UPDATEINDIVIDUALATTRIBUTE.REQUEST, { payload: data });
const massAttributionNutritionSynchedFieldChangeHandle = data =>
  action(MASSATTRIBUTEITEMTAXONOMYSYNCHEDFIELDCHANGED.REQUEST, data);
const massAttributeItemNutritionFieldChange = data => action(MASSATTRIBUTEITEMTNUTIRTIONFIELDCHANGED.REQUEST, data);
const retrieveTaxonomyForItemAfterNutritionalCommentWithTaxonomyUpdate = data =>
  action(MASSATTRIBUTIONTAXONOMYFETCHEDAFTEREACHTAXONOMYWITHNUTRITIONALCOMMENT.SUCCESS, data);
const updateMassAttributeAllItems = data => action(MASSATTRIBUTIONUPDATEALLITEMLIST.REQUEST, data);

// Image Mass Update
const massUpdateSelectionChanged = data => action(MASSUPDATETYPECHANGED.REQUEST, data);
const retrieveAllMassImageUpdateData = data => action(RETRIEVEMASSIMAGEUPLOADDATA.REQUEST, data);
const closeMassUpdateImageOverlay = () => action(CLOSEMASSIMAGEUPLOADOVERLAY.REQUEST, {});
const handleMassImageLoadingPercentage = data => action(CHANGEMASSIMAGELOADINGPERCENTAGE.REQUEST, data);
const handleMassImageUploadFieldValueChanges = data => action(UPDATEMASSIMAGEUPLOADFIELDS.REQUEST, data);
const revertMassImageUploadFieldChange = data => action(REVERTMASSIMAGEUPLOADSUPCFIELD.REQUEST, data);
const submitMassImageUpload = data => action(MASSIMAGEUPLOADSUBMIT.REQUEST, data);
const clearAllChangesForItemInMassImages = data => action(CLEAREACHITEMCHANGESINMASSIMAGES.REQUEST, data);
const handleMassImageValidation = data => action(VALIDATEMASSUPLOADIMAGES.REQUEST, data);

const changeCooDataFieldValue = data => action(COODATACHANGED.REQUEST, data);
const addCooActivity = data => action(COOACTIVITYADD.REQUEST, data);
const deleteCooActivity = data => action(COOACTIVITYDEL.REQUEST, data);
const addCooCountry = data => action(COOCOUNTRYADD.REQUEST, data);
const deleteCooCountry = data => action(COOCOUNTRYDEL.REQUEST, data);
const pushCooData = data => action(COODATAPUSHED.REQUEST, data);
const itemCooDetails = itemdata => action(ITEMCOO.REQUEST, itemdata);

// PFAS Single Item View
const loadItemPfas = data => action(PFAS.REQUEST, data);
const pfasSelectionChanged = data => action(PFASSELECTIONCHANGED.REQUEST, data);
const pfasClearSelections = () => action(PFASCLEARSELECTIONS.REQUEST, {});
const pfasMassNotApplicable = data => action(PFASMASSNOTAPPLICABLE.REQUEST, data);
const PfasUpdate = data => action(PFASUPDATE.REQUEST, data);

// PFAS Mass Update
const togglePfasMassEditModal = data => action(TOGGLEPFASMASSEDIT.REQUEST, { payload: data });
const handlePfasMassEditFilters = filters => action(PFASMASSEDITFILTERS.REQUEST, { payload: filters });
const getBusinessCentersPfas = data => action(ALLBUSINESSCENTERSPFAS.REQUEST, data);
const fetchPfasItemList = data => action(PFASMASSEDITLIST.REQUEST, data);
const updatePfasMassEditCheckboxes = data => action(PFASMASSEDITCHECKBOX.REQUEST, { payload: data });
const updatePfasMassItemAttributeSelection = data => action(PFASMASSEDITITEMATTRIBUTESELECTION.REQUEST, data);
const updatePfasMassEditWholeAttributeSelection = data =>
  action(PFASMASSEDITWHOLESINGLEATTRIBUTESELECTION.REQUEST, data);
const updatePfasMassEditWholeAttributeSelectionClear = () =>
  action(PFASMASSEDITWHOLESINGLEATTRIBUTESELECTIONCLEAR.REQUEST, {});
const updateRegulatoryComplianceFilterChange = data => action(REGULATORYCOMPLIANCESELECTIONCHANGED.REQUEST, data);
const updateRegulatoryComplianceApplyValueChange = data => action(REGULATORYCOMPLIANCEAPPLYVALUE.REQUEST, data);
const clearPfasAppliedValues = () => action(CLEARMASSPFASAPPLIEDVALUES.REQUEST, {});
const applyMassPfasFilteredValues = data => action(APPLYPFASMASSFILTEREDVALUES.REQUEST, data);
const undoMassPfasChanges = () => action(UNDOMASSPFASCHANGES.REQUEST, {});
const submitMassPfas = data => action(SUBMITMASSPFASCHANGES.REQUEST, data);

// FSMA
const changeRegulatorySubTab = tab => action(REGULATORYSUBTABCHANGE.REQUEST, { payload: tab });
const setFsmaChanges = data => action(FSMACHANGES.REQUEST, { payload: data });
const saveFsmaChanges = data => action(SAVEFSMACHANGES.REQUEST, data);

export {
  loadUserDetails,
  loadItemList,
  itemDetails,
  loadTaxonomy,
  unLoadItemDetails,
  loadImages,
  loadFnb,
  addAttrChange,
  addFeedbackChange,
  saveTaxonomy,
  saveTaxonomyQuite,
  collectComment,
  sendComments,
  sendFeedbackComment,
  loadDashComments,
  updateDashComments,
  updateAttribUniComments,
  commentsPopup,
  closeCommentsPopup,
  sendFieldComment,
  sendFnBComment,
  sendImageComment,
  sendDeleteImageComment,
  sendFeedbackImageComment,
  loadScores,
  activateCharts,
  getSummary,
  changeFilterForGraph,
  sendImageStyle,
  loadSupc,
  loadAllVendors,
  selectedSupplierChanged,
  editFnBAttribute,
  updateFnBComments,
  resetTabs,
  tabChanged,
  loadRejectedSupcs,
  getOverallSummary,
  getNutritions,
  sendNutritions,
  loadAutoSavedFnbComments,
  updateImageComments,
  updateImageStyle,
  graphsUpdateComplex,
  fetchFeeExclusionsBySupc,
  getFeeExclusionsCount,
  updateEnutritionData,
  sendNutritionQuestions,
  updateNutritionApplicableStatus,
  getNutritionApplicableStatus,
  getPendingNutritions,
  cancelNutritionUpdate,
  onNutritionRejectFieldsChanged,
  blockUpdateTaxonomyNutritions,
  setSyncedNutritionData,
  getFnbMassEditInitialData,
  fnbMassUpdate,
  toggleFnbMassEditModal,
  loadAllVendorsSilent,
  getGdsnFnbComments,
  getGdsnFnb,
  coreDataFetched,
  changeCoreDataFieldValue,
  createUpdateCoreData,
  coreDataCommentChanged,
  updateCoreData,
  rejectAllCoreAttributesModalOpen,
  confirmRejectAllCoreAttributes,
  cancelRejectAllCoreAttributes,
  getItemDetails,
  resetCurrentCoreDataValues,
  getPgmItemsList,
  updateItemVisibility,
  changeItemVisibility,
  getForcedVisibleSupcs,
  nutritionValuesChanged,
  updateNutritionFieldErrors,
  updateWholeTaxonomyAttributeChanges,
  updateNutritionTaxonomySynchedChanges,
  selfAssignComment,
  fetchCoreAttributesHistory,
  toggleCoreModal,
  massUpdateSelectionChanged,
  toggleAttributeMassEditModal,
  getBusinessCenters,
  handleAttributeMassEditFilters,
  getAttributeMassEditList,
  getAttributesAndPicklistValues,
  pickValueForAttributeSelector,
  updateAttributeFilters,
  clearAttributeSelector,
  applyAttributeSelectorValues,
  massAttributionNutritionSynchedFieldChangeHandle,
  massAttributeItemNutritionFieldChange,
  retrieveTaxonomyForItemAfterNutritionalCommentWithTaxonomyUpdate,
  retrieveAllMassImageUpdateData,
  closeMassUpdateImageOverlay,
  handleMassImageLoadingPercentage,
  handleMassImageUploadFieldValueChanges,
  revertMassImageUploadFieldChange,
  submitMassImageUpload,
  clearAllChangesForItemInMassImages,
  handleMassImageValidation,
  updateAttrMassEditCheckboxes,
  massUpdateAttributes,
  updateMassAttributeAllItems,
  massUndoAttributes,
  updateIndividualAttribute,
  getItemMasterData,
  setUnsavedPreferredImgs,
  submitPreferredImgs,
  getPreferredImageCommentDetails,
  updateAssociatePreferredImage,
  updatePreferredImageReviewStatus,
  approvePreferredImage,
  rejectPreferredImage,
  fetchOverallHistoryForComment,
  updateOverallHistoryComment,
  submitOverallFeedback,
  changeCooDataFieldValue,
  addCooActivity,
  deleteCooActivity,
  addCooCountry,
  deleteCooCountry,
  pushCooData,
  itemCooDetails,
  loadItemPfas,
  pfasSelectionChanged,
  pfasClearSelections,
  pfasMassNotApplicable,
  PfasUpdate,
  togglePfasMassEditModal,
  handlePfasMassEditFilters,
  getBusinessCentersPfas,
  fetchPfasItemList,
  updatePfasMassEditCheckboxes,
  updatePfasMassItemAttributeSelection,
  updatePfasMassEditWholeAttributeSelection,
  updatePfasMassEditWholeAttributeSelectionClear,
  updateRegulatoryComplianceFilterChange,
  updateRegulatoryComplianceApplyValueChange,
  clearPfasAppliedValues,
  applyMassPfasFilteredValues,
  undoMassPfasChanges,
  submitMassPfas,
  setFsmaChanges,
  changeRegulatorySubTab,
  saveFsmaChanges
};
