import React, { useEffect, useCallback } from 'react';
import * as _ from 'lodash';
import { Input, Radio } from 'antd';
import {
  CHILD_NUTRITIONS_OR_PFS,
  NUTRITION_TAB_CN_VALIDATION_TEXT,
  CHILD_NUTRITION_ELIGIBILITY
} from '../../util/Constants';
import {
  getFieldDisabledStatusByTwoParams,
  getDecimalLengthOfNumber,
  getTaxonomyDisabledStatusOnChildNutritionValue,
  checkForFieldPendingNutrition,
  getChildNutritionData,
  isDataSourcedFromQaSpecOrGdsn,
  getPendingOrExistingCnCheckedValues
} from '../../util/Util';
import RejectFeedback from './RejectFeedback';
import CustomTooltip from './Tooltip';

const { TextArea } = Input;
const RadioGroup = Radio.Group;

const ChildNutritions = ({
  nutritionData,
  isReadOnly,
  isItemReadOnly,
  nutritionErrors,
  updateNutritionErrors,
  onFieldChange,
  item,
  pendingNutritions,
  actions,
  isDisabledBySyscoOrGdsnItemFlag
}) => {
  const { taxonomy, taxonomyChanges } = item;

  const handleChildNutritionEligibility = useCallback((name, picklist, changedId) => {
    const {
      pendingNutrition: {
        changedFields,
        isChildNutrition: existingIsChildNutrition,
        sleHasProductFormulationStmt: existingSleHasProductFormulationStmt
      } = { changedFields: [] },
      hasNutritionSynchedTaxonomyAttributeChanges,
      nutritions: { nutritionSourceOid }
    } = item;
    if (name === CHILD_NUTRITION_ELIGIBILITY && !hasNutritionSynchedTaxonomyAttributeChanges) {
      const { isChildNutrition, sleHasProductFormulationStmt } = getChildNutritionData(picklist, changedId);
      let dataObject = {
        ...getPendingOrExistingCnCheckedValues({
          attrType: null,
          existingIsChildNutrition,
          existingSleHasProductFormulationStmt,
          isChildNutrition,
          sleHasProductFormulationStmt,
          pendingNutritions
        })
      };
      if (
        !isDataSourcedFromQaSpecOrGdsn(nutritionSourceOid) &&
        !isDisabledBySyscoOrGdsnItemFlag &&
        existingIsChildNutrition !== isChildNutrition &&
        existingSleHasProductFormulationStmt !== sleHasProductFormulationStmt
      ) {
        dataObject = {
          ...dataObject,
          changedFields: [...new Set([...changedFields, 'isChildNutrition', 'sleHasProductFormulationStmt'])]
        };
        actions.updateNutritionTaxonomySynchedChanges({ hasNutritionSynchedTaxonomyAttributeChanges: true });
      }
      actions.nutritionValuesChanged(dataObject);
    }
  });

  useEffect(() => {
    const { attributes } = taxonomy || {};
    if (attributes && attributes.length) {
      const { name, picklist, selected, attrId } = _.find(attributes, { name: CHILD_NUTRITION_ELIGIBILITY });
      let selectedValue = selected;
      const { attrId: changedSelectedAttrId, selected: changedSelection } = _.defaultTo(
        _.find(taxonomyChanges, { attrId: String(attrId) || Number(attrId) }),
        {}
      );
      if (changedSelectedAttrId & changedSelection) {
        selectedValue = changedSelection;
      }
      handleChildNutritionEligibility(name, picklist, selectedValue);
    }
  }, [taxonomy, taxonomyChanges]);

  const getchildOrPfsRadioButtonValue = () => {
    const { isChildNutrition, sleHasProductFormulationStmt } = nutritionData;
    if (isChildNutrition) return 1;
    if (sleHasProductFormulationStmt) return 2;
    return null;
  };

  const getChildOrPfsDisableStatus = code => {
    const { isChildNutrition, sleHasProductFormulationStmt } = nutritionData;
    if (isReadOnly) return true;
    if (code === 'sleProductId' && sleHasProductFormulationStmt) return true;
    if (!isChildNutrition && !sleHasProductFormulationStmt) return true;
    return false;
  };

  const onChangeChildNutritionsOrPFS = (code, value, isNumber) => {
    const { changedFields } = nutritionData;
    const updatedValue = value === '' || !isNumber ? value : isNumber && Number(value);
    const dataObject = {
      changedFields: [...new Set([...changedFields, code])]
    };
    dataObject[code] = updatedValue;
    onFieldChange(dataObject);
    const valueLength = getDecimalLengthOfNumber(value);
    const updatedErrors = { ...nutritionErrors };
    if (isNumber && !(Number(value) > -1 && Number(value) <= 99.999 && valueLength < 4)) {
      updatedErrors[code] = 'should be equal or between 0 - 99.999';
    } else {
      delete updatedErrors[code];
    }
    updateNutritionErrors({ updatedErrors });
  };

  const radioBtnValue = getchildOrPfsRadioButtonValue();

  const hasValidationErrors = getTaxonomyDisabledStatusOnChildNutritionValue(item, true);

  const getValidationErrorField = () => {
    if (hasValidationErrors) return <span className="validation-error">{NUTRITION_TAB_CN_VALIDATION_TEXT}</span>;
  };

  const getValueForInput = code => {
    const { sleHasProductFormulationStmt } = nutritionData;
    if (code === 'sleProductId' && sleHasProductFormulationStmt) return null;
    return nutritionData[code];
  };

  const getFieldValidationErrors = (code, value) => {
    if (code === 'slePortionSize' && value && value.length > 16)
      return <span className="validation-error">{'Portion Size cannot be more than 16 characters long'}</span>;
  };

  return (
    <div className="nt-section">
      <div className="nt-title with-enut-tooltip">
        K12 Information
        <CustomTooltip tooltipKey="k12" />
      </div>

      <div className="nt-cn-container">
        <RejectFeedback field="slePortionSize" nutritionData={nutritionData} />
        {getValidationErrorField()}
        <RadioGroup className="pfs-radio-btn-grp" value={radioBtnValue} disabled>
          <Radio value={1}>Child Nutrition Label</Radio>
          <Radio value={2}>Product Formulation Statement</Radio>
        </RadioGroup>

        <div className="nt-attr-grp">
          {CHILD_NUTRITIONS_OR_PFS.map(({ code, des, type }) => {
            const value = getValueForInput(code);
            const isDisabled = getChildOrPfsDisableStatus(code);
            const isNumber = type === 'number';

            if (des === 'School Nutrition Note') {
              return (
                <div key={code} className="nt-field-wrapper nt-notes">
                  <div className="text-limit-indicator">
                    {nutritionData[code] ? nutritionData[code].length : 0}
                    /300
                  </div>
                  <div className="nt-attr-lbl">{des}</div>
                  <TextArea
                    className={`txt-ingre-statement ${checkForFieldPendingNutrition(code, pendingNutritions)}`}
                    value={value}
                    rows={10}
                    maxLength={300}
                    disabled={
                      getFieldDisabledStatusByTwoParams(isDisabled, isItemReadOnly) || isDisabledBySyscoOrGdsnItemFlag
                    }
                    onChange={e => onChangeChildNutritionsOrPFS(code, e.target.value, isNumber)}
                  />
                </div>
              );
            }
            return (
              <div key={code} className="nt-field-wrapper">
                <div className="nt-attr-lbl">{des}</div>
                <Input
                  className={`input-child-nutri ${checkForFieldPendingNutrition(code, pendingNutritions)}`}
                  placeholder="Please enter..."
                  value={value}
                  disabled={
                    getFieldDisabledStatusByTwoParams(isDisabled, isItemReadOnly) || isDisabledBySyscoOrGdsnItemFlag
                  }
                  type={isNumber ? 'number' : 'string'}
                  onChange={e => onChangeChildNutritionsOrPFS(code, e.target.value, isNumber)}
                />
                {nutritionErrors[code] && <span className="validation-error">{nutritionErrors[code]}</span>}
                {getFieldValidationErrors(code, value)}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ChildNutritions;
