import React from 'react';

const TraitRowData = ({ field, value, index, level, removedStatus, isExisting, codeTitle }) => {
  return (
    <tr
      key={index}
      className={`${removedStatus ? `table-removed-row` : undefined} ${
        !isExisting ? 'nt-feedback-changed-fields-tr' : undefined
      }`}
    >
      <th>{field}</th>
      <th className="white">{value}</th>
      {codeTitle === 'Allergen' && <th className="white">{level}</th>}
    </tr>
  );
};

export default TraitRowData;
