import _ from 'lodash';
import {
  updateNutritionTraits,
  updateNutritionTraitsDietTypes,
  getTaxonomyPickedTraits,
  updateNutritionClaims,
  getTaxonomyPickedClaimsAndOrganicStatus,
  isDietTypeAttribute,
  isAllergenAttribute,
  isClaimAttribute
} from './Util';

const includesENut = (nutritions, cl) => {
  const isMatch = false;
  const pathValues = _.get(nutritions, cl.eNutPath, false);
  if (pathValues && pathValues.includes) {
    const previousExistingData = _.find(pathValues, item => {
      return item.nutritionalClaimElementCode === cl.nutritionalClaimNutrientElementCode;
    });
    if (previousExistingData && typeof previousExistingData === 'object') {
      return { isMatch: true, existingData: previousExistingData };
    }
  }
  return { isMatch, existingData: [] };
};

const findInObjectArray = (nutritions, cl) => {
  let isMatch = false;
  const pathValues = _.get(nutritions, cl.eNutPath, false);
  let existingObject = {};
  if (pathValues && pathValues.includes) {
    let obj = null;
    try {
      obj = _.find(pathValues, cl.objFind);
    } catch (e) {}
    if (obj && obj[cl.objField]) {
      existingObject = Object.assign({}, obj);
      isMatch = true;
    }
  }
  return { isMatch, existingObject };
};

const findDirectPicklistValue = (nutritions, { objField, osdAttribute, osdPick }) => {
  const fieldValue = nutritions.hasOwnProperty(objField) && nutritions[objField];
  return { isMatch: fieldValue, selectedField: { osdAttribute, osdPick } };
};

const mappingObject = {
  cl: [
    {
      osdAttribute: 'Organic Claim',
      osdPick: 'Not Organic',
      eNutPath: 'organicTradeItemCodes',
      eNutValue: 5,
      check: includesENut,
      isForTradeItemCodes: true
    },
    {
      osdAttribute: 'Organic Claim',
      osdPick: 'Organic',
      eNutPath: 'organicTradeItemCodes',
      eNutValue: 1,
      check: includesENut,
      isForTradeItemCodes: true
    },
    {
      osdAttribute: 'Organic Claim Status',
      osdPick: '100 % Organic',
      eNutPath: 'organicTradeItemCodes',
      eNutValue: 1,
      check: includesENut,
      isForTradeItemCodes: true
    },
    {
      osdAttribute: 'Organic Claim Status',
      osdPick: 'Not Organic',
      eNutPath: 'organicTradeItemCodes',
      eNutValue: 5,
      check: includesENut,
      isForTradeItemCodes: true
    },
    {
      osdAttribute: 'Organic Claim Status',
      osdPick: 'Biodynamic',
      eNutPath: 'organicTradeItemCodes',
      eNutValue: 7,
      check: includesENut,
      isForTradeItemCodes: true
    },
    {
      osdAttribute: 'Organic Claim Status',
      osdPick: 'Disqualified',
      eNutPath: 'organicTradeItemCodes',
      eNutValue: 8,
      check: includesENut,
      isForTradeItemCodes: true
    },
    {
      osdAttribute: 'Organic Claim Status',
      osdPick: 'In Conversion',
      eNutPath: 'organicTradeItemCodes',
      eNutValue: 6,
      check: includesENut,
      isForTradeItemCodes: true
    },
    {
      osdAttribute: 'Organic Claim Status',
      osdPick: 'Made With Organic Ingredients (At Least 70 % By Weight)',
      eNutPath: 'organicTradeItemCodes',
      eNutValue: 3,
      check: includesENut,
      isForTradeItemCodes: true
    },
    {
      osdAttribute: 'Organic Claim Status',
      osdPick: 'Organic (At Least 95 % By Weight)',
      eNutPath: 'organicTradeItemCodes',
      eNutValue: 2,
      check: includesENut,
      isForTradeItemCodes: true
    },
    {
      osdAttribute: 'Organic Claim Status',
      osdPick: 'Some Organic Ingredients (< 70 % By Weight)',
      eNutPath: 'organicTradeItemCodes',
      eNutValue: 4,
      check: includesENut,
      isForTradeItemCodes: true
    },
    {
      osdAttribute: 'Contains HFCS Claim',
      osdPick: 'Contains HFCS',
      eNutPath: 'nutritionalClaimsDetails',
      eNutValue: 'CONTAINS',
      nutritionalClaimNutrientElementCode: 'HIGH_FRUCTOSE_CORN_SYRUP',
      check: includesENut
    },
    {
      osdAttribute: 'Contains HFCS Claim',
      osdPick: 'Does Not Contain HFCS',
      eNutPath: 'nutritionalClaimsDetails',
      eNutValue: 'FREE_FROM',
      nutritionalClaimNutrientElementCode: 'HIGH_FRUCTOSE_CORN_SYRUP',
      check: includesENut
    },
    {
      osdAttribute: 'Fat Nutrient Content Claim',
      osdPick: 'Fat-Free',
      eNutPath: 'nutritionalClaimsDetails',
      eNutValue: 'FREE_FROM',
      nutritionalClaimNutrientElementCode: 'FAT',
      check: includesENut
    },
    {
      osdAttribute: 'Fat Nutrient Content Claim',
      osdPick: 'Low-Fat',
      eNutPath: 'nutritionalClaimsDetails',
      eNutValue: 'LOW',
      nutritionalClaimNutrientElementCode: 'FAT',
      check: includesENut
    },
    {
      osdAttribute: 'Fat Nutrient Content Claim',
      osdPick: 'Reduced/Less Fat',
      eNutPath: 'nutritionalClaimsDetails',
      eNutValue: 'REDUCED_LESS',
      nutritionalClaimNutrientElementCode: 'FAT',
      check: includesENut
    },
    {
      osdAttribute: 'Gluten Claim Status',
      osdPick: 'Gluten-Free',
      eNutPath: 'nutritionalClaimsDetails',
      eNutValue: 'FREE_FROM',
      nutritionalClaimNutrientElementCode: 'GLUTEN',
      check: includesENut
    },
    {
      osdAttribute: 'MSG Status Claim',
      osdPick: 'Contains MSG',
      eNutPath: 'nutritionalClaimsDetails',
      eNutValue: 'CONTAINS',
      nutritionalClaimNutrientElementCode: 'MSG',
      check: includesENut
    },
    {
      osdAttribute: 'MSG Status Claim',
      osdPick: 'Free_From_MSG',
      eNutPath: 'nutritionalClaimsDetails',
      eNutValue: 'FREE_FROM',
      nutritionalClaimNutrientElementCode: 'MSG',
      check: includesENut
    },
    {
      osdAttribute: 'MSG Status Claim',
      osdPick: 'No Added MSG',
      eNutPath: 'nutritionalClaimsDetails',
      eNutValue: 'NO_ADDED',
      nutritionalClaimNutrientElementCode: 'MSG',
      check: includesENut
    },
    // {
    //   osdAttribute: 'Natural Claim',
    //   osdPick: 'Natural',
    //   eNutPath: 'nutritionalClaimsDetails',
    //   eNutValue: 'FREE_FROM',
    //   nutritionalClaimNutrientElementCode: 'NATURAL_FLAVOUR',
    //   check: includesENut
    // },
    // {
    //   osdAttribute: 'Natural Claim',
    //   osdPick: 'No Artificial Color',
    //   eNutPath: 'nutritionalClaimsDetails',
    //   eNutValue: 'FREE_FROM',
    //   nutritionalClaimNutrientElementCode: 'ARTIFICIAL_COLOUR',
    //   check: includesENut
    // },
    // {
    //   osdAttribute: 'Natural Claim',
    //   osdPick: 'No Artificial Flavors',
    //   eNutPath: 'nutritionalClaimsDetails',
    //   eNutValue: 'FREE_FROM',
    //   nutritionalClaimNutrientElementCode: 'ARTIFICIAL_FLAVOUR',
    //   check: includesENut
    // },
    {
      osdAttribute: 'Protein Claim',
      osdPick: 'No Protein',
      eNutPath: 'nutritionalClaimsDetails',
      eNutValue: 'FREE_FROM',
      nutritionalClaimNutrientElementCode: 'PROTEIN',
      check: includesENut
    },
    {
      osdAttribute: 'Protein Claim',
      osdPick: 'Protein',
      eNutPath: 'nutritionalClaimsDetails',
      eNutValue: 'CONTAINS',
      nutritionalClaimNutrientElementCode: 'PROTEIN',
      check: includesENut
    },
    {
      osdAttribute: 'Sodium Nutrient Content Claim',
      osdPick: 'Low Sodium',
      eNutPath: 'nutritionalClaimsDetails',
      eNutValue: 'LOW',
      nutritionalClaimNutrientElementCode: 'SODIUM_SALT',
      check: includesENut
    },
    {
      osdAttribute: 'Sodium Nutrient Content Claim',
      osdPick: 'Reduced/Less Sodium',
      eNutPath: 'nutritionalClaimsDetails',
      eNutValue: 'REDUCED_LESS',
      nutritionalClaimNutrientElementCode: 'SODIUM_SALT',
      check: includesENut
    },
    {
      osdAttribute: 'Sodium Nutrient Content Claim',
      osdPick: 'Sodium Free',
      eNutPath: 'nutritionalClaimsDetails',
      eNutValue: 'FREE_FROM',
      nutritionalClaimNutrientElementCode: 'SODIUM_SALT',
      check: includesENut
    },
    {
      osdAttribute: 'Sodium Nutrient Content Claim',
      osdPick: 'Very Low Sodium',
      eNutPath: 'nutritionalClaimsDetails',
      eNutValue: 'VERY_LOW',
      nutritionalClaimNutrientElementCode: 'SODIUM_SALT',
      check: includesENut
    },
    {
      osdAttribute: 'Sugar Nutrient Content Claim',
      osdPick: 'No/Without Added Sugars',
      eNutPath: 'nutritionalClaimsDetails',
      eNutValue: 'NO_ADDED',
      nutritionalClaimNutrientElementCode: 'SUGARS',
      check: includesENut
    },
    {
      osdAttribute: 'Sugar Nutrient Content Claim',
      osdPick: 'Reduced/Less Sugar',
      eNutPath: 'nutritionalClaimsDetails',
      eNutValue: 'REDUCED_LESS',
      nutritionalClaimNutrientElementCode: 'SUGARS',
      check: includesENut
    },
    {
      osdAttribute: 'Sugar Nutrient Content Claim',
      osdPick: 'Sugar-Free',
      eNutPath: 'nutritionalClaimsDetails',
      eNutValue: 'FREE_FROM',
      nutritionalClaimNutrientElementCode: 'SUGARS',
      check: includesENut
    }
  ],
  dt: [
    {
      osdAttribute: 'Halal Diet Type',
      osdPick: 'Halal',
      eNutPath: 'traits',
      objFind: ({ traitName }) => traitName.toString().toLowerCase() === 'halal',
      objField: 'dietTypeCode',
      dietTypeCode: 'HALAL',
      eNutValue: 'HALAL',
      traitName: 'Halal',
      check: findInObjectArray
    },
    {
      osdAttribute: 'Kosher Diet Type',
      osdPick: 'Kosher',
      eNutPath: 'traits',
      objFind: ({ traitName }) => traitName.toString().toLowerCase() === 'kosher',
      objField: 'dietTypeCode',
      dietTypeCode: 'KOSHER',
      eNutValue: 'KOSHER',
      traitName: 'Kosher',
      check: findInObjectArray
    },
    {
      osdAttribute: 'Vegan Diet Type',
      osdPick: 'Vegan',
      eNutPath: 'traits',
      objFind: ({ traitName }) => traitName.toString().toLowerCase() === 'vegan',
      objField: 'dietTypeCode',
      dietTypeCode: 'VEGAN',
      eNutValue: 'VEGAN',
      traitName: 'Vegan',
      check: findInObjectArray
    },
    {
      osdAttribute: 'Vegetarian Diet Type',
      osdPick: 'Vegetarian',
      eNutPath: 'traits',
      objFind: ({ traitName }) => traitName.toString().toLowerCase() === 'vegetarian',
      objField: 'dietTypeCode',
      dietTypeCode: 'VEGETARIAN',
      eNutValue: 'VEGETARIAN',
      traitName: 'Vegetarian',
      check: findInObjectArray
    }
  ],
  al: [
    {
      osdAttribute: 'Crustacean Shellfish Allergen',
      osdPick: 'Contains Crustacean Shellfish',
      eNutPath: 'traits',
      objFind: ({ traitName }) => traitName.toLowerCase() === 'crustaceans',
      objField: 'levelOfContainment',
      allergenCode: 'AC',
      eNutValue: 'CONTAINS',
      eNutName: 'Crustacean',
      traitName: 'Crustaceans',
      check: findInObjectArray
    },
    {
      osdAttribute: 'Crustacean Shellfish Allergen',
      osdPick: 'Crustacean Shellfish Not Intentionally Included In Product',
      eNutPath: 'traits',
      objFind: ({ traitName }) => traitName.toLowerCase() === 'crustaceans',
      objField: 'levelOfContainment',
      allergenCode: 'AC',
      eNutValue: 'CODE_30',
      eNutName: 'Crustacean',
      traitName: 'Crustaceans',
      check: findInObjectArray
    },
    {
      osdAttribute: 'Crustacean Shellfish Allergen',
      osdPick: 'Undeclared',
      eNutPath: 'traits',
      objFind: ({ traitName }) => traitName.toLowerCase() === 'crustaceans',
      objField: 'levelOfContainment',
      allergenCode: 'AC',
      eNutValue: 'UNDECLARED',
      eNutName: 'Crustacean',
      traitName: 'Crustaceans',
      check: findInObjectArray
    },
    {
      osdAttribute: 'Crustacean Shellfish Allergen',
      osdPick: 'Free From Crustacean Shellfish',
      eNutPath: 'traits',
      objFind: ({ traitName }) => traitName.toLowerCase() === 'crustaceans',
      objField: 'levelOfContainment',
      allergenCode: 'AC',
      eNutValue: 'FREE_FROM',
      eNutName: 'Crustacean',
      traitName: 'Crustaceans',
      check: findInObjectArray
    },
    {
      osdAttribute: 'Crustacean Shellfish Allergen',
      osdPick: 'May Contain Crustacean Shellfish',
      eNutPath: 'traits',
      objFind: ({ traitName }) => traitName.toLowerCase() === 'crustaceans',
      objField: 'levelOfContainment',
      allergenCode: 'AC',
      eNutValue: 'MAY_CONTAIN',
      eNutName: 'Crustacean',
      traitName: 'Crustaceans',
      check: findInObjectArray
    },
    {
      osdAttribute: 'Egg Allergen',
      osdPick: 'Contains Egg',
      eNutPath: 'traits',
      objFind: ({ traitName }) => traitName.toLowerCase() === 'eggs',
      objField: 'levelOfContainment',
      allergenCode: 'AE',
      eNutValue: 'CONTAINS',
      eNutName: 'Eggs',
      traitName: 'Eggs',
      check: findInObjectArray
    },
    {
      osdAttribute: 'Egg Allergen',
      osdPick: 'Egg Not Intentionally Included In Product',
      eNutPath: 'traits',
      objFind: ({ traitName }) => traitName.toLowerCase() === 'eggs',
      objField: 'levelOfContainment',
      allergenCode: 'AE',
      eNutValue: 'CODE_30',
      eNutName: 'Eggs',
      traitName: 'Eggs',
      check: findInObjectArray
    },
    {
      osdAttribute: 'Egg Allergen',
      osdPick: 'Undeclared',
      eNutPath: 'traits',
      objFind: ({ traitName }) => traitName.toLowerCase() === 'eggs',
      objField: 'levelOfContainment',
      allergenCode: 'AE',
      eNutValue: 'UNDECLARED',
      eNutName: 'Eggs',
      traitName: 'Eggs',
      check: findInObjectArray
    },
    {
      osdAttribute: 'Egg Allergen',
      osdPick: 'Free From Egg',
      eNutPath: 'traits',
      objFind: ({ traitName }) => traitName.toLowerCase() === 'eggs',
      objField: 'levelOfContainment',
      allergenCode: 'AE',
      eNutValue: 'FREE_FROM',
      eNutName: 'Eggs',
      traitName: 'Eggs',
      check: findInObjectArray
    },
    {
      osdAttribute: 'Egg Allergen',
      osdPick: 'May Contain Egg',
      eNutPath: 'traits',
      objFind: ({ traitName }) => traitName.toLowerCase() === 'eggs',
      objField: 'levelOfContainment',
      allergenCode: 'AE',
      eNutValue: 'MAY_CONTAIN',
      eNutName: 'Eggs',
      traitName: 'Eggs',
      check: findInObjectArray
    },
    {
      osdAttribute: 'Fish Allergen',
      osdPick: 'Contains Fish',
      eNutPath: 'traits',
      objFind: ({ traitName }) => traitName.toLowerCase() === 'fish',
      objField: 'levelOfContainment',
      allergenCode: 'AF',
      eNutValue: 'CONTAINS',
      eNutName: 'Fish',
      traitName: 'Fish',
      check: findInObjectArray
    },
    {
      osdAttribute: 'Fish Allergen',
      osdPick: 'Free From Fish',
      eNutPath: 'traits',
      objFind: ({ traitName }) => traitName.toLowerCase() === 'fish',
      objField: 'levelOfContainment',
      allergenCode: 'AF',
      eNutValue: 'FREE_FROM',
      eNutName: 'Fish',
      traitName: 'Fish',
      check: findInObjectArray
    },
    {
      osdAttribute: 'Fish Allergen',
      osdPick: 'May Contain Fish',
      eNutPath: 'traits',
      objFind: ({ traitName }) => traitName.toLowerCase() === 'fish',
      objField: 'levelOfContainment',
      allergenCode: 'AF',
      eNutValue: 'MAY_CONTAIN',
      eNutName: 'Fish',
      traitName: 'Fish',
      check: findInObjectArray
    },
    {
      osdAttribute: 'Fish Allergen',
      osdPick: 'Fish Not Intentionally Included In Product',
      eNutPath: 'traits',
      objFind: ({ traitName }) => traitName.toLowerCase() === 'fish',
      objField: 'levelOfContainment',
      allergenCode: 'AF',
      eNutValue: 'CODE_30',
      eNutName: 'Fish',
      traitName: 'Fish',
      check: findInObjectArray
    },
    {
      osdAttribute: 'Fish Allergen',
      osdPick: 'Undeclared',
      eNutPath: 'traits',
      objFind: ({ traitName }) => traitName.toLowerCase() === 'fish',
      objField: 'levelOfContainment',
      allergenCode: 'AF',
      eNutValue: 'UNDECLARED',
      eNutName: 'Fish',
      traitName: 'Fish',
      check: findInObjectArray
    },
    {
      osdAttribute: 'Milk Allergen',
      osdPick: 'Contains Milk',
      eNutPath: 'traits',
      objFind: ({ traitName }) => traitName.toLowerCase() === 'milk',
      objField: 'levelOfContainment',
      allergenCode: 'AM',
      eNutValue: 'CONTAINS',
      eNutName: 'Milk',
      traitName: 'Milk',
      check: findInObjectArray
    },
    {
      osdAttribute: 'Milk Allergen',
      osdPick: 'Free From Milk',
      eNutPath: 'traits',
      objFind: ({ traitName }) => traitName.toLowerCase() === 'milk',
      objField: 'levelOfContainment',
      allergenCode: 'AM',
      eNutValue: 'FREE_FROM',
      eNutName: 'Milk',
      traitName: 'Milk',
      check: findInObjectArray
    },
    {
      osdAttribute: 'Milk Allergen',
      osdPick: 'May Contain Milk',
      eNutPath: 'traits',
      objFind: ({ traitName }) => traitName.toLowerCase() === 'milk',
      objField: 'levelOfContainment',
      allergenCode: 'AM',
      eNutValue: 'MAY_CONTAIN',
      eNutName: 'Milk',
      traitName: 'Milk',
      check: findInObjectArray
    },
    {
      osdAttribute: 'Milk Allergen',
      osdPick: 'Milk Not Intentionally Included In Product',
      eNutPath: 'traits',
      objFind: ({ traitName }) => traitName.toLowerCase() === 'milk',
      objField: 'levelOfContainment',
      allergenCode: 'AM',
      eNutValue: 'CODE_30',
      eNutName: 'Milk',
      traitName: 'Milk',
      check: findInObjectArray
    },
    {
      osdAttribute: 'Milk Allergen',
      osdPick: 'Undeclared',
      eNutPath: 'traits',
      objFind: ({ traitName }) => traitName.toLowerCase() === 'milk',
      objField: 'levelOfContainment',
      allergenCode: 'AM',
      eNutValue: 'UNDECLARED',
      eNutName: 'Milk',
      traitName: 'Milk',
      check: findInObjectArray
    },
    {
      osdAttribute: 'Peanut Allergen',
      osdPick: 'Contains Peanuts',
      eNutPath: 'traits',
      objFind: ({ traitName }) => traitName.toLowerCase() === 'peanuts',
      objField: 'levelOfContainment',
      allergenCode: 'AP',
      eNutValue: 'CONTAINS',
      eNutName: 'Peanuts',
      traitName: 'Peanuts',
      check: findInObjectArray
    },
    {
      osdAttribute: 'Peanut Allergen',
      osdPick: 'Free From Peanuts',
      eNutPath: 'traits',
      objFind: ({ traitName }) => traitName.toLowerCase() === 'peanuts',
      objField: 'levelOfContainment',
      allergenCode: 'AP',
      eNutValue: 'FREE_FROM',
      eNutName: 'Peanuts',
      traitName: 'Peanuts',
      check: findInObjectArray
    },
    {
      osdAttribute: 'Peanut Allergen',
      osdPick: 'May Contain Peanuts',
      eNutPath: 'traits',
      objFind: ({ traitName }) => traitName.toLowerCase() === 'peanuts',
      objField: 'levelOfContainment',
      allergenCode: 'AP',
      eNutValue: 'MAY_CONTAIN',
      eNutName: 'Peanuts',
      traitName: 'Peanuts',
      check: findInObjectArray
    },
    {
      osdAttribute: 'Peanut Allergen',
      osdPick: 'Peanuts Not Intentionally Included In Product',
      eNutPath: 'traits',
      objFind: ({ traitName }) => traitName.toLowerCase() === 'peanuts',
      objField: 'levelOfContainment',
      allergenCode: 'AP',
      eNutValue: 'CODE_30',
      eNutName: 'Peanuts',
      traitName: 'Peanuts',
      check: findInObjectArray
    },
    {
      osdAttribute: 'Peanut Allergen',
      osdPick: 'Undeclared',
      eNutPath: 'traits',
      objFind: ({ traitName }) => traitName.toLowerCase() === 'peanuts',
      objField: 'levelOfContainment',
      allergenCode: 'AP',
      eNutValue: 'UNDECLARED',
      eNutName: 'Peanuts',
      traitName: 'Peanuts',
      check: findInObjectArray
    },
    {
      osdAttribute: 'Soybean Allergen',
      osdPick: 'Contains Soybeans',
      eNutPath: 'traits',
      objFind: ({ traitName }) => traitName.toLowerCase() === 'soy',
      objField: 'levelOfContainment',
      allergenCode: 'AY',
      eNutValue: 'CONTAINS',
      eNutName: 'Soy',
      traitName: 'Soy',
      check: findInObjectArray
    },
    {
      osdAttribute: 'Soybean Allergen',
      osdPick: 'Free From Soybeans',
      eNutPath: 'traits',
      objFind: ({ traitName }) => traitName.toLowerCase() === 'soy',
      objField: 'levelOfContainment',
      allergenCode: 'AY',
      eNutValue: 'FREE_FROM',
      eNutName: 'Soy',
      traitName: 'Soy',
      check: findInObjectArray
    },
    {
      osdAttribute: 'Soybean Allergen',
      osdPick: 'May Contain Soybeans',
      eNutPath: 'traits',
      objFind: ({ traitName }) => traitName.toLowerCase() === 'soy',
      objField: 'levelOfContainment',
      allergenCode: 'AY',
      eNutValue: 'MAY_CONTAIN',
      eNutName: 'Soy',
      traitName: 'Soy',
      check: findInObjectArray
    },
    {
      osdAttribute: 'Soybean Allergen',
      osdPick: 'Soybeans Not Intentionally Included In Product',
      eNutPath: 'traits',
      objFind: ({ traitName }) => traitName.toLowerCase() === 'soy',
      objField: 'levelOfContainment',
      allergenCode: 'AY',
      eNutValue: 'CODE_30',
      eNutName: 'Soy',
      traitName: 'Soy',
      check: findInObjectArray
    },
    {
      osdAttribute: 'Soybean Allergen',
      osdPick: 'Undeclared',
      eNutPath: 'traits',
      objFind: ({ traitName }) => traitName.toLowerCase() === 'soy',
      objField: 'levelOfContainment',
      allergenCode: 'AY',
      eNutValue: '30',
      eNutValue: 'UNDECLARED',
      eNutName: 'Soy',
      traitName: 'Soy',
      check: findInObjectArray
    },
    {
      osdAttribute: 'Tree Nuts Allergen',
      osdPick: 'Contains Tree Nuts',
      eNutPath: 'traits',
      objFind: ({ traitName }) => traitName.toLowerCase() === 'tree nuts',
      objField: 'levelOfContainment',
      allergenCode: 'AN',
      eNutValue: 'CONTAINS',
      eNutName: 'Tree Nuts',
      traitName: 'Tree Nuts',
      check: findInObjectArray
    },
    {
      osdAttribute: 'Tree Nuts Allergen',
      osdPick: 'Free From Tree Nuts',
      eNutPath: 'traits',
      objFind: ({ traitName }) => traitName.toLowerCase() === 'tree nuts',
      objField: 'levelOfContainment',
      allergenCode: 'AN',
      eNutValue: 'FREE_FROM',
      eNutName: 'Tree Nuts',
      traitName: 'Tree Nuts',
      check: findInObjectArray
    },
    {
      osdAttribute: 'Tree Nuts Allergen',
      osdPick: 'May Contain Tree Nuts',
      eNutPath: 'traits',
      objFind: ({ traitName }) => traitName.toLowerCase() === 'tree nuts',
      objField: 'levelOfContainment',
      allergenCode: 'AN',
      eNutValue: 'MAY_CONTAIN',
      eNutName: 'Tree Nuts',
      traitName: 'Tree Nuts',
      check: findInObjectArray
    },
    {
      osdAttribute: 'Tree Nuts Allergen',
      osdPick: 'Tree Nuts Not Intentionally Included In Product',
      eNutPath: 'traits',
      objFind: ({ traitName }) => traitName.toLowerCase() === 'tree nuts',
      objField: 'levelOfContainment',
      allergenCode: 'AN',
      eNutValue: 'CODE_30',
      eNutName: 'Tree Nuts',
      traitName: 'Tree Nuts',
      check: findInObjectArray
    },
    {
      osdAttribute: 'Tree Nuts Allergen',
      osdPick: 'Undeclared',
      eNutPath: 'traits',
      objFind: ({ traitName }) => traitName.toLowerCase() === 'tree nuts',
      objField: 'levelOfContainment',
      allergenCode: 'AN',
      eNutValue: 'UNDECLARED',
      eNutName: 'Tree Nuts',
      traitName: 'Tree Nuts',
      check: findInObjectArray
    },
    {
      osdAttribute: 'Wheat Allergen',
      osdPick: 'Contains Wheat',
      eNutPath: 'traits',
      objFind: ({ traitName }) => traitName.toLowerCase() === 'wheat',
      objField: 'levelOfContainment',
      allergenCode: 'UW',
      eNutValue: 'CONTAINS',
      eNutName: 'Wheat',
      traitName: 'Wheat',
      check: findInObjectArray
    },
    {
      osdAttribute: 'Wheat Allergen',
      osdPick: 'Free From Wheat',
      eNutPath: 'traits',
      objFind: ({ traitName }) => traitName.toLowerCase() === 'wheat',
      objField: 'levelOfContainment',
      eNutValue: 'FREE_FROM',
      allergenCode: 'UW',
      eNutName: 'Wheat',
      traitName: 'Wheat',
      check: findInObjectArray
    },
    {
      osdAttribute: 'Wheat Allergen',
      osdPick: 'May Contain Wheat',
      eNutPath: 'traits',
      objFind: ({ traitName }) => traitName.toLowerCase() === 'wheat',
      objField: 'levelOfContainment',
      allergenCode: 'UW',
      eNutValue: 'MAY_CONTAIN',
      eNutName: 'Wheat',
      traitName: 'Wheat',
      check: findInObjectArray
    },
    {
      osdAttribute: 'Wheat Allergen',
      osdPick: 'Wheat Not Intentionally Included In Product',
      eNutPath: 'traits',
      objFind: ({ traitName }) => traitName.toLowerCase() === 'wheat',
      objField: 'levelOfContainment',
      allergenCode: 'UW',
      eNutValue: 'CODE_30',
      eNutName: 'Wheat',
      traitName: 'Wheat',
      check: findInObjectArray
    },
    {
      osdAttribute: 'Wheat Allergen',
      osdPick: 'Undeclared',
      eNutPath: 'traits',
      objFind: ({ traitName }) => traitName.toLowerCase() === 'wheat',
      objField: 'levelOfContainment',
      allergenCode: 'UW',
      eNutValue: 'UNDECLARED',
      eNutName: 'Wheat',
      traitName: 'Wheat',
      check: findInObjectArray
    },
    {
      osdAttribute: 'Sesame Allergen',
      osdPick: 'Contains Sesame',
      eNutPath: 'traits',
      objFind: ({ traitName }) => traitName.toLowerCase() === 'sesame seeds',
      objField: 'levelOfContainment',
      allergenCode: 'AS',
      eNutValue: 'CONTAINS',
      eNutName: 'Sesame',
      traitName: 'Sesame seeds',
      check: findInObjectArray
    },
    {
      osdAttribute: 'Sesame Allergen',
      osdPick: 'Free From Sesame',
      eNutPath: 'traits',
      objFind: ({ traitName }) => traitName.toLowerCase() === 'sesame seeds',
      objField: 'levelOfContainment',
      eNutValue: 'FREE_FROM',
      allergenCode: 'AS',
      eNutName: 'Sesame',
      traitName: 'Sesame seeds',
      check: findInObjectArray
    },
    {
      osdAttribute: 'Sesame Allergen',
      osdPick: 'May Contain Sesame',
      eNutPath: 'traits',
      objFind: ({ traitName }) => traitName.toLowerCase() === 'sesame seeds',
      objField: 'levelOfContainment',
      allergenCode: 'AS',
      eNutValue: 'MAY_CONTAIN',
      eNutName: 'Sesame',
      traitName: 'Sesame seeds',
      check: findInObjectArray
    },
    {
      osdAttribute: 'Sesame Allergen',
      osdPick: 'Sesame Not Intentionally Included',
      eNutPath: 'traits',
      objFind: ({ traitName }) => traitName.toLowerCase() === 'sesame seeds',
      objField: 'levelOfContainment',
      allergenCode: 'AS',
      eNutValue: 'CODE_30',
      eNutName: 'Sesame',
      traitName: 'Sesame seeds',
      check: findInObjectArray
    },
    {
      osdAttribute: 'Sesame Allergen',
      osdPick: 'Undeclared',
      eNutPath: 'traits',
      objFind: ({ traitName }) => traitName.toLowerCase() === 'sesame seeds',
      objField: 'levelOfContainment',
      allergenCode: 'AS',
      eNutValue: 'UNDECLARED',
      eNutName: 'Sesame',
      traitName: 'Sesame seeds',
      check: findInObjectArray
    }
  ],
  general: [
    {
      osdAttribute: 'Child Nutrition Eligibility',
      osdPick: 'Product Formulation Statement',
      objField: 'sleHasProductFormulationStmt',
      eNutValue: 'true',
      check: findDirectPicklistValue
    },
    {
      osdAttribute: 'Child Nutrition Eligibility',
      osdPick: 'Usda Child Nutrition (Cn) Labeling Program',
      objField: 'isChildNutrition',
      eNutValue: 'true',
      check: findDirectPicklistValue
    }
  ]
};

const getNutritionObjectKey = name => {
  if (isAllergenAttribute({ name })) return 'al';
  if (isDietTypeAttribute({ name })) return 'dt';
  if (isClaimAttribute({ name })) return 'dt';
  return 'general';
};

const findTaxonomyAttr = (attributes, attrName) => {
  return _.find(attributes, ({ name }) => name.toLowerCase() === attrName.toLowerCase());
};

const findTaxonomyPick = (attr, pickName) => {
  return _.find(attr.picklist, ({ value }) => value.toLowerCase() === pickName.toLowerCase());
};

const getNutritionRelatedOsdValue = (attribute, nutritions, taxonomyAttributes) => {
  let isEqualBothBalues = false;
  const mappingObjectKey = getNutritionObjectKey(attribute);
  if (mappingObjectKey && mappingObject[mappingObjectKey]) {
    try {
      mappingObject[mappingObjectKey]
        .filter(({ osdAttribute }) => osdAttribute === attribute)
        .forEach(row => {
          let { isMatch, existingObject } = row.check(nutritions, row);
          if (isMatch && existingObject) {
            const taxonmyAttr = findTaxonomyAttr(taxonomyAttributes, row.osdAttribute);
            if (taxonmyAttr) {
              if (existingObject[row.objField] === row.eNutValue) {
                const taxonmyPick = findTaxonomyPick(taxonmyAttr, row.osdPick);
                if (taxonmyPick && taxonmyPick.id === taxonmyAttr.selected) {
                  isEqualBothBalues = true;
                  throw new Error();
                }
              }
            }
          }
        });
    } catch (error) {}
  }
  return isEqualBothBalues;
};

const changeTaxonomyNutrition = selectedItem => {
  let { nutritions, taxonomy } = selectedItem;
  let changedAttrs = [];

  ['cl', 'dt', 'al'].forEach(attrType => {
    mappingObject[attrType].forEach(row => {
      let { isMatch } = row.check(nutritions, row);
      if (isMatch) {
        let taxonmyAttr = findTaxonomyAttr(taxonomy.attributes, row.osdAttribute);
        if (taxonmyAttr) {
          let taxonmyPick = findTaxonomyPick(taxonmyAttr, row.osdPick);
          if (taxonmyPick && taxonmyPick.id !== taxonmyAttr.selected) {
            changedAttrs.push({
              attrId: taxonmyAttr.attrId,
              selected: taxonmyPick.id.toString()
            });
          }
        }
      }
    });
  });

  return changedAttrs;
};

const changeTaxonomyToNutrition = selectedItem => {
  const { nutritions, taxonomy = {} } = selectedItem;
  let traits = [];
  let claims = [];
  let organicTradeItemCode = null;

  const findAllergens = name => {
    return mappingObject['al'].filter(({ osdAttribute }) => osdAttribute.toLowerCase() === name.toLowerCase());
  };

  const findDietTypes = name => {
    return mappingObject['dt'].filter(({ osdAttribute }) => osdAttribute.toString() === name.toString());
  };

  const findClaims = name => {
    return mappingObject['cl'].filter(({ osdAttribute }) => osdAttribute.toString() === name.toString());
  };

  taxonomy &&
    taxonomy.attributes.forEach(eachAttribute => {
      const isAllergen = isAllergenAttribute(eachAttribute);
      const isDietType = isDietTypeAttribute(eachAttribute);
      const isClaim = isClaimAttribute(eachAttribute);

      // filter out allergens
      if (isAllergen) {
        const allergensForAttribute = findAllergens(eachAttribute.name);
        allergensForAttribute &&
          allergensForAttribute.every(eachAllergen => {
            const { isMatch, existingObject } = eachAllergen.check(nutritions, eachAllergen);
            const taxonomyPick = findTaxonomyPick(eachAttribute, eachAllergen.osdPick);
            if (isMatch) {
              if (taxonomyPick && taxonomyPick.id === eachAttribute.selected) {
                const index = _.findIndex(traits, { allergenCode: eachAllergen.allergenCode });
                traits = [
                  ...updateNutritionTraits(traits, index, existingObject, eachAllergen, eachAttribute, taxonomyPick)
                ];
                return false;
              } else {
                return true;
              }
            } else {
              traits = [
                ...getTaxonomyPickedTraits(taxonomyPick, eachAttribute, traits, {
                  levelOfContainment: eachAllergen.eNutValue,
                  allergenCode: eachAllergen.allergenCode,
                  name: eachAllergen.eNutName,
                  traitName: eachAllergen.traitName,
                  isExisting: false,
                  osdAttributeName: eachAllergen.osdAttribute
                })
              ];
              return true;
            }
          });
      }

      // filter out diet types
      if (isDietType) {
        const dietTypesForAttribute = findDietTypes(eachAttribute.name);
        dietTypesForAttribute &&
          dietTypesForAttribute.forEach(eachDietType => {
            const { isMatch, existingObject } = eachDietType.check(nutritions, eachDietType);
            const taxonomyPick = findTaxonomyPick(eachAttribute, eachDietType.osdPick);
            if (isMatch) {
              if (taxonomyPick.id === eachAttribute.selected) {
                const index = _.findIndex(traits, { dietTypeCode: eachDietType.dietTypeCode });
                traits = [...updateNutritionTraitsDietTypes(traits, index, eachDietType, existingObject)];
              }
            } else {
              traits = [
                ...getTaxonomyPickedTraits(taxonomyPick, eachAttribute, traits, {
                  dietTypeCode: eachDietType.dietTypeCode,
                  name: eachDietType.osdPick,
                  traitName: eachDietType.traitName,
                  isExisting: false,
                  osdAttributeName: eachDietType.osdAttribute
                })
              ];
            }
          });
      }

      // filter out claims
      if (isClaim) {
        const claimsForAttribute = findClaims(eachAttribute.name);
        claimsForAttribute &&
          claimsForAttribute.forEach(eachClaim => {
            const { isMatch, existingData } = eachClaim.check(nutritions, eachClaim);
            const taxonomyPick = findTaxonomyPick(eachAttribute, eachClaim.osdPick);
            if (isMatch) {
              if (!eachClaim.isForTradeItemCodes) {
                if (taxonomyPick && taxonomyPick.id === eachAttribute.selected) {
                  const index = _.findIndex(claims, {
                    nutritionalClaimElementCode: eachClaim.nutritionalClaimElementCode
                  });
                  claims = [...updateNutritionClaims(claims, index, existingData, eachClaim)];
                }
              }
            } else {
              const { updatedClaims, updatedOrganicTradeItemCode } = getTaxonomyPickedClaimsAndOrganicStatus(
                claims,
                taxonomyPick,
                eachAttribute,
                eachClaim,
                existingData,
                organicTradeItemCode
              );
              claims = [...updatedClaims];
              organicTradeItemCode = updatedOrganicTradeItemCode;
            }
          });
      }
    });
  return {
    traits,
    nutritionalClaimsDetails: [...new Set(claims)],
    organicTradeItemCodes: organicTradeItemCode ? [organicTradeItemCode] : []
  };
};

export default { changeTaxonomyNutrition, changeTaxonomyToNutrition, getNutritionRelatedOsdValue };
