import { all, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { getRequest, patchRequest, postRequest, putRequest, imagesUploadRequest } from '_http';
import {
  USER,
  ITEMS,
  ITEMMASTER,
  ITEMTAXO,
  ITEMIMAGE,
  IMAGEDELETE,
  ITEMFNB,
  TAXOSAVE,
  SENDCOMMENTS,
  COMMENTS,
  UPDATECOMMENT,
  SENDCOMMENT,
  ITEMCOMMENTS,
  ITEMSCOUNT,
  SCORES,
  SUMMARY,
  IMAGESTYLE,
  IMGLOADING,
  IMGCOMMENT,
  FEEDBACK,
  IMGRESERVATION,
  IMAGEFEED,
  SUPCBASIC,
  VENDORS,
  SUBSUVC,
  FNBCOMMENTS,
  EDITATTRIBUTE,
  UPDATEFNBCOMMENT,
  REJECTEDSUPCS,
  OVERALLSUMMARY,
  NUTRITIONS,
  UPDATENUTRITIONS,
  UPDATEIMAGECOMMENT,
  AUTOSAVE,
  UPDATEIMAGESTYLE,
  UPDATEFEEDBACKSORTFILTERS,
  UPDATEATTRIBCOMMENT,
  COMPLEXGRAPHRENDERING,
  FEEEXCLUSIONSBYSUPC,
  FEEEXCLUSIONSCOUNT,
  UPDATENUTRITIONDATA,
  NUTRITIONQUESTIONS,
  NUTRITIONAPPLICABLESTATUS,
  UPDATENUTRITIONAPPLICABLESTATUS,
  PENDINGNUTRITIONS,
  CANCELNUTRITIONUPDATE,
  TAXOQUITESAVE,
  FNBMASSEDITINITIALDATA,
  FNBMASSEDIT,
  VENDORSSILENT,
  GDSNFNBCOMMENTS,
  GDSNFNB,
  COREDATA,
  COREDATACOMMENT,
  SENDCOREDATACOMMENT,
  GETITEMDETAILS,
  PGMITEMS,
  UPDATEVISIBILITY,
  FORCEDVISIBLESUPCS,
  SELFASSIGNCOMMENT,
  COREATTRIBUTEHISTORY,
  HANDLEAALREADYASSIGNEDCOREDATAISSUE,
  MDMITEMMASTER,
  SUBMITPREFERREDIMAGES,
  PREFERREDIMAGECOMMENT,
  APPROVEPREFERREDIMAGE,
  REJECTPREFERREDIMAGE,
  FETCHOVERALLITEMFEEDBACKHISTORY,
  SUBMITOVERALLFEDBACK,
  UPDATEALLOVERALLHISTORY,
  FETCHITEMCOMMENTSOVERALLHISTORY,
  FETCHITEMVIEWEXCLUSIONS,
  COODATAPUSHED,
  ITEMCOO,
  ITEMSSUMMARY,
  HANDLECNREMOVALSILENCE,
  PFAS,
  PFASUPDATE,
  FSMA,
  SAVEFSMACHANGES
} from 'actions/actionTypes';
import { includes, split } from 'lodash';

import * as _ from 'lodash';
import { Modal } from 'antd';
import openNotification from 'components/Notification';
import { action } from 'reduxHelpers';
import {
  getValuesFromTwoFields,
  checkStatusWithTwoAndConditions,
  checkIsNotEmptyAndAppendUri,
  getSpreadedArrayOrDefault,
  checkIsNotNullAndAppendUri,
  getValueBasedOnTheCondition,
  getFieldDisabledStatusByTwoParams,
  checkValueAndAppendUrl,
  checkValueAndAppendUrlWithReplacements,
  assignFilterValues,
  checkAllTableFiltersWithTableFilter,
  checkAllTableFiltersWithTableSearch,
  assignDefaultPageNo,
  initializeNutritionData,
  checkIsForNewNutritionFeedback,
  getDefaultFiltersForComments,
  devideTaxonomyAndSynchedTaxonomyChanges,
  parseAsJson,
  checkForExistingAndCurrentAttributeValueChangesAreNotEqual,
  getNutritionalFeedbackStatus,
  jsonParseReturnEmptyObject,
  filterCommentsByType,
  getLastFeedbackForHistory,
  formatCooStepParam,
  getRelevantPickListValuesForAttr,
  checkIfPfasHasChanges,
  formatPfasRequest,
  getDefaultValuesForPfas,
  getDefaultValueForPfasClaim,
  checkIfTraitsAreChanges
} from '../util/Util';
import { createIndexedDbConnection } from '../util/Connection';
import {
  CORE,
  ENUT_EXCLUDED_BUSINESS_CENTERS,
  CHILD_NUTRITION_ELIGIBILITY,
  CHILD_NUTRITION_RELATED_FIELDS,
  CN_PFS_EMPTY_FIELDS
} from '../util/Constants';
import { watchMassImageUploadDataAsync, watchAttributeMassEditAsync, watchPfasMassEditAsync } from './MassUpdateSaga';
import { ACTIVITY_TYPE_KEY, emptyCooCountryData, ACTIVITY_CODE_KEY } from 'util/CoreDataConstants';
import { imageCategories } from 'util/Data';

const adPrefix = process.env.REACT_APP_AD_PREFIX;
const oktaPrefix = process.env.REACT_APP_OKTA_PREFIX;

const getUserId = username => {
  if (includes(username, adPrefix)) {
    return split(username, '@')[0].replace(adPrefix, '');
  }
  const oktaEx = new RegExp(oktaPrefix, 'ig');
  return username.replace(oktaEx, '');
};

function* loadUserAsync({ userId }) {
  try {
    const response = yield getRequest(`/user/${getUserId(userId)}?app_name=ITMAPP`);
    yield put({ type: USER.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: USER.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: "COULDN'T LOAD YOUR DATA"
      })
    );
  }
}

const getBrandFiltersIndex = filters => _.findIndex(filters, { param: 'brand' });

function* loadCommentsAsync({
  fType,
  fStatus = [],
  page,
  search,
  tableFilters = null,
  tableSearchData = null,
  brandGroupFilters = [],
  orderBy = null,
  dateRangeFilter,
  isCoreDataManager,
  isSbsUser,
  isLoadingFirstTime,
  isHidingProprietaryFeedbacksPermission
}) {
  try {
    let pageNumber = page;
    const brandFilters = getValuesFromTwoFields(brandGroupFilters, []);
    let allTableFilters = getSpreadedArrayOrDefault(tableFilters);
    const { defaultFilters, defaultOrderBy } = getDefaultFiltersForComments(
      allTableFilters,
      isSbsUser,
      isCoreDataManager,
      isHidingProprietaryFeedbacksPermission,
      orderBy,
      isLoadingFirstTime
    );

    orderBy = defaultOrderBy;

    if (isSbsUser && checkStatusWithTwoAndConditions(defaultFilters, !_.isEmpty(defaultFilters))) {
      allTableFilters = [...defaultFilters];
    }

    const brandFilterIndex = getBrandFiltersIndex(allTableFilters);

    if (tableSearchData && !_.isEmpty(tableSearchData)) {
      allTableFilters = [...allTableFilters, ...tableSearchData];
    }

    if (checkStatusWithTwoAndConditions(brandFilters, !_.isEmpty(brandFilters))) {
      _.forEach(brandFilters, elem => {
        if (brandFilterIndex > -1) {
          allTableFilters.splice(brandFilterIndex, 1, { param: 'brand', val: [elem] });
        } else {
          allTableFilters.push({ param: 'brand', val: [elem] });
        }
      });
    } else {
      if (brandFilterIndex > -1) {
        allTableFilters.splice(brandFilterIndex, 1);
      }
    }

    if (checkStatusWithTwoAndConditions(dateRangeFilter, !_.isEmpty(dateRangeFilter))) {
      if (!isSbsUser) allTableFilters.push(dateRangeFilter);
      else {
        const rangeIndex = _.findIndex(allTableFilters, { param: dateRangeFilter.param });
        if (rangeIndex > -1) {
          allTableFilters.splice(rangeIndex, 1, { ...allTableFilters[rangeIndex], operator: 'range' });
        } else {
          allTableFilters.push(dateRangeFilter);
        }
      }
    }

    if (!pageNumber) {
      pageNumber = 1;
    }
    let uri = `?page=${pageNumber}`;
    uri = checkIsNotEmptyAndAppendUri(allTableFilters, 'tablefilter', uri);
    uri = checkIsNotEmptyAndAppendUri(orderBy, 'orderby', uri);
    if (search) {
      uri = `${uri}&search=${encodeURIComponent(search.replace(/%/, '###'))}`;
    }
    if (checkStatusWithTwoAndConditions(fType, fType !== undefined)) {
      uri = `${uri}&type=${encodeURIComponent(fType)}`;
    }
    uri = checkIsNotNullAndAppendUri(fStatus, 'status', uri);

    const response = yield getRequest(`/dash/comments${uri}`);

    let commentsData = [];
    let distinctSupcs = [];
    const imageCommentIds = [];
    const fnbCommentIds = [];
    if (response.data.comments.length > 0) {
      commentsData = response.data.comments;
      distinctSupcs = _.uniq(_.map(commentsData, 'supc'));
      commentsData.forEach(comment => {
        if (['IMAGE', 'ATRBYTIMG'].includes(comment.type)) {
          imageCommentIds.push(comment.id);
        }
        if (['FnB', 'ATRBYTFNB'].includes(comment.type)) {
          fnbCommentIds.push(comment.id);
        }
      });
    }

    yield put({
      type: UPDATEFEEDBACKSORTFILTERS.REQUEST,
      tableFilters: isSbsUser ? [...allTableFilters] : tableFilters,
      tableSearchData,
      brandGroupFilters,
      orderBy,
      fStatus,
      dateRangeFilter
    });

    yield put({
      type: COMMENTS.MERGE,
      payload: commentsData,
      supcs: _.join(distinctSupcs, '|'),
      recordCount: response.data.count,
      imageCommentIds: _.join(imageCommentIds, ',')
    });

    yield put({
      type: FNBCOMMENTS.REQUEST,
      fnbCommentIds: _.join(fnbCommentIds, ',')
    });
  } catch (error) {
    yield put({ type: COMMENTS.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: "COULDN'T LOAD YOUR DATA"
      })
    );
  }
}

function* sendImageResRequestAsync({ resIds }) {
  let data = [];
  try {
    const response = yield getRequest(`/filereservations/${resIds.join(',')}`);
    data = response.data;
  } catch (e) {
    console.log('file reservation get request failed');
  }
  yield put({ type: IMGRESERVATION.SUCCESS, payload: data });
}

function* sendImageCommentRequestAsync({ commentIds }) {
  try {
    let data = [];
    if (commentIds.length > 0) {
      const response = yield getRequest(`/dash/imagecomments/${commentIds}`);
      data = response.data;
    }
    yield put({ type: IMGCOMMENT.SUCCESS, payload: data });
    const resIds = [];
    if (data && data.length > 0) {
      data.forEach(imageComment => {
        if (['DELETE_REPLACE', 'CREATE'].includes(imageComment.changeType)) {
          resIds.push(imageComment.reservationId);
        }
      });
    }
    if (resIds.length > 0) {
      yield put({
        type: IMGRESERVATION.REQUEST,
        resIds
      });
    }
  } catch (error) {
    yield put({ type: IMGCOMMENT.FAILURE, payload: error.message });
  }
}

function* loadCommentsInfoMergeAsync({ supcs, imageCommentIds }) {
  try {
    let data = [];
    if (supcs.length > 0) {
      const response = yield getRequest(`/dash/details?supcs=${encodeURI(supcs)}`);
      data = response.data;
    }
    yield put({ type: COMMENTS.SUCCESS, payload: data });
    if (imageCommentIds.length > 0) {
      yield put({
        type: IMGCOMMENT.REQUEST,
        commentIds: imageCommentIds
      });
    }
  } catch (error) {
    yield put({ type: COMMENTS.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: "COULDN'T LOAD YOUR DATA"
      })
    );
  }
}

function* updateCommentAsync({ commentId }) {
  try {
    commentId = commentId.commentId ? commentId.commentId : commentId;
    const response = yield patchRequest(`/dash/comments/${commentId}/status/30`);
    if (response.data && response.data.error === true) {
      yield put({ type: UPDATECOMMENT.FAILURE, payload: response.data.message });
      yield put(
        action('SHOW_NOTIFICATION', {
          description: response.data.message,
          className: 'error',
          message: "COULDN'T APPROVE"
        })
      );
    } else {
      yield put({ type: UPDATECOMMENT.SUCCESS, payload: response.data });
    }
  } catch (error) {
    yield put({ type: UPDATECOMMENT.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: "COULDN'T UPDATE THE STATUS OF THE COMMENT"
      })
    );
  }
}

function* updateAttribCommentAsync({ commentId, comment }) {
  try {
    commentId = commentId.commentId ? commentId.commentId : commentId;

    let postData = {
      comment,
      commentID: commentId,
      status: 30
    };

    const response = yield patchRequest(`/dash/comments/${commentId}/status/30`, postData);
    if (response.data && response.data.error === true) {
      yield put({ type: UPDATECOMMENT.FAILURE, payload: response.data.message });
      yield put(
        action('SHOW_NOTIFICATION', {
          description: response.data.message,
          className: 'error',
          message: "COULDN'T APPROVE"
        })
      );
    } else {
      yield put({ type: UPDATECOMMENT.SUCCESS, payload: response.data });
    }
  } catch (error) {
    yield put({ type: UPDATECOMMENT.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: "COULDN'T UPDATE THE STATUS OF THE COMMENT"
      })
    );
  }
}

function* updateFnBCommentAsync({ commentId, comment, updatedData, status, invalid }) {
  if (invalid) {
    yield put({ type: UPDATEFNBCOMMENT.FAILURE });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: 'Comments cannot be empty',
        className: 'error',
        message: "COULDN'T UPDATE THE STATUS OF THE FNB COMMENT"
      })
    );
  } else {
    try {
      let postData = null;
      updatedData = updatedData.map(itm => {
        return { ...itm, comment: itm.comment ? itm.comment : '.' };
      });

      if (updatedData) {
        postData = {
          comment,
          commentID: commentId,
          review: updatedData,
          status
        };
      }

      const response = yield patchRequest(`/dash/comments/${commentId}/status/${status}`, postData);

      if (_.get(response.data, 'error', false)) {
        throw Error("COULDN'T UPDATE THE STATUS OF THE FNB COMMENT");
      } else {
        yield put({ type: UPDATEFNBCOMMENT.SUCCESS, payload: { commentId, updatedData, status } });
        yield put(
          action('SHOW_NOTIFICATION', {
            description: 'UPDATED THE COMMENT STATUS!',
            className: 'info',
            message: 'SUCCESS'
          })
        );
      }
    } catch (error) {
      yield put({ type: UPDATEFNBCOMMENT.FAILURE });
      yield put(
        action('SHOW_NOTIFICATION', {
          description: error.message,
          className: 'error',
          message: "COULDN'T UPDATE THE STATUS OF THE FNB COMMENT"
        })
      );
    }
  }
}

function* isImageCurrentlyPreferred({ assetId, supc, currentStyle, changeType }) {
  if (changeType !== 'STYLE_CHANGE') return false;

  try {
    const currentStyleBucket = _.findKey(imageCategories, value => _.includes(value.styles, _.toLower(currentStyle)));
    const response = yield getRequest(`/dash/comments/preferredimage?supc=${supc}&stylebucket=${currentStyleBucket}`);
    const target = response.data && _.find(response.data, obj => obj.assetId === assetId);
    return _.get(target, 'isPreferred', false);
  } catch (error) {
    console.log(error);
  }
}

function* updateImageCommentAsync({ commentId, comment, updatedData, status, preferredImgData }) {
  const isCurrentlyPreferred = isImageCurrentlyPreferred(preferredImgData);
  try {
    let postData = null;
    if (status !== 30 && updatedData) {
      postData = {
        comment,
        Id: commentId,
        review: [{ ...updatedData }],
        status
      };
    }

    const response = yield patchRequest(`/dash/comments/${commentId}/status/${status}`, postData);
    if (response.data && response.data.error === true) {
      yield put({ type: UPDATEIMAGECOMMENT.FAILURE, payload: response.data.message });
      yield put(
        action('SHOW_NOTIFICATION', {
          description: response.data.message,
          className: 'error',
          message: "COULDN'T UPDATE THE STATUS OF THE IMAGE COMMENT"
        })
      );
    } else {
      yield put({ type: UPDATEIMAGECOMMENT.SUCCESS, payload: { commentId, updatedData, status } });
      yield put(
        action('SHOW_NOTIFICATION', {
          description: 'UPDATED THE COMMENT STATUS!',
          className: 'info',
          message: 'SUCCESS'
        })
      );

      if (status === 30) {
        const { changeType } = preferredImgData;

        if (changeType === 'CREATE') {
          yield defaultPreferredImageRequest(response.data, preferredImgData);
        }

        if (changeType === 'STYLE_CHANGE' && isCurrentlyPreferred) {
          yield handlePreferredImageOnStyleChange(preferredImgData, commentId);
        }
      }
    }
  } catch (error) {
    yield put({ type: UPDATEIMAGECOMMENT.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: "COULDN'T UPDATE THE STATUS OF THE IMAGE COMMENT"
      })
    );
  }
}

function* loadItemListAsync({
  suvc,
  page,
  search,
  subSuvc,
  graphfilter,
  supc,
  getHierarchy,
  fnbRejectFilterOn,
  imageRejectFilterOn,
  feeFilterOn,
  isAutoSavedFnbFilterOn,
  tableFilters,
  tableSearchData,
  brandGroupFilters,
  freeSearch,
  orderBy,
  nutritionRejectFilterOn,
  coreDataRejectFilterOn
}) {
  fnbRejectFilterOn = getFieldDisabledStatusByTwoParams(fnbRejectFilterOn, false);
  imageRejectFilterOn = getFieldDisabledStatusByTwoParams(imageRejectFilterOn, false);
  feeFilterOn = getFieldDisabledStatusByTwoParams(feeFilterOn, false);
  isAutoSavedFnbFilterOn = getFieldDisabledStatusByTwoParams(isAutoSavedFnbFilterOn, false);
  brandGroupFilters = getFieldDisabledStatusByTwoParams(brandGroupFilters, []);
  nutritionRejectFilterOn = getFieldDisabledStatusByTwoParams(nutritionRejectFilterOn, false);
  if (!(orderBy && orderBy.val !== false)) {
    orderBy = null;
  }

  let allTableFilters = [];

  const { allTableFiltersAfterCheck, tableFiltersAfterCheck } = checkAllTableFiltersWithTableFilter(
    allTableFilters,
    tableFilters
  );
  allTableFilters = [...allTableFiltersAfterCheck];
  tableFilters = tableFiltersAfterCheck;

  const { allTableAfterCheck, allSearchFiltersAfter } = checkAllTableFiltersWithTableSearch(
    allTableFilters,
    tableSearchData
  );
  allTableFilters = allTableAfterCheck;
  tableSearchData = allSearchFiltersAfter;

  if (checkStatusWithTwoAndConditions(brandGroupFilters, !_.isEmpty(brandGroupFilters))) {
    _.forEach(brandGroupFilters, elem => {
      switch (elem) {
        case 'sysco':
          allTableFilters.push({ param: 'syscoBrand', val: ['Y'] });
          break;
        case 'packer':
          allTableFilters.push({ param: 'brandCode', val: ['PACKER'] });
          break;
        case 'national':
          allTableFilters.push({ param: 'national', val: ['national'] });
          break;
        default:
          break;
      }
    });
  } else if (checkStatusWithTwoAndConditions(graphfilter, !_.isEmpty(graphfilter))) {
    allTableFilters = [...assignFilterValues(allTableFilters, graphfilter)];
  }

  try {
    page = assignDefaultPageNo(page);
    let uri = `?page=${page}`;
    uri = checkValueAndAppendUrlWithReplacements(search, 'search', uri);

    if (supc) {
      const supcs = getValueBasedOnTheCondition(_.isArray(supc), _.join(supc, '|'), supc);
      uri = `${uri}&supc=${encodeURIComponent(supcs.replace(/%/, '###'))}`;
    }

    uri = checkValueAndAppendUrlWithReplacements(subSuvc, 'sub_suvc', uri);

    uri = checkValueAndAppendUrl(graphfilter, 'graphfilter', uri);

    uri = checkIsNotEmptyAndAppendUri(allTableFilters, 'tablefilter', uri);

    uri = checkIsNotEmptyAndAppendUri(orderBy, 'orderby', uri);

    const response = yield getRequest(`/suvc/${suvc}/items${uri}`);
    const recordCount = response.data.count;
    let {
      data: { items }
    } = response;

    if (freeSearch && !_.isEmpty(items)) {
      yield getSummaryBySupcsAsync({ items });
    }

    // Filter out supplier_visibile = 0 items and update unsaved fnb items list
    if (isAutoSavedFnbFilterOn) {
      const { visibleItems, notVisible } = yield filterItemsByItemVisibility(items);
      items = visibleItems;

      if (!_.isEmpty(notVisible)) {
        yield put(
          action('SHOW_NOTIFICATION', {
            description: 'Some of the items are currently not showed in the Application',
            className: 'info',
            message: 'Notice'
          })
        );
      }
    }

    yield put({
      type: ITEMS.SUCCESS,
      payload: {
        items,
        fnbRejectFilterOn,
        imageRejectFilterOn,
        feeFilterOn,
        isAutoSavedFnbFilterOn,
        tableFilters,
        tableSearchData,
        brandGroupFilters,
        orderBy,
        freeSearch,
        nutritionRejectFilterOn,
        coreDataRejectFilterOn
      }
    });
    if (recordCount) {
      yield put({ type: ITEMSCOUNT.SUCCESS, payload: parseInt(recordCount) });
    }

    if (allTableFilters.length > 0) {
      const nonSyscoOrBrand = allTableFilters.filter(({ param }) => !['syscoBrand', 'brandName'].includes(param));
      yield put({
        type: COMPLEXGRAPHRENDERING.REQUEST,
        payload: getValueBasedOnTheCondition(nonSyscoOrBrand.length > 0, true, false)
      });
    } else {
      yield put({ type: COMPLEXGRAPHRENDERING.REQUEST, payload: false });
    }

    if (getHierarchy) {
      try {
        const subSuvcResponse = yield getRequest(`/subsuvc/${suvc}`);
        yield put({ type: SUBSUVC.SUCCESS, payload: subSuvcResponse.data });
      } catch (subSuvcError) {
        console.log('No sub-suvcs');
      }
    }
  } catch (error) {
    yield put({ type: ITEMS.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'ITEMS LOADING ERROR'
      })
    );
  }
  yield put({ type: ITEMFNB.TOOLTIP_REQUEST });
}

function* loadItemSupcAsync({ suvc, supc }) {
  try {
    const response = yield getRequest(`/suvc/${suvc}/items?page=1&search=${supc}`);
    const items = _.get(response, 'data.items', []);
    const item = _.find(items, obj => obj.supc === supc);

    if (item) {
      yield put({ type: ITEMMASTER.REQUEST, item, supc, suvc });
      yield put({ type: ITEMCOO.REQUEST, supc, syscoBrand: item.syscoBrand, gdsn: item.gdsn });
    } else {
      yield put({ type: ITEMMASTER.FAILURE, payload: 'Item not found' });
    }
  } catch (error) {
    yield put({ type: ITEMMASTER.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message ? error.message : 'Please refresh.',
        className: 'error',
        message: error.message ? 'FAILED' : 'SESSION EXPIRED'
      })
    );
  }
}

function* loadItemAsync({ stepId, suvc, supc }) {
  try {
    let masterPayload = [];
    try {
      const response = yield getRequest(`/suvc/${suvc}/items/${supc}/master`);
      masterPayload = response.data;
    } catch (masterErr) {
      yield put(
        action('SHOW_NOTIFICATION', {
          description: 'Product Core Data loading error!',
          className: 'error',
          message: 'SOMETHING WENT WRONG'
        })
      );
    }
    yield put({ type: ITEMMASTER.SUCCESS, payload: masterPayload });

    try {
      const comments = yield getRequest(`/suvc/${suvc}/items/${supc}/comments`);

      if (comments.data && Array.isArray(comments.data)) {
        yield put({ type: ITEMCOMMENTS.SUCCESS, payload: comments.data });
        const pendingCoreDataComment = comments.data.filter(
          ({ field, status: commentStatus }) => field === 'CORE' && [0, 10, 20, 40].includes(commentStatus)
        );
        if (pendingCoreDataComment && pendingCoreDataComment.length > 0) {
          const commentData = {
            ...pendingCoreDataComment[0],
            comment: JSON.parse(pendingCoreDataComment[0].comment)
          };
          yield put({ type: COREDATA.REQUEST, payload: commentData });
        }

        yield put({ type: FETCHITEMCOMMENTSOVERALLHISTORY.REQUEST, comments: comments.data });
      } else {
        yield put({ type: ITEMCOMMENTS.SUCCESS, payload: [] });
      }
    } catch (commentError) {
      yield put({ type: ITEMCOMMENTS.SUCCESS, payload: [] });
    }

    yield put({ type: ITEMTAXO.REQUEST, supc, suvc });
    yield put({ type: ITEMIMAGE.REQUEST, supc, suvc });
    yield put({ type: ITEMFNB.REQUEST, supc, suvc });
    yield put({ type: NUTRITIONS.REQUEST, supc }); // comment this line when disabling enut application
    yield put({ type: NUTRITIONAPPLICABLESTATUS.REQUEST, supc });
    yield put({ type: PENDINGNUTRITIONS.REQUEST, supc });
    yield put({ type: FETCHITEMVIEWEXCLUSIONS.REQUEST, supc, suvc });
    yield put({ type: FSMA.REQUEST, supc });
  } catch (error) {
    yield put({ type: ITEMMASTER.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: "We couldn't find item details.",
        className: 'error',
        message: 'SOMETHING WENT WRONG'
      })
    );
  }
}

function* loadTaxonomyAsync({ supc, suvc }) {
  try {
    const response = yield getRequest(`/suvc/${suvc}/items/${supc}/taxonomy`);
    yield put({ type: ITEMTAXO.SUCCESS, payload: response.data });
    yield put({ type: PFAS.REQUEST, supc });
  } catch (error) {
    yield put({ type: ITEMTAXO.FAILURE, payload: error.message });
  }
}

function* loadImagesAsync({ supc, suvc }) {
  try {
    const response = yield getRequest(`/suvc/${suvc}/items/${supc}/images`);
    yield put({ type: ITEMIMAGE.SUCCESS, payload: response.data.images || [] });
  } catch (error) {
    yield put({ type: ITEMIMAGE.FAILURE, payload: error.message });
  }
}

function* loadFnbAsync({ supc, suvc }) {
  try {
    const response = yield getRequest(`/suvc/${suvc}/items/${supc}/fnbs`);
    yield put({ type: ITEMFNB.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: ITEMFNB.FAILURE, payload: error.message });
  }
}

function* loadFnbTooltipsAsync() {
  try {
    const response = yield getRequest(`/tooltips/fnb`);
    yield put({ type: ITEMFNB.TOOLTIP_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: ITEMFNB.TOOLTIP_FAILURE, payload: error.message });
  }
}

function* sendFnBCommentsAsync({ supc, suvc, basicData, exsistingFnB, fnb }) {
  try {
    const data = {
      ...fnb,
      stepId: basicData.stepId,
      existingFnB: exsistingFnB
    };
    const response = yield putRequest(`/suvc/${suvc}/items/${supc}/fnbs`, data);
    yield put({ type: ITEMFNB.COMMENTS_SUCCESS, payload: response.data, ...{ suvc, supc, fnbUpdate: true } });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: 'Features & Benefits sent!',
        className: 'info',
        message: 'SUCCESS'
      })
    );
    const fnbsResponse = yield getRequest(`/suvc/${suvc}/items/${supc}/fnbs`);
    yield put({ type: ITEMFNB.SUCCESS, payload: fnbsResponse.data });
    const unsavedFnb = deleteFromSupplierTable(suvc, supc);
    if (unsavedFnb) {
      yield put({ type: AUTOSAVE.REQUEST, payload: { autoSavedData: unsavedFnb } });
    }
  } catch (error) {
    yield put({ type: ITEMFNB.COMMENTS_FAILURE, payload: error.message });
  }
}

async function deleteFromSupplierTable(suvc, supc) {
  let allUnsavedFnb = null;
  try {
    const indexedDb = createIndexedDbConnection('item-management');
    await indexedDb.deleteValue('supplier', supc);
    const allData = await indexedDb.getAllValue('supplier');
    if (allData && allData.length > 0) {
      allUnsavedFnb = allData.map(eachData => {
        return eachData.supc;
      });
    }
    return allUnsavedFnb;
  } catch (err) {
    return null;
  }
}

function* sendFnbCommentUpdate({ suvc, supc, fnbUpdate }) {
  if (fnbUpdate) {
    yield put({ type: ITEMCOMMENTS.REQUEST, ...{ suvc, supc } });
  }
}

function* updateCnRemovalInComments({ commentData, childNutritionAttribute }) {
  const { comment, suvc, supc, id } = commentData;
  // update if already existing comment
  if (id) {
    try {
      let { changedFields, taxonomyChanges, ...restCommentData } = JSON.parse(comment);
      changedFields = changedFields.filter(
        eachChangedField =>
          ![
            ...Object.keys(CHILD_NUTRITION_RELATED_FIELDS),
            'isChildNutrition',
            'sleHasProductFormulationStmt'
          ].includes(eachChangedField)
      );

      const { traits, nutritionalClaimsDetails } = restCommentData;
      const hasTraitsChanged = checkIfTraitsAreChanges(traits);
      const hasClaimsChanged = checkIfTraitsAreChanges(nutritionalClaimsDetails);

      let updatedComment = JSON.stringify({
        ...JSON.parse(comment),
        sleHasProductFormulationStmt: false,
        isChildNutrition: false,
        ...CN_PFS_EMPTY_FIELDS,
        changedFields,
        taxonomyChanges: taxonomyChanges.filter(({ attrId }) => attrId != childNutritionAttribute),
        ...CHILD_NUTRITION_RELATED_FIELDS
      });
      // close the comment if no changes are available
      if (!hasClaimsChanged && changedFields && !changedFields.length && !hasTraitsChanged) {
        yield patchRequest(`/dash/comments/${id}/status/90`, {
          commentId: id,
          comment: updatedComment,
          status: 90
        });
      } else {
        yield patchRequest(`/suvc/${suvc}/items/${supc}/comments/${id}`, {
          ...commentData,
          comment: updatedComment
        });
      }

      yield put({ type: PENDINGNUTRITIONS.REQUEST, supc });
    } catch (error) {
      yield put(
        action('SHOW_NOTIFICATION', {
          description: error.message,
          className: 'error',
          message: 'FAILED REMOVING CN/PFS FLAGS'
        })
      );
    }
  }
}

function* handleCnFlagRemoval({
  taxonomyChangesOnlyCn,
  taxonomy,
  oid,
  nutritionSourceOid,
  pendingNutritions,
  isNutritionDetailsNotApplicable
}) {
  if (taxonomyChangesOnlyCn && taxonomyChangesOnlyCn.length) {
    const [{ attrId, selected }] = taxonomyChangesOnlyCn;
    const selectedValue = getRelevantPickListValuesForAttr(taxonomy.attributes, attrId, selected);
    // if not selected value is available for child nutrition field, should pass to netmenu
    if (selectedValue && ['not applicable'].includes(selectedValue.toLowerCase()) && !isNutritionDetailsNotApplicable) {
      if (oid && nutritionSourceOid && nutritionSourceOid == 21) {
        // call the new endpoint to update the flags
        yield patchRequest(`/nutritions/items/cn/${oid}`, {});
      }
      // check if there's a pending nutritional feedback, if so update CN/PFS flags to false there as well
      if (pendingNutritions && pendingNutritions.length) {
        yield updateCnRemovalInComments({ commentData: pendingNutritions[0], childNutritionAttribute: attrId });
      }
      yield put({ type: HANDLECNREMOVALSILENCE.REQUEST });
    }
  }
}

const getChildNutritionFieldValues = (taxonomy, currentTaxonomyChanges) => {
  let childNutritionObject = {};
  // check if taxonomy changes are available
  if (currentTaxonomyChanges && currentTaxonomyChanges.length) {
    // check if taxonomy attributes are available
    if (taxonomy && taxonomy.attributes) {
      // check the CN related taxonomy
      childNutritionObject = _.find(taxonomy.attributes, { name: CHILD_NUTRITION_ELIGIBILITY });
    }
    // check if child nutrition attribute is available
    if (childNutritionObject && childNutritionObject.attrId) {
      // fetch all taxonomy changes except cn
      let taxonomyChangesWithoutCn = currentTaxonomyChanges.filter(
        ({ attrId }) => parseInt(attrId) != parseInt(childNutritionObject.attrId)
      );

      // fetch only cn taxonomy changes
      let taxonomyChangesOnlyCn = _.filter(
        currentTaxonomyChanges,
        obj => parseInt(obj.attrId) === parseInt(childNutritionObject.attrId)
      );

      return { taxonomyChangesOnlyCn: taxonomyChangesOnlyCn, taxonomyChangesWithoutCn };
    }
    return { taxonomyChangesOnlyCn: [], taxonomyChangesWithoutCn: currentTaxonomyChanges };
  }
  return { taxonomyChangesOnlyCn: [], taxonomyChangesWithoutCn: [] };
};

function* saveTaxonomyAsync({
  supc,
  suvc,
  taxonomyComment,
  taxonomyChanges,
  nutritions: { nutritionSourceOid, oid } = {},
  taxonomy,
  pendingNutritions,
  pendingNutrition,
  basicData: { gdsn, syscoBrand } = {},
  isNutritionDetailsNotApplicable
}) {
  try {
    const postData = {
      comment: taxonomyComment,
      attributes: taxonomyChanges
    };
    // handle CN/PFS flag removal with nutritional data
    const { taxonomyChangesOnlyCn } = getChildNutritionFieldValues(taxonomy, taxonomyChanges);
    yield handleCnFlagRemoval({
      taxonomy,
      taxonomyChangesOnlyCn,
      oid,
      nutritionSourceOid,
      pendingNutritions,
      isNutritionDetailsNotApplicable
    });

    const response = yield patchRequest(`/suvc/${suvc}/items/${supc}/taxonomy`, postData);
    yield put({ type: TAXOSAVE.SUCCESS, payload: response.data });
    const { bc: { id } = {} } = taxonomy || { bc: {} };
    // comment below line when disabling enut application
    if (
      checkIsForNewNutritionFeedback(pendingNutritions, pendingNutrition) &&
      !(gdsn === 'Y' || syscoBrand === 'Y' || [23, 31].includes(nutritionSourceOid)) &&
      !ENUT_EXCLUDED_BUSINESS_CENTERS.includes(id)
    )
      Modal.confirm({
        title: 'Please enter the Nutrition Information on the Nutrition tab',
        okText: 'Ok',
        cancelButtonProps: { style: { display: 'none' } },
        onOk: () => {}
      });
  } catch (error) {
    yield put({ type: TAXOSAVE.FAILURE, payload: error.message });
    if (error.response) {
      const { status } = error.response;
      if (500 <= parseInt(status)) {
        yield put(
          action('SHOW_NOTIFICATION', {
            description: 'FAILED SAVING TAXONOMY INFO',
            className: 'error',
            message: '500 Error - Contact OSD Supplier at osdsupplier@corp.sysco.com'
          })
        );
      } else {
        yield put(
          action('SHOW_NOTIFICATION', {
            description: error.message,
            className: 'error',
            message: 'FAILED SAVING TAXONOMY INFO'
          })
        );
      }
    } else {
      yield put(
        action('SHOW_NOTIFICATION', {
          description: error.message,
          className: 'error',
          message: 'FAILED SAVING TAXONOMY INFO'
        })
      );
    }
  }
}

function* saveTaxonomyQuiteAsync({ supc, suvc, taxonomyChanges }) {
  try {
    const postData = {
      comment: '',
      attributes: taxonomyChanges
    };
    const response = yield patchRequest(`/suvc/${suvc}/items/${supc}/taxonomy`, postData);
    yield put({ type: TAXOQUITESAVE.SUCCESS, payload: response.data });
  } catch (error) {}
}

function* sendItemCommentRequestAsync({ suvc, supc }) {
  try {
    const comments = yield getRequest(`/suvc/${suvc}/items/${supc}/comments`);
    if (comments.data && Array.isArray(comments.data)) {
      yield put({ type: ITEMCOMMENTS.SUCCESS, payload: comments.data });
    } else {
      yield put({ type: ITEMCOMMENTS.SUCCESS, payload: [] });
    }
  } catch (commentError) {
    yield put({ type: ITEMCOMMENTS.SUCCESS, payload: [] });
  }
}

function* sendStyleImageCommentAsync({ supc, suvc, image, imageStyle, comment, isWithChangedName }) {
  yield put({ type: IMGLOADING.REQUEST, payload: {} });
  const errorObj = {
    description: '',
    className: 'error',
    message: 'IMAGE UPLOAD FAILED'
  };
  try {
    let commentData = {
      supc,
      suvc,
      comment: comment.comment,
      style: imageStyle,
      oldStyle: comment.originalValue || 'NO STYLE',
      meta: '',
      stepId: comment.stepId,
      otmmAssetId: image.assetId,
      otmmThumbnailRenditionId: image.renditions.thumbnail ? image.renditions.thumbnail.id : '',
      otmmMasterRenditionId: image.renditions.master ? image.renditions.master.id : '',
      otmmPreviewRenditionId: image.renditions.preview ? image.renditions.preview.id : '',
      previousCommentId: comment.previousCommentId || null
    };
    if (image && image.name && image.name !== '' && isWithChangedName)
      commentData = { ...commentData, meta: JSON.stringify({ name: image.name }) };
    const response = yield postRequest(`/suvc/${suvc}/items/${supc}/images/update`, commentData);
    yield put({ type: ITEMIMAGE.COMMENTS_SUCCESS, payload: response.data });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: 'REQUEST SENT!',
        className: 'info',
        message: 'SUCCESS'
      })
    );
  } catch (error) {
    yield put({ type: ITEMIMAGE.COMMENTS_FAILURE, payload: error.message });
    yield put(action('SHOW_NOTIFICATION', { ...errorObj, description: error.message }));
  }
  yield put({ type: IMGLOADING.SUCCESS, payload: {} });
  yield put({ type: ITEMCOMMENTS.REQUEST, ...{ suvc, supc } });
}

function* sendFeedImageCommentAsync(commentObj) {
  const { comment } = commentObj;
  const { suvc, supc, stepId } = comment;
  try {
    comment.type = 'IMAGE';
    const data = {
      comments: [{ ...comment }],
      stepId,
      supc,
      suvc
    };
    let response = null;
    let updatedComments = [];
    response = yield postRequest(`/suvc/${suvc}/items/${supc}/comments`, data);
    updatedComments = response.data.comments;
    yield put({ type: SENDCOMMENT.SUCCESS, payload: updatedComments });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: 'COMMENTS SENT!',
        className: 'info',
        message: 'SUCCESS'
      })
    );
  } catch (error) {
    yield put({ type: SENDCOMMENT.FAILURE, payload: error.message });
  }
  yield put({ type: IMGLOADING.SUCCESS, payload: {} });
  yield put({ type: ITEMCOMMENTS.REQUEST, ...{ suvc, supc } });
}

function* sendDeleteImageCommentAsync(commentObj) {
  const { supc, suvc, image, comment, reason } = commentObj;
  yield put({ type: IMGLOADING.REQUEST, payload: {} });
  const errorObj = {
    description: '',
    className: 'error',
    message: 'IMAGE UPLOAD FAILED'
  };
  try {
    const commentData = {
      supc,
      suvc,
      comment: `Delete image. reason=[${reason}] ${comment.comment}`,
      style: image.imageStyle || 'NO STYLE',
      meta: '',
      otmmAssetId: image.assetId,
      otmmThumbnailRenditionId: image.renditions.thumbnail ? image.renditions.thumbnail.id : '',
      otmmMasterRenditionId: image.renditions.master ? image.renditions.master.id : '',
      otmmPreviewRenditionId: image.renditions.preview ? image.renditions.preview.id : '',
      originalURL: comment.originalValue,
      stepId: comment.stepId,
      previousCommentId: comment.previousCommentId || null
    };
    const response = yield postRequest(`/suvc/${suvc}/items/${supc}/images/delete`, commentData);
    yield put({ type: ITEMIMAGE.COMMENTS_SUCCESS, payload: response.data });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: 'REQUEST SENT!',
        className: 'info',
        message: 'SUCCESS'
      })
    );
  } catch (error) {
    yield put({ type: ITEMIMAGE.COMMENTS_FAILURE, payload: error.message });
    yield put(action('SHOW_NOTIFICATION', { ...errorObj, description: error.message }));
  }
  yield put({ type: IMGLOADING.SUCCESS, payload: {} });
  yield put({ type: ITEMCOMMENTS.REQUEST, ...{ suvc, supc } });
}

function* sendImageCommentsAsync({
  supc,
  suvc,
  image,
  imageStyle,
  givenName,
  comment,
  deletionDetails,
  preferredImageData = {}
}) {
  const { isFirstImage, isPreferred } = preferredImageData;
  const errorObj = {
    description: '',
    className: 'error',
    message: 'IMAGE UPLOAD FAILED'
  };
  yield put({ type: IMGLOADING.REQUEST, payload: {} });
  try {
    console.log('images : ', image);
    const postData = {
      name: getValueBasedOnTheCondition(image.name, image.name, image.uid),
      contentType: image.type,
      extension: /[.]/.exec(image.name) ? /[^.]+$/.exec(image.name)[0] : 'undefined',
      metadata: {},
      size: image.size,
      imageStyle
    };

    const reservationResponse = yield postRequest(`/suvc/${suvc}/items/${supc}/images`, postData, true);

    let error = null;
    if (reservationResponse && reservationResponse.data && reservationResponse.data.id) {
      const bodyFormData = new FormData();
      bodyFormData.append('new_image', image.originFileObj);
      bodyFormData.append('reservation_id', reservationResponse.data.id);
      bodyFormData.append('supc', supc);
      console.log('reservationResponse :', reservationResponse);
      const uploadResponse = yield imagesUploadRequest(`/otmmstream/upload`, bodyFormData);

      let deleteReplace = {};
      if (checkStatusWithTwoAndConditions(deletionDetails, deletionDetails.isDeletion)) {
        const getRenditionIds = renditionType =>
          deletionDetails.image &&
          deletionDetails.image.renditions &&
          deletionDetails.image.renditions[renditionType] &&
          getValueBasedOnTheCondition(
            deletionDetails.image.renditions[renditionType].id,
            deletionDetails.image.renditions[renditionType].id,
            null
          );
        deleteReplace = {
          isDeleteReplace: true,
          assetId: deletionDetails.image.assetId,
          thumbnailRenditionId: getRenditionIds('thumbnail'),
          masterRenditionId: getRenditionIds('master'),
          previewRenditionId: getRenditionIds('preview')
        };
      }

      if (checkStatusWithTwoAndConditions(uploadResponse, uploadResponse.status === 200)) {
        const { stepId } = comment;
        let meta = {
          ...uploadResponse.data.data,
          supc,
          style: imageStyle,
          name: givenName,
          isPreferred
        };

        if (isFirstImage) meta.isFirstImage = true;

        const commentData = {
          supc,
          suvc,
          comment: comment.comment,
          style: imageStyle,
          meta: JSON.stringify(meta),
          reservationId: reservationResponse.data.id,
          stepId,
          previousCommentId: getFieldDisabledStatusByTwoParams(comment.previousCommentId, null),
          ...deleteReplace
        };

        const response = yield postRequest(`/suvc/${suvc}/items/${supc}/images/create`, commentData);
        yield put({ type: ITEMIMAGE.COMMENTS_SUCCESS, payload: response.data });
        yield put(
          action('SHOW_NOTIFICATION', {
            description: 'IMAGE SENT!',
            className: 'info',
            message: 'SUCCESS'
          })
        );
      } else {
        error = getFieldDisabledStatusByTwoParams(uploadResponse.data.msg, 'something went wrong');
      }
    } else {
      error = getFieldDisabledStatusByTwoParams(reservationResponse.data.err, 'something went wrong');
    }

    if (error) {
      yield put({ type: ITEMIMAGE.COMMENTS_FAILURE, payload: error });
      yield put(action('SHOW_NOTIFICATION', { ...errorObj, description: error }));
    }
  } catch (error) {
    yield put({ type: ITEMIMAGE.COMMENTS_FAILURE, payload: error.message });
    yield put(action('SHOW_NOTIFICATION', { ...errorObj, description: error.message }));
  }
  yield put({ type: IMGLOADING.SUCCESS, payload: {} });
  yield put({ type: ITEMCOMMENTS.REQUEST, ...{ suvc, supc } });
}

function* sendCommentsAsync({ supc, suvc, basicData, masterComments }) {
  try {
    const data = {
      supc,
      stepId: basicData.stepId,
      suvc,
      comments: masterComments
    };
    const response = yield postRequest(`/suvc/${suvc}/items/${supc}/comments`, data);
    yield put({ type: SENDCOMMENTS.SUCCESS, payload: response.data });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: 'COMMENTS SENT!',
        className: 'info',
        message: 'SUCCESS'
      })
    );
  } catch (error) {
    yield put({ type: SENDCOMMENTS.FAILURE, payload: error.message });
  }
}

function* handleFetchPendingOverallHistory({ comments }) {
  try {
    const overallComments = filterCommentsByType(comments, 'OVERALL');
    if (overallComments && overallComments.length) {
      const overallHistoryResponse = yield postRequest(`/dash/comments/history/overall-item-status`, {
        commentId: overallComments[0].id
      });
      yield put({ type: FETCHITEMCOMMENTSOVERALLHISTORY.SUCCESS, payload: overallHistoryResponse.data });
    }
  } catch (error) {
    yield put({ type: FETCHITEMCOMMENTSOVERALLHISTORY.FAILURE });
  }
}

function* sendFeedbackAsync({ comment, pendingOverallFeedbackHistory, userData }) {
  try {
    const { suvc, supc, stepId } = comment;
    const data = {
      comments: [{ ...comment }],
      stepId,
      supc,
      suvc
    };
    let response = null;
    let updatedComments = [];
    let overallFeedbackHistory = {
      supc,
      suvc,
      commentId: null,
      changedFrom: comment.comment,
      changedTo: comment.comment,
      statusFrom: -1,
      statusTo: 0,
      commentText: null,
      changedBy: userData.email
    };
    if (comment.id && comment.id > 0) {
      let requestPayload = {
        ...comment,
        status: comment.status === 10 ? 20 : comment.status
      };
      const lastElement = getLastFeedbackForHistory(pendingOverallFeedbackHistory);
      if (lastElement) {
        const { changedTo, statusTo } = lastElement;
        overallFeedbackHistory = {
          supc,
          suvc,
          commentId: comment.id,
          changedFrom: changedTo,
          changedTo: comment.comment,
          statusFrom: statusTo,
          statusTo: statusTo == 10 ? 20 : 0,
          commentText: null,
          changedBy: userData.email
        };
        requestPayload = {
          ...requestPayload,
          overallFeedbackHistory,
          status: statusTo == 10 ? 20 : 0
        };
      }
      response = yield patchRequest(`/suvc/${suvc}/items/${supc}/comments/${comment.id}`, requestPayload);
      updatedComments = [response.data];
      yield put({ type: FETCHITEMCOMMENTSOVERALLHISTORY.REQUEST, comments: updatedComments });
    } else {
      response = yield postRequest(`/suvc/${suvc}/items/${supc}/comments`, { ...data, overallFeedbackHistory });
      updatedComments = response.data.comments;
      yield put({ type: FETCHITEMCOMMENTSOVERALLHISTORY.REQUEST, comments: response.data.comments });
    }
    yield put({ type: FEEDBACK.SUCCESS, payload: updatedComments });
    yield put({ type: SENDCOMMENT.SUCCESS, payload: updatedComments });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: 'Overall Feedback SENT!',
        className: 'info',
        message: 'SUCCESS'
      })
    );
  } catch (error) {
    yield put({ type: FEEDBACK.FAILURE, payload: error.message });
  }
}

function* sendCommentAsync({ comment }) {
  try {
    const { suvc, supc, stepId } = comment;
    const data = {
      comments: [{ ...comment }],
      stepId,
      supc,
      suvc
    };
    let response = null;
    let updatedComments = [];
    if (comment.id && comment.id > 0) {
      response = yield patchRequest(`/suvc/${suvc}/items/${supc}/comments/${comment.id}`, comment);
      updatedComments = [response.data];
    } else {
      console.log('----- sendCommentAsync', data);
      response = yield postRequest(`/suvc/${suvc}/items/${supc}/comments`, data);
      updatedComments = response.data.comments;
    }
    yield put({ type: SENDCOMMENT.SUCCESS, payload: updatedComments });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: 'COMMENTS SENT!',
        className: 'info',
        message: 'SUCCESS'
      })
    );
  } catch (error) {
    yield put({ type: SENDCOMMENT.FAILURE, payload: error.message });
  }
}

function* loadScoresAsync({ suvc }) {
  try {
    const response = yield getRequest(`/scores/all/${suvc}`);
    let scores = [];
    if (response.data.scores) {
      scores = response.data.scores;
    }
    yield put({ type: SCORES.SUCCESS, payload: scores });
  } catch (error) {
    yield put({ type: SCORES.FAILURE, payload: error.message });
  }
}

function* getSummaryAsync({ suvc }) {
  try {
    let page = 0;
    let items = [];
    let response;
    do {
      response = yield getRequest(`/summary/${suvc}?page=${page}`);
      items = [...items, ...response.data];
      page++;
    } while (response.data.length === 1000);
    yield put({ type: SUMMARY.SUCCESS, payload: items });
  } catch (error) {
    yield put({ type: SUMMARY.FAILURE, payload: error.message });
  }
}

function* getAllVendorsAsync() {
  try {
    const response = yield getRequest('/vendors');
    let suppliers = [];
    if (response.data.suppliers) {
      suppliers = response.data.suppliers;
    }
    yield put({ type: VENDORS.SUCCESS, payload: suppliers });
  } catch (error) {
    yield put({ type: VENDORS.FAILURE, payload: error.message });
  }
}

function* showNotificationAsync(notificationAction) {
  const { message, description, className } = notificationAction;
  yield openNotification({ message, description, className });
}

function* loadFnBCommentsAsync({ fnbCommentIds }) {
  try {
    if (fnbCommentIds.length > 0) {
      const response = yield getRequest(`/dash/fnbcomments/${fnbCommentIds}`);
      const data = _.get(response, 'data', []);

      yield put({ type: FNBCOMMENTS.SUCCESS, payload: data });
    } else {
      yield put({ type: FNBCOMMENTS.FAILURE });
    }
  } catch (error) {
    yield put({ type: FNBCOMMENTS.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: "COULDN'T LOAD FNB COMMENTS DATA"
      })
    );
  }
}

function* editFnBAttributeAsync({ suvc, supc, fieldData, callBack }) {
  try {
    const response = yield patchRequest(`/vendors/${suvc}/items/${supc}/fnbs`, fieldData);

    if (response.status === 200) {
      yield put({ type: EDITATTRIBUTE.SUCCESS, payload: { pending: response.data, supc } });
      callBack(true);
    } else {
      throw new Error('Saving attribute changes failed!');
    }
  } catch (error) {
    callBack(false);
    yield put({ type: EDITATTRIBUTE.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: "COULDN'T COMPLETE YOUR REQUEST"
      })
    );
  }
}

function* getRejectedSupcsAsync({ suvc }) {
  try {
    const response = yield getRequest(`/feedback/reject?suvc=${suvc}`);
    yield put({
      type: REJECTEDSUPCS.SUCCESS,
      payload: {
        rejectedFnbs: _.get(response.data, 'fnb', []),
        rejectedImages: _.get(response.data, 'image', []),
        rejectedNutritions: _.get(response.data, 'nutrition', []),
        rejectedCoreData: _.get(response.data, 'core', []),
        rejectedOverall: _.get(response.data, 'overall', [])
      }
    });
  } catch (error) {
    yield put({ type: REJECTEDSUPCS.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: "COULDN'T LOAD REJECTED SUPCS"
      })
    );
  }
}

function* getOverallSummaryAsync({ suvc, graphFilter }) {
  console.log({ suvc, graphFilter });
  try {
    const uri = graphFilter ? `?graphfilter=${encodeURIComponent(graphFilter)}` : `?graphfilter=[]`;
    const response = yield getRequest(`/summary/all/${suvc}${uri}`);

    yield put({ type: OVERALLSUMMARY.SUCCESS, payload: { overallSummary: _.get(response, 'data', {}) } });
  } catch (error) {
    yield put({ type: OVERALLSUMMARY.FAILURE, payload: error.message });
  }
}

function* getNutritionDataAsync({ supc }) {
  try {
    const response = yield getRequest(`/nutritions/${supc}`);
    const formattedNutritions = initializeNutritionData(response.data);
    yield put({ type: NUTRITIONS.SUCCESS, payload: { nutritions: response.data, formattedNutritions } });
  } catch (error) {
    yield put({ type: NUTRITIONS.FAILURE, payload: error.message });
  }
}

function* sendNutritionDataAsync({ updatedData, suvc, supc, stepId, item }) {
  try {
    const {
      pendingNutritions,
      taxonomy: { attributes },
      taxonomyComment
    } = item;
    const { taxonomyChanges } = updatedData;
    const [taxonomyChangesOnly, nutritionSynchedTaxonomyChangesOnly] = devideTaxonomyAndSynchedTaxonomyChanges(
      taxonomyChanges,
      attributes
    );
    const comment = {
      id: null,
      suvc,
      stepId,
      field: 'NUTRITION',
      type: 'NUTRITION',
      comment: JSON.stringify({ ...updatedData, taxonomyChanges: nutritionSynchedTaxonomyChangesOnly }),
      originalValue: ''
    };

    const postData = {
      comments: [comment],
      stepId,
      supc,
      suvc
    };
    if (taxonomyChangesOnly && taxonomyChangesOnly.length) {
      const response = yield patchRequest(`/suvc/${suvc}/items/${supc}/taxonomy`, {
        comment: taxonomyComment,
        attributes: taxonomyChangesOnly
      });
      yield put({ type: TAXOSAVE.SUCCESS, payload: response.data });
    }
    if (pendingNutritions && pendingNutritions.length) {
      const { id, status } = pendingNutritions[0];
      const { newValue, statusText } = getNutritionalFeedbackStatus(status);
      const { changedFields, traits, nutritionalClaimsDetails } = updatedData;
      const hasTraitsChanged = checkIfTraitsAreChanges(traits);
      const hasClaimsChanged = checkIfTraitsAreChanges(nutritionalClaimsDetails);
      // close the comment if no changes are available
      if (!hasClaimsChanged && changedFields && !changedFields.length && !hasTraitsChanged) {
        yield patchRequest(`/dash/comments/${id}/status/90`, {
          commentId: id,
          comment: pendingNutritions[0].comment,
          status: 90
        });
      } else {
        yield patchRequest(`/dash/comments/${id}/status/${newValue}`, {
          commentId: id,
          comment: JSON.stringify({ ...updatedData, feedback: { ...updatedData.feedback, status: statusText } }),
          status: newValue
        });
      }
    } else {
      yield postRequest(`/suvc/${suvc}/items/${supc}/comments`, postData);
    }
    yield put({ type: PENDINGNUTRITIONS.REQUEST, supc });
    yield put({ type: UPDATENUTRITIONS.SUCCESS });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: 'Successfully updated',
        className: 'info',
        message: 'SUCCESS'
      })
    );
  } catch (error) {
    yield put({ type: UPDATENUTRITIONS.FAILURE });
  }
}

function* updateImageStyleAsync({ comment }) {
  try {
    const { id, style, supc, changeType, meta } = comment;
    const response = yield patchRequest(`/supc/${supc}/images/${id}`, { style, changeType, meta });
    yield put({ type: UPDATEIMAGESTYLE.SUCCESS, payload: { comment: response.data } });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: 'IMAGE STYLE UPDATED!',
        className: 'info',
        message: 'SUCCESS'
      })
    );
  } catch (error) {
    yield put({ type: UPDATEIMAGESTYLE.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: "COULDN'T UPDATE IMAGE STYLE"
      })
    );
  }
}

const getFeeExclusionUrl = (suvc, supc) => `/fee/exclusions/suvc/${suvc}/supc/${supc}`;

function* fetchFeeExclusionsBySupcAsync({ suvc, supc }) {
  try {
    const response = yield getRequest(getFeeExclusionUrl(suvc, supc));

    yield put({
      type: FEEEXCLUSIONSBYSUPC.SUCCESS,
      payload: {
        supc,
        feeExclusions: _.get(response, 'data.exclusions', []),
        itemMasterData: _.get(response, 'data.itemData', {})
      }
    });
  } catch (error) {
    yield put({ type: FEEEXCLUSIONSBYSUPC.FAILURE, payload: { supc } });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: "COULDN'T GET FEE EXCLUSION DETAILS"
      })
    );
  }
}

function* fetchFeeExclusionsCountAsync({ suvc }) {
  try {
    const response = yield getRequest(`/fee/exclusions/suvc/${suvc}/count`);

    yield put({
      type: FEEEXCLUSIONSCOUNT.SUCCESS,
      payload: { feeExclusionsSupcs: _.get(response, 'data.supcs', []) }
    });
  } catch (error) {
    yield put({ type: FEEEXCLUSIONSCOUNT.FAILURE });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: "COULDN'T GET FEE EXCLUSIONS COUNT"
      })
    );
  }
}

function* displayNutritionApproveFailureAsync({ message, errorContentObject }) {
  yield put({ type: UPDATENUTRITIONDATA.FAILURE, payload: message });
  yield put(action('SHOW_NOTIFICATION', errorContentObject));
}

function* updateNutritionCommentAsync({
  commentId,
  comment,
  previousComment,
  status,
  supc,
  suvc,
  gtin,
  supplierName,
  materialDescription,
  taxonomyChanges
}) {
  try {
    const { changedFields } = jsonParseReturnEmptyObject(previousComment);
    if (status !== 10) {
      const { nutritionSourceOid, ...rest } = comment;
      const nutritionData = {
        ...rest,
        nutritionSourceOid: 21
      };
      if (comment.oid) {
        yield patchRequest(`/nutritions/${comment.oid}/supc/${supc}`, {
          ...nutritionData,
          hasCnOrPfsChanged: changedFields.includes('isChildNutrition', 'sleHasProductFormulationStmt'),
          manufacturer: {
            name: supplierName
          },
          gtin,
          gln: null,
          alternateIdentifier: supc,
          name: materialDescription,
          vendorItemLink: [{ vendorItemIdentifier: supc, vendorItemOwnerOid: 110 }]
        });
      } else {
        yield postRequest(`/nutritions/items/supcs/${supc}`, {
          ...nutritionData
        });
      }
      if (taxonomyChanges && taxonomyChanges.length) {
        try {
          yield patchRequest(`/suvc/${suvc}/items/${supc}/taxonomy`, {
            comment: '',
            attributes: taxonomyChanges
          });
        } catch (e) {
          yield displayNutritionApproveFailureAsync({
            message: e.message,
            errorContentObject: {
              description: e.message,
              className: 'error',
              message: 'Something went wrong with taxonomy update'
            }
          });
        }
      }
    }
    const id = commentId.commentId ? commentId.commentId : commentId;
    const response = yield patchRequest(`/dash/comments/${id}/status/${status}`, {
      id,
      comment: previousComment,
      review: [],
      status
    });
    yield put({ type: UPDATENUTRITIONDATA.SUCCESS, payload: response.data });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: 'Successfully updated the feedback',
        className: 'info',
        message: 'SUCCESS'
      })
    );
    if (response.data && response.data.error === true) {
      yield displayNutritionApproveFailureAsync({
        message: response.data.message,
        errorContentObject: {
          description: response.data.message,
          className: 'error',
          message: "COULDN'T APPROVE"
        }
      });
    }
  } catch (error) {
    let description = error.response;
    if (error.response.data && error.response.data.description) description = error.response.data.description;
    yield displayNutritionApproveFailureAsync({
      message: error.message,
      errorContentObject: {
        description,
        className: 'error',
        message: 'Something went wrong with the nutritional API'
      }
    });
  }
}

function* sendNutritionQuestionsAsync({ supc, data, togglePopup }) {
  try {
    yield postRequest(`/nutritions/supcs/${supc}/questions`, data);

    togglePopup();

    yield put({ type: NUTRITIONQUESTIONS.SUCCESS });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: 'Successfully sent the question',
        className: 'info',
        message: 'SUCCESS'
      })
    );
  } catch (error) {
    yield put({ type: NUTRITIONQUESTIONS.FAILURE });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: "COULDN'T COMPLETE YOUR REQUEST"
      })
    );
  }
}

function* getNutritionApplicableStatusAsync({ supc }) {
  try {
    const {
      data: { isNutritionDetailsNotApplicable }
    } = yield getRequest(`/nutritions/items/${supc}`);
    yield put({ type: NUTRITIONAPPLICABLESTATUS.SUCCESS, payload: isNutritionDetailsNotApplicable });
  } catch (error) {
    yield put({ type: NUTRITIONAPPLICABLESTATUS.FAILURE });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: "COULDN'T GET NUTRITION APPLICABLE STATUS"
      })
    );
  }
}

function* updateNutritionApplicableStatusAsync({ supc, status }) {
  try {
    const data = {
      isNutritionDetailsNotApplicable: status
    };

    const response = yield patchRequest(`/nutritions/items/${supc}`, data);
    console.log(response);

    yield put({ type: UPDATENUTRITIONAPPLICABLESTATUS.SUCCESS, payload: status });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: 'Successfully set to not applicable',
        className: 'info',
        message: 'SUCCESS'
      })
    );
  } catch (error) {
    yield put({ type: UPDATENUTRITIONAPPLICABLESTATUS.FAILURE });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: "COULDN'T COMPLETE YOUR REQUEST"
      })
    );
  }
}

function* getPendingNutritionAsync({ supc }) {
  try {
    const params = [
      { param: 'supc', val: [supc], operator: 'like' },
      { param: 'type', val: [5] },
      { param: 'status', val: ['0', '10', '20'] }
    ];

    const uri = `?tablefilter=${encodeURIComponent(JSON.stringify(params))}`;
    const response = yield getRequest(`/dash/comments${uri}`);
    const data = _.get(response, 'data.comments', []);

    let pendingNutrition = {};

    if (data && data.length) {
      try {
        const [{ comment, status }] = data;
        pendingNutrition = { ...JSON.parse(comment), status };
      } catch (error) {}
    }

    yield put({ type: PENDINGNUTRITIONS.SUCCESS, payload: { pendingNutrition, pendingNutritions: data } });
  } catch (error) {
    yield put({ type: PENDINGNUTRITIONS.FAILURE });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: "COULDN'T GET NUTRITION APPLICABLE STATUS"
      })
    );
  }
}

function* cancelNutritionUpdateAsync({ commentId }) {
  try {
    const response = yield patchRequest(`/dash/comments/${commentId}/status/90`);
    if (response.data && response.data.error === true) {
      throw Error(response.data.message);
    } else {
      yield put({ type: CANCELNUTRITIONUPDATE.SUCCESS, payload: { commentId } });
      yield put(
        action('SHOW_NOTIFICATION', {
          description: 'Successfully canceled',
          className: 'info',
          message: 'SUCCESS'
        })
      );
    }
  } catch (error) {
    yield put({ type: CANCELNUTRITIONUPDATE.FAILURE });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: "COULDN'T CANCEL"
      })
    );
  }
}

function* fetchFnbMassUpdateInitialDataAsync({ suvc, supcs }) {
  try {
    const { taxonomies, fnbs, failed, masterData } = yield fetchMultipleFnbAndTaxonomy(suvc, supcs);

    if (!_.isEmpty(failed)) {
      yield put(
        action('SHOW_NOTIFICATION', {
          description: `Couldn't load data for the following items: ${_.join(failed, ', ')}`,
          className: 'error',
          message: "COULDN'T LOAD ITEMS"
        })
      );
    }

    yield put({
      type: FNBMASSEDITINITIALDATA.SUCCESS,
      payload: { supcs: _.difference(supcs, failed), fnbs, taxonomies, masterData }
    });
  } catch (error) {
    yield put({ type: FNBMASSEDITINITIALDATA.FAILURE });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: "COULDN'T FETCH FNB MASS EDIT DATA"
      })
    );
  }
}

async function fetchMultipleFnbAndTaxonomy(suvc, supcs) {
  const taxonomies = [];
  const masterData = [];
  const fnbs = [];
  const failed = [];

  const taxoPromises = [];
  const masterPromises = [];
  const fnbPromises = [];

  _.forEach(supcs, supc => {
    const taxoRequest = getRequest(`/suvc/${suvc}/items/${supc}/taxonomy`);
    taxoPromises.push(taxoRequest);

    const masterRequest = getRequest(`/suvc/${suvc}/items/${supc}/master`);
    masterPromises.push(masterRequest);

    const fnbRequest = getRequest(`/suvc/${suvc}/items/${supc}/fnbs`);
    fnbPromises.push(fnbRequest);
  });

  const taxoResults = await Promise.allSettled(taxoPromises);
  const masterResults = await Promise.allSettled(masterPromises);
  const fnbResults = await Promise.allSettled(fnbPromises);

  _.forEach(taxoResults, (res, index) => {
    const supc = supcs[index];
    const bc = _.get(res, 'value.data.bc.name', null);

    if (res.status === 'fulfilled' && !_.isEmpty(bc)) {
      taxonomies.push(res.value.data);
    } else {
      failed.push(supc);
    }
  });

  _.forEach(masterResults, (res, index) => {
    const supc = supcs[index];
    const values = _.get(res, 'value.data.values', {});

    if (res.status === 'fulfilled' && !_.isEmpty(values)) {
      values.supc = supc;
      masterData.push(values);
    } else if (!_.includes(failed, supc)) {
      failed.push(supc);
    }
  });

  _.forEach(fnbResults, (res, index) => {
    const supc = supcs[index];
    const fnb = _.get(res, 'value.data', null);

    if (res.status === 'fulfilled' && !_.isEmpty(fnb)) {
      fnbs.push(fnb);
    } else if (res.status === 'rejected') {
      const code = _.get(res, 'reason.response.status', null);
      if (code !== 404 && !_.includes(failed, supc)) {
        failed.push(supc);
      }
    }
  });

  return { taxonomies, fnbs, failed, masterData };
}

function* fnbMassUpdateAsync({ updatedData }) {
  try {
    const { success, failed, unsavedFnb } = yield saveMultipleFnb(updatedData);

    if (!_.isEmpty(success)) {
      const description = _.isEmpty(failed)
        ? 'Features & Benefits sent!'
        : `Features & Benefits sent for the following items: ${_.join(success, ', ')}`;
      yield put(
        action('SHOW_NOTIFICATION', {
          description,
          className: 'info',
          message: 'SUCCESS'
        })
      );
    }

    if (!_.isEmpty(failed)) {
      yield put(
        action('SHOW_NOTIFICATION', {
          description: `Couldn't send the F&B for the following items: ${_.join(failed, ', ')}`,
          className: 'error',
          message: 'FAILED'
        })
      );
    }

    yield put({ type: AUTOSAVE.REQUEST, autoSavedData: unsavedFnb });

    yield put({
      type: FNBMASSEDIT.SUCCESS,
      payload: { showFnbMassUpdateModal: !_.isEmpty(failed), failed }
    });
  } catch (error) {
    yield put({ type: FNBMASSEDIT.FAILURE });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: "COULDN'T COMPLETE FNB MASS UPDATE"
      })
    );
  }
}

async function saveMultipleFnb(updatedData) {
  const success = [];
  const failed = [];
  let unsavedFnb = [];

  const promiseArray = _.map(updatedData, obj => {
    const { suvc, supc, data } = obj;
    return putRequest(`/suvc/${suvc}/items/${supc}/fnbs`, data);
  });

  const results = await Promise.allSettled(promiseArray);

  _.forEach(results, (res, index) => {
    const supc = _.get(updatedData[index], 'supc', null);

    if (res.status === 'fulfilled') {
      success.push(supc);
    } else {
      failed.push(supc);
    }
  });

  for (const supc of success) {
    await removeFromIndexDb('item-management', 'supplier', supc);
  }

  const unsavedData = await getAllFromIndexDb('item-management', 'supplier');

  if (!_.isEmpty(unsavedData)) {
    unsavedFnb = _.map(unsavedData, obj => obj.supc);
  }

  return { success, failed, unsavedFnb };
}

async function removeFromIndexDb(dbName, tableName, supc) {
  try {
    const indexedDb = createIndexedDbConnection(dbName);
    await indexedDb.deleteValue(tableName, supc);
  } catch (error) {
    console.log(error);
  }
}

async function getAllFromIndexDb(dbName, tableName) {
  try {
    const indexedDb = createIndexedDbConnection(dbName);
    const data = await indexedDb.getAllValue(tableName);
    return data;
  } catch (error) {
    console.log(error);
  }
}

function* getGdsnFnbCommentsAsync({ supc, gtin }) {
  try {
    const response = yield getRequest(`/gdsnfnb/${gtin}`);
    yield put({ type: GDSNFNBCOMMENTS.SUCCESS, payload: { gdsnFnb: _.get(response, 'data', {}), supc } });
  } catch (error) {
    yield put({ type: GDSNFNBCOMMENTS.FAILURE, payload: { supc } });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: "COULDN'T GET GDSN FNB DATA"
      })
    );
  }
}

function* getGdsnFnbAsync({ gtin }) {
  try {
    const response = yield getRequest(`/gdsnfnb/${gtin}`);
    yield put({ type: GDSNFNB.SUCCESS, payload: _.get(response, 'data', {}) });
  } catch (error) {
    yield put({ type: GDSNFNB.FAILURE });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: "COULDN'T GET GDSN FNB DATA"
      })
    );
  }
}

function* sendCoreDataCommentsAsync({ suvc, supc, coreData, basicData, master }) {
  try {
    const updatedCoreDataComments = {};
    Object.keys(coreData.comment).forEach((key, index) => {
      const { isRejectedFixed, isChangedByValidation, value, existingValue, ...rest } = { ...coreData.comment[key] };
      if (
        (!isChangedByValidation || (isChangedByValidation && value !== '' && existingValue !== value)) &&
        checkForExistingAndCurrentAttributeValueChangesAreNotEqual(key, master, value)
      ) {
        updatedCoreDataComments[key] = {
          isRejected: false,
          isFixed: false,
          feedback: '',
          value,
          existingValue,
          ...rest,
          isPending: true
        };
      }
    });
    let comment = {
      field: 'CORE',
      type: 'CORE',
      comment: JSON.stringify(updatedCoreDataComments),
      originalValue: ''
    };
    if (coreData.id) comment = { ...comment, id: coreData.id };
    let response;
    if (Object.keys(updatedCoreDataComments).length) {
      if (coreData.id && coreData.id > 0) {
        response = yield patchRequest(`/suvc/${suvc}/items/${supc}/comments/${coreData.id}`, {
          comment: JSON.stringify(updatedCoreDataComments),
          supc,
          suvc,
          status: coreData.status
        });
      } else {
        response = yield postRequest(`/suvc/${suvc}/items/${supc}/comments`, {
          comments: [comment],
          stepId: basicData.stepId,
          supc,
          suvc
        });
      }
      let updatedData = {};
      if (!coreData.id) {
        updatedData = {
          ...response.data.comments[0],
          comment: JSON.parse(response.data.comments[0].comment)
        };
      } else {
        updatedData = {
          ...response.data,
          comment: JSON.parse(response.data.comment)
        };
      }
      yield put({ type: COREDATACOMMENT.SUCCESS, payload: updatedData });
      yield put(
        action('SHOW_NOTIFICATION', {
          description: `Successfully ${coreData.id ? 'updated' : 'created'} the Core data feedback`,
          className: 'info',
          message: 'SUCCESS'
        })
      );
    } else {
      yield put({ type: COREDATACOMMENT.FAILURE });
      yield put(
        action('SHOW_NOTIFICATION', {
          description: `Data you provided are same as existing data, please update with newer values`,
          className: 'error',
          message: 'Same Information'
        })
      );
    }
  } catch (error) {
    yield put({ type: COREDATACOMMENT.FAILURE });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: `Error ${coreData.id ? 'updating' : 'creating'} Core data feedback`
      })
    );
  }
}

function* getPgmItemListAsync({ supc, page, search, forcedVisibilityBannerOn, clearList }) {
  try {
    if (clearList) {
      yield put({
        type: PGMITEMS.SUCCESS,
        payload: {
          list: [],
          page: 1,
          search: '',
          recordCount: 0,
          forcedVisibilityBannerOn: false
        }
      });
    } else {
      let body = {};
      if (!page) {
        page = 1;
      }
      let uri = `?page=${page}`;
      if (search) {
        uri = `${uri}&search=${encodeURIComponent(search.replace(/%/, '###'))}`;
      }
      if (supc) {
        body = {
          supcs: supc
        };
      }

      const response = yield postRequest(`/pgmitems${uri}`, body);

      yield put({
        type: PGMITEMS.SUCCESS,
        payload: {
          list: _.get(response, 'data.items', []),
          page,
          search,
          recordCount: _.get(response, 'data.count', 0),
          forcedVisibilityBannerOn
        }
      });
    }
  } catch (error) {
    yield put({ type: PGMITEMS.FAILURE });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: "COULDN'T GET PGM ITEMS"
      })
    );
  }
}

function* updateCoreDataCommentAsync({ comment, id, supc, suvc, stepId }) {
  try {
    const response = yield patchRequest(`/dash/comments/${id}/status/30`, {
      comment,
      id,
      field: CORE,
      type: CORE,
      suvc,
      supc,
      stepId
    });
    if (response.data && response.data.error === true) {
      throw new Error(response.data);
    } else {
      yield put({ type: SENDCOREDATACOMMENT.SUCCESS, payload: response.data });
      yield put(
        action('SHOW_NOTIFICATION', {
          description: 'Succcessfully updated the core data feedback',
          className: 'info',
          message: 'SUCCESS'
        })
      );
    }
  } catch (error) {
    yield put({ type: SENDCOREDATACOMMENT.FAILURE, payload: error.message });
    let description = error.message;
    if (error && error.response && error.response.data && error.response.data.description) {
      const jsonedValue = parseAsJson(error.response.data.description);
      if (jsonedValue) {
        const { id: commentId, assignee } = jsonedValue;
        yield put({ type: HANDLEAALREADYASSIGNEDCOREDATAISSUE.REQUEST, id: commentId, assignee });
        description = 'Unauthorized, Somebody has assigned';
      }
    }
    yield put(
      action('SHOW_NOTIFICATION', {
        description,
        className: 'error',
        message: "COULDN'T UPDATE THE STATUS OF THE COMMENT"
      })
    );
  }
}

function* updateForcedVisibilityAsync({ data }) {
  try {
    yield putRequest(`/itemvisibility`, data);

    yield put({ type: UPDATEVISIBILITY.SUCCESS, payload: data });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: 'Successfully updated the item visibility',
        className: 'info',
        message: 'SUCCESS'
      })
    );
  } catch (error) {
    yield put({ type: UPDATEVISIBILITY.FAILURE });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: "COULDN'T UPDATE THE ITEM VISIBILITY"
      })
    );
  }
}

function* getSpecificStiboItemAsync({ supc, id }) {
  try {
    const response = yield getRequest(`/items/${supc}`);
    yield put({ type: GETITEMDETAILS.SUCCESS, payload: { ..._.get(response, 'data', {}), commentId: id } });
  } catch (error) {
    yield put({ type: GETITEMDETAILS.FAILURE });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: "COULDN'T GET ITEM DETAILS"
      })
    );
  }
}

function* getForcedVisibleSupcsAsync() {
  try {
    const response = yield getRequest(`/itemvisibility`);
    yield put({ type: FORCEDVISIBLESUPCS.SUCCESS, payload: _.get(response, 'data.supcs', []) });
  } catch (error) {
    yield put({ type: FORCEDVISIBLESUPCS.FAILURE });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: "COULDN'T FETCH PGM SUPCS"
      })
    );
  }
}

function* selfAssignCommentAsync({ commentId, userId }) {
  try {
    yield patchRequest(`/dash/comments/${commentId}/assign`, { userId });
    yield put({ type: SELFASSIGNCOMMENT.SUCCESS, payload: { commentId, userId } });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: 'Successfully assigned!',
        className: 'info',
        message: 'SUCCESS'
      })
    );
  } catch (error) {
    yield put({ type: SELFASSIGNCOMMENT.FAILURE, payload: { commentId } });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'FAILED TO ASSIGN COMMENT'
      })
    );
  }
}

function* getCoreAttributesHistoryAsync({ supc, attribute, range }) {
  try {
    let historyRecords = [];
    let rangeParam = range && range.includes && range.includes('|') ? `&range=${encodeURIComponent(range)}` : '';
    let params = `?supc=${encodeURIComponent(supc)}&attribute=${encodeURIComponent(attribute)}${rangeParam}`;

    let recCount = 1000,
      page = 0,
      response = [];

    while (recCount == 1000) {
      try {
        response = yield getRequest(`/dash/comments/history/core${params}&page=${page}`);
      } catch (e) {}
      let resData = _.get(response, 'data', []);
      historyRecords = [...historyRecords, ...resData];
      recCount = resData.length;
      page++;
    }

    if (historyRecords.length == 0) {
      throw { message: 'No history records found.' };
    }

    yield put({ type: COREATTRIBUTEHISTORY.SUCCESS, payload: historyRecords });
  } catch (error) {
    yield put({ type: COREATTRIBUTEHISTORY.FAILURE });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'NO HISTORY FOUND'
      })
    );
  }
}

function* getItemMasterDataAsync({ supc, fields }) {
  try {
    const response = yield getRequest(`/item-master/items/${supc}?fields=${encodeURIComponent(fields.join())}`);
    yield put({ type: MDMITEMMASTER.SUCCESS, payload: _.get(response, 'data.item', {}) });
  } catch (error) {
    console.log(error);
    yield put({ type: MDMITEMMASTER.FAILURE });
  }
}

function* fetchOverallItemStatusHistoryAsync({ commentId, range }) {
  try {
    let payload = {};
    if (commentId) {
      payload = { commentId };
    } else if (range) {
      yield put({
        type: UPDATEALLOVERALLHISTORY.REQUEST
      });
      payload = {
        changedAt: range
      };
    }
    const response = yield postRequest(`/dash/comments/history/overall-item-status`, payload);
    if (range) {
      yield put({
        type: UPDATEALLOVERALLHISTORY.SUCCESS,
        payload: _.get(response, 'data', [])
      });
    } else {
      yield put({
        type: FETCHOVERALLITEMFEEDBACKHISTORY.SUCCESS,
        payload: _.get(response, 'data', []),
        fetchedCommentId: parseInt(commentId)
      });
    }
  } catch (error) {
    if (range) {
    } else {
      yield put({ type: FETCHOVERALLITEMFEEDBACKHISTORY.FAILURE, failedCommentId: parseInt(commentId) });
    }
  }
}

function* submitOverallFeedbackAsync({ currentChanges, commentData }) {
  try {
    const { supc, suvc, changedFrom, changedTo, statusFrom, statusTo, commentText } = currentChanges;
    const { id, comment, assignee: changedBy } = commentData;
    const overallFeedbackHistory = {
      supc,
      suvc,
      changedFrom,
      changedTo,
      statusFrom,
      statusTo,
      commentText,
      changedBy
    };
    const response = yield patchRequest(`/dash/comments/${id}/status/${statusTo}`, {
      commentId: id,
      comment,
      status: statusTo,
      overallFeedbackHistory
    });
    yield put({
      type: SUBMITOVERALLFEDBACK.SUCCESS,
      payload: { ...overallFeedbackHistory, commentId: id, newStatus: statusTo }
    });
    yield put({ type: FETCHOVERALLITEMFEEDBACKHISTORY.REQUEST, commentId: id });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: 'COMMENTS SENT!',
        className: 'info',
        message: 'SUCCESS'
      })
    );
  } catch (error) {
    yield put({ type: SUBMITOVERALLFEDBACK.FAILURE });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: "COULDN'T SEND COMMENT"
      })
    );
  }
}

function* pushCooDataAsync(reqData) {
  try {
    const { supc, cooDatas, supplierName, basicData } = reqData;
    const trueVendorName = basicData.trueVendorName ? basicData.trueVendorName : '';
    let validateData = JSON.parse(JSON.stringify(cooDatas));
    validateData = validateData.filter(activityData => {
      if (activityData[ACTIVITY_TYPE_KEY].value) {
        return activityData;
      }
    });
    validateData.forEach(item => {
      let temp = [...item.country];
      item.country = temp.filter(countryData => {
        if (countryData[ACTIVITY_CODE_KEY].value) {
          return countryData;
        }
      });
      if (item.country.length === 0) {
        item.country.push({ ...emptyCooCountryData });
      }
    });

    const stepParams = formatCooStepParam(supplierName, trueVendorName, validateData);
    const response = yield patchRequest(`/items/${supc}/coo/update`, stepParams);
    yield put({ type: COODATAPUSHED.SUCCESS, payload: validateData });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: `Successfully updated the COO data`,
        className: 'info',
        message: 'SUCCESS'
      })
    );
  } catch (error) {
    yield put({ type: COODATAPUSHED.FAILURE });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: `Error updating COO data`
      })
    );
  }
}

function* fetchItemViewExclusionsAsync({ supc, suvc }) {
  try {
    const response = yield getRequest(getFeeExclusionUrl(suvc, supc));
    yield put({ type: FETCHITEMVIEWEXCLUSIONS.SUCCESS, payload: _.get(response, 'data.exclusions', []) });
  } catch (error) {
    console.log(error);
    yield put({ type: FETCHITEMVIEWEXCLUSIONS.FAILURE });
  }
}

function* loadItemCooAsync({ supc, syscoBrand, gdsn }) {
  try {
    const response = yield getRequest(`/items/${supc}/coo`);
    yield put({ type: ITEMCOO.SUCCESS, payload: { cooData: response.data, syscoBrand, gdsn } });
  } catch (error) {
    yield put({ type: ITEMCOO.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: 'Product Coo Data loading error!',
        className: 'error',
        message: 'SOMETHING WENT WRONG'
      })
    );
  }
}

function* getSummaryBySupcsAsync({ items }) {
  try {
    const supcs = _.map(items, item => item.supc);
    const response = yield postRequest(`/summary/items/fetch`, { supcs });
    yield put({ type: ITEMSSUMMARY.SUCCESS, payload: _.get(response, 'data', []) });
  } catch (error) {
    yield put(
      action('SHOW_NOTIFICATION', {
        description: 'Loading item level scores failed',
        className: 'error',
        message: 'SOMETHING WENT WRONG'
      })
    );
  }
}

async function filterItemsByItemVisibility(items) {
  let visibleItems = [];
  let notVisible = [];

  for (const item of items) {
    if (item.sdpVisible === 1) {
      visibleItems.push(item);
    } else {
      notVisible.push(item);
      await removeFromIndexDb('item-management', 'supplier', item.supc);
    }
  }

  return { visibleItems, notVisible };
}

function* getItemPfasInformationAsync({ supc }) {
  try {
    const response = yield getRequest(`/items/${supc}/pfas`);
    yield put({ type: PFAS.SUCCESS, payload: _.get(response, 'data', {}) });
  } catch (error) {
    yield put({ type: PFAS.FAILURE });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: 'Loading PFAS Attributes Failed',
        className: 'error',
        message: 'SOMETHING WENT WRONG'
      })
    );
  }
}

function* updatePfasInformationAsync(data) {
  try {
    const {
      pfas: { changes, attributes: pfasAttributes },
      supc,
      suvc,
      taxonomy: { attributes: taxonomyAttributes }
    } = data;
    const changedAttributes = checkIfPfasHasChanges(data);
    if (!changedAttributes.length) throw new Error();
    let attributes = formatPfasRequest(changes, changedAttributes);
    const response = yield postRequest(`/items/${supc}/pfas`, { attributes });
    const payload = _.get(response, 'data', {});

    const updatedChanges = getDefaultValuesForPfas(payload.attributes);
    const latestAttributes = [...getDefaultValueForPfasClaim(taxonomyAttributes, pfasAttributes, updatedChanges)];

    const pfasClaimLevelAttribute = _.find(latestAttributes, ({ name }) => name === 'PFAS Claim');
    if (pfasClaimLevelAttribute && pfasClaimLevelAttribute.attrId && pfasClaimLevelAttribute.pfasSelected) {
      yield saveTaxonomyQuiteAsync({
        supc,
        suvc,
        taxonomyChanges: [
          ...data.taxonomyChanges,
          { attrId: `${pfasClaimLevelAttribute.attrId}`, selected: `${pfasClaimLevelAttribute.pfasSelected}` }
        ]
      });
    }
    yield put({ type: PFASUPDATE.SUCCESS, payload });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: `Successfully updated PFAS information`,
        className: 'info',
        message: 'SUCCESS'
      })
    );
  } catch (error) {
    yield put({ type: PFASUPDATE.FAILURE });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: 'Uploading PFAS Attributes Failed',
        className: 'error',
        message: 'SOMETHING WENT WRONG'
      })
    );
  }
}

function* submitPreferredImagesAsync({ data, supc, suvc, unsavedPreferredImgs }) {
  try {
    yield postRequest(`/suvc/${suvc}/items/${supc}/images/preferred`, data);

    yield put(
      action('SHOW_NOTIFICATION', {
        description: 'Preferred Image Request Sent',
        className: 'info',
        message: 'SUCCESS'
      })
    );

    yield put({ type: SUBMITPREFERREDIMAGES.SUCCESS, payload: unsavedPreferredImgs });
  } catch (error) {
    console.log(error);
    yield put({ type: SUBMITPREFERREDIMAGES.FAILURE });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'COULD NOT MARK THE IMAGES AS PREFERRED'
      })
    );
  }
}

function* getPreferredImageCommentDetailsAsync(data) {
  const { commentId, supc, styleBucket } = data.payload;
  try {
    const response = yield getRequest(`/dash/comments/preferredimage?supc=${supc}&stylebucket=${styleBucket}`);
    yield put({ type: PREFERREDIMAGECOMMENT.SUCCESS, payload: { additionalImages: response.data, commentId } });
  } catch (error) {
    console.log(error);
    yield put({ type: PREFERREDIMAGECOMMENT.FAILURE, payload: { commentId } });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'PREFERRED IMAGE COMMENT DETAILS'
      })
    );
  }
}

function* approvePreferredImageAsync({ payload, postData }) {
  const { supc, prefferedImage, styleBucket, commentId } = payload;
  const patchData = {
    commentId,
    supc,
    suvc: postData.suvc,
    prefferedImage,
    styleBucket,
    additionalImages: JSON.stringify(postData.additionalImages)
  };

  try {
    yield patchRequest(`/dash/comments/preferredimage/approve`, patchData);

    yield put(
      action('SHOW_NOTIFICATION', {
        description: 'Preferred Image Approved',
        className: 'info',
        message: 'SUCCESS'
      })
    );

    yield put({ type: APPROVEPREFERREDIMAGE.SUCCESS, payload: { commentId, loading: false } });
  } catch (error) {
    console.log(error);
    yield put({ type: APPROVEPREFERREDIMAGE.FAILURE, payload: { commentId, loading: false } });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'COULD NOT APPROVE THE PREFERRED IMAGE'
      })
    );
  }
}

function* rejectPreferredImageAsync({ payload, postData }) {
  const { supc, review, commentId, associatePreferred, styleBucket } = payload;
  const patchData = {
    commentId,
    supc,
    suvc: postData.suvc,
    review,
    associatePreferred: associatePreferred || null,
    styleBucket,
    additionalImages: JSON.stringify(postData.additionalImages)
  };

  try {
    yield patchRequest(`/dash/comments/preferredimage/reject`, patchData);

    yield put(
      action('SHOW_NOTIFICATION', {
        description: 'Preferred Image Rejected',
        className: 'info',
        message: 'SUCCESS'
      })
    );

    yield put({ type: REJECTPREFERREDIMAGE.SUCCESS, payload: { commentId, loading: false } });
  } catch (error) {
    console.log(error);
    yield put({ type: REJECTPREFERREDIMAGE.FAILURE, payload: { commentId, loading: false } });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'COULD NOT REJECT THE PREFERRED IMAGE'
      })
    );
  }
}

function* defaultPreferredImageRequest(responseData, { style, suvc }) {
  try {
    const { id, supc, stepId } = responseData;
    let styleBucket = null;
    let styleLabel = null;

    const imgResponse = yield getRequest(`/dash/imagecomments/${[id]}`);
    const originalMeta = _.get(imgResponse, `data[0].meta`, null);

    if (!originalMeta) {
      throw Error('No meta data found');
    }

    const originalMetaJson = JSON.parse(originalMeta);

    _.forOwn(imageCategories, (value, key) => {
      if (_.includes(value.styles, _.toLower(style))) {
        styleBucket = key;
        styleLabel = value.caption;
      }
    });

    let meta = {
      reservationId: originalMetaJson.reservation_id,
      name: originalMetaJson.name,
      styleBucket,
      trigger: 'NEW_IMAGE',
      parentCommentId: id,
      mime: originalMetaJson.mime
    };

    if (originalMetaJson.isFirstImage) meta.isFirstImage = true;

    if (!originalMetaJson.isFirstImage) {
      const postData = [
        {
          supc: supc,
          suvc: suvc,
          comment: `Preferred Image - ${styleLabel} (Automated Request)`,
          style: _.toUpper(style),
          meta: JSON.stringify(meta),
          stepId: stepId,
          otmmAssetId: 'TEMP_PLACEHOLDER',
          otmmThumbnailRenditionId: '',
          otmmMasterRenditionId: '',
          otmmPreviewRenditionId: ''
        }
      ];

      yield postRequest(`/suvc/${suvc}/items/${supc}/images/preferred`, postData);
    }
  } catch (error) {
    console.log(error);
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'COULD NOT MARK THE IMAGE AS PREFERRED'
      })
    );
  }
}

function* handlePreferredImageOnStyleChange(preferredImgData, parentCommentId) {
  const { supc, assetId, suvc, stepId, currentStyle, style: newStyle, brand } = preferredImgData;
  const currentStyleBucket = _.findKey(imageCategories, value => _.includes(value.styles, _.toLower(currentStyle)));
  const newStyleBucket = _.findKey(imageCategories, value => _.includes(value.styles, _.toLower(newStyle)));

  try {
    const postData = {
      supc,
      suvc,
      assetId,
      stepId,
      parentCommentId,
      currentStyle,
      currentStyleBucket,
      currentBucketLabel: _.get(imageCategories, `[${currentStyleBucket}][caption]`, null),
      newStyle,
      newStyleBucket,
      newBucketLabel: _.get(imageCategories, `[${newStyleBucket}][caption]`, null),
      brand
    };

    yield postRequest(`/dash/comments/stylechange/preferredimage`, postData);
  } catch (error) {
    console.log(error);
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'COULD NOT MARK THE IMAGE AS PREFERRED'
      })
    );
  }
}

function* loadFsmaAsync({ supc }) {
  try {
    const response = yield getRequest(`/items/${supc}/fsma`);
    yield put({
      type: FSMA.SUCCESS,
      payload: _.get(response, 'data.attributes', [])
    });
  } catch (error) {
    yield put({ type: FSMA.FAILURE, payload: error.message });
  }
}

function* saveFsmaAsync({ supc, attributes, username }) {
  console.log('username :', username);
  const postData = {
    attributes: _.map(attributes, attr => ({ ...attr, selected: [attr.selected] })),
    username
  };

  try {
    const response = yield postRequest(`/items/${supc}/fsma`, postData);
    yield put({ type: SAVEFSMACHANGES.SUCCESS, payload: _.get(response, 'data.attributes', []) });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: 'Successfully Updated',
        className: 'info',
        message: 'SUCCESS'
      })
    );
  } catch (error) {
    console.log(error);
    yield put({ type: SAVEFSMACHANGES.FAILURE });
  }
}

function* watchLoadUser() {
  yield takeLatest(USER.REQUEST, loadUserAsync);
}

function* watchCommentsInfoMerge() {
  yield takeLatest(COMMENTS.MERGE, loadCommentsInfoMergeAsync);
}

function* watchLoadComments() {
  yield takeLatest(COMMENTS.REQUEST, loadCommentsAsync);
}

function* watchUpdateComment() {
  yield takeLatest(UPDATECOMMENT.REQUEST, updateCommentAsync);
}

function* watchUpdateAttribComment() {
  yield takeLatest(UPDATEATTRIBCOMMENT.REQUEST, updateAttribCommentAsync);
}

function* watchLoadItemList() {
  yield takeLatest(ITEMS.REQUEST, loadItemListAsync);
}

function* watchLoadItem() {
  yield takeLatest(ITEMMASTER.REQUEST, loadItemAsync);
}

function* watchLoadItemSupc() {
  yield takeLatest(SUPCBASIC.REQUEST, loadItemSupcAsync);
}

function* watchLoadTaxonomy() {
  yield takeLatest(ITEMTAXO.REQUEST, loadTaxonomyAsync);
}

function* watchLoadImages() {
  yield takeLatest(ITEMIMAGE.REQUEST, loadImagesAsync);
}

function* watchLoadFnb() {
  yield takeLatest(ITEMFNB.REQUEST, loadFnbAsync);
}

function* watchLoadFnbTooltips() {
  yield takeLatest(ITEMFNB.TOOLTIP_REQUEST, loadFnbTooltipsAsync);
}

function* watchSendFnbComments() {
  yield takeLatest(ITEMFNB.COMMENTS_REQUEST, sendFnBCommentsAsync);
}
function* watchSendImagesComments() {
  yield takeLatest(ITEMIMAGE.COMMENTS_REQUEST, sendImageCommentsAsync);
}
function* watchSaveTaxonomy() {
  yield takeLatest(TAXOSAVE.REQUEST, saveTaxonomyAsync);
}

function* watchSaveTaxonomyQuite() {
  yield takeLatest(TAXOQUITESAVE.REQUEST, saveTaxonomyQuiteAsync);
}

function* watchSendComments() {
  yield takeLatest(SENDCOMMENTS.REQUEST, sendCommentsAsync);
}
function* watchSendFeedback() {
  yield takeLatest(FEEDBACK.REQUEST, sendFeedbackAsync);
}

function* watchSendComment() {
  yield takeLatest(SENDCOMMENT.REQUEST, sendCommentAsync);
}

function* watchGetSummary() {
  yield takeLatest(SUMMARY.REQUEST, getSummaryAsync);
}

function* watchLoadScores() {
  yield takeLatest(SCORES.REQUEST, loadScoresAsync);
}

function* watchSendDeleteImageComment() {
  yield takeLatest(IMAGEDELETE.COMMENTS_REQUEST, sendDeleteImageCommentAsync);
}

function* watchSendFeedImageComment() {
  yield takeLatest(IMAGEFEED.REQUEST, sendFeedImageCommentAsync);
}

function* watchSendItemCommentRequest() {
  yield takeLatest(ITEMCOMMENTS.REQUEST, sendItemCommentRequestAsync);
}

function* watchSendStyleImageComment() {
  yield takeLatest(IMAGESTYLE.REQUEST, sendStyleImageCommentAsync);
}

function* watchFnbCommentUpdate() {
  yield takeLatest(ITEMFNB.COMMENTS_SUCCESS, sendFnbCommentUpdate);
}

function* watchSendImageCommentRequest() {
  yield takeLatest(IMGCOMMENT.REQUEST, sendImageCommentRequestAsync);
}

function* watchImageResRequest() {
  yield takeLatest(IMGRESERVATION.REQUEST, sendImageResRequestAsync);
}

function* watchAllVendorsRequest() {
  yield takeLatest(VENDORS.REQUEST, getAllVendorsAsync);
}

function* watchShowNotification() {
  yield takeEvery('SHOW_NOTIFICATION', showNotificationAsync);
}

function* watchLoadFnBCommentsAsync() {
  yield takeEvery(FNBCOMMENTS.REQUEST, loadFnBCommentsAsync);
}

function* watchEditFnBAttributeAsync() {
  yield takeEvery(EDITATTRIBUTE.REQUEST, editFnBAttributeAsync);
}

function* watchUpdateFnBCommentAsync() {
  yield takeEvery(UPDATEFNBCOMMENT.REQUEST, updateFnBCommentAsync);
}

function* watchGetRejectedSupcsAsync() {
  yield takeEvery(REJECTEDSUPCS.REQUEST, getRejectedSupcsAsync);
}

function* watchGetOverallSummaryAsync() {
  yield takeEvery(OVERALLSUMMARY.REQUEST, getOverallSummaryAsync);
}

function* watchGetNutritionDataAsync() {
  yield takeEvery(NUTRITIONS.REQUEST, getNutritionDataAsync);
}

function* watchSendNutritionDataAsync() {
  yield takeEvery(UPDATENUTRITIONS.REQUEST, sendNutritionDataAsync);
}

function* watchUpdateImageCommentAsync() {
  yield takeEvery(UPDATEIMAGECOMMENT.REQUEST, updateImageCommentAsync);
}

function* watchUpdateImageStyleAsync() {
  yield takeEvery(UPDATEIMAGESTYLE.REQUEST, updateImageStyleAsync);
}

function* watchFetchFeeExclusionsBySupcAsync() {
  yield takeEvery(FEEEXCLUSIONSBYSUPC.REQUEST, fetchFeeExclusionsBySupcAsync);
}

function* watchFetchFeeExclusionsCountAsync() {
  yield takeEvery(FEEEXCLUSIONSCOUNT.REQUEST, fetchFeeExclusionsCountAsync);
}

function* watchUpdateNutritionDetailsAsync() {
  yield takeEvery(UPDATENUTRITIONDATA.REQUEST, updateNutritionCommentAsync);
}

function* watchSendNutritionQuestionsAsync() {
  yield takeEvery(NUTRITIONQUESTIONS.REQUEST, sendNutritionQuestionsAsync);
}

function* watchGetNutritionApplicableStatusAsync() {
  yield takeEvery(NUTRITIONAPPLICABLESTATUS.REQUEST, getNutritionApplicableStatusAsync);
}

function* watchUpdateNutritionApplicableStatusAsync() {
  yield takeEvery(UPDATENUTRITIONAPPLICABLESTATUS.REQUEST, updateNutritionApplicableStatusAsync);
}

function* watchGetPendingNutritionAsync() {
  yield takeEvery(PENDINGNUTRITIONS.REQUEST, getPendingNutritionAsync);
}

function* watchCancelNutritionUpdateAsync() {
  yield takeEvery(CANCELNUTRITIONUPDATE.REQUEST, cancelNutritionUpdateAsync);
}

function* watchFetchMultipleFnbAsync() {
  yield takeEvery(FNBMASSEDITINITIALDATA.REQUEST, fetchFnbMassUpdateInitialDataAsync);
}

function* watchFnbMassUpdateAsync() {
  yield takeEvery(FNBMASSEDIT.REQUEST, fnbMassUpdateAsync);
}

function* watchGetAllVendorsAsync() {
  yield takeEvery(VENDORSSILENT.REQUEST, getAllVendorsAsync);
}

function* watchGetGdsnFnbCommentsAsync() {
  yield takeEvery(GDSNFNBCOMMENTS.REQUEST, getGdsnFnbCommentsAsync);
}

function* watchGetGdsnFnbAsync() {
  yield takeEvery(GDSNFNB.REQUEST, getGdsnFnbAsync);
}

function* watchCreateUpdateCoreDataCommentsAsync() {
  yield takeEvery(COREDATACOMMENT.REQUEST, sendCoreDataCommentsAsync);
}

function* watchUpdateCoreDataCommentsAsync() {
  yield takeEvery(SENDCOREDATACOMMENT.REQUEST, updateCoreDataCommentAsync);
}

function* watchGetItemDetailsAsync() {
  yield takeEvery(GETITEMDETAILS.REQUEST, getSpecificStiboItemAsync);
}
function* watchGetPgmItemListAsync() {
  yield takeEvery(PGMITEMS.REQUEST, getPgmItemListAsync);
}

function* watchUpdateForcedVisibilityAsync() {
  yield takeEvery(UPDATEVISIBILITY.REQUEST, updateForcedVisibilityAsync);
}

function* watchGetForcedVisibleSupcsAsync() {
  yield takeEvery(FORCEDVISIBLESUPCS.REQUEST, getForcedVisibleSupcsAsync);
}

function* watchSelfAssignCommentAsync() {
  yield takeEvery(SELFASSIGNCOMMENT.REQUEST, selfAssignCommentAsync);
}

function* watchGetCoreAttributesHistoryAsync() {
  yield takeEvery(COREATTRIBUTEHISTORY.REQUEST, getCoreAttributesHistoryAsync);
}

function* watchGetItemMasterDataAsync() {
  yield takeLatest(MDMITEMMASTER.REQUEST, getItemMasterDataAsync);
}

function* watchSubmitPreferredImagesAsync() {
  yield takeLatest(SUBMITPREFERREDIMAGES.REQUEST, submitPreferredImagesAsync);
}

function* watchGetPreferredImageCommentDetailsAsync() {
  yield takeLatest(PREFERREDIMAGECOMMENT.REQUEST, getPreferredImageCommentDetailsAsync);
}

function* watchApprovePreferredImageAsync() {
  yield takeLatest(APPROVEPREFERREDIMAGE.REQUEST, approvePreferredImageAsync);
}

function* watchRejectPreferredImageAsync() {
  yield takeLatest(REJECTPREFERREDIMAGE.REQUEST, rejectPreferredImageAsync);
}

function* watchFetchOverallItemStatusFeedbackAsync() {
  yield takeLatest(FETCHOVERALLITEMFEEDBACKHISTORY.REQUEST, fetchOverallItemStatusHistoryAsync);
}

function* watchSubmitOverallFeedbackAsync() {
  yield takeLatest(SUBMITOVERALLFEDBACK.REQUEST, submitOverallFeedbackAsync);
}

function* watchHandleOverallFeedbackFetchAsync() {
  yield takeLatest(FETCHITEMCOMMENTSOVERALLHISTORY.REQUEST, handleFetchPendingOverallHistory);
}

function* watchItemViewExclusionsFetchAsync() {
  yield takeLatest(FETCHITEMVIEWEXCLUSIONS.REQUEST, fetchItemViewExclusionsAsync);
}

function* watchPushCooDataAsync() {
  yield takeEvery(COODATAPUSHED.REQUEST, pushCooDataAsync);
}

function* watchLoadItemCoo() {
  yield takeLatest(ITEMCOO.REQUEST, loadItemCooAsync);
}

function* watchGetSummaryBySupcsAsync() {
  yield takeLatest(ITEMSSUMMARY.REQUEST, getSummaryBySupcsAsync);
}

function* watchGetItemPfasAsync() {
  yield takeLatest(PFAS.REQUEST, getItemPfasInformationAsync);
}

function* watchPfasUpdateAsync() {
  yield takeLatest(PFASUPDATE.REQUEST, updatePfasInformationAsync);
}

function* watchLoadFsmaAsync() {
  yield takeLatest(FSMA.REQUEST, loadFsmaAsync);
}

function* watchSaveFsmaAsync() {
  yield takeLatest(SAVEFSMACHANGES.REQUEST, saveFsmaAsync);
}

export default function* rootSaga() {
  yield all([
    watchLoadUser(),
    watchShowNotification(),
    watchLoadItemList(),
    watchLoadItem(),
    watchLoadTaxonomy(),
    watchLoadImages(),
    watchLoadFnb(),
    watchLoadFnbTooltips(),
    watchSendFnbComments(),
    watchSendImagesComments(),
    watchSaveTaxonomy(),
    watchSendComments(),
    watchSendComment(),
    watchSendFeedback(),
    watchLoadComments(),
    watchUpdateComment(),
    watchCommentsInfoMerge(),
    watchLoadScores(),
    watchGetSummary(),
    watchSendDeleteImageComment(),
    watchSendStyleImageComment(),
    watchSendImageCommentRequest(),
    watchImageResRequest(),
    watchSendFeedImageComment(),
    watchSendItemCommentRequest(),
    watchLoadItemSupc(),
    watchAllVendorsRequest(),
    watchLoadFnBCommentsAsync(),
    watchEditFnBAttributeAsync(),
    watchUpdateFnBCommentAsync(),
    watchUpdateAttribComment(),
    watchFnbCommentUpdate(),
    watchGetRejectedSupcsAsync(),
    watchGetOverallSummaryAsync(),
    watchGetNutritionDataAsync(),
    watchSendNutritionDataAsync(),
    watchUpdateImageCommentAsync(),
    watchUpdateImageStyleAsync(),
    watchFetchFeeExclusionsBySupcAsync(),
    watchFetchFeeExclusionsCountAsync(),
    watchUpdateNutritionDetailsAsync(),
    watchSendNutritionQuestionsAsync(),
    watchGetNutritionApplicableStatusAsync(),
    watchUpdateNutritionApplicableStatusAsync(),
    watchGetPendingNutritionAsync(),
    watchCancelNutritionUpdateAsync(),
    watchSaveTaxonomyQuite(),
    watchFetchMultipleFnbAsync(),
    watchFnbMassUpdateAsync(),
    watchGetAllVendorsAsync(),
    watchGetGdsnFnbCommentsAsync(),
    watchGetGdsnFnbAsync(),
    watchCreateUpdateCoreDataCommentsAsync(),
    watchUpdateCoreDataCommentsAsync(),
    watchGetItemDetailsAsync(),
    watchGetPgmItemListAsync(),
    watchUpdateForcedVisibilityAsync(),
    watchGetForcedVisibleSupcsAsync(),
    watchSelfAssignCommentAsync(),
    watchGetCoreAttributesHistoryAsync(),
    watchMassImageUploadDataAsync(),
    watchAttributeMassEditAsync(),
    watchGetItemMasterDataAsync(),
    watchSubmitPreferredImagesAsync(),
    watchGetPreferredImageCommentDetailsAsync(),
    watchApprovePreferredImageAsync(),
    watchRejectPreferredImageAsync(),
    watchFetchOverallItemStatusFeedbackAsync(),
    watchSubmitOverallFeedbackAsync(),
    watchHandleOverallFeedbackFetchAsync(),
    watchItemViewExclusionsFetchAsync(),
    watchPushCooDataAsync(),
    watchLoadItemCoo(),
    watchGetSummaryBySupcsAsync(),
    watchGetItemPfasAsync(),
    watchPfasUpdateAsync(),
    watchPfasMassEditAsync(),
    watchLoadFsmaAsync(),
    watchSaveFsmaAsync()
  ]);
}
