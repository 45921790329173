import React, { useCallback } from 'react';
import { Modal } from 'antd';

const ConfirmationModal = ({ isVisible, handleVisibility, handleSubmit }) => {
  const handleOnOkButtonClick = useCallback(() => {
    handleSubmit();
    handleVisibility(false);
  }, []);
  return (
    <Modal visible={isVisible} onCancel={() => handleVisibility(false)} onOk={() => handleOnOkButtonClick()}>
      <div className="mass-image-upload-confirmation-body">
        Are you sure, you want to proceed with the changes you made?
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
