import React from 'react';
import { Tooltip } from 'antd';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import TaxonomyFilter from '../TaxonomyFilter';
import * as actionCreators from '../../actions';
import { isValidHierarchyName, isPartialHierarchy, isNonFoodBc } from '../../util/Util';
import { PARTIAL_HIERARCHY_MESSAGE } from '../../util/Constants';

export default function AttributeMassEditTaxoFilter({ hasUnsubmittedData }) {
  const dispatch = useDispatch();
  const { handleAttributeMassEditFilters } = bindActionCreators(actionCreators, dispatch);

  const fetchingAttributes = useSelector(state => _.get(state.massUpdate.attribute, 'fetchingAttributes', false));
  const bc = useSelector(state => _.get(state.massUpdate.attribute, 'filters.bc', null));
  const ig = useSelector(state => _.get(state.massUpdate.attribute, 'filters.ig', null));
  const ag = useSelector(state => _.get(state.massUpdate.attribute, 'filters.ag', null));
  const bcs = useSelector(state => _.get(state.scorecards, 'taxonomy', {}));
  const completeTaxonomy = useSelector(state => _.get(state.massUpdate.attribute, 'completeTaxonomy', []));
  const attributeFilters = useSelector(state => _.get(state.massUpdate.attribute, 'filters.attributeFilters', []));

  const igs = bcs[bc] || {};
  const ags = igs[ig] || {};
  const isNonFood = isNonFoodBc(completeTaxonomy, bc);

  const onChangeFilter = taxoFilters => {
    if (!hasUnsubmittedData()) {
      handleAttributeMassEditFilters(taxoFilters);
    }
  };

  const renderMessage = () => {
    if (!isValidHierarchyName(bc) && !fetchingAttributes) {
      return <div className="taxo-message">* Required</div>;
    } else if (isNonFood && isPartialHierarchy(bc, ag)) {
      return (
        <div className="taxo-message">
          You have selected a non-food business center. To mass edit attributes, please select a complete hierarchy.
        </div>
      );
    }
  };

  const renderToolTip = () => {
    if ((!isValidHierarchyName(bc) || isPartialHierarchy(bc, ag)) && !isNonFood) {
      return (
        <Tooltip
          overlayClassName="mass-attr-tooltip"
          placement="bottom"
          title={<div className="mass-attr-tooltip-text">{PARTIAL_HIERARCHY_MESSAGE}</div>}
        >
          <ExclamationCircleOutlined className="mass-attr-exclamation-icon" />
        </Tooltip>
      );
    }
  };

  return (
    <div className="attr-mass-taxo-filter-wrapper">
      <div>
        <TaxonomyFilter
          className="attr-mass-taxo-filter"
          bc={bc}
          ig={ig}
          ag={ag}
          businessCenters={bcs}
          itemGroups={igs}
          attributeGroups={ags}
          onChangeFilter={onChangeFilter}
          attributeFilters={attributeFilters}
        />
        {renderMessage()}
      </div>
      {renderToolTip()}
    </div>
  );
}
