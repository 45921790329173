import React from 'react';
import { Chart } from 'react-google-charts';
import _ from 'lodash';

class AttributionAttributeTypeBarChart extends React.Component {
  calculate() {
    let data = [
      ['Attribute Type', 'Complete', 'In-Complete', { role: 'annotation' }],
      ['PRODUCT ATTRIBUTES', 0, 0, ''],
      ['PRODUCT DIET TYPES', 0, 0, ''],
      ['PRODUCT ALLERGENS', 0, 0, ''],
      ['PRODUCT CLAIMS', 0, 0, ''],
      ['PRODUCT CERTIFICATIONS', 0, 0, '']
    ];

    const calculateCount = (item, attrType, index) => {
      // if (_.isUndefined(item?.dim?.attr?.at?.[attrType])) return;

      if (item?.dim?.attr?.at?.[attrType] === 100) {
        data[index][1] += 1;
      } else {
        data[index][2] += 1;
      }
    };

    _.forEach(this.props.summaryItems, item => {
      calculateCount(item, 'pa', 1);
      calculateCount(item, 'dt', 2);
      calculateCount(item, 'al', 3);
      calculateCount(item, 'cl', 4);
      calculateCount(item, 'ct', 5);
    });

    return data;
  }

  render() {
    const getAttributeTypeCode = name => {
      switch (name) {
        case 'PRODUCT ATTRIBUTES':
          return 'pa';
        case 'PRODUCT DIET TYPES':
          return 'dt';
        case 'PRODUCT ALLERGENS':
          return 'al';
        case 'PRODUCT CLAIMS':
          return 'cl';
        case 'PRODUCT CERTIFICATIONS':
          return 'ct';
      }
    };

    let { summaryItems, filterGraph } = this.props;

    let calculatedValues = this.calculate();

    if (summaryItems.length <= 0) {
      return <div></div>;
    }

    return (
      <div className="attribute-type-chart-wrapper" style={{ width: '350px' }}>
        <div className={'attribute-type-chart-title'}>PRODUCT ATTRIBUTE BY SECTION</div>
        <div className={'attribute-type-chart'}>
          <Chart
            chartType="BarChart"
            data={calculatedValues}
            legendToggle
            options={{
              width: 350,
              height: 300,
              legend: 'none',
              bar: { groupWidth: '50%' },
              isStacked: true,
              colors: ['#92bb88', '#e9705f'],
              backgroundColor: '#f9f6f8',
              tooltip: {
                textStyle: {
                  color: '#7b8c92'
                }
              },
              hAxis: {
                textStyle: {
                  color: '#7b8c92'
                },
                baselineColor: '#7b8c92'
              },
              vAxis: {
                textStyle: {
                  color: '#7b8c92',
                  fontSize: 9
                },
                baselineColor: '#7b8c92'
              }
            }}
            chartEvents={[
              {
                eventName: 'select',
                callback({ chartWrapper }) {
                  const { row, column } = chartWrapper.getChart().getSelection()[0],
                    dataRow = row + 1,
                    attrName = calculatedValues[dataRow][0],
                    attrCode = getAttributeTypeCode(attrName),
                    complete = column === 1 ? 'complete' : 'incomplete';
                  filterGraph([{ param: attrCode, val: complete }], []);
                }
              }
            ]}
          />
        </div>
      </div>
    );
  }
}
export default AttributionAttributeTypeBarChart;
