import React from 'react';
import { Dropdown, Menu, Button, Icon } from 'antd';
import { bindActionCreators } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

import * as actionCreators from '../../actions';
import { MAIN_ATTRIBUTE_FILTER_OPTIONS, ATTRIBUTE_FILTER_OPTIONS } from '../../util/Constants';
import { isPartialHierarchy, isNonFoodBc } from '../../util/Util';

export default function AttributeFilter({ onFilterChange }) {
  const dispatch = useDispatch();
  const { updateAttributeFilters } = bindActionCreators(actionCreators, dispatch);

  const attributeFilters = useSelector(state => _.get(state.massUpdate.attribute, 'filters.attributeFilters', []));
  const bc = useSelector(state => _.get(state.massUpdate.attribute, 'filters.bc', null));
  const ag = useSelector(state => _.get(state.massUpdate.attribute, 'filters.ag', null));
  const completeTaxonomy = useSelector(state => _.get(state.massUpdate.attribute, 'completeTaxonomy', []));

  const isPartial = isPartialHierarchy(bc, ag);

  const onSelectAttribute = newAttribute => {
    const index = _.findIndex(attributeFilters, attr => newAttribute === attr);

    if (index === -1) {
      const clone = [...attributeFilters, newAttribute];
      updateAttributeFilters({ filters: clone, isPartial });
      onFilterChange();
    }
  };

  const onRemoveAttribute = value => {
    let remainingFilters = _.filter(attributeFilters, attr => value !== attr);
    updateAttributeFilters({ filters: remainingFilters, isPartial });
    onFilterChange();
  };

  const renderDropdown = () => {
    const menuItems = _.map(MAIN_ATTRIBUTE_FILTER_OPTIONS, attr => {
      const disablePartial = isPartial && _.includes(['Product Attributes', 'Certifications'], attr);
      // const disableNonfood = isNonFoodBc(completeTaxonomy, bc) && attr !== 'Product Attributes';
      const disabled = disablePartial; //|| disableNonfood;

      if (!_.includes(['Claims', 'Certifications'], attr)) {
        return (
          <Menu.Item key={attr} disabled={disabled} onClick={() => onSelectAttribute(attr)}>
            {attr}
          </Menu.Item>
        );
      } else {
        const subMenus = _.filter(ATTRIBUTE_FILTER_OPTIONS, i => _.includes(i, attr));

        return (
          <Menu.SubMenu key={attr} title={`Product ${attr}`} disabled={disabled}>
            {_.map(subMenus, sub => {
              return (
                <Menu.Item key={sub} disabled={disabled} onClick={() => onSelectAttribute(sub)}>
                  {sub}
                </Menu.Item>
              );
            })}
          </Menu.SubMenu>
        );
      }
    });

    const menu = <Menu>{menuItems}</Menu>;

    return (
      <Dropdown className="attr-mass-filter-dropdown" overlay={menu} trigger={['click']}>
        <Button>
          Select <Icon type="down" />
        </Button>
      </Dropdown>
    );
  };

  const renderFiltersList = () => {
    return (
      <div className="mass-attr-filters-list">
        {_.map(attributeFilters, attr => {
          return (
            <div key={attr} className="attribute-block">
              <div>{attr}</div>
              <div className="btn-remove-attr" onClick={() => onRemoveAttribute(attr)}>
                x
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="attr-mass-filter">
      <div className="attr-filter-label">Attribute List Filters</div>
      {renderDropdown()}
      {renderFiltersList()}
    </div>
  );
}
