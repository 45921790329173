import React from 'react';
import { Chart } from 'react-google-charts';

class AttributionBrandBarChart extends React.Component {
  calculate() {
    let { summaryItems } = this.props;

    let data = [];
    data.push(['Brand', 'Complete', 'In-Complete', { role: 'annotation' }]);

    let brandData = [],
      brands = [];

    summaryItems.forEach(item => {
      let itemBrand = item.prm && item.prm.br ? item.prm.br : '';
      if (itemBrand !== '' && item.dim.attr) {
        if (!brandData[itemBrand]) {
          brandData[itemBrand] = {
            completed: 0,
            incomplete: 0,
            sysco: item.prm.sy === '1'
          };
          brands.push(itemBrand);
        }
        if (item.dim.attr.cmpt) {
          brandData[itemBrand].completed++;
        } else {
          brandData[itemBrand].incomplete++;
        }
      }
    });

    const brandSort = (a, b) => {
      let aBrand = brandData[a],
        bBrand = brandData[b],
        aTotal = aBrand.completed + aBrand.incomplete,
        bTotal = bBrand.completed + bBrand.incomplete;
      if (aBrand.incomplete === 0 && bBrand.incomplete !== 0) {
        return 1; // ---- because, decending
      }
      if (aBrand.incomplete !== 0 && bBrand.incomplete === 0) {
        return -1;
      }
      if (aBrand.sysco && !bBrand.sysco) {
        return -1; // --- because, decending
      }
      if (!aBrand.sysco && bBrand.sysco) {
        return 1;
      }
      if (aTotal < bTotal) {
        return 1;
      } else {
        return -1;
      }
    };

    const makeSortingBrands = sortFunction => brands.sort(sortFunction);

    let maxBrands = 10;
    let brandSorted = makeSortingBrands(brandSort);

    let index = 0;
    brandSorted.forEach(brand => {
      if (index >= maxBrands) {
        return;
      }
      index++;
      data.push([brand, brandData[brand].completed, brandData[brand].incomplete, '']);
    });

    return data;
  }

  render() {
    let { filterGraph } = this.props;
    let calculatedValues = this.calculate();

    if (calculatedValues.length <= 1) {
      return <div></div>;
    }

    let height = 300,
      groupWidth = 50,
      width = 350;
    if (calculatedValues.length > 15) {
      width = 600;
      if (calculatedValues.length > 50) {
        groupWidth = 95;
        width = 1000;
      }
    }

    return (
      <div className="brand-chart-wrapper" style={{ height: `${height}px`, width: `${width}px` }}>
        <div className={'brand-chart-title'}>PRODUCT ATTRIBUTE COMPLETION BY BRAND</div>
        <div className={'brand-chart'}>
          <Chart
            chartType="ColumnChart"
            data={calculatedValues}
            legendToggle
            options={{
              width: width,
              height: height,
              legend: 'none',
              bar: { groupWidth: `${groupWidth}%`, groupHeight: `${groupWidth}%` },
              isStacked: true,
              colors: ['#92bb88', '#e9705f'],
              backgroundColor: '#f9f6f8',
              tooltip: {
                textStyle: {
                  color: '#7b8c92'
                }
              },
              hAxis: {
                textStyle: {
                  color: '#7b8c92',
                  fontSize: 7
                },
                baselineColor: '#7b8c92'
              },
              vAxis: {
                textStyle: {
                  color: '#7b8c92'
                },
                baselineColor: '#7b8c92',
                format: '0'
              }
            }}
            chartEvents={[
              {
                eventName: 'select',
                callback({ chartWrapper }) {
                  const { row, column } = chartWrapper.getChart().getSelection()[0],
                    dataRow = row + 1,
                    brand = calculatedValues[dataRow][0],
                    complete = column === 1 ? '1' : '0';
                  filterGraph(
                    [
                      { param: 'brand', val: brand },
                      { param: 'comp_attr', val: complete }
                    ],
                    ['comp_attr', 'brand']
                  );
                }
              }
            ]}
          />
        </div>
      </div>
    );
  }
}
export default AttributionBrandBarChart;
