import React from 'react';
import { childNutritionProductFormulationKeys } from '../../enums';
import { getIsFeedbackChangedClassName, getChangedTitle } from '../../util/Util';

const ChildNutritionRowData = ({ field, value, className }) => {
  return (
    <tr className={className} key={field} title={getChangedTitle(className)}>
      <th>{field}</th>
      <th className="white">{value}</th>
    </tr>
  );
};

const formatToString = value => {
  try {
    return value.toString();
  } catch (e) {
    return 'N/A';
  }
};

const ChildNutritionData = ({ comment, mainKey }) => {
  return (
    <div className="nutrition-container">
      <div className="nutrition-title">Child Nutritions/ Product Formulation Statements</div>
      <div key={mainKey}>
        <table className="nutrition-data-table">
          <tbody className="nutritions-tbody">
            {Object.keys(childNutritionProductFormulationKeys).map(function(childNutritionKey, childNutritionIndex) {
              return (
                <ChildNutritionRowData
                  field={childNutritionProductFormulationKeys[childNutritionKey]}
                  value={formatToString(comment[childNutritionKey])}
                  key={childNutritionKey}
                  className={getIsFeedbackChangedClassName(comment.changedFields, childNutritionKey)}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ChildNutritionData;
