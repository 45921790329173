import React from 'react';
import { Button } from 'antd';

class ImageMissing extends React.Component {
  render() {
    let { actions, item, imageCategoryName, imageCategory, existingStyles } = this.props;

    const commentsPopupNewImage = () => {
      actions.commentsPopup({
        item: item,
        field: 'Add New Image',
        currentValue: '',
        fieldType: 'IMAGE_NEW',
        fieldCaption: ' ',
        existingComment: null,
        imageCategoryName: imageCategoryName,
        imageCategory: imageCategory,
        existingStyles: existingStyles
      });
    };

    return (
      <div className="image-wrapper">
        <div className="image-overlay missing">
          <Button
            type="primary"
            disabled={item.readOnly}
            className="btn-new-image"
            onClick={commentsPopupNewImage}
            title="Upload images"
          >
            +
          </Button>
        </div>
      </div>
    );
  }
}

export default ImageMissing;
