import React from 'react';
import { Checkbox } from 'antd';
import { getUpdatedTaxonomy, removeFromUpdatedTaxonomy } from '../../util/Util';

const SustainabilityMassUpdateCheckBox = ({
  description,
  isDisabled,
  item: { taxonomy, taxonomyChanges },
  filter,
  textToFilter,
  actions
}) => {
  const getRelatedAttributes = () => {
    let filteredAttr = [];
    if (taxonomy && taxonomy.attributes && taxonomy.attributes.length > 0) {
      filteredAttr = taxonomy.attributes.filter(ele => filter(ele, true));
    }
    return filteredAttr;
  };

  const filterPickListValues = () => {
    const attributes = getRelatedAttributes();
    let updatedDataArray = [];
    attributes.forEach(({ picklist, attrId, name, source }) => {
      const filteredPicklist = picklist.filter(({ value }) => value.indexOf(textToFilter) > -1);
      if (filteredPicklist && filteredPicklist.length) {
        updatedDataArray = [...updatedDataArray, { attrId, selected: filteredPicklist[0].id, name, source }];
      }
    });
    return updatedDataArray;
  };

  const checkBoxChanged = value => {
    let updatedTaxonomyData = [...taxonomyChanges];
    filterPickListValues().forEach(({ attrId: id, selected }) => {
      const existingFieldIndex = updatedTaxonomyData.findIndex(({ attrId }) => attrId === id.toString());
      if (value.target.checked) {
        updatedTaxonomyData = [
          ...getUpdatedTaxonomy(existingFieldIndex, updatedTaxonomyData, id.toString(), selected.toString())
        ];
      } else {
        updatedTaxonomyData = [...removeFromUpdatedTaxonomy(existingFieldIndex, updatedTaxonomyData)];
      }
    });
    actions.updateWholeTaxonomyAttributeChanges({ updatedTaxonomyData });
  };
  return (
    <Checkbox
      className="mass-free-from-checkbox"
      disabled={isDisabled || !getRelatedAttributes().length}
      onChange={checkBoxChanged}
    >
      {description}
    </Checkbox>
  );
};

export default SustainabilityMassUpdateCheckBox;
