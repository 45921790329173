export const feedback = {
  attributeItemStatus: {
    name: 'Item Status',
    tooltip: null,
    selected: null,
    validationMsg: 'Please select a valid Item Status',
    picklist: [
      { id: 'ITS000001', value: 'Item does not belong to supplier' },
      { id: 'ITS000002', value: 'Proprietary Item' }
      /*,{ id: 'ITS000003', value: 'Discontinued Item' }*/
    ],
    title: 'Item Status'
  },
  discontinuedItem: {
    name: 'Discontinued Item',
    tooltip: null,
    selected: null,
    validationMsg: 'Please select a valid Status',
    picklist: [
      { id: 'ITS000003-01', value: 'Discontinued, depleted' },
      { id: 'ITS000003-02', value: 'Discontinued, replacement offered' },
      { id: 'ITS000003-03', value: 'Discontinued, significant attribute changes' },
      { id: 'ITS000003-04', value: 'Duplicate' }
    ],
    title: 'Reason',
    attributeList: {
      'Discontinued,_replacement_offered': [
        {
          name: 'Product Number',
          tooltip: null,
          validationMsg: 'Please provide a valid Product Number',
          validation: /^\d{7}$/,
          id: 'ADL000001',
          selected: null,
          parent: 'discontinuedItem.attributeList.Discontinued,_replacement_offered',
          title: 'Product Number'
        },
        {
          name: 'GTIN',
          tooltip: null,
          validationMsg: 'Please provide a valid GTIN',
          validation: /^\d{14}$/,
          id: 'ADL000002',
          selected: null,
          parent: 'discontinuedItem.attributeList.Discontinued,_replacement_offered',
          title: 'GTIN'
        },
        {
          name: 'MPC',
          tooltip: null,
          validationMsg: 'Please provide a valid MPC',
          validation: /^[a-zA-Z0-9#_.-]*$/,
          id: 'ADL000003',
          selected: null,
          parent: 'discontinuedItem.attributeList.Discontinued,_replacement_offered',
          title: 'MPC'
        }
      ],
      Duplicate: [
        {
          name: 'Product Number',
          tooltip: null,
          validationMsg: 'Please provide a valid Product Number',
          validation: /^\d{7}$/,
          id: 'ADL000001',
          selected: null,
          parent: 'discontinuedItem.attributeList.Duplicate',
          title: 'Product Number'
        }
      ]
    }
  },
  notBelongsToSupplier: {
    name: 'Item does not belong to Supplier',
    tooltip: null,
    selected: null,
    validationMsg: 'Please select a valid Status',
    picklist: [
      { id: 'ITS000001-01', value: 'No longer produced by Supplier' },
      { id: 'ITS000002-02', value: 'Acquired by another company' },
      { id: 'ITS000003-03', value: `Product attributes aren't recognizable` },
      { id: 'ITS000004-04', value: 'Never My Item' }
    ],
    title: 'Reason',
    attributeList: {
      No_longer_produced_by_Supplier: [
        {
          name: 'Reason',
          tooltip: null,
          validationMsg: 'Please provide a reason',
          validation: /^(?!\s*$).+/,
          id: 'ADL000000',
          selected: null,
          parent: 'notBelongsToSupplier.attributeList.No_longer_produced_by_Supplier',
          title: 'Reason for no longer produced by supplier'
        },
        {
          name: 'Product Number',
          tooltip: null,
          validationMsg: 'Please provide a valid Product Number',
          validation: /^\d{7}$/,
          id: 'ADL000001',
          selected: null,
          parent: 'notBelongsToSupplier.attributeList.No_longer_produced_by_Supplier',
          title: 'Product Number'
        },
        {
          name: 'GTIN',
          tooltip: null,
          validationMsg: 'Please provide a valid GTIN',
          validation: /^\d{14}$/,
          id: 'ADL000002',
          selected: null,
          parent: 'notBelongsToSupplier.attributeList.No_longer_produced_by_Supplier',
          title: 'GTIN'
        },
        {
          name: 'MPC',
          tooltip: null,
          validationMsg: 'Please provide a valid MPC',
          validation: /^[a-zA-Z0-9#_.-]*$/,
          id: 'ADL000003',
          selected: null,
          parent: 'notBelongsToSupplier.attributeList.No_longer_produced_by_Supplier',
          title: 'MPC'
        }
      ],
      Acquired_by_another_company: [
        {
          name: 'Reason',
          tooltip: null,
          validationMsg: 'Please provide a reason',
          validation: /^(?!\s*$).+/,
          id: 'ADL000001',
          selected: null,
          parent: 'notBelongsToSupplier.attributeList.Acquired_by_another_company',
          title: 'Acquired By Reason'
        },
        {
          name: 'Acquired By',
          tooltip: null,
          validationMsg: 'Please provide acquired by',
          validation: /^(?!\s*$).+/,
          id: 'ADL000002',
          selected: null,
          parent: 'notBelongsToSupplier.attributeList.Acquired_by_another_company',
          title: 'Reason for Acquired By'
        }
      ],
      Product_attributes_arent_recognizable: [
        {
          name: 'Reason',
          tooltip: null,
          validationMsg: 'Please provide a reason',
          validation: /^(?!\s*$).+/,
          id: 'ADL000001',
          selected: null,
          parent: `notBelongsToSupplier.attributeList.Product_attributes_arent_recognizable`,
          title: `Reason for Product attributes aren't recognizable`
        },
        {
          name: 'Attributes not mine',
          tooltip: null,
          validationMsg: 'Please provide acquired by',
          validation: /^(?!\s*$).+/,
          id: 'ADL000002',
          selected: null,
          parent: `notBelongsToSupplier.attributeList.Product_attributes_arent_recognizable`,
          title: 'Attributes not mine'
        }
      ],
      Never_My_Item: [
        {
          name: 'Reason',
          tooltip: null,
          validationMsg: 'Please provide a reason',
          validation: /^(?!\s*$).+/,
          id: 'ADL000001',
          selected: null,
          parent: `notBelongsToSupplier.attributeList.Never_My_Item`,
          title: `Reason for Never My Item`
        },
        {
          name: 'Never my item',
          tooltip: null,
          validationMsg: 'Please provide never my item',
          validation: /^(?!\s*$).+/,
          id: 'ADL000002',
          selected: null,
          parent: `notBelongsToSupplier.attributeList.Never_My_Item`,
          title: 'Reason for not been mine'
        }
      ]
    }
  },
  attributeDiscontinueList: [
    {
      name: 'Product Number',
      tooltip: null,
      validationMsg: 'Please provide a valid Product Number',
      validation: /^\d{7}$/,
      id: 'ADL000001',
      selected: null
    },
    {
      name: 'GTIN',
      tooltip: null,
      validationMsg: 'Please provide a valid GTIN',
      validation: /^\d{14}$/,
      id: 'ADL000002',
      selected: null
    },
    {
      name: 'MPC',
      tooltip: null,
      validationMsg: 'Please provide a valid MPC',
      validation: /^[a-zA-Z0-9#_.-]*$/,
      id: 'ADL000003',
      selected: null
    }
  ]
};

export default feedback;
