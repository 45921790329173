import React from 'react';
import { Checkbox, Select } from 'antd';
import _ from 'lodash';
import ImageOverallDonutChart from './ImageOverallDonutChart';
import ImageTypeChart from './ImageTypeChart';
import ImageBrandBarChart from './ImageBrandBarChart';
import { imageCategories } from '../util/Data';
import {
  filterSummaryItems,
  parseJson,
  appendToArrayWithSpread,
  getValueBasedOnTheCondition,
  sortArray,
  stringifyJson,
  getFilterGraphParameters,
  getConditionStatus
} from '../util/Util';

class ImageCharts extends React.Component {
  render() {
    let {
      summaryItems,
      actions,
      suvc,
      graphFilter,
      filterForGraphs,
      taxonomy,
      brands,
      tableFilters,
      orderBy,
      brandGroupFilters,
      tableSearchData,
      isComplexRendering,
      clearAllFilters,
      isHiddingFilters
    } = this.props;

    const { Option } = Select;

    const paramTypes = ['ip', 'ii', 'io', 'ic', 'il'];

    const stripFromFilter = strip => {
      const existingGraphFilter = JSON.parse(graphFilter);
      const strippedFilters = _.filter(existingGraphFilter, filterObj => {
        return strip.indexOf(filterObj.param) === -1;
      });
      return strippedFilters;
    };

    const filterGraph = (add, remove) => {
      let filterStr = null;

      let currentGraphFilter = parseJson(graphFilter);

      currentGraphFilter = stripFromFilter(remove);

      currentGraphFilter = appendToArrayWithSpread(currentGraphFilter, add);

      filterStr = stringifyJson(currentGraphFilter);

      actions.loadItemList({
        suvc,
        page: 1,
        search: '',
        subSuvc: null,
        graphfilter: filterStr,
        tableFilters,
        orderBy,
        brandGroupFilters,
        tableSearchData
      });
    };

    const applyFilterForGraph = filter => {
      const updatedFilter = { ...filterForGraphs, ...filter };
      actions.changeFilterForGraph({ filter: { ...updatedFilter } });
    };

    const applyBrandFilterForGraph = e => {
      const brandFilter = getValueBasedOnTheCondition(e.target.checked, '1', null);
      applyFilterForGraph({
        sysco: brandFilter
      });
      const { add, remove } = getFilterGraphParameters('sysco', brandFilter, ['sysco']);
      filterGraph(add, remove);
    };

    const applyBrandNameFilterForGraph = newBrandName => {
      applyFilterForGraph({
        brand: newBrandName
      });
      const { add, remove } = getFilterGraphParameters('brand', newBrandName, ['brand']);
      filterGraph(add, remove);
    };

    const applyBcFilterForGraph = newBc => {
      applyFilterForGraph({
        bc: newBc,
        ig: null,
        ag: null
      });
      const { add, remove } = getFilterGraphParameters('bc', newBc, ['bc', 'ig', 'ag']);
      filterGraph(add, remove);
    };

    const applyIgFilterForGraph = newIg => {
      applyFilterForGraph({
        bc: filterForGraphs.bc,
        ig: newIg,
        ag: null
      });
      const { add, remove } = getFilterGraphParameters('ig', newIg, ['ig', 'ag']);
      filterGraph(add, remove);
    };

    const applyAgFilterForGraph = newAg => {
      applyFilterForGraph({
        bc: filterForGraphs.bc,
        ig: filterForGraphs.ig,
        ag: newAg
      });
      const { add, remove } = getFilterGraphParameters('ag', newAg, ['ag']);
      filterGraph(add, remove);
    };

    const filteredItems = filterSummaryItems(summaryItems, filterForGraphs, graphFilter, tableFilters);
    let businessCenters = Object.getOwnPropertyNames(taxonomy);
    let itemGroups = [];
    let attributeGroups = [];

    if (filterForGraphs.bc !== null) {
      itemGroups = Object.getOwnPropertyNames(taxonomy[filterForGraphs.bc]);
      if (filterForGraphs.ig !== null) {
        attributeGroups = Object.getOwnPropertyNames(taxonomy[filterForGraphs.bc][filterForGraphs.ig]);
      }
    }

    if (summaryItems && summaryItems.length > 0) {
      let graphsPanel = null;
      if (filteredItems.length === 0) {
        graphsPanel = <div className="loading-msg">No data to visualize for the selected filter.</div>;
      } else {
        graphsPanel = (
          <div className="attribution-charts-wrapper-graphs">
            <ImageOverallDonutChart summaryItems={filteredItems} filterGraph={filterGraph} paramTypes={paramTypes} />
            {_.map(imageCategories, imageCat => (
              <ImageTypeChart
                typeField={imageCat.scoreField}
                typeCaption={imageCat.caption}
                summaryItems={filteredItems}
                filterGraph={filterGraph}
                paramTypes={paramTypes}
              />
            ))}
            <ImageBrandBarChart summaryItems={filteredItems} filterGraph={filterGraph} paramTypes={paramTypes} />
          </div>
        );
      }

      brands = sortArray(brands);
      businessCenters = sortArray(businessCenters);
      itemGroups = sortArray(itemGroups);
      attributeGroups = sortArray(attributeGroups);

      return (
        <div className="attribution-charts-wrapper">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className={`filter-fanel${graphFilter ? ' active' : ''}`}
            version="1.1"
            id="Layer_1"
            x="0px"
            y="0px"
            style={{ enableBackground: 'new -100 -100 700 700' }}
            fill="#abac92"
            viewBox="-100 -120 700 700"
            onClick={clearAllFilters}
          >
            <g>
              <path d="M241.7,512H192V253.7L12.8,40.3V0h486.4v40.1L328.5,253.5v171.8L241.7,512z M226.1,477.9h1.5l66.8-66.8V241.5L460.3,34.1   H52.2l174,207.1L226.1,477.9z" />
            </g>
          </svg>
          {!isHiddingFilters && (
            <div className="score-filters-row">
              <Select
                defaultValue={filterForGraphs.brand}
                onChange={applyBrandNameFilterForGraph}
                className="graph-brand-filter"
              >
                <Option value={null} key="0">
                  All Brands
                </Option>
                {brands.map(brandOption => {
                  return (
                    <Option value={brandOption} key={brandOption}>
                      {brandOption}
                    </Option>
                  );
                })}
              </Select>
              <Select defaultValue={filterForGraphs.bc} onChange={applyBcFilterForGraph}>
                <Option value={null} key="0">
                  All Business Centers
                </Option>
                {businessCenters.map(bcOption => {
                  return (
                    <Option value={bcOption} key={bcOption}>
                      {bcOption}
                    </Option>
                  );
                })}
              </Select>
              <Select
                defaultValue={filterForGraphs.ig}
                disabled={getConditionStatus(itemGroups.length === 0)}
                onChange={applyIgFilterForGraph}
              >
                <Option value={null} key="0">
                  All Item Groups
                </Option>
                {itemGroups.map(igOption => {
                  return (
                    <Option value={igOption} key={igOption}>
                      {igOption}
                    </Option>
                  );
                })}
              </Select>
              <Select
                defaultValue={filterForGraphs.ag}
                disabled={getConditionStatus(attributeGroups.length === 0)}
                onChange={applyAgFilterForGraph}
              >
                <Option value={null} key="0">
                  All Attribute Groups
                </Option>
                {attributeGroups.map(agOption => {
                  return (
                    <Option value={agOption} key={agOption}>
                      {agOption}
                    </Option>
                  );
                })}
              </Select>
              <Checkbox
                defaultChecked={getConditionStatus(filterForGraphs.sysco === '1')}
                onChange={applyBrandFilterForGraph}
              >
                Sysco Brand
              </Checkbox>
            </div>
          )}
          {graphsPanel}
          {isComplexRendering && (
            <div className="filter-complex-container">
              <span className="warning-text">* Filters are too complex to render graphs</span>
              <span onClick={clearAllFilters} className="reset-span">
                Reset filters
              </span>
            </div>
          )}
        </div>
      );
    }
    return (
      <div className="attribution-charts-wrapper">
        <div className="loading-msg">Loading....</div>
      </div>
    );
  }
}

export default ImageCharts;
