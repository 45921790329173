import React from 'react';
import { Input, Button, Modal, Table, Select, Tag, Icon, Tooltip } from 'antd';
import ReactResizeDetector from 'react-resize-detector';
import _ from 'lodash';

import ItemDatesBar from './ItemDatesBar';
import isEmpty from 'validator/lib/isEmpty';
import {
  featuresAndBenifitsCommentKeys,
  fnbCommentAttributes,
  nonFoodItemBusinessCenterNames,
  gdsnFnbTooltips
} from '../util/Data';
import {
  filterCollectFnBComments,
  restructureFnBCollectStatement,
  tableStyles,
  isEligibleForGdsnFnb,
  getFnbFieldName,
  checkObjectPropertyExists,
  replaceFirstBullets,
  getButtonTitle,
  getValueBasedOnTheCondition,
  checkStatusWithThreeAndConditions,
  getValuesFromTwoFields,
  checkStatusWithTwoAndConditions,
  getFieldDisabledStatusByTwoParams,
  getFieldCommentValueIdOrDefault,
  checkNonFoodStatusForFieldComment,
  getFieldKeyOrAssignDefaultValue,
  checkValueAndReturnElement,
  addBulletOnNewLine,
  getRequiredFnbFields
} from '../util/Util';
import { formatFnb, findRepeatedFnb } from './FnBFormatter';

import { createIndexedDbConnection } from '../util/Connection';
import {
  FNB_CHARACTER_LIMIT,
  PRODUCT_DESCRIPTOR_CHARACTER_LIMIT,
  OVERALL_FNB_CHARACTER_LIMIT,
  FnB_TYPE,
  GDSN_VALUE,
  FnB_TAB,
  THREE_DOTS,
  IGNORING_GDSN_FNB_ATTRIBUTES,
  FNB_KEYS,
  FNB_EXCLUDED_WORDS
} from '../util/Constants';

const indexedDb = createIndexedDbConnection('item-management');
const { TextArea } = Input;
let { Option } = Select;

class FieldComment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: '',
      userInput: { value: '', error: 'default' },
      execution: 0,
      fnbComment: {
        genFnb: { value: '', error: '' },
        descriptor: { value: '', error: '' },
        packaging: { value: '', error: '' },
        size: { value: '', error: '' },
        yield: { value: '', error: '' },
        quality: { value: '', error: '' },
        prep: { value: '', error: '' },
        storage: { value: '', error: '' },
        handling: { value: '', error: '' },
        additional: { value: '', error: '' },
        marketing: { value: '', error: '' },
        culinary: { value: '', error: '' },
        msg: '',
        loading: false,
        visible: false
      },
      previousFnbs: {},
      imageComment: {
        image: {
          fileList: '',
          value: '',
          error: ''
        },
        imageStyle: ''
      },
      exampleVisibility: {
        descriptor: false,
        packaging: false,
        size: false,
        yield: false,
        quality: false,
        preop: false,
        storage: false,
        handling: false,
        additional: false,
        marketing: false,
        culinary: false
      },
      isNonFoodItem: false,
      isAutoSaveData: false,
      autoSavedData: null,
      isContinuedWithPreviousData: false,
      isEditMode: true,
      isSelectedAutoSavedData: false
    };
    this.sendFieldComments = this.sendFieldComments.bind(this);
    this.collectComments = this.collectComments.bind(this);
  }

  genarateFnBComments(fnbComment) {
    return filterCollectFnBComments(fnbComment, true);
  }

  collectFnBComments(fnbComment) {
    return filterCollectFnBComments(fnbComment);
  }

  componentDidMount() {
    this.configureIndexedDb('supplier');
  }

  componentDidUpdate(prevProps) {
    try {
      const { fieldComment: { item: { supc } } = { item: {} } } = prevProps;
      const {
        fieldComment: {
          item: {
            supc: propsSupc,
            activeTab,
            basicData: { gtin, gdsn }
          },
          type
        } = { item: {} },
        isGdsnFetchedOnce
      } = this.props;
      if (supc !== propsSupc && propsSupc) {
        this.fetchSpecificDataFromIndexedDb('supplier', propsSupc);
        if (type === FnB_TYPE && gdsn === GDSN_VALUE && activeTab === FnB_TAB && !isGdsnFetchedOnce) {
          this.props.actions.getGdsnFnb({ gtin });
        }
      }
    } catch (error) {}
  }

  configureIndexedDb = async tableName => {
    try {
      await indexedDb.createObjectStore([{ tableName: tableName, indexes: 'supc', indexFieldArray: [] }]);
    } catch (error) {}
  };

  fetchSpecificDataFromIndexedDb = async (tableName, condition) => {
    try {
      const results = await indexedDb.getValue(tableName, condition);
      if (results) {
        this.setState({
          ...this.state,
          isAutoSaveData: true,
          autoSavedData: results?.fnb || null,
          isEditMode: false
        });
      }
    } catch (error) {
      return null;
    }
  };

  handleContinoueWithPreviousData = () => {
    this.setState({
      ...this.state,
      fnbComment: this.state.autoSavedData,
      isAutoSaveData: false,
      autoSavedData: null,
      isContinuedWithPreviousData: true,
      comment: this.state.autoSavedData?.genFnb?.value || ''
    });
  };

  handleCancelContinouData = async () => {
    const { fieldComment: { item: { supc } } = { item: {} } } = this.props;
    await indexedDb.deleteValue('supplier', supc);
    this.setState({
      ...this.state,
      isAutoSaveData: false
    });
  };

  static getDerivedStateFromProps(props, state) {
    const fieldComment = props.fieldComment;
    if (
      checkStatusWithThreeAndConditions(
        fieldComment.field === 'fnb',
        fieldComment.pending,
        fieldComment.execution > state.execution
      )
    ) {
      const PendingComment = fieldComment.pending;
      const predReq =
        fieldComment &&
        fieldComment.item &&
        fieldComment.item.taxonomy &&
        fieldComment.item.taxonomy.bc &&
        !nonFoodItemBusinessCenterNames.includes(fieldComment.item.taxonomy.bc.name);
      return {
        ...state,
        execution: 1,
        comment: getValueBasedOnTheCondition(fieldComment.existingComment, fieldComment.existingComment, ''),
        fnbComment: {
          ...state.fnbComment,
          genFnb: {
            value: getValueBasedOnTheCondition(fieldComment.existingComment, fieldComment.existingComment, ''),
            error: ''
          },
          descriptor: {
            ...state.fnbComment.descriptor,
            value: getValuesFromTwoFields(replaceFirstBullets(PendingComment.descriptor), ''),
            error: '',
            required: true
          },
          packaging: {
            ...state.fnbComment.packaging,
            value: getValuesFromTwoFields(replaceFirstBullets(PendingComment.packaging), ''),
            error: '',
            required: true
          },
          size: {
            ...state.fnbComment.size,
            value: getValuesFromTwoFields(replaceFirstBullets(PendingComment.size), ''),
            error: '',
            required: true
          },
          yield: {
            ...state.fnbComment.yield,
            value: getValuesFromTwoFields(replaceFirstBullets(PendingComment.yield), ''),
            error: '',
            required: true
          },
          quality: {
            ...state.fnbComment.quality,
            value: getValuesFromTwoFields(replaceFirstBullets(PendingComment.quality), ''),
            error: '',
            required: true
          },
          prep: {
            ...state.fnbComment.prep,
            value: getValuesFromTwoFields(replaceFirstBullets(PendingComment.prep), ''),
            error: '',
            required: predReq
          },
          storage: {
            ...state.fnbComment.storage,
            value: getValuesFromTwoFields(replaceFirstBullets(PendingComment.storage), ''),
            error: '',
            required: true
          },
          handling: {
            ...state.fnbComment.handling,
            value: getValuesFromTwoFields(replaceFirstBullets(PendingComment.handling), ''),
            error: '',
            required: true
          },
          additional: {
            ...state.fnbComment.additional,
            value: getValuesFromTwoFields(replaceFirstBullets(PendingComment.additional), ''),
            error: '',
            required: true
          },
          marketing: {
            ...state.fnbComment.marketing,
            value: getValuesFromTwoFields(replaceFirstBullets(PendingComment.marketing), ''),
            error: '',
            required: false
          },
          culinary: {
            ...state.fnbComment.culinary,
            value: getValuesFromTwoFields(replaceFirstBullets(PendingComment.culinary), ''),
            error: '',
            required: false
          },
          msg: '',
          loading: false,
          visible: false,
          isNonFoodItem: !predReq
        }
      };
    } else if (
      fieldComment &&
      fieldComment.item &&
      fieldComment.item.basicData &&
      fieldComment.userInput &&
      fieldComment.execution > state.execution
    ) {
      return {
        ...state,
        execution: 1,
        userInput: {
          value: fieldComment.existingUserInput,
          error: getValueBasedOnTheCondition(fieldComment.existingUserInput, '', 'default')
        },
        comment: fieldComment.existingComment
      };
    } else if (
      fieldComment &&
      fieldComment.item &&
      fieldComment.item.basicData &&
      fieldComment.execution > state.execution
    ) {
      return {
        ...state,
        execution: 1,
        comment: fieldComment.existingComment
      };
    }
    return {
      ...state
    };
  }

  collectComments(comment) {
    this.setState({
      comment: comment
    });
  }

  userConfirmation(fnbComment, previousComment) {
    let msg = [];

    for (const [key, { name }] of Object.entries(featuresAndBenifitsCommentKeys)) {
      if (replaceFirstBullets(previousComment[key]) !== fnbComment[key].value) {
        msg.push({
          Field: name,
          Previous: replaceFirstBullets(previousComment[key]) || 'No Statement',
          New: fnbComment[key].value || ' Statement Removed '
        });
      }
    }
    if (msg.length > 0) {
      this.setState({
        fnbComment: {
          ...fnbComment,
          msg: msg,
          visible: true
        }
      });
    } else {
      let { actions } = this.props;
      actions.closeCommentsPopup();
    }
  }

  validataFnBComments(fnbComment) {
    if (!fnbComment) return false;

    let flag = true;
    let { fieldComment } = this.props;
    let totalCharacterCount = this.getOverallStatementCharactersCount();
    const requiredFields = getRequiredFnbFields(_.get(fieldComment, 'item', null));
    const repeatedFnb = this.getRepeatedFnbs(fnbComment);

    for (const field of FNB_KEYS) {
      if (!fnbComment[field]) {
        flag = false;
        break;
      }

      if (_.includes(repeatedFnb, field)) {
        fnbComment[field].error = 'Cannot have repeated content';
        flag = false;
      } else if (_.includes(requiredFields, field) && _.isEmpty(fnbComment[field].value)) {
        fnbComment[field].error = `${getFnbFieldName(field)} Cannot be Empty`;
        flag = false;
      } else if (this.hasMeaninglessContent(fnbComment[field].value)) {
        fnbComment[field].error = 'Please add value added content';
        flag = false;
      } else if (this.hasExcludedWords(0, fnbComment[field].value)) {
        fnbComment[field].error = this.getExcludedWordsMessage(0, fnbComment[field].value);
        flag = false;
      } else if (this.hasExcludedWords(1, fnbComment[field].value)) {
        fnbComment[field].error = FNB_EXCLUDED_WORDS[1].message;
        flag = false;
      } else if (this.hasExcludedWords(2, fnbComment[field].value)) {
        fnbComment[field].error = FNB_EXCLUDED_WORDS[2].message;
        flag = false;
      } else if (this.hasReachedTheMaxCharacterLimit(field, fnbComment[field].value)) {
        fnbComment[field].error = 'Maximum number of characters reached';
        flag = false;
      } else {
        fnbComment[field].error = '';
      }
    }

    if (!fnbComment.genFnb || !fnbComment.genFnb.value || isEmpty(fnbComment.genFnb.value)) {
      fnbComment.genFnb.error = 'Features and Benefits statement Cannot be Empty';
      flag = false;
    } else if (totalCharacterCount > OVERALL_FNB_CHARACTER_LIMIT) {
      fnbComment.genFnb.error = `Features and Benefits statement longer than ${OVERALL_FNB_CHARACTER_LIMIT} words`;
      flag = false;
    } else {
      fnbComment.genFnb.error = '';
    }

    this.setState({
      fnbComment: fnbComment
    });

    return flag;
  }

  hasExcludedWords(index, value) {
    const flag = _.some(FNB_EXCLUDED_WORDS[index].values, keyword => {
      const regex = new RegExp(`\\b${keyword}\\b`, 'g');
      return regex.test(_.toLower(value));
    });

    return flag;
  }

  getExcludedWordsMessage(index, value) {
    const words = [];

    _.forEach(FNB_EXCLUDED_WORDS[index].values, keyword => {
      const regex = new RegExp(`\\b${keyword}\\b`, 'g');
      if (regex.test(_.toLower(value))) {
        words.push(keyword);
      }
    });

    return `${FNB_EXCLUDED_WORDS[index].message} ${words.join(', ')}`;
  }

  hasMeaninglessContent(value) {
    const regex = new RegExp(/^[^a-zA-Z0-9]+$/, 'g');
    return regex.test(_.toLower(value));
  }

  formatEligibleFnb(fnbComment) {
    const fnbRequest = {
      descriptor: fnbComment.descriptor.value,
      packaging: fnbComment.packaging.value,
      size: fnbComment.size.value,
      yield: fnbComment.yield.value,
      quality: fnbComment.quality.value,
      prep: fnbComment.prep.value,
      storage: fnbComment.storage.value,
      handling: fnbComment.handling.value,
      additional: fnbComment.additional.value,
      marketing: fnbComment.marketing.value,
      culinary: fnbComment.culinary.value
    };

    const formattedFnb = formatFnb(fnbRequest);
    const updatedFnbComment = this.updateStateWithFormattedFnb(fnbComment, formattedFnb);
    return updatedFnbComment;
  }

  updateStateWithFormattedFnb(fnbComment, formattedFnb) {
    let fnbCommentClone = { ...fnbComment };

    _.forEach(FNB_KEYS, key => {
      const value = formattedFnb[key];
      fnbCommentClone[key] = { ...fnbCommentClone[key], value };
    });

    const genFnb = this.genarateFnBComments(fnbCommentClone);

    fnbCommentClone = {
      ...fnbCommentClone,
      genFnb: {
        ...fnbComment.genFnb,
        value: genFnb
      }
    };

    this.setState({ comment: genFnb, fnbComment: fnbCommentClone });
    return fnbCommentClone;
  }

  getRepeatedFnbs(fnbComment) {
    const fnbRequest = {
      descriptor: _.get(fnbComment, 'descriptor.value', null),
      packaging: _.get(fnbComment, 'packaging.value', null),
      size: _.get(fnbComment, 'size.value', null),
      yield: _.get(fnbComment, 'yield.value', null),
      quality: _.get(fnbComment, 'quality.value', null),
      prep: _.get(fnbComment, 'prep.value', null),
      storage: _.get(fnbComment, 'storage.value', null),
      handling: _.get(fnbComment, 'handling.value', null),
      additional: _.get(fnbComment, 'additional.value', null),
      marketing: _.get(fnbComment, 'marketing.value', null),
      culinary: _.get(fnbComment, 'culinary.value', null)
    };

    return findRepeatedFnb(fnbRequest);
  }

  sendFieldComments() {
    let { fieldComment, actions } = this.props;

    if (fieldComment.type === 'FnB') {
      let { fnbComment } = this.state;

      fnbComment = this.formatEligibleFnb(fnbComment);

      if (this.validataFnBComments(fnbComment)) {
        if (
          !fieldComment.pending.descriptor &&
          !fieldComment.pending.packaging &&
          !fieldComment.pending.size &&
          !fieldComment.pending.yield &&
          !fieldComment.pending.quality &&
          !fieldComment.pending.prep &&
          !fieldComment.pending.storage &&
          !fieldComment.pending.handling &&
          !fieldComment.pending.additional &&
          !fieldComment.pending.marketing &&
          !fieldComment.pending.culinary
        ) {
          const item = fieldComment.item;
          const fnbValue = getValueBasedOnTheCondition(
            checkStatusWithThreeAndConditions(item.fnb, item.fnb.fnb, this.collectFnBComments(item.fnb.fnb)),
            this.collectFnBComments(item.fnb.fnb),
            'No Features & Benefits statement.'
          );
          let fnbRequest = {
            descriptor: fnbComment.descriptor.value,
            packaging: fnbComment.packaging.value,
            size: fnbComment.size.value,
            yield: fnbComment.yield.value,
            quality: fnbComment.quality.value,
            prep: fnbComment.prep.value,
            storage: fnbComment.storage.value,
            handling: fnbComment.handling.value,
            additional: fnbComment.additional.value,
            marketing: fnbComment.marketing.value,
            culinary: fnbComment.culinary.value
          };

          actions.sendFnBComment(item.supc, item.suvc, item.basicData, fnbValue, fnbRequest);
          return;
        }
        this.userConfirmation(fnbComment, fieldComment.pending);
        return;
      }
      return;
    } else if (fieldComment.type === 'IMAGE_NEW') {
      const { imageComment } = this.state;
      const item = fieldComment.item;
      actions.sendImageComment(item.supc, item.suvc, imageComment.image.value, imageComment.imageStyle);
      return;
    } else if (fieldComment.userInput) {
      if (fieldComment.userInput && this.state.userInput && this.state.userInput.value === '') {
        return false;
      }
      if (
        checkStatusWithTwoAndConditions(
          fieldComment.userInput.type === 'Select',
          getFieldDisabledStatusByTwoParams(!this.state.comment, this.state.comment === '')
        )
      ) {
        return false;
      }
      let comment = {
        field: fieldComment.field,
        originalValue: fieldComment.currentValue,
        type: fieldComment.type,
        comment:
          '[ ' +
          fieldComment.userInput.placeholder +
          ' -> ' +
          this.state.userInput.value +
          ' ] ' +
          getValueBasedOnTheCondition(this.state.comment, '\n[ Description -> ' + this.state.comment + ' ] ', ''),
        supc: fieldComment.item.supc,
        suvc: fieldComment.item.suvc,
        stepId: fieldComment.item.basicData.stepId,
        id: getValueBasedOnTheCondition(fieldComment.existingComment, fieldComment.existingComment.id, null)
      };
      actions.sendFieldComment({ comment: comment });
      return;
    }
    if (this.state.comment === '') {
      return false;
    }
    let comment = {
      field: fieldComment.field,
      originalValue: fieldComment.currentValue,
      type: fieldComment.type,
      comment: this.state.comment,
      supc: fieldComment.item.supc,
      suvc: fieldComment.item.suvc,
      stepId: fieldComment.item.basicData.stepId,
      id: getFieldCommentValueIdOrDefault(fieldComment.existingComment, null)
    };
    actions.sendFieldComment({ comment: comment });
  }

  handleOk = () => {
    let { fieldComment, actions } = this.props;
    const { fnbComment } = this.state;
    const item = fieldComment.item;
    const fnbValue =
      item.fnb && item.fnb.fnb && this.collectFnBComments(item.fnb.fnb)
        ? this.collectFnBComments(item.fnb.fnb)
        : 'No Features & Benefits statement.';
    let fnbRequest = {
      descriptor: fnbComment.descriptor.value,
      packaging: fnbComment.packaging.value,
      size: fnbComment.size.value,
      yield: fnbComment.yield.value,
      quality: fnbComment.quality.value,
      prep: fnbComment.prep.value,
      storage: fnbComment.storage.value,
      handling: fnbComment.handling.value,
      additional: fnbComment.additional.value,
      marketing: fnbComment.marketing.value,
      culinary: fnbComment.culinary.value
    };

    actions.sendFnBComment(item.supc, item.suvc, item.basicData, fnbValue, fnbRequest);
    this.setState({
      fnbComment: {
        ...fnbComment,
        loading: true
      },
      isContinuedWithPreviousData: false
    });
    setTimeout(() => {
      this.setState({
        fnbComment: {
          ...fnbComment,
          loading: false,
          visible: false
        }
      });
    }, 3000);
  };

  handleCancel = () => {
    const { fnbComment } = this.state;
    this.setState({
      fnbComment: {
        ...fnbComment,
        visible: false
      }
    });
  };

  handleImageupload = info => {
    const { imageComment } = this.state;

    if (this.handleImageValidation(info.file)) {
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);

      fileList = fileList.map(file => {
        file.status = 'done';
        return file;
      });

      this.setState({
        imageComment: {
          ...imageComment,
          image: {
            fileList: fileList,
            value: info.file,
            error: ''
          }
        }
      });
    }
  };

  handleImageTypeSelect = value => {
    const { imageComment } = this.state;
    this.setState({
      imageComment: {
        ...imageComment,
        imageStyle: value
      }
    });
  };

  handleImageValidation(file) {
    var flag = true;
    if (file.type !== 'image/png') {
      flag = false;
      let secondsToGo = 5;
      const modal = Modal.error({
        title: 'Invalid File Type',
        content: `${file.name} is not a png file.`
      });
      const timer = setInterval(() => {
        secondsToGo -= 1;
      }, 1000);
      setTimeout(() => {
        clearInterval(timer);
        modal.destroy();
      }, secondsToGo * 1000);
    }
    return flag;
  }

  onCustomRequest = file => {
    // this is intoduced to
  };

  handlePreviousDataSwitch = async status => {
    const { autoSavedData } = this.state;
    let data = {
      isEditMode: true
    };
    if (status) {
      data = {
        ...data,
        fnbComment: autoSavedData,
        comment: autoSavedData?.genFnb?.value || '',
        isSelectedAutoSavedData: true
      };
    } else {
      await this.handleCancelContinouData();
    }
    this.setState(data);
  };

  getOverallStatementCharactersCount = () => {
    let count = 0;

    _.forEach(fnbCommentAttributes, attribute => {
      let attributeObject = _.get(this.state.fnbComment, attribute, null);

      if (attributeObject) {
        let value = _.get(attributeObject, 'value', '');
        count = count + value.length;
      }
    });

    return count;
  };

  getGdsnFnbFields = field => {
    return _.get(this.props.gdsnFnb, `fnb.${field}`, '');
  };

  getFnbFieldValue = field => {
    return this.state.fnbComment[field] ? this.state.fnbComment[field].value : '';
  };

  getInitialCharacterCountForField = field => {
    let characterLimit = FNB_CHARACTER_LIMIT;
    if (field === 'descriptor') characterLimit = PRODUCT_DESCRIPTOR_CHARACTER_LIMIT;
    return characterLimit;
  };

  getValueForTheGdsnField = field => {
    let valueForTheFnbField, previousFnbs;
    if (checkObjectPropertyExists(this.state.previousFnbs, field) && !_.isEmpty(this.state.fnbComment[field].value)) {
      const { [field]: fieldValue, ...rest } = this.state.previousFnbs;
      valueForTheFnbField = fieldValue;
      previousFnbs = { ...rest };
    } else {
      const {
        [field]: { value }
      } = this.state.fnbComment;
      const gdsnFieldValue = replaceFirstBullets(this.getGdsnFnbFields(field));
      valueForTheFnbField = gdsnFieldValue.substring(0, this.getInitialCharacterCountForField(field));
      previousFnbs = {
        ...this.state.previousFnbs,
        [field]: value
      };
    }
    return { valueForTheFnbField, previousFnbs };
  };

  handleUserInput = (type, validation, e) => {
    if (type === 'Select') {
      if (e != null) {
        this.setState({
          userInput: { value: e.toString(), error: '' }
        });
      } else {
        this.setState({
          userInput: { value: null, error: validation.msg }
        });
      }
    } else if (type === 'Input') {
      try {
        if (validation.regex.test(e.target.value)) {
          this.setState({
            userInput: { value: e.target.value, error: '' }
          });
        } else {
          this.setState({
            userInput: { value: e.target.value, error: validation.msg }
          });
        }
      } catch (error) {}
    }
  };

  getUpdatedCommentsError = (reviewComment, field) => {
    let commentError = [];
    if (reviewComment && reviewComment.length > 0) {
      const selectedComment = _.find(reviewComment, { attribute: field });
      if (selectedComment) {
        const { status, comment } = selectedComment;
        commentError = [status, comment];
      }
    }
    return commentError;
  };

  getFnbDataUpdated = (fieldComment, fnbData) => {
    let data = fnbData;
    if (fieldComment.item && fieldComment.item.fnb) {
      data = fieldComment.item.fnb;
    }
    return data;
  };

  getItemBasicData = (fieldComment, basicData) => {
    let data = basicData;
    if (fieldComment.item && fieldComment.item.basicData) {
      data = { ...fieldComment.item.basicData };
    }
    return data;
  };

  getCalculatedStyles = status => {
    let styles = {
      color: '#074623',
      backgroundColor: '#d3ecc7',
      borderColor: '#6ecc40'
    };
    if (status === 'REJECT') {
      styles = {
        color: '#f5222d',
        backgroundColor: '#fff7e6',
        borderColor: '#ffa39e'
      };
    } else if (status === 'DENY') {
      styles = {
        color: '#fa8c16',
        backgroundColor: '#fff7e6',
        borderColor: '#ffd591'
      };
    }
    return styles;
  };

  getTagExplanation = status => {
    let explanation = status;
    if (status === 'REJECT') {
      explanation =
        'Rejected! Please enter a valid statement. This will reduce scores until you provide a valid statement.';
    } else if (status === 'DENY') {
      explanation =
        'Sysco would like to have more information. This will not reduce scores but we encourage you to give us more info.';
    } else if (status === 'FIXED') {
      explanation = 'This is marked as fixed, and this will not reduce scores until a Sysco associate confirms.';
    }
    return explanation;
  };

  getBcIgAgDisplayed = (bc, ig, ag) => {
    if (bc && ig && ag) {
      return (
        <div className="taxo-label-wrapper">
          <div title="Business Center">{bc}</div>
          <Icon className="taxo-label-arrow" type="right" />
          <div title="Item Group">{ig}</div>
          <Icon className="taxo-label-arrow" type="right" />
          <div title="Attribute Group">{ag}</div>
        </div>
      );
    }
  };

  getDisplayValueBasedOnFieldCommentType = fieldComment => {
    if (fieldComment.type === 'IMAGE') {
      return <img className="comments-image" src={fieldComment.currentValue} alt="" />;
    } else if (fieldComment.type === 'COREDATA') {
      return <div className="comments-attr-value">{fieldComment.currentValue}</div>;
    }
  };

  getFieldCommentSelect = (fieldComment, onUserInputChange, userInput) => {
    if (fieldComment.userInput && fieldComment.userInput.type === 'Select' && fieldComment.userInput.placeholder) {
      return (
        <div>
          {' '}
          {fieldComment.show && (
            <Select
              defaultValue={fieldComment.existingUserInput}
              className="comments-value comments-user-select"
              dropdownMatchSelectWidth={false}
              onChange={onUserInputChange(
                fieldComment.userInput.placeholder,
                'Select',
                fieldComment.userInput.validation
              )}
            >
              <Option key={0} value={null}>
                Please select...
              </Option>
              {fieldComment.userInput.picklist.map((pick, index) => (
                <Option key={parseInt(pick.id)} value={pick.value}>
                  {pick.value}
                </Option>
              ))}
            </Select>
          )}
          <div className="data-error user-comment-error">
            {' '}
            {userInput.error === 'default' ? userInput.error.msg : userInput.error}{' '}
          </div>
        </div>
      );
    }
  };

  getFieldComment = (fieldComment, onUserInputChange, userInput) => {
    if (fieldComment.userInput && fieldComment.userInput.type === 'Text' && fieldComment.userInput.placeholder) {
      return (
        <div>
          {fieldComment.show && (
            <TextArea
              className="comments-value comments-user-input"
              defaultValue={fieldComment.existingUserInput}
              rows={1}
              onBlur={onUserInputChange(fieldComment.userInput.placeholder, 'Input', fieldComment.userInput.validation)}
              onKeyUp={onUserInputChange(
                fieldComment.userInput.placeholder,
                'Input',
                fieldComment.userInput.validation
              )}
              placeholder={fieldComment.userInput.placeholder}
            />
          )}
          <div className="data-error user-comment-error">
            {' '}
            {userInput.error === 'default' ? userInput.error.msg : userInput.error}{' '}
          </div>
        </div>
      );
    }
  };

  checkFieldHasErrors = userInput => {
    return userInput && userInput.error !== '' ? 'error ' : '';
  };

  getFieldCommentDescriptor = fieldComment => {
    if (fieldComment.userInput && fieldComment.userInput.descriptor) {
      return <div className="comments-attr-lbl">{fieldComment.userInput.descriptor}</div>;
    }
  };

  getFnbFieldDefaultValue = (fieldComment, fnbComment) => {
    if (fieldComment.existingComment && !this.state.isContinuedWithPreviousData) {
      return fieldComment.existingComment.comment;
    }
    return (this.state.isContinuedWithPreviousData && fnbComment['genFnb'].value) || '';
  };

  displayTooltipExample = (tooltip, setExampleVisible) => {
    if (
      (tooltip.example_food && tooltip.example_food.constructor === Array && tooltip.example_food.length > 0) ||
      (tooltip.example_nonfood && tooltip.example_nonfood.constructor === Array && tooltip.example_nonfood.length > 0)
    ) {
      return (
        <span onClick={() => setExampleVisible(tooltip.field)}>
          {getValueBasedOnTheCondition(this.state.exampleVisibility[tooltip.field], ' hide ', ' see ')} examples
        </span>
      );
    }
  };

  displayFoodNonFoodExamples = (nonFood, tooltip, displayExamples) => {
    if (!nonFood) {
      return (
        <div className="title">
          <div className="sub-title">Food Examples:</div>
          {tooltip.example_food && tooltip.example_food.length > 0 ? (
            displayExamples(tooltip.example_food)
          ) : (
            <li>N/A</li>
          )}
        </div>
      );
    }
    if (nonFood) {
      return (
        <div className="title">
          <div className="sub-title">Non-Food Examples:</div>
          {tooltip.example_nonfood &&
          tooltip.example_nonfood.constructor === Array &&
          tooltip.example_nonfood.length > 0 ? (
            displayExamples(tooltip.example_nonfood)
          ) : (
            <li>N/A</li>
          )}
        </div>
      );
    }
  };

  getBodyClassNameForGdsnFnb = fieldComment => {
    if (fieldComment.type === 'FnB' && this.state.isAutoSaveData && !this.state.isSelectedAutoSavedData)
      return 'empty-container-unsaved';
    return '';
  };

  getFieldCommentHint = fieldComment => {
    if (fieldComment.hint && fieldComment.hint !== '') {
      return <div className="comments-hint">{fieldComment.hint}</div>;
    }
    return <div className="comments-seperator" style={{ marginBottom: '60px' }}></div>;
  };

  displayFnbCommentsTable = (fnbComment, fnbConfirmcolumns) => {
    if (fnbComment && fnbComment.msg) {
      return <Table pagination={false} columns={fnbConfirmcolumns} dataSource={fnbComment.msg} />;
    }
  };

  getReviewClass = reviewError => {
    if (reviewError.length > 0 && reviewError[0] !== 'FIXED') return 'review-error';
  };

  getReviewError = (reviewError, getStyleChanges, tagExplanation) => {
    if (reviewError.length > 0) {
      return (
        <div className="rejected-denied">
          <Tag style={{ ...getStyleChanges(reviewError) }} color="warning" title={tagExplanation(reviewError)}>
            {reviewError[0]}
          </Tag>
          : {reviewError[1]}
        </div>
      );
    }
  };

  getExampleClassWhenGdsnFnb = (fieldComment, tooltip) => {
    if (isEligibleForGdsnFnb(fieldComment) && this.state.exampleVisibility[tooltip.field])
      return 'with-example-container';
  };

  displayAutoSavedContent = fnbCommentAttributes => {
    if (this.state.autoSavedData) {
      return fnbCommentAttributes.map(key => {
        return (
          <div key={key} className="data">
            <span className="keys">{getValuesFromTwoFields(featuresAndBenifitsCommentKeys[key]?.name, key)}:</span>
            <span className="values">{` ${getValuesFromTwoFields(this.state.autoSavedData[key].value, '')}`}</span>
          </div>
        );
      });
    }
  };

  hasReachedTheMaxCharacterLimit = (field, value) => {
    const limit = field === 'descriptor' ? PRODUCT_DESCRIPTOR_CHARACTER_LIMIT : FNB_CHARACTER_LIMIT;
    return _.size(value) === limit;
  };

  render() {
    let { fieldComment, actions } = this.props;
    const { comment, fnbComment, userInput } = this.state;
    let totalCharacterCount = this.getOverallStatementCharactersCount();

    let nonFood = checkNonFoodStatusForFieldComment(fieldComment, nonFoodItemBusinessCenterNames, true);

    let data = {
      materialDescription: '',
      supc: '',
      brandName: '',
      manufactProdCode: ''
    };

    const fnbConfirmcolumns = [
      {
        title: 'Field Name',
        dataIndex: 'Field',
        key: 'Field',
        render: text => <b>{text}</b>
      },
      {
        title: 'Previous Value',
        dataIndex: 'Previous',
        key: 'Previous',
        render: (text, record) => {
          return tableStyles(text);
        }
      },
      {
        title: 'New Value',
        dataIndex: 'New',
        key: 'New',
        render: (text, record) => {
          return tableStyles(text);
        }
      }
      // , {
      //   title: 'Action',
      //   key: 'action',
      //   render: (text, record) => (
      //     <span>
      //       <a href="#">Approve</a>
      //       <span className="ant-divider" />
      //       <a href="#">Reject</a>
      //     </span>
      //   ),
      // }
    ];

    let fnbData;

    const collectCommentsFn = event => {
      this.collectComments(event.target.value);
    };

    const collectTempFnBvalidation = field => {
      const { fnbComment } = this.state;
      return fnbComment[field];
    };

    const collectFnBsatement = (event, field) => {
      const { fnbComment } = this.state;
      const currentValue = this.state.fnbComment[field].value;

      event = addBulletOnNewLine(event, field);

      if (
        checkStatusWithTwoAndConditions(
          currentValue !== event?.target?.value,
          !checkStatusWithTwoAndConditions(
            currentValue < event?.target?.value,
            totalCharacterCount >= OVERALL_FNB_CHARACTER_LIMIT
          )
        )
      ) {
        let fnbCommentNew = restructureFnBCollectStatement(event, field, fnbComment);
        const genFnb = this.genarateFnBComments(fnbCommentNew);

        fnbCommentNew = {
          ...fnbCommentNew,
          genFnb: {
            ...fnbComment.genFnb,
            value: genFnb
          }
        };
        const {
          fieldComment: { item: { supc, suvc } = { supc: null, suvc: null } }
        } = this.props;
        this.setState(prevState => {
          return { comment: genFnb, fnbComment: fnbCommentNew };
        });
        this.validataFnBComments(fnbCommentNew);
        updateFieldValues(
          'supplier',
          {
            fnb: { ...fnbCommentNew },
            suvc,
            supc: `${supc}`
          },
          [suvc, `${supc}`]
        );
      } // To limit the overall statement use else
    };

    const updateFieldValues = async (table, data, keys) => {
      await indexedDb.putValue(table, data, keys);
    };

    const setGdsnValue = field => {
      const { valueForTheFnbField, previousFnbs } = this.getValueForTheGdsnField(field);
      this.setState({
        previousFnbs: {
          ...previousFnbs
        }
      });
      collectFnBsatement({ target: { value: valueForTheFnbField } }, field);
    };

    const sendFieldCommentsFn = () => {
      this.sendFieldComments();
    };

    fnbData = this.getFnbDataUpdated(fieldComment, fnbData);
    data = { ...this.getItemBasicData(fieldComment, data) };

    let onUserInputChange = (name, type, validation) => {
      return e => {
        this.handleUserInput(type, validation, e);
      };
    };

    const closePopup = () => {
      // Reset the state to default
      this.setState({
        execution: 0,
        comment: '',
        userInput: { value: '', error: 'default' },
        fnbComment: {
          descriptor: { value: '', error: '' },
          packaging: { value: '', error: '' },
          size: { value: '', error: '' },
          yield: { value: '', error: '' },
          quality: { value: '', error: '' },
          prep: { value: '', error: '' },
          storage: { value: '', error: '' },
          handling: { value: '', error: '' },
          additional: { value: '', error: '' },
          marketing: { value: '', error: '' },
          culinary: { value: '', error: '' }
        },
        isSelectedAutoSavedData: false
      });
      actions.closeCommentsPopup();
    };

    const handleExampleVisibility = field => {
      const newExampleVisibility = {
        ...this.state.exampleVisibility,
        [field]: !this.state.exampleVisibility[field]
      };
      this.setState({
        exampleVisibility: newExampleVisibility
      });
    };

    const getListItemsForExample = foodOrNonFoodItem => {
      return foodOrNonFoodItem.map((eachExample, index) => {
        return <li key={index}>{eachExample}</li>;
      });
    };

    const getReviewCommentError = field => {
      const { reviewComment } = this.props;
      let commentError = this.getUpdatedCommentsError(reviewComment, field);
      return commentError;
    };

    const stylesForTag = ([status]) => {
      return this.getCalculatedStyles(status);
    };

    const explanationForTag = ([status]) => {
      return this.getTagExplanation(status);
    };

    let bc = _.get(fieldComment, 'item.taxonomy.bc.name', null);
    let ig = _.get(fieldComment, 'item.taxonomy.ig.name', null);
    let ag = _.get(fieldComment, 'item.taxonomy.ag.name', null);

    const submittingFnb = fieldComment.type === 'FnB' && !fnbComment.visible && this.props.isSubmitting;

    return (
      <ReactResizeDetector
        handleWidth
        handleHeight
        render={({ width, height }) => (
          <div
            className="comments-wrapper"
            style={{
              transform: getValueBasedOnTheCondition(fieldComment.show, 'translateX(0vw)', 'translateX(100vw)')
            }}
          >
            <div style={{ height: window.innerHeight - 100 + 'px', overflow: 'auto' }}>
              <div
                className={`comments-title-bar ${getValueBasedOnTheCondition(
                  isEligibleForGdsnFnb(fieldComment),
                  'with-gdsn-fnb-contnet',
                  undefined
                )}`}
              >
                <div className="back-button" onClick={closePopup}></div>
                <div className="item-view-title-bar">
                  <div className="item-view-title-name">
                    <div title={data.materialDescription}>{data.materialDescription}&nbsp;</div>
                    <div className="supc" title="SUPC. This is the product code used in Sysco">
                      &nbsp;({data.supc})
                    </div>
                  </div>
                  <div className="item-view-title-name sub-title">
                    <div title="Brand name">{data.brandName}&nbsp;</div>
                    <div title="Manufacturer product code">
                      {getValueBasedOnTheCondition(
                        data.manufactProdCode,
                        ' | ' + data.manufactProdCode,
                        '',
                        data.manufactProdCode !== ''
                      )}
                    </div>
                  </div>
                  {this.getBcIgAgDisplayed(bc, ig, ag)}
                  <ItemDatesBar />
                </div>
              </div>
              <div
                className={`comments-body ${getValueBasedOnTheCondition(
                  isEligibleForGdsnFnb(fieldComment),
                  'with-comments-gdsn-fnb',
                  undefined
                )}`}
              >
                {fieldComment.type !== 'FnB' && (
                  <div>
                    <div className="comments-attr">
                      <div className="comments-attr-lbl">
                        {getValueBasedOnTheCondition(fieldComment.fieldCaption, fieldComment.fieldCaption, 'Field')}
                      </div>
                      {this.getDisplayValueBasedOnFieldCommentType(fieldComment)}
                    </div>
                    {getValueBasedOnTheCondition(
                      checkStatusWithTwoAndConditions(fieldComment.hint, fieldComment.hint !== ''),
                      <div className="comments-hint">{fieldComment.hint}</div>,
                      <div className="comments-seperator"></div>
                    )}
                    <div className={`comments-text ${this.checkFieldHasErrors(userInput)}`}>
                      {this.getFieldCommentDescriptor(fieldComment)}
                      {this.getFieldComment(fieldComment, onUserInputChange, userInput)}
                      {this.getFieldCommentSelect(fieldComment, onUserInputChange, userInput)}
                      <div className="comments-attr-lbl">Enter Your Feedback</div>
                      {checkValueAndReturnElement(
                        fieldComment.show,
                        <TextArea
                          className="comments-value"
                          defaultValue={getFieldKeyOrAssignDefaultValue(fieldComment.existingComment, 'comment', '')}
                          rows={4}
                          onBlur={collectCommentsFn}
                          onKeyUp={collectCommentsFn}
                        />
                      )}
                    </div>
                  </div>
                )}
                {fieldComment.type === 'FnB' && (
                  <div>
                    <Modal
                      width={'780px'}
                      visible={fnbComment.visible}
                      title="Please confirm..."
                      iconType
                      onOk={this.handleOk}
                      onCancel={this.handleCancel}
                      footer={[
                        <Button key="back" onClick={this.handleCancel}>
                          Cancel
                        </Button>,
                        <Button key="submit" type="primary" loading={fnbComment.loading} onClick={this.handleOk}>
                          Send
                        </Button>
                      ]}
                    >
                      {this.displayFnbCommentsTable(fnbComment, fnbConfirmcolumns)}
                    </Modal>

                    <div className="comments-attr attr-overall-statement">
                      {fieldComment.type === 'FnB' && this.state.isAutoSaveData && !this.state.isSelectedAutoSavedData && (
                        <div className="auto-saved-indicator">
                          <div className="actions">
                            <span className="span">Continue with unsaved data?</span>
                            <div style={{ display: 'flex' }}>
                              <div className="warning-btn" onClick={() => this.handlePreviousDataSwitch(true)}>
                                Yes
                              </div>
                              <div className="warning-btn" onClick={() => this.handlePreviousDataSwitch(false)}>
                                No
                              </div>
                            </div>
                          </div>
                          <div className="auto-saved-data">{this.displayAutoSavedContent(fnbCommentAttributes)}</div>
                        </div>
                      )}
                      <div className="comments-attr-lbl">
                        {getValueBasedOnTheCondition(fieldComment.fieldCaption, fieldComment.fieldCaption, 'Field')}
                      </div>
                      {fieldComment.type === 'FnB' && (
                        <div
                          className={`comments-text ${getValueBasedOnTheCondition(
                            fnbComment.genFnb.error !== '',
                            'error ',
                            ''
                          )}`}
                        >
                          <div className="fnb-textarea-count">
                            <div
                              className={getValueBasedOnTheCondition(
                                totalCharacterCount > OVERALL_FNB_CHARACTER_LIMIT,
                                'fnb-textarea-count-error',
                                ''
                              )}
                            >
                              {totalCharacterCount}
                            </div>
                            <div>{`/${OVERALL_FNB_CHARACTER_LIMIT}`}</div>
                          </div>
                          <TextArea
                            className="ant-input attr-textarea disable fnb"
                            defaultValue={this.getFnbFieldDefaultValue(fieldComment, fnbComment)}
                            value={comment}
                          />
                          <div className="data-error "> {fnbComment.genFnb.error} </div>
                        </div>
                      )}
                    </div>
                    {this.getFieldCommentHint(fieldComment)}
                    <div className="comments-text">
                      {fnbData &&
                        fnbData.tooltip &&
                        fnbCommentAttributes.map(element => {
                          const tooltip = _.find(fnbData.tooltip, { field: element });
                          const reviewError = getReviewCommentError(tooltip.field);
                          return (
                            <div
                              className={`comments-text ${getValueBasedOnTheCondition(
                                collectTempFnBvalidation(tooltip.field).error !== '',
                                'error ',
                                '',
                                reviewError.length > 0
                              )} ${getValueBasedOnTheCondition(
                                isEligibleForGdsnFnb(fieldComment),
                                'with-gdsn-fnb-content',
                                undefined
                              )} ${this.getExampleClassWhenGdsnFnb(fieldComment, tooltip)}`}
                              key={tooltip.field}
                            >
                              <div className="comments-attr-lbl">
                                {' '}
                                {getFnbFieldName(tooltip.field)}{' '}
                                {getValueBasedOnTheCondition(
                                  collectTempFnBvalidation(tooltip.field).required,
                                  ' *',
                                  ''
                                )}{' '}
                              </div>
                              {fieldComment.show && (
                                <TextArea
                                  className={`comments-value ${this.getReviewClass(reviewError)}`}
                                  value={this.getFnbFieldValue(tooltip.field)}
                                  rows={4}
                                  onBlur={e => collectFnBsatement(e, tooltip.field)}
                                  onKeyUp={e => collectFnBsatement(e, tooltip.field)}
                                  onChange={e => collectFnBsatement(e, tooltip.field)}
                                  disabled={!this.state.isEditMode}
                                  maxLength={getValueBasedOnTheCondition(
                                    tooltip.field === 'descriptor',
                                    PRODUCT_DESCRIPTOR_CHARACTER_LIMIT,
                                    FNB_CHARACTER_LIMIT
                                  )}
                                />
                              )}
                              <div className="data-error show"> {collectTempFnBvalidation(tooltip.field).error}</div>
                              {this.getReviewError(reviewError, stylesForTag, explanationForTag)}
                              <div
                                className={`guide-line ${getValueBasedOnTheCondition(
                                  isEligibleForGdsnFnb(fieldComment),
                                  'with-gdsn-fnb',
                                  undefined
                                )}`}
                              >
                                {tooltip.tooltip}
                                {this.displayTooltipExample(tooltip, handleExampleVisibility)}
                                {this.state.exampleVisibility[tooltip.field] && (
                                  <div
                                    className={`example ${getValueBasedOnTheCondition(
                                      isEligibleForGdsnFnb(fieldComment),
                                      'with-gdsn-fnb',
                                      undefined
                                    )}`}
                                  >
                                    {this.displayFoodNonFoodExamples(nonFood, tooltip, getListItemsForExample)}
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                )}
              </div>
              {isEligibleForGdsnFnb(fieldComment) && (
                <div className={`comments-body gdsn-fnb-body ${this.getBodyClassNameForGdsnFnb(fieldComment)}`}>
                  <div className={`empty-container ${fnbComment.genFnb.error !== '' && 'empty-error-container'}`}>
                    <div>
                      <div className="bread-crum-caption">GDSN Features & Benefits</div>
                      <div className="gdsn-paragraph">
                        This is a Features and Benefits statement prepared based on item data in GDSN. You can copy them
                        by using the arrow buttons, to the edit panel at the right.
                      </div>
                      <div className="comments-seperator with-gdsn"></div>
                    </div>
                  </div>
                  <div className="syndicator">
                    {' '}
                    <span className="span">
                      GDSN last syndication: {_.get(this.props.gdsnFnb, 'lastUpdated', THREE_DOTS)}
                    </span>
                  </div>
                  <div>
                    {fnbCommentAttributes.map(field => {
                      return (
                        <div
                          className={`comments-text with-gdsn-fnb-content ${this.state.exampleVisibility[field] &&
                            'example-container'}`}
                          key={field}
                        >
                          {!IGNORING_GDSN_FNB_ATTRIBUTES.includes(field) && (
                            <>
                              <div className="comments-attr-lbl gdsn-value-container">
                                {`${getFnbFieldName(field)} - GDSN`}{' '}
                                <Tooltip
                                  title={
                                    <div style={{ paddingLeft: '15px', paddingRight: '15px' }} className="gdsn-info">
                                      {gdsnFnbTooltips[field]}
                                    </div>
                                  }
                                >
                                  i
                                </Tooltip>
                              </div>
                              <div className="gdsn-fnb-arrow-wrapper">
                                <TextArea
                                  className={`comments-value`}
                                  value={getValueBasedOnTheCondition(
                                    this.props.isGdsnFnbLoading,
                                    'Loading...',
                                    this.getGdsnFnbFields(field)
                                  )}
                                  rows={4}
                                  disabled
                                />
                                <Icon
                                  className="copy-gdsn-fnb-arrow"
                                  type="caret-right"
                                  onClick={() => setGdsnValue(field)}
                                  theme="outlined"
                                  style={{
                                    color: getValueBasedOnTheCondition(
                                      checkObjectPropertyExists(this.state.previousFnbs, field),
                                      '#008ace',
                                      '#888',
                                      !_.isEmpty(this.state.fnbComment[field].value)
                                    ),
                                    pointerEvents: getValueBasedOnTheCondition(
                                      checkStatusWithTwoAndConditions(
                                        this.getGdsnFnbFields(field),
                                        this.state.isEditMode
                                      ),
                                      'auto',
                                      'none'
                                    ),
                                    cursor: getValueBasedOnTheCondition(
                                      checkStatusWithTwoAndConditions(
                                        this.getGdsnFnbFields(field),
                                        this.state.isEditMode
                                      ),
                                      'pointer',
                                      'not-allowed'
                                    )
                                  }}
                                  title="Copy/reset"
                                />
                              </div>
                              <div className="copy-character-length-exceeded-container">
                                {replaceFirstBullets(this.getGdsnFnbFields(field)).length > 1000 && (
                                  <span>{`Max character length(${this.getInitialCharacterCountForField(
                                    field
                                  )}) exceeded, only the first ${this.getInitialCharacterCountForField(
                                    field
                                  )} characters will be copied.`}</span>
                                )}
                              </div>
                            </>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              <div className="btn-footer">
                <Button className="footer-btn btn-cancel" onClick={closePopup}>
                  CANCEL
                </Button>
                {getValueBasedOnTheCondition(
                  fieldComment.userInput,
                  <Button
                    className="footer-btn btn-confirm"
                    onClick={sendFieldCommentsFn}
                    disabled={userInput.error !== ''}
                  >
                    {getButtonTitle(fieldComment.type)}
                  </Button>,
                  <Button
                    className="footer-btn btn-confirm"
                    disabled={submittingFnb}
                    loading={submittingFnb}
                    onClick={sendFieldCommentsFn}
                  >
                    {getButtonTitle(fieldComment.type)}
                  </Button>
                )}
              </div>
            </div>
          </div>
        )}
      />
    );
  }
}

export default FieldComment;
