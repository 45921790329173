import React from 'react';
import { Dropdown, Menu, Button, Icon } from 'antd';
import { bindActionCreators } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

import * as actionCreators from '../../actions';
import { REGULATORY_COMPLIANCE_FILTERING_OPTIONS, ATTRIBUTE_FILTER_OPTIONS } from '../../util/Constants';
import { isPartialHierarchy, isNonFoodBc } from '../../util/Util';

export default function RegulatoryComplianceFilter({ onFilterChange }) {
  const dispatch = useDispatch();
  const { updateRegulatoryComplianceFilterChange } = bindActionCreators(actionCreators, dispatch);

  const attributeFilters = useSelector(state => _.get(state.massUpdate.pfas, 'filters.attributeFilters', []));
  const bc = useSelector(state => _.get(state.massUpdate.pfas, 'filters.bc', null));
  const ag = useSelector(state => _.get(state.massUpdate.pfas, 'filters.ag', null));
  const completeTaxonomy = useSelector(state => _.get(state.massUpdate.attribute, 'completeTaxonomy', []));

  const onSelectAttribute = newAttribute => {
    const index = _.findIndex(attributeFilters, attr => newAttribute === attr);

    if (index === -1) {
      const clone = [...attributeFilters, newAttribute];
      updateRegulatoryComplianceFilterChange({ filters: clone });
      onFilterChange();
    }
  };

  const onRemoveAttribute = value => {
    const remainingFilters = _.filter(attributeFilters, attr => value !== attr);
    updateRegulatoryComplianceFilterChange({ filters: remainingFilters });
    onFilterChange();
  };

  const renderDropdown = () => {
    const menuItems = _.map(REGULATORY_COMPLIANCE_FILTERING_OPTIONS, attr => {
      return (
        <Menu.Item key={attr} onClick={() => onSelectAttribute(attr)}>
          {attr}
        </Menu.Item>
      );
    });

    const menu = <Menu>{menuItems}</Menu>;

    return (
      <Dropdown className="attr-mass-filter-dropdown" overlay={menu} trigger={['click']}>
        <Button>
          Select <Icon type="down" />
        </Button>
      </Dropdown>
    );
  };

  const renderFiltersList = () => {
    return (
      <div className="mass-attr-filters-list">
        {_.map(attributeFilters, attr => {
          return (
            <div key={attr} className="attribute-block">
              <div>{attr}</div>
              <div className="btn-remove-attr" onClick={() => onRemoveAttribute(attr)}>
                x
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="attr-mass-filter">
      <div className="attr-filter-label">Regulatory Compliance</div>
      {renderDropdown()}
      {renderFiltersList()}
    </div>
  );
}
