import React from 'react';
import { nutritionPropertyKeys } from '../../enums';
import { getIsFeedbackChangedClassName } from '../../util/Util';

const NutritionRowData = ({ value, fieldKey, changedFields }) => {
  return (
    <div className="nutrition-container" key={fieldKey}>
      <div className="nutrition-title">{nutritionPropertyKeys[fieldKey]}</div>
      <table className="nutrition-data-table">
        <tbody className="nutritions-tbody">
          <tr className={`${getIsFeedbackChangedClassName(changedFields, fieldKey)}`}>
            <th>{nutritionPropertyKeys[fieldKey]}</th>
            <th className="white">{value}</th>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default NutritionRowData;
