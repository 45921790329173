import React, { useState } from 'react';
import { Select, Input, Tooltip } from 'antd';
import _ from 'lodash';

import CoreAttributeHistory from './../CoreAttributeHistory';
import { CORE_DATA_FIELDS, CORE_DATA_REJECT_STATUS_DROPDOWNS } from '../../util/CoreDataConstants';
import {
  getPendingCoreDataSelectedValue,
  getCommentTextAreaStylesForRejections,
  getCoreDataCommentDisabledFields,
  getStatusDisabledForSelect,
  isCommentAssignedToMe,
  getCoreDataValueOrDescription
} from '../../util/Util';

const { Option } = Select;
const { TextArea } = Input;

const [REJECTED_STATUS, FIX_STATUS, APPROVE_STATUS, REVIEW_STATUS, BLANK_STATUS] = CORE_DATA_REJECT_STATUS_DROPDOWNS;

export default function PendingCoreData({
  updatedComment,
  id,
  status,
  handleAttributeChange,
  currentUser,
  assignee,
  supc
}) {
  const [selectStatuses, setSelectStatuses] = useState({});
  const getRejectedStatus = key => {
    return getPendingCoreDataSelectedValue(updatedComment, key, selectStatuses);
  };

  const onChangeRejectStatus = (value, key) => {
    setSelectStatuses({
      ...selectStatuses,
      [key]: value
    });
    let pendingStatus = true;
    let rejectStatus = false;
    let fixedStatus = false;
    let internalReviewStatus = false;
    let approveStatus = false;
    if (value === BLANK_STATUS) pendingStatus = true;
    if (value === REJECTED_STATUS) rejectStatus = true;
    if (value === FIX_STATUS) fixedStatus = true;
    if (value === REVIEW_STATUS) internalReviewStatus = true;
    if (value === APPROVE_STATUS) {
      approveStatus = true;
      pendingStatus = false;
    }
    const updatedData = {
      ...updatedComment,
      [key]: {
        ...updatedComment[key],
        isPending: pendingStatus,
        isRejected: rejectStatus,
        isFixed: fixedStatus,
        isRejectedFixed: false,
        isInternalReview: internalReviewStatus,
        isApproved: approveStatus
      }
    };
    handleAttributeChange({ id, comment: JSON.stringify(updatedData) });
  };

  const onChangeDescription = (value, key) => {
    let updatedData = {};

    if (updatedComment[key]?.isInternalReview) {
      updatedData = { ...updatedComment, [key]: { ...updatedComment[key], newInternalReview: value } };
    } else {
      updatedData = { ...updatedComment, [key]: { ...updatedComment[key], newFeedback: value } };
    }

    handleAttributeChange({ id, comment: JSON.stringify(updatedData) });
  };

  const isFeedbackTextInputVisible = (updatedComment, key) => {
    if (
      updatedComment[key]?.isRejected ||
      updatedComment[key]?.isFixed ||
      updatedComment[key]?.isRejectedFixed ||
      updatedComment[key]?.isInternalReview
    ) {
      return true;
    }
  };

  return (
    <div className="core-data-table-container">
      <table className="core-data-table">
        <tbody className="core-data-tbody">
          <tr>
            <th>Attribute</th>
            <th>Current Value</th>
            <th>Changed Value</th>
            <th style={{ width: '200px' }}>Status</th>
            <th style={{ width: '275px' }}>Description (Optional)</th>
          </tr>
          {Object.keys(updatedComment).map((key, index) => {
            let element = null;
            let feedback = _.get(updatedComment, `[${key}].feedback`, '');
            let feedbackMessage = _.isArray(feedback) ? _.get(_.last(feedback), 'text', '') : feedback;
            let newFeedback = _.get(updatedComment, `[${key}].newFeedback`, false);

            if (updatedComment[key]?.hasOwnProperty('newFeedback')) {
              feedbackMessage = newFeedback;
            }

            if (updatedComment[key]?.isInternalReview) {
              let internalReview = _.get(updatedComment, `[${key}].internalReview`, '');
              internalReview = _.isArray(internalReview) ? _.get(_.last(internalReview), 'text', '') : internalReview;
              let newInternalReview = _.get(updatedComment, `[${key}].newInternalReview`, false);

              if (updatedComment[key]?.hasOwnProperty('newInternalReview')) {
                feedbackMessage = newInternalReview;
              } else {
                feedbackMessage = internalReview;
              }
            }

            element = (
              <tr key={index}>
                <td>{CORE_DATA_FIELDS[key].caption}</td>
                <td>{updatedComment[key].existingValue}</td>
                <td>{getCoreDataValueOrDescription(key, updatedComment[key].value)}</td>
                <td>
                  <Select
                    disabled={
                      getCoreDataCommentDisabledFields(status, 30) || !isCommentAssignedToMe(currentUser, assignee)
                    }
                    value={getRejectedStatus(key)}
                    dropdownMatchSelectWidth={false}
                    style={{ width: '100%' }}
                    onChange={value => onChangeRejectStatus(value, key)}
                    className="image-feedback-dropdown"
                  >
                    {CORE_DATA_REJECT_STATUS_DROPDOWNS.map(value => {
                      return (
                        <Option
                          key={value}
                          value={value}
                          disabled={getStatusDisabledForSelect(key, value, updatedComment, selectStatuses)}
                        >
                          {value}
                        </Option>
                      );
                    })}
                  </Select>
                  <CoreAttributeHistory supc={supc} attribute={key} type={'ATTRIBUTE_HISTORY'} />
                </td>
                <td style={{ textAlign: 'right' }}>
                  {isFeedbackTextInputVisible(updatedComment, key) && (
                    <TextArea
                      className={`${getCommentTextAreaStylesForRejections(updatedComment, key, 'text-box-rejected')}`}
                      value={feedbackMessage}
                      rows={4}
                      disabled={
                        getCoreDataCommentDisabledFields(status, 30, updatedComment, key) ||
                        !isCommentAssignedToMe(currentUser, assignee)
                      }
                      onChange={e => onChangeDescription(e.target.value, key)}
                    />
                  )}
                  {_.isArray(feedback) && feedback.length > 1 && updatedComment[key]?.isRejected && (
                    <Tooltip
                      overlayClassName={'indication-dot-tooltip'}
                      title={
                        <div style={{ paddingTop: '15px' }}>
                          <ul>
                            {feedback.map(f => (
                              <li key={f.text}>• {f.text}</li>
                            ))}
                          </ul>
                        </div>
                      }
                    >
                      <span role="img" aria-label="history-icon">
                        🕑
                      </span>
                    </Tooltip>
                  )}
                </td>
              </tr>
            );
            return element;
          })}
        </tbody>
      </table>
    </div>
  );
}
