import React from 'react';

const getClaimChangedStatus = (isExisting, previousValue, nutritionalClaimTypeCode) => {
  if (!isExisting) return true;
  if (isExisting && previousValue !== nutritionalClaimTypeCode) return true;
};

const ClaimRowData = ({
  field,
  nutritionalClaimElementCode,
  index,
  nutritionalClaimTypeCode,
  isExisting,
  previousValue
}) => {
  return (
    <tr
      key={index}
      className={`${
        getClaimChangedStatus(isExisting, previousValue, nutritionalClaimTypeCode)
          ? 'nt-feedback-changed-fields-tr'
          : undefined
      }`}
    >
      <th>{field}</th>
      <th className="white">{nutritionalClaimElementCode}</th>
      <th className="white">{nutritionalClaimTypeCode}</th>
    </tr>
  );
};

const ClaimsData = ({ comment, mainKey }) => {
  return (
    <div className="nutrition-container">
      <div className="nutrition-title">Claims</div>
      <div key={mainKey}>
        <table className="nutrition-data-table">
          <tbody className="nutritions-tbody">
            <tr className="table-header-nutrition">
              <th className="nutrition-property-name">Property</th>
              <th className="nutrition-property-name">Nutrition Claim Element Code</th>
              <th className="nutrition-property-name">Nutrition Claim Type Code</th>
            </tr>
            {console.log('comment is---------------', comment)}
            {comment[mainKey].map((eachClaim, claimIndex) => {
              const {
                name,
                isExisting,
                nutritionalClaimElementCode,
                nutritionalClaimTypeCode,
                previousValue
              } = eachClaim;
              return (
                <ClaimRowData
                  field={name}
                  nutritionalClaimElementCode={nutritionalClaimElementCode}
                  nutritionalClaimTypeCode={nutritionalClaimTypeCode}
                  index={claimIndex}
                  key={claimIndex}
                  isExisting={isExisting}
                  previousValue={previousValue}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ClaimsData;
