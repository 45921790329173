import React from 'react';

const DynamicRequiredField = ({ type, changes }) => {
  if (changes[type] && changes[type].selectedImagePreview) {
    return (
      <div className="mass-upload-select-dynamic-required-fields">
        <span>*</span>
      </div>
    );
  }
  return null;
};

export default DynamicRequiredField;
