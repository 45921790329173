import React from 'react';

const RejectCountBanner = ({ count, isFilterOn, onToggle, title, countMessage, className }) => {
  return (
    count > 0 && (
      <div className={`${className}${isFilterOn ? ' active' : ''}`} onClick={onToggle} title={title}>
        <div className="fnbr-count">{count}</div>
        <div>{countMessage}</div>
      </div>
    )
  );
};

export default RejectCountBanner;
