import React, { useRef, useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { Button, Modal, Icon } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import FnBMassUpdateTable from './FnBMassUpdateTable';
import openNotification from 'components/Notification';
import * as actionCreators from '../../actions';
import { createIndexedDbConnection } from '../../util/Connection';
import { fnbCommentAttributes } from '../../util/Data';
import {
  filterCollectFnBComments,
  updateValueAndGetBasedOnCondition,
  updateSavedFnbArray,
  getValuesFromTwoFields,
  getFnbMassUpdateClosableState,
  checkValueAndReturnElement
} from '../../util/Util';

const indexedDb = createIndexedDbConnection('item-management');

function FnBMassUpdateModal({ suvc, supcs, rejectedFnbs }, ref) {
  const tableRef = useRef(null);
  const windowHeight = window.innerHeight;
  const dispatch = useDispatch();
  const {
    getFnbMassEditInitialData,
    fnbMassUpdate,
    toggleFnbMassEditModal,
    loadAutoSavedFnbComments
  } = bindActionCreators(actionCreators, dispatch);

  const fnbMassEditItems = useSelector(state => _.get(state.vendor, 'fnbMassEditItems', []));
  const savingFnbMassUpdate = useSelector(state => _.get(state.vendor, 'savingFnbMassUpdate', []));
  const showModal = useSelector(state => _.get(state.vendor, 'showFnbMassUpdateModal', false));
  const fetchingFnbMassEditData = useSelector(state => _.get(state.vendor, 'fetchingFnbMassEditData', false));
  const allUnsaved = useSelector(state => _.get(state.vendor, 'autoSavedFnb', []));

  const [selectedUnsavedData, setSelectedUnsavedData] = useState([]);
  const [showInitialMessage, setShowInitialMessage] = useState(false);
  const [showExitMessage, setShowExitMessage] = useState(false);
  const [fnbMassSupcs, setFnbMassSupcs] = useState([]);
  const [hasFnBRejects, setHasFnBRejects] = useState(false);

  useEffect(() => {
    configureIndexDb();
  }, []);

  useEffect(() => {
    let hasRejects = false;
    let filtered = _.filter(supcs, supc => {
      const isRejected = _.includes(rejectedFnbs, supc);
      hasRejects = updateValueAndGetBasedOnCondition(isRejected, hasRejects, true);
      return !isRejected;
    });

    setHasFnBRejects(hasRejects);
    setFnbMassSupcs(filtered);
  }, [supcs, rejectedFnbs]);

  useImperativeHandle(ref, () => ({ openModal }));

  const openModal = () => {
    getFnbMassEditInitialData({ suvc, supcs: fnbMassSupcs });
    toggleFnbMassEditModal({ isVisible: true });
    setSelectedUnsavedData([]);

    if (!_.isEmpty(getSelectedUnsavedSupcs())) {
      setShowInitialMessage(true);
    }
  };

  const closeModal = async () => {
    if (!savingFnbMassUpdate) {
      const allUnsavedLatest = await indexedDb.getAllValue('supplier');
      const allUnsavedLatestSupcs = _.map(allUnsavedLatest, obj => obj.supc);
      const selectedSupcs = _.map(fnbMassEditItems, obj => obj.supc);
      const found = selectedSupcs.some(supc => allUnsavedLatestSupcs.includes(supc));

      if (found) {
        setShowExitMessage(true);
        return;
      }
      toggleFnbMassEditModal({ isVisible: false });
    }
  };

  const configureIndexDb = async () => {
    try {
      await indexedDb.createObjectStore([{ tableName: 'supplier', indexes: 'supc', indexFieldArray: [] }]);
    } catch (error) {
      console.log(error);
    }
  };

  const loadAutoSavedFnb = async () => {
    let saved = [];
    let selectedUnsavedSupcs = getSelectedUnsavedSupcs();

    for (const supc of selectedUnsavedSupcs) {
      try {
        const results = await indexedDb.getValue('supplier', supc);
        saved = [...updateSavedFnbArray(saved, results)];
      } catch (error) {
        console.log(error);
      }
    }

    setSelectedUnsavedData(saved);
  };

  const discardAutoSavedFnb = async () => {
    const selectedSupcs = _.map(fnbMassEditItems, obj => obj.supc);
    const selectedUnsaved = _.filter(selectedSupcs, supc => _.includes(allUnsaved, supc));
    let remaining = _.difference(allUnsaved, selectedUnsaved);

    for (const supc of selectedUnsaved) {
      try {
        await indexedDb.deleteValue('supplier', supc);
      } catch (error) {
        console.log(error);
      }
    }

    setSelectedUnsavedData([]);
    loadAutoSavedFnbComments({ autoSavedData: remaining });
  };

  const continueWithAutoSavedFnb = async () => {
    await loadAutoSavedFnb();
    setShowInitialMessage(false);
  };

  const continueWithoutAutoSavedFnb = () => {
    discardAutoSavedFnb();
    setShowInitialMessage(false);
  };

  const keepUnsavedAndExit = async () => {
    toggleFnbMassEditModal({ isVisible: false });
    const allUnsavedLatest = await indexedDb.getAllValue('supplier');
    const allUnsavedLatestSupcs = _.map(allUnsavedLatest, obj => obj.supc);
    loadAutoSavedFnbComments({ autoSavedData: allUnsavedLatestSupcs });
    setShowExitMessage(false);
  };

  const discardUnsavedAndExit = () => {
    discardAutoSavedFnb();
    toggleFnbMassEditModal({ isVisible: false });
    setShowExitMessage(false);
  };

  const getSelectedUnsavedSupcs = () => {
    return _.filter(fnbMassSupcs, supc => _.includes(allUnsaved, supc));
  };

  const getUnsavedFnbFields = supc => {
    let target = _.find(selectedUnsavedData, obj => supc === obj.supc);

    if (target) {
      let fnb = {};

      _.forEach(fnbCommentAttributes, attr => {
        fnb[attr] = _.get(target, `fnb[${attr}][value]`, '');
      });

      return fnb;
    }
  };

  const getFormattedTableData = () => {
    let tableData = [];

    _.forEach(fnbMassEditItems, obj => {
      let unsaved = getUnsavedFnbFields(obj.supc);

      let row = {
        supc: obj.supc,
        ...obj.basicDetails,
        taxonomy: obj.taxonomy,
        existing: obj.existing,
        hasPending: obj.hasPending,
        initialFnb: obj.initialFnb
      };

      if (!_.isEmpty(unsaved)) {
        row = { ...row, ...unsaved };
      } else {
        row = { ...row, ...obj.fnb };
      }

      tableData.push(row);
    });

    return tableData;
  };

  const getFooterButtons = () => {
    const buttons = [];

    if (fetchingFnbMassEditData) {
      return null;
    } else if (getValuesFromTwoFields(showInitialMessage, showExitMessage)) {
      buttons.push(
        <Button
          key="cancel"
          className="footer-btn"
          onClick={showInitialMessage ? continueWithoutAutoSavedFnb : discardUnsavedAndExit}
        >
          NO
        </Button>
      );

      buttons.push(
        <Button
          key="yes"
          className="footer-btn btn-confirm fnb-mass-edit-save-btn"
          type="primary"
          onClick={showInitialMessage ? continueWithAutoSavedFnb : keepUnsavedAndExit}
        >
          YES
        </Button>
      );
    } else {
      buttons.push(
        <Button key="cancel" className="footer-btn" disabled={savingFnbMassUpdate} onClick={closeModal}>
          CANCEL
        </Button>
      );

      buttons.push(
        <Button
          key="send"
          className="footer-btn btn-confirm fnb-mass-edit-save-btn"
          type="primary"
          disabled={getValuesFromTwoFields(_.isEmpty(fnbMassEditItems), savingFnbMassUpdate)}
          loading={savingFnbMassUpdate}
          onClick={onClickSend}
        >
          SUBMIT FOR REVIEW
        </Button>
      );
    }

    return buttons;
  };

  const getOnlyUpdatedRecords = data => {
    let updated = [];

    _.forEach(data, obj => {
      let hasUpdates = false;

      _.forEach(fnbCommentAttributes, attr => {
        const initialValue = _.get(obj, `initialFnb.${attr}`, null);
        const currentValue = _.get(obj, `${attr}`, null);

        if (initialValue !== currentValue) {
          hasUpdates = true;
          return false;
        }
      });

      if (hasUpdates) {
        updated.push(obj);
      }
    });

    return updated;
  };

  const onClickSend = () => {
    const isAllFieldsValid = tableRef.current.validateAllFields();

    if (isAllFieldsValid) {
      const tableData = tableRef.current.getTableData();
      const updatedRecords = getOnlyUpdatedRecords(tableData);

      const postData = _.map(updatedRecords, obj => {
        const { supc, stepId, existing } = obj;
        let fnb = {};

        _.forEach(fnbCommentAttributes, attr => {
          fnb[attr] = _.get(obj, `${attr}`, '');
        });

        const existingFnB = filterCollectFnBComments(existing);
        const data = { ...fnb, stepId, existingFnB };
        return { suvc, supc, data };
      });

      fnbMassUpdate({ updatedData: postData });
    } else {
      openNotification({
        className: 'error',
        message: 'INVALID INPUTS',
        description: 'Please fill all the required fields'
      });
    }
  };

  const renderModalBody = () => {
    if (fetchingFnbMassEditData) {
      return <Icon className="fnb-mass-upload-loader" type="loading" />;
    } else if (showInitialMessage) {
      return (
        <div className="fnb-mass-modal-center">
          You have unsaved F&B data for the selected items. Do you want to continue with the unsaved data?
        </div>
      );
    } else if (showExitMessage) {
      return (
        <div className="fnb-mass-modal-center">
          You have unsaved F&B data for the selected items. Do you want to keep them?
        </div>
      );
    } else {
      return (
        <React.Fragment>
          {hasFnBRejects && (
            <div className="fnb-mass-top-msg">Items with rejected F&B data will not be visible in this view.</div>
          )}
          <FnBMassUpdateTable
            ref={tableRef}
            suvc={suvc}
            savingFnbMassUpdate={savingFnbMassUpdate}
            data={getFormattedTableData()}
            indexedDb={indexedDb}
            hasFnBRejects={hasFnBRejects}
          />
        </React.Fragment>
      );
    }
  };

  let closable = getFnbMassUpdateClosableState(savingFnbMassUpdate, showInitialMessage, showExitMessage);

  return (
    <Modal
      className="fnb-mass-update-modal"
      visible={showModal}
      maskClosable={false}
      closable={closable}
      width={'98%'}
      bodyStyle={{
        height: `${windowHeight - 115}px`
      }}
      footer={getFooterButtons()}
      onCancel={closeModal}
    >
      {renderModalBody()}
    </Modal>
  );
}

export default forwardRef(FnBMassUpdateModal);
