import _ from 'lodash';
import React from 'react';
import ReactResizeDetector from 'react-resize-detector';
import { Spin } from 'antd';
import TextAttribute from './TextAttribute';
import TextCooAttribute from './TextCooAttribute';
import FeedbackAttribute from './FeedbackAttribute';
import feedback from '../helpers/feedback';
import {
  CORE_DATA_FIELDS,
  ACTIVITY_TYPE_KEY,
  ACTIVITY_CODE_KEY,
  ACTIVITY_SUBCODE_KEY,
  REGION_DESC_KEY
} from '../util/CoreDataConstants';
import { COUNTRY_CODE, COUNTRY_SUBDIVISION } from 'util/CountryCode';
import {
  getFieldDisabledStatusByTwoParams,
  isCooFieldDisabled,
  getValueBasedOnTheCondition,
  getRejectedFeedbackOrPending,
  fetchItemStatus
} from '../util/Util';
import { Button, Table, Tooltip } from 'antd';
import DisabledCooMessage from './DisabledCooMessage';

class ProductInfo extends React.Component {
  constructor() {
    super();
    for (let i = 0; i < 4; i++) {
      this[`feedbackAttributeRef${i}`] = React.createRef();
    }
  }

  handleCancel = () => {
    for (let i = 0; i < 4; i++) {
      this[`feedbackAttributeRef${i}`]?.current?.handleCancel() || console.log();
    }
  };

  formatText = text => text.replaceAll(' ', '_').replaceAll(`'`, '');

  getFeedbackKey = secondStatus => {
    if (secondStatus === 'Discontinued Item')
      return {
        feedbackKey: 'discontinuedItem',
        subTitle: 'Indicate the discontinuation Code.',
        attributeSectionTitle: 'Provide a replacement or suggested item to replace the discontinued item.'
      };
    if (secondStatus === 'Item does not belong to supplier')
      return {
        feedbackKey: 'notBelongsToSupplier',
        subTitle: 'Indicate the reason Code.',
        attributeSectionTitle: 'Provide a reason & the details'
      };
  };

  getDiscontinouedAttributeListBasedOnTheSelection = itemFeedback => {
    const { changers, secondStatus, mainStatus } = itemFeedback || {};
    if (changers && changers.length) {
      const discontinuedData = _.find(changers, eachChange => {
        return (
          eachChange.selected === 'Discontinued Item' || eachChange.selected === 'Item does not belong to supplier'
        );
      });
      if (discontinuedData && discontinuedData.selected) {
        let attributeList;
        const { feedbackKey, subTitle, attributeSectionTitle } = this.getFeedbackKey(discontinuedData.selected);
        if (secondStatus && mainStatus) {
          attributeList = feedback[mainStatus].attributeList[secondStatus];
        }
        if (attributeList && attributeList.length)
          return { discontinuedAttributeList: attributeList, feedbackKey, subTitle, attributeSectionTitle, mainStatus };
      }
    }
    return { mainStatus };
  };

  handleCountryAdd = activityIndex => {
    this.props.actions.addCooCountry({ activityIndex });
  };

  handleCountryDel = (activityIndex, countryIndex) => {
    this.props.actions.deleteCooCountry({ activityIndex, countryIndex });
  };

  handleActivityAdd = () => {
    this.props.actions.addCooActivity({});
  };

  handleActivityDel = activityIndex => {
    this.props.actions.deleteCooActivity({ activityIndex });
  };

  formatCooTableData = ({ cooDatas: datas, basicData: { gdsn, syscoBrand } }) => {
    const activityMap = {};
    CORE_DATA_FIELDS[ACTIVITY_TYPE_KEY].list.forEach(item => {
      activityMap[item.value] = item.label;
    });
    const countryCodeMap = {};
    COUNTRY_CODE.forEach(item => {
      countryCodeMap[item.value] = item.label;
    });
    // const countrySubCodeMap = {};
    // Object.keys(COUNTRY_SUBDIVISION).forEach(key => {
    //   COUNTRY_SUBDIVISION[key].forEach(item => {
    //     countrySubCodeMap[item.value] = item.label;
    //   });
    // });
    const tableData = [];
    let key = 1;
    datas.forEach(item => {
      item.country.forEach(subItem => {
        if (item[ACTIVITY_TYPE_KEY].value || gdsn == 'Y' || syscoBrand == 'Y') {
          tableData.push({
            key,
            activityType: activityMap[item[ACTIVITY_TYPE_KEY].value],
            countryCode: countryCodeMap[subItem[ACTIVITY_CODE_KEY].value],
            subCode: subItem[ACTIVITY_SUBCODE_KEY].value
            // subCode: countrySubCodeMap[subItem[ACTIVITY_SUBCODE_KEY].value]
          });
          key++;
        }
      });
    });
    return tableData;
  };

  render() {
    let {
      item,
      actions,
      pendingComments,
      brand,
      isCoreDataSubmitable,
      pendingOverallFeedbackHistory,
      feeExclusions
    } = this.props;
    const { syscoBrand, gdsn } = item.basicData;
    const {
      discontinuedAttributeList,
      subTitle,
      attributeSectionTitle,
      mainStatus
    } = this.getDiscontinouedAttributeListBasedOnTheSelection(item.feedback);
    const fields = {
      core: [
        { key: 'syy_itm_a_materialunabbreviateddesc', caption: 'Material Unabbreviated Description' },
        { key: 'syy_itm_a_pack', caption: 'Pack' },
        { key: 'syy_itm_a_size', caption: 'Size', hint: '' },
        { key: 'syy_itm_a_sizeuom', caption: 'Size UOM' },
        { key: 'syy_itm_a_catchweight', caption: 'Catch Weight' },
        { key: 'syy_itm_a_containerreqmts', caption: 'Container Requirements' },
        { key: 'syy_itm_a_brandname_calc', caption: 'Brand Name' },
        {
          key: 'syy_itm_a_manufactprodcode',
          caption: 'Manufacturer Product Code',
          gdsnDisabled: true,
          tooltip: 'manufactprodcode'
        },
        { key: 'syy_itm_a_gtin', caption: 'GTIN' }
      ],
      dim: [
        { key: 'syy_itm_a_length', caption: 'Length', gdsnDisabled: true, tooltip: 'length' },
        { key: 'syy_itm_a_width', caption: 'Width', gdsnDisabled: true, tooltip: 'width' },
        { key: 'syy_itm_a_height', caption: 'Height', gdsnDisabled: true, tooltip: 'height' },
        { key: 'syy_itm_a_lwhuom', caption: 'L/W/H UOM', gdsnDisabled: true, tooltip: 'lwhuom' },
        { key: 'syy_itm_a_calc_baseunit', caption: 'Base Unit' },
        { key: 'syy_itm_a_netweight', caption: 'Net Weight', gdsnDisabled: true, tooltip: 'netweight' },
        { key: 'syy_itm_a_grossweight', caption: 'Gross Weight', gdsnDisabled: true, tooltip: 'grossweight' },
        { key: 'syy_itm_a_volume', caption: 'Volume', gdsnDisabled: true, tooltip: 'volume' },
        { key: 'syy_itm_a_vendorstoragecode', caption: 'Supplier Storage Code' },
        {
          key: 'syy_itm_a_vendorstoragetemperaturemin',
          caption: 'Supplier Storage Temperature Min',
          gdsnDisabled: true,
          tooltip: 'vendorstoragetemperaturemin'
        },
        {
          key: 'syy_itm_a_vendorstoragetemperaturemax',
          caption: 'Supplier Storage Temperature Max',
          gdsnDisabled: true,
          tooltip: 'vendorstoragetemperaturemax'
        },
        {
          key: 'syy_itm_a_vendorstoragetemperatureuom',
          caption: 'Supplier Storage Temperature UOM',
          gdsnDisabled: true,
          tooltip: 'vendorstoragetemperatureuom'
        },
        { key: 'syy_itm_a_vendorti', caption: 'Supplier Ti', gdsnDisabled: true, tooltip: 'vendorti' },
        { key: 'syy_itm_a_vendorhi', caption: 'Supplier Hi', gdsnDisabled: true, tooltip: 'vendorhi' },
        { key: 'syy_itm_a_totalshelflife', caption: 'Total Shelf Life', gdsnDisabled: true, tooltip: 'totalshelflife' },
        { key: 'syy_itm_a_hazardous', caption: 'Hazardous' }
      ]
    };

    const itemStatus = fetchItemStatus(pendingComments);

    const columns = [
      {
        title: 'Activity Type',
        dataIndex: 'activityType',
        width: '50px',
        key: 'activityType'
      },
      {
        title: 'Country Code, Country of Activity',
        dataIndex: 'countryCode',
        width: '80px',
        key: 'countryCode'
      },
      {
        title: 'Country of Activity Subdivision Code',
        dataIndex: 'subCode',
        width: '100px',
        key: 'subCode'
      }
    ];

    const cooTableData = this.formatCooTableData(item);

    let errorFields = [];
    if (item && item.coreData && item.coreData.comment) {
      Object.keys(item.coreData.comment).forEach((key, index) => {
        if (
          getFieldDisabledStatusByTwoParams(item.coreData.comment[key].isRejected, item.coreData.comment[key].isFixed)
        )
          errorFields.push(CORE_DATA_FIELDS[key].caption);
      });
    }

    return (
      <ReactResizeDetector
        handleWidth
        handleHeight
        render={({ width, height }) => (
          <div className="attr-panel" style={{ height: window.innerHeight + 'px' }}>
            <div className="attr-section">
              {errorFields.length > 0 && (
                <div className={`pending-feedback-section`}>
                  <div className="comment-row reject no-cursor">
                    <div className="type no-cursor">Core Data Feedback Rejected : </div>
                    <div className="comment no-cursor" style={{ maxWidth: '90%' }}>
                      {errorFields.join(' | ')}
                    </div>
                  </div>
                </div>
              )}
              {getRejectedFeedbackOrPending(pendingOverallFeedbackHistory, pendingComments, { itemStatus })}
              <div className="attr-section-title">Item Status</div>
              <div className="attr-section-subtitle">Update the status of the item.</div>
              {!item.readOnly && (
                <div className="attr-set">
                  <FeedbackAttribute
                    actions={actions}
                    item={item}
                    attribute={feedback.attributeItemStatus}
                    pendingComments={pendingComments}
                    ref={this.feedbackAttributeRef1}
                    pendingOverallFeedbackHistory={pendingOverallFeedbackHistory}
                    feeExclusions={feeExclusions}
                  />
                </div>
              )}
              {!item.readOnly && item.feedback && mainStatus && item.feedback.mainStatus === mainStatus && (
                <div>
                  <div className="attr-section-subtitle">{subTitle}</div>
                  <div className="attr-set">
                    <FeedbackAttribute
                      actions={actions}
                      item={item}
                      attribute={feedback[mainStatus]}
                      pendingComments={pendingComments}
                      ref={this.feedbackAttributeRef2}
                      pendingOverallFeedbackHistory={pendingOverallFeedbackHistory}
                    />
                  </div>
                  {discontinuedAttributeList && (
                    <>
                      <div className="attr-section-subtitle">{attributeSectionTitle}</div>
                      <div className="attr-set">
                        {discontinuedAttributeList.map(attribute => (
                          <FeedbackAttribute
                            key={attribute.id}
                            actions={actions}
                            item={item}
                            attribute={attribute}
                            pendingComments={pendingComments}
                            ref={this.feedbackAttributeRef3}
                            isDisabled={isCoreDataSubmitable}
                            pendingOverallFeedbackHistory={pendingOverallFeedbackHistory}
                          />
                        ))}
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>

            <div className="attr-section">
              <div className="attr-section-title">Core Data</div>
              <div className="attr-section-subtitle">Please review the fields below.</div>
              <div className="attr-set">
                {fields.core.map(field => (
                  <TextAttribute
                    key={field.key}
                    item={item}
                    field={field}
                    actions={actions}
                    brand={brand}
                    pendingComments={pendingComments}
                  />
                ))}
              </div>
            </div>

            <div className="attr-section">
              <div className="attr-section-title">Measurements</div>
              <div className="attr-section-subtitle">Please review the fields below.</div>
              <div className="attr-set">
                {fields.dim.map(field => (
                  <TextAttribute
                    key={field.key}
                    item={item}
                    field={field}
                    actions={actions}
                    brand={false}
                    pendingComments={pendingComments}
                  />
                ))}
                {/* <div className="attr-wrapper" style={{ height: '130px' }}></div> */}
              </div>
            </div>

            <div className="attr-section">
              <DisabledCooMessage gdsn={gdsn === 'Y'} syscoBrand={syscoBrand === 'Y'} />
              <div className="attr-section-title">Country of Origin</div>
              {/* <div className="attr-section-subtitle">Please review the fields below.</div> */}
              <Spin tip="Loading..." spinning={item.fetchingCooData}>
                <div className="origin-tbl">
                  <div className="activity-detail-header" style={{ marginBottom: '10px', marginTop: '-20px' }}>
                    Place of Activity (Country of Origin)
                  </div>
                  <Table columns={columns} dataSource={cooTableData} size="small" pagination={false} bordered />
                </div>
                <div className="activity-detail-header">Product Activity Details</div>
                <div className="attr-set">
                  {item.cooDatas.map((activity, activityArrIndex) => {
                    let fields = [];
                    Object.keys(activity).forEach((key, keyIndex) => {
                      if (key === 'country') {
                        activity[key].forEach((countryField, countryArrIndex) => {
                          fields.push(
                            <TextCooAttribute
                              key={`${countryField[ACTIVITY_CODE_KEY].key}-${activityArrIndex}-${countryArrIndex}`}
                              item={item}
                              field={countryField[ACTIVITY_CODE_KEY]}
                              actions={actions}
                              activityIndex={activityArrIndex}
                              countryIndex={countryArrIndex}
                            />
                          );
                          fields.push(
                            <TextCooAttribute
                              key={`${countryField[ACTIVITY_SUBCODE_KEY].key}-${activityArrIndex}-${countryArrIndex}`}
                              item={item}
                              field={countryField[ACTIVITY_SUBCODE_KEY]}
                              actions={actions}
                              activityIndex={activityArrIndex}
                              countryIndex={countryArrIndex}
                            />
                          );
                          if (activity[key].length > 1) {
                            fields.push(
                              <Tooltip
                                key={`delCountry_${activityArrIndex}_${countryArrIndex}`}
                                title={
                                  <span style={{ paddingLeft: '15px', paddingRight: '15px' }}>Delete Country</span>
                                }
                                placement="topRight"
                              >
                                <Button
                                  className="minus-btn"
                                  icon="minus-square"
                                  onClick={() => this.handleCountryDel(activityArrIndex, countryArrIndex)}
                                  disabled={isCooFieldDisabled(item)}
                                />
                              </Tooltip>
                            );
                          }
                        });
                        fields.push(
                          <div
                            key={`addCountry_${activityArrIndex}`}
                            className={`country-add ${getValueBasedOnTheCondition(
                              isCooFieldDisabled(item),
                              'disabled-content',
                              undefined
                            )}`}
                          >
                            <span>Add Country</span>
                            <Button
                              icon="plus-square"
                              onClick={() => this.handleCountryAdd(activityArrIndex)}
                              disabled={isCooFieldDisabled(item)}
                            />
                          </div>
                        );
                      } else if (
                        key === 'product_activity_type_code' ||
                        key === 'product_activity_region_description'
                      ) {
                        fields.push(
                          <TextCooAttribute
                            key={`${activity[key].key}-${activityArrIndex}`}
                            item={item}
                            field={activity[key]}
                            actions={actions}
                            activityIndex={activityArrIndex}
                          />
                        );
                        if (item.cooDatas.length >= 2 && key === REGION_DESC_KEY) {
                          fields.push(
                            <Tooltip
                              key={`delType_${activityArrIndex}`}
                              title={
                                <span style={{ paddingLeft: '15px', paddingRight: '15px' }}>Delete Activity Type</span>
                              }
                              placement="topRight"
                            >
                              <Button
                                className="minus-btn"
                                icon="minus-square"
                                onClick={() => this.handleActivityDel(activityArrIndex)}
                                disabled={isCooFieldDisabled(item)}
                              />
                            </Tooltip>
                          );
                        }
                      }
                    });
                    return [...fields];
                  })}

                  <div
                    className={`activity-type-add ${getValueBasedOnTheCondition(
                      isCooFieldDisabled(item),
                      'disabled-content',
                      undefined
                    )}`}
                  >
                    <span>Add Activity Type</span>
                    <Button icon="plus-square" onClick={this.handleActivityAdd} disabled={isCooFieldDisabled(item)} />
                  </div>
                </div>
              </Spin>
              <div className="attr-wrapper" style={{ height: '130px' }}></div>
            </div>
          </div>
        )}
      />
    );
  }
}

export default ProductInfo;
