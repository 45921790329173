import React from 'react';

function AppLoader({show}) {
  return (
    <div className={show ? 'app-loader show' : 'app-loader'}>
        <svg className="loading-spinner" viewBox="0 0 50 50">
            <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"> </circle>
        </svg>
    </div>
  );
}

export default AppLoader;
