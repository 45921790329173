import React from 'react';
import { Tag } from 'antd';

const RejectFeedback = ({ field, nutritionData }) => {
  const { feedback } = nutritionData;
  if (
    feedback &&
    feedback.status === 'REJECT' &&
    feedback.comments &&
    Object.keys(feedback.comments).length &&
    field &&
    feedback.comments[field]
  ) {
    const reason = feedback.comments[field];
    return (
      <div className="enut-reject-feedback feedback-text">
        <Tag
          style={{
            color: '#f5222d',
            backgroundColor: '#fff7e6',
            borderColor: '#ffa39e'
          }}
          color="warning"
          title={reason}
        >
          Reject
        </Tag>
        : <span className="feedback-text">{reason}</span>
      </div>
    );
  }
  return null;
};

export default RejectFeedback;
