import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import AppLoader from '../components/AppLoader';
import * as batchesActions from 'actions';
import ItemView from 'components/ItemView';
import _ from 'lodash';
import ReactResizeDetector from 'react-resize-detector';
import { Table, Pagination } from 'antd';
import { GDSN_VALUE } from 'util/Constants';
import ItemScores from './../components/ItemScores';

function FreeItemsListPage({
  actions,
  selectedItem,
  itemsFetching,
  suvc,
  list,
  fieldComment,
  pendingComments,
  page,
  recordCount,
  handlePagination,
  summaryItems
}) {
  if (itemsFetching) {
    return <AppLoader show />;
  }

  const renderBrandsColumn = (brandName, item) => {
    let symbol = '';
    let symbolTitle = '';

    if (item.brandCode === 'PACKER') {
      symbol = 'P';
      symbolTitle = 'Packer Brand';
    } else if (item.syscoBrand === 'Y') {
      symbol = 'S';
      symbolTitle = 'Sysco Brand';
    }

    return (
      <div className="ellipsed brands-cell" title={brandName}>
        {!_.isEmpty(symbol) && (
          <div className={`brand-indicator ${symbol.toLowerCase()}brand`} title={symbolTitle}>
            {symbol}
          </div>
        )}
        <div className="brand-name">{brandName}</div>
      </div>
    );
  };

  let columns = [
    {
      title: 'SUPC',
      dataIndex: 'supc',
      key: 'supc',
      width: 100
    },
    {
      title: (
        <div className="list-item-scores score-header">
          <div className="list-item-score overall">
            <span>Total</span>
          </div>
          <div className="list-item-score">
            <span title="Attribution">Attrib...</span>
          </div>
          <div className="list-item-score">
            <span>FnB</span>
          </div>
          <div className="list-item-score">
            <span>Images</span>
          </div>
        </div>
      ),
      dataIndex: 'supc',
      key: 'supc2',
      width: 80,
      render: supc => <ItemScores supc={supc} summaryItems={summaryItems} />,
      align: 'right'
    },
    {
      title: 'Description',
      dataIndex: 'materialDescription',
      key: 'materialDescription',
      width: 'auto',
      render: desc => (
        <div>
          <span>{desc && desc.toUpperCase()}</span>
        </div>
      )
    },
    {
      title: 'Brand',
      dataIndex: 'brandName',
      key: 'brandName',
      width: 130,
      render: (brandName, item) => renderBrandsColumn(brandName, item)
    },
    {
      title: 'True Vendor',
      dataIndex: 'suvc',
      key: 'suvc',
      width: 180,
      render: (suvc, item) => (
        <div className="ellipsed allcap" title={`${suvc} - ${item.trueVendorName}`}>
          {`${suvc} - ${item.trueVendorName}`}
        </div>
      )
    },
    {
      title: 'MPC',
      dataIndex: 'manufactProdCode',
      key: 'manufactProdCode',
      width: 160
    },
    {
      title: 'GDSN',
      dataIndex: 'gdsn',
      key: 'gdsn',
      width: 60,
      render: gdsn => <span>{gdsn === GDSN_VALUE ? '✓' : ''}</span>,
      align: 'center'
    },
    {
      title: 'GTIN',
      dataIndex: 'gtin',
      key: 'gtin',
      width: 180
    }
  ];

  const onRowHandler = item => {
    actions.itemDetails({ stepId: item.stepId, supc: item.supc, item, suvc: item.suvc });
    actions.itemCooDetails({ supc: item.supc, syscoBrand: item.syscoBrand, gdsn: item.gdsn });
  };

  selectedItem.readOnly = true;

  return (
    <React.Fragment>
      {itemsFetching && <AppLoader show />}
      {selectedItem && selectedItem.supc && (
        <ItemView
          suvc={suvc}
          item={selectedItem}
          actions={actions}
          fieldComment={fieldComment}
          pendingComments={pendingComments}
        />
      )}
      <ReactResizeDetector
        handleWidth
        handleHeight
        render={({ width, height }) => (
          <div className="grid-panel items-list-table">
            <Table
              className="items-list-tbl"
              columns={columns}
              dataSource={list}
              size="small"
              pagination={false}
              onRowClick={onRowHandler}
            />
            <Pagination
              size="small"
              className="grid-pagination"
              onChange={handlePagination}
              total={recordCount}
              showTotal={total => `Total ${total} items`}
              current={page}
              defaultPageSize={50}
              hideOnSinglePage
            />
          </div>
        )}
      />
    </React.Fragment>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user.data,
    userPermissions: state.user.data.permissions,
    selectedItem: state.selectedItem,
    itemsFetching: state.vendor.fetching || state.selectedItem.fetching || state.vendor.list == null,
    listFetching: state.vendor.fetching,
    list: state.vendor.list,
    suvc: ownProps.isInternal ? state.vendor.selectedSuvc : state.vendor.suvc,
    handlePagination: ownProps.handlePagination,
    fieldComment: state.fieldComment,
    pendingComments: state.pendingComments,
    page: state.page.page,
    search: state.page.search,
    subSuvc: state.page.subSuvc,
    recordCount: state.page.recordCount,
    suvcDetails: state.vendor.details,
    scorecards: state.scorecards,
    summaryItems: state.scorecards.summaryItems,
    graphFilter: state.page.graphfilter,
    rejectedFnbs: state.vendor.rejectedFnbs,
    rejectedImages: state.vendor.rejectedImages,
    overallSummary: state.scorecards.overallSummary,
    fetchingOverallSummary: state.scorecards.fetchingOverallSummary,
    fnbRejectFilterOn: state.vendor.fnbRejectFilterOn,
    autoSavedFnb: state.vendor.autoSavedFnb,
    imageRejectFilterOn: state.vendor.imageRejectFilterOn,
    feeFilterOn: state.vendor.feeFilterOn,
    isAutoSavedFnbFilterOn: state.vendor.isAutoSavedFnbFilterOn,
    tableFilters: state.vendor.tableFilters,
    tableSearchData: state.vendor.tableSearchData,
    brandGroupFilters: state.vendor.brandGroupFilters,
    orderBy: state.vendor.orderBy,
    subSuvcFilters: state.vendor.details.subSuvcs,
    isFiltersComplex: state.vendor.isFiltersComplex,
    feeExclusionsSupcs: state.vendor.feeExclusionsSupcs
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(batchesActions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FreeItemsListPage));

FreeItemsListPage.propTypes = {};
