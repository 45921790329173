import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';

import * as actionsBatch from 'actions';
import NutritionsForm from './NutritionsForm';

class NutritionTab extends Component {
  render() {
    const {
      nutritions,
      suvc,
      supc,
      stepId,
      fetchingNutritions,
      updatingNutritions,
      readOnly,
      innerRef,
      actions,
      sendingNutritionQuestions,
      email,
      isNutritionDetailsNotApplicable,
      updatingNutritionsApplicableStatus,
      fetchingNutritionsApplicableStatus,
      nutritionsApplicableStatusError,
      pendingNutritions,
      rejectedNutritions,
      cancelingNutritionUpdate,
      trueVendorName,
      gln,
      gtin,
      materialDescription,
      isItemReadOnly,
      gdsn,
      syscoBrand,
      pendingNutrition,
      item
    } = this.props;

    if (nutritionsApplicableStatusError) {
      return (
        <div className="nutrition-tab nt-loading-wrapper">
          <div className="nt-loading">Something went wrong! Please refresh the page and try again.</div>
        </div>
      );
    }
    if (fetchingNutritionsApplicableStatus || fetchingNutritions) {
      return (
        <div className="nutrition-tab nt-loading-wrapper">
          <div className="nt-loading">Loading...</div>
        </div>
      );
    }
    if (!_.isEmpty(nutritions)) {
      return (
        <NutritionsForm
          ref={innerRef}
          suvc={suvc}
          supc={supc}
          email={email}
          stepId={stepId}
          nutritions={nutritions}
          readOnly={readOnly}
          actions={actions}
          updatingNutritions={updatingNutritions}
          sendNutritions={actions.sendNutritions}
          sendingNutritionQuestions={sendingNutritionQuestions}
          isNutritionDetailsNotApplicable={isNutritionDetailsNotApplicable}
          updatingNutritionsApplicableStatus={updatingNutritionsApplicableStatus}
          pendingNutritions={pendingNutritions}
          rejectedNutritions={rejectedNutritions}
          cancelingNutritionUpdate={cancelingNutritionUpdate}
          trueVendorName={trueVendorName}
          gln={gln}
          gtin={gtin}
          materialDescription={materialDescription}
          isItemReadOnly={isItemReadOnly}
          gdsn={gdsn}
          syscoBrand={syscoBrand}
          pendingNutrition={pendingNutrition}
          nutritionValuesChanged={actions.nutritionValuesChanged}
          updateNutritionErrors={actions.updateNutritionFieldErrors}
          item={item}
        />
      );
    }
    return (
      <div className="nutrition-tab nt-loading-wrapper">
        <div className="nt-loading">No nutriton data found</div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    suvc: state.selectedItem.suvc,
    supc: state.selectedItem.supc,
    email: state.user.data.email,
    stepId: _.get(state.selectedItem.basicData, 'stepId', null),
    nutritions: state.selectedItem.nutritions,
    fetchingNutritions: state.selectedItem.fetchingNutritions,
    updatingNutritions: state.selectedItem.updatingNutritions,
    fetchingOsdAttributes: state.selectedItem.pending,
    sendingNutritionQuestions: state.selectedItem.sendingNutritionQuestions,
    isNutritionDetailsNotApplicable: state.selectedItem.isNutritionDetailsNotApplicable,
    updatingNutritionsApplicableStatus: state.selectedItem.updatingNutritionsApplicableStatus,
    fetchingNutritionsApplicableStatus: state.selectedItem.fetchingNutritionsApplicableStatus,
    nutritionsApplicableStatusError: state.selectedItem.nutritionsApplicableStatusError,
    pendingNutritions: state.selectedItem.pendingNutritions,
    rejectedNutritions: state.selectedItem.rejectedNutritions,
    cancelingNutritionUpdate: state.selectedItem.cancelingNutritionUpdate,
    trueVendorName: _.get(state.selectedItem, 'basicData.trueVendorName', null),
    gln: _.get(state.selectedItem, 'basicData.gln', null),
    gtin: _.get(state.selectedItem, 'basicData.gtin', null),
    materialDescription: _.get(state.selectedItem, 'basicData.materialDescription', null),
    isItemReadOnly: state.selectedItem.readOnly,
    gdsn: _.get(state.selectedItem, 'basicData.gdsn', null),
    syscoBrand: _.get(state.selectedItem, 'basicData.syscoBrand', null),
    hasNutritionDataBeenFetched: state.selectedItem.isNutritionDataHasBeenFetched,
    pendingNutrition: state.selectedItem.pendingNutrition,
    item: state.selectedItem
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(actionsBatch, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(NutritionTab);
