import React, { forwardRef, useEffect } from 'react';

import PendingThirdpartyUniversal from './PendingThirdpartyUniversal';

const ThirdpartyUniversalExpanded = (
  {
    commentData,
    onAttributeChange,
    onRejectAllModalOpen,
    onConfirmRejectAll,
    onCancelRejectAll,
    isAllRejectModalOpen,
    getItemDetails
  },
  ref
) => {
  const { comment, id, status, supc } = commentData;
  const updatedComment = JSON.parse(comment);

  useEffect(() => {
    getItemDetails({ supc, id });
  }, [supc, id, getItemDetails]);

  const handleAttributeChange = data => {
    onAttributeChange({ ...data });
  };

  return (
    <div className="row-expand" style={{ display: 'flex' }}>
      <div className="image-expand-info-table">
        <PendingThirdpartyUniversal
          updatedComment={updatedComment}
          id={id}
          handleAttributeChange={handleAttributeChange}
          status={status}
          onRejectAllModalOpen={() => onRejectAllModalOpen()}
          onConfirmRejectAll={onConfirmRejectAll}
          onCancelRejectAll={onCancelRejectAll}
          isAllRejectModalOpen={isAllRejectModalOpen}
        />
      </div>
    </div>
  );
};

export default forwardRef(ThirdpartyUniversalExpanded);
