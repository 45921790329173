import React from 'react';
import { Table, Select, Input } from 'antd';
import _ from 'lodash';
import {
  getFormat,
  getOtmmBaseUrl,
  formatBytes,
  getStyleCode,
  getImageVirusScanStatus,
  getValueBasedOnTheCondition,
  getFieldDisabledStatusByTwoParams,
  checkObjectAndKeyExists,
  checkArrayAndLength,
  handleImageOnClick,
  getValuesFromTwoFields,
  updateMetaContent,
  getClassNameFromConditions,
  checkStatusWithFourOrConditions,
  getUppdateCaseValueOrNull
} from '../util/Util';

import { REJECTED_REASON_CODES, DEFAULT_STATUS, REJECT, FIXED } from '../util/Constants';
import { imageStyles } from '../util/Data';

const { Option } = Select;
const { TextArea } = Input;
const REJECT_OPTIONS = [DEFAULT_STATUS, REJECT, FIXED];

const INNER_WIDTH = window.innerWidth;
const SMALL_DEVICE = INNER_WIDTH <= 1517;

class CommentExpandedImage extends React.Component {
  constructor(props) {
    super(props);
    let reasonCode = REJECTED_REASON_CODES[0].code;
    let status = null;
    const { imageComment } = props.comment;

    if (_.get(imageComment, 'review.status', null)) {
      status = imageComment.review.status;
    } else if (_.get(imageComment, 'previous.review.status', null)) {
      status = imageComment.previous.review.status;
    }

    if (_.get(imageComment, 'review.code', null)) {
      reasonCode = imageComment.review.code;
    }
    this.state = {
      status,
      reasonCode,
      comment: _.get(imageComment, 'review.comment', ''),
      changedStyle: null,
      savedChangeStyle: null
    };
  }

  getStyleFromComment = comment => {
    if (comment && comment.imageComment && comment.imageComment.style) return comment.imageComment.style;
    return comment.style;
  };

  getImageStyleValue = (changedStyle, savedChangeStyle, comment, style) => {
    return (changedStyle || savedChangeStyle || this.getStyleFromComment(comment) || style).toUpperCase();
  };

  getDefaultImageStyleValue = (comment, style) => {
    return (this.getStyleFromComment(comment) || style || '').toUpperCase();
  };

  renderStatusColumn = () => {
    const defaultValue = this.state.status ? this.state.status : REJECT_OPTIONS[0];
    return (
      <Select
        disabled={getValuesFromTwoFields(this.props.comment.status === 30, this.props.isUpdating)}
        defaultValue={defaultValue}
        style={{ width: '100%' }}
        onChange={value => this.onChangeStatus(value)}
        className="image-feedback-dropdown"
      >
        {REJECT_OPTIONS.map(option => {
          let disabled = false;

          if (
            (option === DEFAULT_STATUS && this.props.comment.status !== 0) ||
            (option === FIXED && this.props.comment.status !== 20)
          ) {
            disabled = true;
          }

          return (
            <Option key={option} value={option} disabled={disabled}>
              {option}
            </Option>
          );
        })}
      </Select>
    );
  };

  renderReasonColumn = () => {
    if (this.state.status === REJECT) {
      const target = _.find(REJECTED_REASON_CODES, obj => this.state.reasonCode === obj.code);
      const defaultValue = target.name;

      return (
        <Select
          disabled={this.props.comment.status === 30 || this.props.isUpdating}
          defaultValue={defaultValue}
          dropdownMatchSelectWidth={false}
          style={{ width: '100%' }}
          onChange={value => this.onChangeReason(value)}
          className="image-feedback-dropdown"
        >
          {REJECTED_REASON_CODES.map(obj => {
            return (
              <Option key={obj.code} value={obj.code}>
                {obj.name}
              </Option>
            );
          })}
        </Select>
      );
    }
    if (this.state.status === FIXED) {
      const { imageComment } = this.props.comment;
      const prevCode = _.get(imageComment, 'previous.review.code', null);
      const target = _.find(REJECTED_REASON_CODES, obj => prevCode === obj.code);

      return <div>{_.get(target, 'name', '')}</div>;
    }
  };

  renderCommentColumn = () => {
    if (this.state.status === REJECT) {
      return (
        <TextArea
          // autoSize={{ minRows: 3, maxRows: 8 }}
          defaultValue={this.state.comment}
          rows={4}
          disabled={this.props.comment.status === 30 || this.props.isUpdating}
          onChange={e => this.onChangeComment(e.target.value)}
        />
      );
    }
    if (this.state.status === FIXED) {
      const { imageComment } = this.props.comment;
      const prevComment = _.get(imageComment, 'previous.review.comment', '');

      return <div>{prevComment}</div>;
    }
  };

  onChangeStyle = style => {
    this.setState({ changedStyle: style });
  };

  onChangeStatus = value => {
    if (value === REJECT) {
      this.setState({ status: REJECT, reasonCode: REJECTED_REASON_CODES[0].code, comment: '' });
      this.props.addImageCommentToRejectState(this.props.comment.id);
    } else if (value === FIXED) {
      this.setState({ status: FIXED, reasonCode: null, comment: '' });
      this.props.removeImageCommentFromRejectState(this.props.comment.id);
    } else {
      this.setState({ status: null, reasonCode: null, comment: '' });
      this.props.removeImageCommentFromRejectState(this.props.comment.id);
    }
  };

  onChangeReason = code => {
    this.setState({ reasonCode: code });
  };

  onChangeComment = comment => {
    this.setState({ comment });
  };

  getUpdatedImageData = () => {
    return {
      status: this.state.status,
      code: this.state.reasonCode,
      comment: this.state.comment
    };
  };

  render() {
    const { comment } = this.props;
    let dataSource = [];
    let columns = [];
    const { imageComment } = comment;

    let meta = {
      height: null,
      mime: '',
      name: '',
      reservation_id: '',
      rgba: true,
      size: null,
      style: '',
      supc: '',
      url: '',
      width: null
    };

    meta = updateMetaContent(meta, imageComment);

    const getAwsImageUrlByRes = () => {
      return `${getOtmmBaseUrl()}/otmmstream/s3image/${imageComment.reservationId}.${getFormat(meta.mime)}`;
    };

    const getVirusCaption = () => {
      return getImageVirusScanStatus(comment.virusStatus);
    };

    const getRenditionUrl = type => {
      type = getFieldDisabledStatusByTwoParams(type, 'preview');
      const order = {
        master: ['otmmMasterRenditionId', 'otmmPreviewRenditionId', 'otmmThumbnailRenditionId'],
        preview: ['otmmPreviewRenditionId', 'otmmThumbnailRenditionId', 'otmmMasterRenditionId']
      };
      for (let i = 0; i < order[type].length; i++) {
        if (checkArrayAndLength(imageComment[order[type][i]])) {
          return `${getOtmmBaseUrl()}/otmmstream/productimage/${imageComment[order[type][i]]}`;
        }
      }
      return '';
    };

    const renderEditColumn = (text, record) => {
      const { isEditable } = record;
      const { changedStyle } = this.state;

      const cancelHandler = () => {
        this.onChangeComment({ ...comment, isEditable: false });
        this.setState({ changedStyle: null });
      };

      return (
        <div className="editable-row-operations right">
          {isEditable ? (
            <div>
              <span
                className="btn-fnb"
                style={{ marginRight: '5px' }}
                onClick={() => {
                  const {
                    comment: {
                      imageComment: { changeType, meta }
                    }
                  } = this.state;
                  this.onChangeComment({ ...comment, style: changedStyle, isEditable: false });
                  this.setState({
                    savedChangeStyle: changedStyle
                  });
                  let metaName = '';
                  if (checkObjectAndKeyExists(meta, 'name')) {
                    let changeNeedsIndex = meta.name.lastIndexOf('_');
                    changeNeedsIndex += 4;
                    metaName =
                      meta.name.substring(0, changeNeedsIndex) +
                      getStyleCode(changedStyle) +
                      meta.name.substring(changeNeedsIndex + 1);
                  }
                  this.props.handlePostStyleChange({
                    ...comment,
                    style: changedStyle,
                    changeType,
                    meta: { ...meta, name: metaName, style: changedStyle }
                  });
                }}
              >
                Save
              </span>
              <span className="btn-fnb" onClick={cancelHandler}>
                Cancel
              </span>
            </div>
          ) : (
            <span
              className={`btn-fnb ${getClassNameFromConditions(
                this.props.comment.status === 30,
                'disabled-btn-fnb',
                ''
              )}`}
              style={{ marginLeft: '5px' }}
              onClick={() => {
                this.onChangeComment({ ...comment, isEditable: true });
              }}
            >
              Edit
            </span>
          )}
        </div>
      );
    };

    const renderImageStyleColumn = (text, data) => {
      const { style, isEditable } = data;
      const { changedStyle, savedChangeStyle } = this.state;
      return (
        <div>
          <Select
            disabled={!isEditable}
            dropdownMatchSelectWidth={false}
            defaultValue={this.getDefaultImageStyleValue(comment, style)}
            style={{ width: '100%' }}
            onChange={value => this.onChangeStyle(value)}
            value={this.getImageStyleValue(changedStyle, savedChangeStyle, comment, style)}
            className="image-feedback-dropdown"
          >
            {imageStyles.map(option => {
              return (
                <Option key={option.toUpperCase()} value={option.toUpperCase()}>
                  {option.toUpperCase()}
                </Option>
              );
            })}
          </Select>
          {renderEditColumn(text, data)}
        </div>
      );
    };

    switch (imageComment.changeType) {
      case 'DELETE_REPLACE': {
        columns = [
          {
            title: 'Change Type',
            dataIndex: 'changetype',
            key: 'changetype',
            width: 150
          },
          {
            title: 'Delete Image',
            dataIndex: 'image',
            key: 'image',
            render: () => (
              <div
                onClick={() => {
                  window.open(getRenditionUrl('master'));
                }}
                className="comment-expand-image"
                style={{
                  backgroundImage: `url("${getRenditionUrl()}")`
                }}
              ></div>
            ),
            width: 200
          },
          {
            title: `New Image (${imageComment.style.toUpperCase()})`,
            dataIndex: 'image',
            key: 'image',
            render: () => (
              <div
                onClick={() => {
                  handleImageOnClick(getVirusCaption, getAwsImageUrlByRes);
                }}
                className={`comment-expand-image ${getValueBasedOnTheCondition(
                  getVirusCaption().length > 0,
                  'pending',
                  ''
                )}`}
                style={{
                  backgroundImage: getValueBasedOnTheCondition(
                    getVirusCaption().length === 0,
                    `url("${getAwsImageUrlByRes()}")`,
                    'none'
                  )
                }}
              >
                {getVirusCaption()}
              </div>
            ),
            width: 200
          },
          {
            title: 'Properties',
            dataIndex: 'info',
            key: 'info',
            render: () => (
              <div className="image-info-data">
                <div className="row">
                  <div>Name</div>
                  <div title={meta.name}>{meta.name}</div>
                </div>
                <div className="row">
                  <div>MIME</div>
                  <div title={meta.name}>{meta.mime}</div>
                </div>
                <div className="row">
                  <div>Color Mode </div>
                  <div title={getValueBasedOnTheCondition(meta.rgba, 'RGB', '')}>
                    {getValueBasedOnTheCondition(meta.rgba, 'RGB', '')}
                  </div>
                </div>
                <div className="row">
                  <div>Dimension </div>
                  <div>{`${meta.width}x${meta.height}`}</div>
                </div>
                <div className="row">
                  <div>Size </div>
                  <div>{formatBytes(meta.size, 1)}</div>
                </div>
              </div>
            )
          },
          {
            title: 'Status',
            width: getValueBasedOnTheCondition(SMALL_DEVICE, 110, 150),
            render: () => this.renderStatusColumn()
          },
          {
            title: 'Reject Reason',
            width: getValueBasedOnTheCondition(SMALL_DEVICE, 150, 210),
            render: () => this.renderReasonColumn()
          },
          {
            title: 'Description (Optional)',
            width: 'auto',
            render: () => this.renderCommentColumn()
          }
        ];
        const {
          comment: { style, isEditable },
          changedStyle
        } = this.state;
        dataSource = [
          {
            image: '',
            changetype: 'Delete & Replace',
            info: '',
            style: checkStatusWithFourOrConditions(changedStyle, getUppdateCaseValueOrNull(imageComment.style), style),
            isEditable: getValuesFromTwoFields(isEditable, false)
          }
        ];
        break;
      }
      case 'CREATE': {
        columns = [
          {
            title: 'Change Type',
            dataIndex: 'changetype',
            key: 'changetype',
            width: 150
          },
          {
            title: 'Image Style',
            dataIndex: 'style',
            key: 'style',
            width: 150,
            render: (text, record) => renderImageStyleColumn(text, record)
          },
          {
            title: 'Image',
            dataIndex: 'image',
            key: 'image',
            render: () => (
              <div
                onClick={() => {
                  handleImageOnClick(getVirusCaption, getAwsImageUrlByRes);
                }}
                className={`comment-expand-image ${getValueBasedOnTheCondition(
                  getVirusCaption().length > 0,
                  'pending',
                  ''
                )}`}
                style={{
                  backgroundImage: getValueBasedOnTheCondition(
                    getVirusCaption().length === 0,
                    `url("${getAwsImageUrlByRes()}")`,
                    'none'
                  )
                }}
              >
                {getVirusCaption()}
              </div>
            ),
            width: 200
          },
          {
            title: 'Properties',
            dataIndex: 'info',
            key: 'info',
            render: () => (
              <div className="image-info-data">
                <div className="row">
                  <div>Name</div>
                  <div title={meta.name}>{meta.name}</div>
                </div>
                <div className="row">
                  <div>MIME</div>
                  <div title={meta.name}>{meta.mime}</div>
                </div>
                <div className="row">
                  <div>Color Mode </div>
                  <div title={getValueBasedOnTheCondition(meta.rgba, 'RGB', '')}>
                    {getValueBasedOnTheCondition(meta.rgba, 'RGB', '')}
                  </div>
                </div>
                <div className="row">
                  <div>Dimension </div>
                  <div>{`${meta.width}x${meta.height}`}</div>
                </div>
                <div className="row">
                  <div>Size </div>
                  <div>{formatBytes(meta.size, 1)}</div>
                </div>
              </div>
            )
          },
          {
            title: 'Status',
            width: getValueBasedOnTheCondition(SMALL_DEVICE, 110, 150),
            render: () => this.renderStatusColumn()
          },
          {
            title: 'Reject Reason',
            width: getValueBasedOnTheCondition(SMALL_DEVICE, 150, 210),
            render: () => this.renderReasonColumn()
          },
          {
            title: 'Description (Optional)',
            width: 'auto',
            render: () => this.renderCommentColumn()
          }
        ];
        const {
          comment: { style, isEditable },
          changedStyle
        } = this.state;
        dataSource = [
          {
            image: '',
            changetype: 'New Image Upload',
            info: '',
            style: checkStatusWithFourOrConditions(changedStyle, getUppdateCaseValueOrNull(imageComment.style), style),
            isEditable: getValuesFromTwoFields(isEditable, false)
          }
        ];
        break;
      }
      case 'DELETE': {
        columns = [
          {
            title: 'Change Type',
            dataIndex: 'changetype',
            key: 'changetype',
            width: 150
          },
          {
            title: 'Image Style',
            dataIndex: 'style',
            key: 'style',
            width: 150
          },
          {
            title: 'Image',
            dataIndex: 'image',
            key: 'image',
            render: () => (
              <div
                onClick={() => {
                  window.open(getRenditionUrl('master'));
                }}
                className="comment-expand-image"
                style={{
                  backgroundImage: `url("${getRenditionUrl()}")`
                }}
              ></div>
            ),
            width: 200
          },
          {
            title: 'Status',
            width: getValueBasedOnTheCondition(SMALL_DEVICE, 110, 150),
            render: () => this.renderStatusColumn()
          },
          {
            title: 'Reject Reason',
            width: getValueBasedOnTheCondition(SMALL_DEVICE, 150, 210),
            render: () => this.renderReasonColumn()
          },
          {
            title: 'Description (Optional)',
            width: getValueBasedOnTheCondition(SMALL_DEVICE, 330, 440),
            render: () => this.renderCommentColumn()
          }
        ];
        dataSource = [
          {
            image: '',
            changetype: 'Image Deletion',
            style: getValueBasedOnTheCondition(imageComment.style, imageComment.style.toUpperCase(), '')
          }
        ];
        break;
      }
      case 'STYLE_CHANGE': {
        columns = [
          {
            title: 'Change Type',
            dataIndex: 'changetype',
            key: 'changetype',
            width: 150
          },
          {
            title: 'Current Style',
            dataIndex: 'currStyle',
            key: 'currStyle',
            width: 200
          },
          {
            title: 'New Style',
            // dataIndex: 'style',
            // key: 'style',
            width: 200,
            render: (text, record) => renderImageStyleColumn(text, record)
          },
          {
            title: 'Image',
            dataIndex: 'image',
            key: 'image',
            render: () => (
              <div
                onClick={() => {
                  window.open(getRenditionUrl('master'));
                }}
                className="comment-expand-image"
                style={{
                  backgroundImage: `url("${getRenditionUrl()}")`
                }}
              ></div>
            ),
            width: 200
          },
          {
            title: 'Status',
            width: getValueBasedOnTheCondition(SMALL_DEVICE, 110, 150),
            render: () => this.renderStatusColumn()
          },
          {
            title: 'Reject Reason',
            width: getValueBasedOnTheCondition(SMALL_DEVICE, 150, 210),
            render: () => this.renderReasonColumn()
          },
          {
            title: 'Description (Optional)',
            width: 'auto',
            render: () => this.renderCommentColumn()
          }
        ];
        const {
          comment: { isEditable, style },
          changedStyle
        } = this.state;
        dataSource = [
          {
            image: '',
            changetype: 'Style Change',
            style: checkStatusWithFourOrConditions(
              changedStyle,
              imageComment.style && imageComment.style.toUpperCase(),
              style
            ),
            currStyle: getValueBasedOnTheCondition(comment.originalValue, comment.originalValue.toUpperCase(), ''),
            isEditable: getValuesFromTwoFields(isEditable, false)
          }
        ];
        break;
      }
      default:
        break;
    }
    return (
      <div className="row-expand" style={{ display: 'flex' }}>
        <div className="image-expand-info-table">
          <Table dataSource={dataSource} columns={columns} pagination={false} />
        </div>
      </div>
    );
  }
}

export default CommentExpandedImage;
